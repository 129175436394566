import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, TemplateRef } from '@angular/core';

import { AdminUserRolesService } from 'src/app/services/admin-user-roles.service';
import { AdminUsersService } from 'src/app/services/admin-users.service';
import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from 'src/app/services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements OnInit {

  dtOptions: any = {};
  permissions: any = {};

  users: any = [];
  roles: any = [];
  modalRef: BsModalRef;
  userEdit: any;
  userCreate: any;

  constructor(
    private adminUsersService: AdminUsersService,
    private adminUserRolesService: AdminUserRolesService,
    private utils: UtilsService,
    private modalService: BsModalService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.dtOptions = {
      paging: false,
      info: false
    };
    this.permissions.create = this.auth.hasPermissions('ManagementCreate');
    this.permissions.edit = this.auth.hasPermissions('ManagementEdit');
    this.permissions.delete = this.auth.hasPermissions('ManagementDelete');
    this.permissions.read = this.auth.hasPermissions('ManagementRead');
    if (this.permissions.read) {
      this.getUsers();
      this.getRoles();
    }
  }

  getUsers() {
    this.adminUsersService.getAllUsers()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utils.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        this.users = [...aux];
      });
  }

  getRoles() {
    this.adminUserRolesService.getAllRoles()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utils.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        this.roles = [...aux];
      });
  }

  openEditUser(modal: TemplateRef<any>, user) {
    this.userEdit = { ...user };
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  openEditPassword(modal: TemplateRef<any>, user) {
    this.closeModal();
    this.userEdit = { ...user };
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  openCreateUser(modal: TemplateRef<any>) {
    this.userCreate = {
      active: true,
    };
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  closeModal() {
    this.modalRef.hide();
    this.userEdit = {};
  }

  editUser() {
    if (
      this.userEdit.name === undefined || this.userEdit.name === null || this.userEdit.name.trim() === '' ||
      this.userEdit.username === undefined || this.userEdit.username === null || this.userEdit.username.trim() === '' ||
      this.userEdit.email === undefined || this.userEdit.email === null || this.userEdit.email.trim() === '' ||
      this.userEdit.role === undefined || this.userEdit.role === null
    ) {
      this.utils.sendConfirmation('warning', 'Tem de preencher todos os campos');
    } else {
      if (this.userEdit.active === undefined || this.userEdit.active === null) {
        this.userEdit.active = false;
      }
      this.adminUsersService.updateUser(this.userEdit.id, this.userEdit)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utils.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const index = this.users.findIndex(user => user.id === this.userEdit.id);
          if (index !== -1) {
            this.userEdit.roleName = this.roles.find(role => role.id == this.userEdit.role).name;
            this.users[index] = { ...this.userEdit };
          }
          this.closeModal();
          this.utils.sendNotification('success', 'Utilizador Atualizado');
        });
    }
  }

  editPassword() {
    if (this.userEdit.password !== undefined && this.userEdit.password !== null && this.userEdit.password.trim() !== '') {
      if (this.userEdit.password === this.userEdit.passwordCheck) {
        this.adminUsersService.updatePasswordByAdmin(this.userEdit.id, this.userEdit.password)
          .subscribe(res => {
            this.closeModal();
            this.utils.sendNotification('success', 'Password Alterada');
          });
      } else {
        this.utils.sendConfirmation('error', 'As Passwords não coincidem');
      }

    } else {
      this.utils.sendConfirmation('warning', 'Tem de preencher a password');
    }
  }

  createUser() {
    if (
      this.userCreate.name === undefined || this.userCreate.name === null || this.userCreate.name.trim() === '' ||
      this.userCreate.username === undefined || this.userCreate.username === null || this.userCreate.username.trim() === '' ||
      this.userCreate.email === undefined || this.userCreate.email === null || this.userCreate.email.trim() === '' ||
      this.userCreate.password === undefined || this.userCreate.password === null || this.userCreate.password.trim() === '' ||
      this.userCreate.role === undefined || this.userCreate.role === null
    ) {
      this.utils.sendConfirmation('warning', 'Tem de preencher todos os campos');
    } else {
      if (this.userCreate.passwordCheck === this.userCreate.password) {
        this.adminUsersService.createUser(this.userCreate)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utils.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            this.getUsers();
            this.closeModal();
            this.utils.sendNotification('success', 'Utilizador Criado');
          });
      } else {
        this.utils.sendConfirmation('error', 'As Passwords não coincidem');
      }
    }
  }

  deleteUser(user) {
    this.utils.sendConfirmationQuestion(user.name, 'Tem a certeza que deseja apagar este utilizador?').then(confirm => {
      if (confirm) {
        this.adminUsersService.deleteUser(user.id)
          .subscribe(res => {
            const index = this.users.findIndex(userList => userList.id === user.id);
            if (index !== -1) {
              this.users.splice(index, 1);
            }
            this.utils.sendNotification('success', 'Utilizador apagado');
          });
      }
    });
  }

}
