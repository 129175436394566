import { Component, OnInit, TemplateRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { MagazinesService } from '../../../services/magazines.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FileUploader } from 'ng2-file-upload';

import * as globals from '../../../../assets/globals';

@Component({
  selector: 'app-digital-magazines',
  templateUrl: './digital-magazines.component.html',
  styleUrls: ['./digital-magazines.component.scss']
})
export class DigitalMagazinesComponent implements OnInit {

  dtOptions: any = {};
  magazines: any[] = [];

  permissions: any = {};

  magazineToEdit: any = {};
  magazineToAdd: any = {};
  modalRef: BsModalRef;

  uploaderFeatured: FileUploader;
  uploaderPDF: FileUploader;
  img_url: String = '';
  created = false;
  display_url: String = '';

  pdfBoolean: boolean = false;
  featuredBoolean: boolean = false;

  constructor(private magazinesService: MagazinesService,
    private utilsService: UtilsService,
    private spinner: NgxSpinnerService,
    private auth: AuthService,
    private modalService: BsModalService) { }

  ngOnInit() {
    this.permissions.edit = this.auth.hasPermissions('MagazinesEdit');
    this.permissions.read = this.auth.hasPermissions('MagazinesRead');
    this.permissions.create = this.auth.hasPermissions('MagazinesCreate');
    this.dtOptions = {
      paging: true,
      info: false
    };
    if (this.permissions.read) {
      this.getDigitalMagazines();
    }
  }

  getDigitalMagazines() {
    this.magazinesService.getDigitalMagazines()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        if (res) {
          this.magazines = res as any;
          this.magazines.forEach(magazine => {
            magazine.init_date = this.utilsService.convertDateStringToDate(magazine.init_date);
            magazine.end_date = this.utilsService.convertDateStringToDate(magazine.end_date);
          })
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  addMagazine() {
    if (this.magazineToAdd.year !== undefined && this.magazineToAdd.year.trim().length > 0 && this.magazineToAdd.init_date !== undefined && this.magazineToAdd.end_date !== undefined) {
      this.magazinesService.addDigitalMagazine(this.magazineToAdd)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          this.magazineToAdd.id = aux.magazine.insertId;
          this.magazines.push(this.magazineToAdd);
          this.created = true;
          this.uploadFeaturedImageCreate();
          this.uploadPDFCreate();
          this.utilsService.sendNotification('success', 'Revista adicionada');
        });
    } else {
      this.utilsService.sendConfirmation('warning', 'Adicionar Revista', 'Para adicionar nova revista é necessário preencher os dados todos!');
    }
  }

  editMagazine() {
    if (this.magazineToEdit.year !== undefined && this.magazineToEdit.year.trim().length > 0 && this.magazineToEdit.init_date !== undefined && this.magazineToEdit.end_date !== undefined) {
      this.magazinesService.editDigitalMagazine(this.magazineToEdit.id, this.magazineToEdit)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            const index = this.magazines.findIndex(magazine => magazine.id === this.magazineToEdit.id);
            if (index !== -1) {
              this.magazines[index] = { ...this.magazineToEdit };
            }
            if (this.uploaderPDF.queue.length != 0 || this.uploaderFeatured.queue.length != 0) {
              this.uploadFeaturedImage();
              this.uploadPDF();
              this.featuredBoolean = false;
              this.pdfBoolean = false;
            } else {
              this.closeModal();
            }
            this.utilsService.sendNotification('success', 'Revista editada');
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
        });
    } else {
      this.utilsService.sendConfirmation('warning', 'Editar Revista', 'Para editar revista é necessário preencher os dados todos!');
    }
  }

  deleteMagazine(magazine) {
    this.utilsService.sendConfirmationQuestion(magazine.year, 'Tem a certeza que deseja apagar esta revista?').then(confirm => {
      if (confirm) {
        this.magazinesService.deleteDigitalMagazine(magazine.id)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const index = this.magazines.findIndex(obj => obj.id === magazine.id);
            if (index !== -1) {
              this.magazines.splice(index, 1);
            }
            this.utilsService.sendNotification('success', 'Revista removida');
          });
      }
    });
  }

  openAddModal(modal: TemplateRef<any>) {
    this.magazineToAdd = {
      title: '',
      year: '',
      init_date: '',
      end_date: ''
    };
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
    this.defineUploaderFeatured();
    this.defineUploaderPDF();
  }

  openEditModal(modal: TemplateRef<any>, magazine) {
    this.magazineToEdit = { ...magazine };
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
    this.defineUploaderFeatured();
    this.defineUploaderPDF();
  }

  closeModal() {
    this.modalRef.hide();
    this.magazineToEdit = {};
    this.magazineToAdd = {};
  }

  defineUploaderFeatured() {
    this.uploaderFeatured = new FileUploader({
      url: globals.base_api_path + 'files/digital-magazine/featured',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderFeatured.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.img_url = JSON.parse(response).url;
      if (this.created == true) {
        this.magazineToAdd.img_url = this.img_url;
        this.magazinesService.editDigitalMagazine(this.magazineToAdd.id, this.magazineToAdd)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              this.closeModal();
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (!aux.success) {
              this.utilsService.sendSystemErrorAlert();
            }
          });
      } else if (this.uploaderPDF.queue.length == 0) {
        this.closeModal();
      }
    };
    this.uploaderFeatured._onErrorItem = (err) => {
      this.uploaderFeatured.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderFeatured();
    };
  }

  uploadFeaturedImage() {
    this.uploaderFeatured.queue.forEach((img, index) => {
      img._onBuildForm = (form) => {
        form.append('id', this.magazineToEdit.id);
      };
      img.upload();
    });
  }

  uploadFeaturedImageCreate() {
    this.uploaderFeatured.queue.forEach((img, index) => {
      img._onBuildForm = (form) => {
        form.append('id', this.magazineToAdd.id);
      };
      img.upload();
    });
  }

  defineUploaderPDF() {
    this.uploaderPDF = new FileUploader({
      url: globals.base_api_path + 'files/digital-magazine/pdf',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderPDF.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.display_url = JSON.parse(response).url;
      if (this.created == true) {
        this.magazineToAdd.display_url = this.display_url;
        this.magazinesService.editDigitalMagazine(this.magazineToAdd.id, this.magazineToAdd)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              this.closeModal();
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (!aux.success) {
              this.utilsService.sendSystemErrorAlert();
            }
            this.created = false;
            this.closeModal();
          });
      } else {
        this.closeModal();
      }
    };
    this.uploaderPDF._onErrorItem = (err) => {
      this.uploaderPDF.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderPDF();
    };
  }

  uploadPDF() {
    this.uploaderPDF.queue.forEach((pdf, index) => {
      pdf._onBuildForm = (form) => {
        form.append('id', this.magazineToEdit.id);
      };
      pdf.upload();
    });
  }

  uploadPDFCreate() {
    this.uploaderPDF.queue.forEach((pdf, index) => {
      pdf._onBuildForm = (form) => {
        form.append('id', this.magazineToAdd.id);
      };
      pdf.upload();
    });
  }

  changePDF(){
    this.pdfBoolean = true;
  }

  changeFeatured(){
    this.featuredBoolean = true;
  }
}
