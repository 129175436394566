import * as globals from '../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';
import { FormControl, FormGroup } from '@angular/forms';

import { AuthService } from 'src/app/services/auth.service';
import { CoursesService } from '../../services/courses.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from '../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})

export class CoursesComponent implements OnInit {
  uploaderFeatured: FileUploader;
  model_create: any = {};
  featured_img: any = {};
  imgDate: any;
  createCourseBool: boolean;
  speakers: any;
  selectedSponsors = [];
  selectedSponsorsBackup = [];
  loadingSponsors;
  sponsorsToAdd = [];
  sponsorsToRemove = [];
  sponsors = [];
  allFields: Boolean;
  model_activity: any = {};
  schedule: any = {};
  activitiesToShow = [];
  activitiesToAdd = [];
  addActivityBool: boolean;

  nr_items_page = globals.nr_items_page;
  p; // paginação;
  courses;
  search: any = {};

  permissions: any = {};
  dates = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private coursesService: CoursesService,
    private utilsService: UtilsService,
    public sanitizer: DomSanitizer,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('CoursesCreate');
    this.permissions.edit = this.auth.hasPermissions('CoursesEdit');
    this.permissions.delete = this.auth.hasPermissions('CoursesDelete');
    this.permissions.read = this.auth.hasPermissions('CoursesRead');
    if (this.permissions.read) {
      this.getAllCourses();
    }
  }

  getAllCourses() {
    this.coursesService.getAllCourses()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.courses = res as any;
        this.courses.forEach(course => {
          course.date = this.utilsService.convertDateStringToDate(course.date);
        });
      });
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }

  goToCourse(courseId, page): void {
    this.router.navigate([globals.baseUrls.courses + '/' + courseId + '/' + page]);
  }

  addCourse() {
    this.defineUploaderFeatured();
    this.getAllSpeakers();
    this.getAllSponsors();
    this.addCourseToogle();
  }

  addCourseToogle() {
    this.createCourseBool = !this.createCourseBool;
  }

  cancelCreateCourse() {
    this.addCourseToogle();
    this.model_create = {};
  }

  defineUploaderFeatured() {
    this.uploaderFeatured = new FileUploader({
      url: globals.base_api_path + 'files/upload/course/featured',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderFeatured.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.model_create.id = JSON.parse(response).course_id;
      this.model_create.featured_img = JSON.parse(response).url;
      this.imgDate = Date.now();
      this.coursesService.updateCourse(this.model_create.id, this.model_create)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.updateCourseSchedule();
            //this.utilsService.sendNotification('success', 'Curso criado');
          } else if (!aux.success) {
            this.utilsService.sendSystemErrorAlert();
          }
        });
      this.defineUploaderFeatured();
    };
    this.uploaderFeatured._onErrorItem = (err) => {
      this.uploaderFeatured.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderFeatured();
    };
  }

  uploadFeaturedImage() {
    this.uploaderFeatured.queue.forEach((img, index) => {
      img._onBuildForm = (form) => {
        form.append('id', this.model_create.id);
      };
      img.upload();
    });
  }

  showFeaturedImage() {
    if (this.uploaderFeatured.queue.length > 1) {
      this.uploaderFeatured.queue.splice(0, 1);
    }
    this.uploaderFeatured.queue.forEach(foto => {
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      this.featured_img.url = url;
      this.featured_img.foto = foto;
    });
  }

  verifyModel() {
    if (this.model_create.title == undefined || this.model_create.title.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_create.init_date == undefined || this.model_create.init_date.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_create.price == undefined || this.model_create.price.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_create.place == undefined || this.model_create.place.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_create.locality == undefined || this.model_create.locality.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_create.conditions == undefined || this.model_create.conditions.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_create.description == undefined || this.model_create.description.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_create.payment == undefined || this.model_create.payment.trim().length === 0) {
      this.allFields = false;
    }
    if (this.featured_img.url == undefined || this.featured_img.url.trim().length === 0) {
      this.allFields = false;
    }
  }

  createCourse() {
    this.verifyModel();
    this.model_create.sponsorsToAdd = this.sponsorsToAdd;
    this.model_create.sponsorsToRemove = this.sponsorsToRemove;
    if (this.allFields == false) {
      this.utilsService.sendConfirmation('warning', 'Necessita de Preencher todos os campos em branco');
      this.allFields = true;
    } else {
      this.coursesService.addCourse(this.model_create)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.model_create.id = aux.id;
            this.uploadFeaturedImage();
            /*this.updateCourseSchedule();
            this.utilsService.sendNotification('success', 'Curso criado');*/
          }
        });
    }
  }

  addSponsor(event) {
    const id = event.id;
    const index = this.sponsorsToRemove.indexOf(id);
    if (index > -1) {
      this.sponsorsToRemove.splice(index, 1);
    } else {
      this.sponsorsToAdd.push(id);
    }
  }

  removeSponsor(event) {
    const id = event.value.id;
    const index = this.sponsorsToAdd.indexOf(id);
    if (index > -1) {
      this.sponsorsToAdd.splice(index, 1);
    } else {
      this.sponsorsToRemove.push(id);
    }
  }

  clearSponsors() {
    this.sponsorsToRemove = this.selectedSponsorsBackup;
  }

  getAllSpeakers() {
    this.coursesService.getAllSpeakers()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        if (res) {
          this.speakers = res as any;
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  getAllSponsors() {
    this.coursesService.getAllSponsors()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        if (res) {
          this.sponsors = res as any;
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  updateCourseSchedule() {
    this.schedule.activities = [];
    this.schedule.activitiesToAdd = this.activitiesToAdd;
    this.schedule.activitiesToRemove = [];
    this.coursesService.updateCourseSchedule(this.model_create.id, this.schedule)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          this.utilsService.sendNotification('success', 'Curso Criado');
          this.goToCourse(this.model_create.id, 'info');
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  addActivity() {
    this.activitiesToShow.push(this.model_activity);
    this.activitiesToAdd.push(this.model_activity);
    this.addActivityToogle();
    this.model_activity = {};
  }

  removeActivity(activity) {
    const index = this.activitiesToShow.indexOf(activity);
    this.activitiesToShow.splice(index, 1);
    this.activitiesToAdd.splice(index, 1);
  }

  addActivityToogle() {
    if (this.dates.length == 1) {
      this.model_activity.day = 1;
    }
    this.addActivityBool = !this.addActivityBool;
  }

  cancelAddActivityToogle() {
    this.addActivityToogle();
    this.model_activity = {};
  }

  getDates(nrDays) {
    this.dates = [];
    this.dates[0] = this.model_create.init_date;
    for (let i = 1; i < nrDays; i++) {
      var sdate = new Date(this.model_create.init_date);
      sdate.setDate(sdate.getDate() + i);
      this.dates[i] = sdate;
    }
  }

  nrDaysBetweenDates() {
    const date1 = new Date(this.model_create.init_date);
    const date2 = new Date(this.model_create.end_date);
    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;
    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();
    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);
    this.getDates(diffInDays + 1);
  }

}