import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';
import { FormControl, FormGroup } from '@angular/forms';
import { CoursesService } from '../../../services/courses.service';
import { AuthService } from 'src/app/services/auth.service';
import { SweepstakesService } from '../../../services/sweepstakes.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-create-sweepstake',
  templateUrl: './create-sweepstake.component.html',
  styleUrls: ['./create-sweepstake.component.scss']
})
export class CreateSweepstakeComponent implements OnInit {

  siteUrl = globals.site_base_url;
  uploaderFeatured: FileUploader;
  model_create: any = {};
  featured_img: any = {};
  model_prize: any = {};
  imgDate: any;
  allFields: boolean;
  sweepstakeId;
  selectedSponsors = [];
  selectedSponsorsBackup = [];
  loadingSponsors;
  sponsorsToAdd = [];
  sponsorsToRemove = [];
  sponsors = [];
  sweepstakePrizes: any = {};
  prizes = [];
  prizesToAdd = [];
  prizesToRemove = [];
  showPrizes = [];
  addPrizeBool: boolean;
  permissions: any = {};

  constructor(private route: ActivatedRoute,
    private router: Router,
    private sweepstakesService: SweepstakesService,
    private utilsService: UtilsService,
    private spinner: NgxSpinnerService,
    public sanitizer: DomSanitizer,
    private auth: AuthService,
    private coursesService: CoursesService) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('SweepstakesCreate');
    this.permissions.edit = this.auth.hasPermissions('SweepstakesEdit');
    this.permissions.delete = this.auth.hasPermissions('SweepstakesDelete');
    this.permissions.read = this.auth.hasPermissions('SweepstakesRead');
    if (this.permissions.read) {
      this.defineUploaderFeatured();
      this.getAllSponsors();
    }
  }

  getAllSponsors() {
    this.coursesService.getAllSponsors()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        if (res) {
          this.sponsors = res as any;
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  defineUploaderFeatured() {
    this.uploaderFeatured = new FileUploader({
      url: globals.base_api_path + 'files/upload/sweepstake/featured',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderFeatured.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.model_create.id = JSON.parse(response).sweepstake_id;
      this.model_create.featured_img = JSON.parse(response).url;
      this.imgDate = Date.now();
      this.sweepstakesService.updateSweepstake(this.model_create.id, this.model_create)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Sorteio criado (imagem)');
            this.goBack();
          } else if (!aux.success) {
            this.utilsService.sendSystemErrorAlert();
          }
        });
      this.defineUploaderFeatured();
    };
    this.uploaderFeatured._onErrorItem = (err) => {
      //console.log(err);
      this.uploaderFeatured.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderFeatured();
    };
  }

  uploadFeaturedImage() {
    this.uploaderFeatured.queue.forEach((img, index) => {
      img._onBuildForm = (form) => {
        form.append('id', this.model_create.id);
      };
      img.upload();
    });
  }

  showFeaturedImage() {
    if (this.uploaderFeatured.queue.length > 1) {
      this.uploaderFeatured.queue.splice(0, 1);
    }
    this.uploaderFeatured.queue.forEach(foto => {
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      this.featured_img.url = url;
      this.featured_img.foto = foto;
    });
  }

  addSponsor(event) {
    const id = event.id;
    const index = this.sponsorsToRemove.indexOf(id);
    if (index > -1) {
      this.sponsorsToRemove.splice(index, 1);
    } else {
      this.sponsorsToAdd.push(id);
    }
  }

  removeSponsor(event) {
    const id = event.value.id;
    const index = this.sponsorsToAdd.indexOf(id);
    if (index > -1) {
      this.sponsorsToAdd.splice(index, 1);
    } else {
      this.sponsorsToRemove.push(id);
    }
  }

  clearSponsors() {
    this.sponsorsToRemove = this.selectedSponsorsBackup;
  }

  verifyModel() {
    if (this.model_create.title == undefined || this.model_create.title.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_create.init_date == undefined || this.model_create.init_date.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_create.end_date == undefined || this.model_create.end_date.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_create.conditions == undefined || this.model_create.conditions.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_create.type == undefined || this.model_create.type.trim().length === 0) {
      this.allFields = false;
    }
    if (this.featured_img.url == undefined || this.featured_img.url.trim().length === 0) {
      this.allFields = false;
    }
  }

  goBack() {
    const aux = this.router.url.split('/');
    aux.length -= 1;
    const backLink = aux.join('/');
    this.router.navigate([backLink]);
  }

  createSweepstake() {
    this.verifyModel();
    this.model_create.sponsorsToAdd = this.sponsorsToAdd;
    this.model_create.sponsorsToRemove = this.sponsorsToRemove;
    if (this.allFields == false) {
      this.utilsService.sendConfirmation('warning', 'Necessita de Preencher todos os campos em branco');
      this.allFields = true;
    } else {
      this.sweepstakesService.createSweepstake(this.model_create)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.model_create.id = aux.id;
            this.uploadFeaturedImage();
            this.updateSweepstakePrizes();
            this.utilsService.sendNotification('success', 'Sorteio criado');
          }
        });
    }
  }

  addPrize() {
    this.prizesToAdd.push(this.model_prize);
    this.showPrizes.push(this.model_prize);
    this.model_prize = {};
  }

  removePrize(prize) {
    /*const index = this.Prizes.indexOf(Prize);
    this.Prizes.splice(index, 1);*/
    const index2 = this.showPrizes.indexOf(prize);
    this.showPrizes.splice(index2, 1);
    this.prizesToRemove.push(prize);
  }

  addPrizeToogle() {
    this.addPrizeBool = !this.addPrizeBool;
  }

  cancelAddPrizeToogle() {
    this.addPrizeToogle();
    this.model_prize = {};
  }

  updateSweepstakePrizes() {
    this.sweepstakePrizes.prizes = this.prizes;
    this.sweepstakePrizes.prizesToAdd = this.prizesToAdd;
    this.sweepstakePrizes.prizesToRemove = this.prizesToRemove;
    this.sweepstakesService.updateSweepstakePrizes(this.model_create.id, this.sweepstakePrizes)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          this.utilsService.sendNotification('success', 'Sorteio atualizado');
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }
}
