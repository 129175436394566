import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, Host, Inject, OnInit } from '@angular/core';

import { FileUploader } from 'ng2-file-upload';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { PublicService } from '../../../services/public.service';
import { UsersService } from '../../../services/users.service';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-create-public',
  templateUrl: './create-public.component.html',
  styleUrls: ['./create-public.component.scss']
})
export class CreatePublicComponent implements OnInit {

  model: any = {};
  error = '';
  loading = false;
  returnUrl: string;

  interests: any[] = [];

  countries: any[];
  districts: any[];
  cities: any[];

  maritalStatus: any[];
  literaryAbilities: any[];

  phone_code;

  completeRegistration;
  completeId;
  completeEmail;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private utilsService: UtilsService,
    private userService: UsersService) { }

  ngOnInit() {
    this.initDropdowns();
    this.getCategories();
  }

  initModel() {
    this.model.country = globals.portugalCountryName; // Predefinição pais = Portugal
    this.updatePhoneCode();
    this.model.termos = false;
    this.model.cartao_vantagens = true;
    this.model.newsletter = true;
    this.model.engaged = false;
  }

  initDropdowns() {
    this.utilsService.getCountries()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.countries = data as any;
        this.updatePhoneCode();
      });
    this.utilsService.getDistricts()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any).subscribe(data => {
          this.districts = data as any;
        });
    this.utilsService.getCities()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.cities = data as any;
      });
    this.utilsService.getMaritalStatus()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.maritalStatus = data as any;
      });
    this.utilsService.getLiteraryAbilities()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.literaryAbilities = data as any;
      });
  }

  getCategories() {
    this.utilsService.getInterestsList()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.interests = res as any;
      });
  }

  confirmRegister() {
    if (this.model.cartao_vantagens || this.model.newsletter) {
      let message = '';
      if (this.model.cartao_vantagens && this.model.newsletter) {
        message = 'Declaro que pretendo aderir à newsletter e ao Clube Benefícios.';
      } else if (this.model.newsletter) {
        message = 'Declaro que pretendo aderir à newsletter.';
      } else if (this.model.cartao_vantagens) {
        message = 'Declaro que pretendo aderir ao Clube Benefícios.';
      }
      this.utilsService.sendConfirmationQuestion(message).then(confirm => {
        if (confirm) {
          this.createPublic();
        }
      });
    } else {
      this.createPublic();
    }
  }

  createPublic() {
    this.modelUndefinedToNull();
    this.modelFillInterests();
    if (this.model.termos) {
      if (this.model.password === this.model.password_check) {
        this.loading = true;
        this.userService.createUserPublic(this.model)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              if (err.error && err.error.message.code === 'ER_DUP_ENTRY') {
                const message = err.error.message.sqlMessage;
                if (message.includes('email')) {
                  this.utilsService.sendConfirmation('error', 'O email fornecido já se encontra registado');
                } else if (message.includes('username')) {
                  this.utilsService.sendConfirmation('error', 'O username introduzido já se encontra utilizado');
                } else if (message.includes('nif')) {
                  this.utilsService.sendConfirmation('error', 'O nif introduzido já se encontra utilizado');
                }
              } else {
                this.utilsService.sendSystemErrorAlert();
              }
              this.loading = false;
              return null;
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (aux.success) {
              if (aux.errorInterests) {
                this.utilsService.sendConfirmation('warning', 'Ouve um erro ao definir os seus interesses', 'O seu utilizador foi criado no entanto não foram adicionados os interesses. Utilize por favor a seção de perfil da sua dashboard para atualizar os mesmos. Por favor verifique o seu mail para confirmar a sua conta. Se não receber o email pode reenviar no seu perfil ou em ' + globals.site_base_url + 'verify_email');
              } else {
                this.utilsService.sendConfirmation('success', 'Utilizador criado com sucesso', 'Por favor verifique o seu mail para confirmar a sua conta. Se não receber o email pode reenviar no seu perfil ou em ' + globals.site_base_url + 'verify_email');
              }
              this.router.navigate([this.returnUrl]);
            } else {
              this.utilsService.sendSystemErrorAlert();
            }
            this.loading = false;
          });
      } else {
        this.utilsService.sendConfirmation('warning', 'As passwords não coincidem');
      }
    } else {
      this.utilsService.sendConfirmation('warning', 'Tem de aceitar os termos de utilização');
    }
  }

  modelFillInterests() {
    this.model.interests = [];
    this.interests.forEach(interest => {
      if (interest.selected) {
        this.model.interests.push(interest);
      }
    });
  }

  modelUndefinedToNull() {
    if (this.model.phone_nr === undefined) {
      this.model.phone_nr = '';
    }
    if (this.model.address === undefined) {
      this.model.address = '';
    }
    if (this.model.country === undefined) {
      this.model.country = null;
    }
    if (this.model.district === undefined) {
      this.model.district = null;
    }
    if (this.model.locality === undefined) {
      this.model.locality = null;
    }
    if (this.model.country === undefined) {
      this.model.country = null;
    }
    if (this.model.postal === undefined) {
      this.model.postal = '';
    }
    if (this.model.occupation === undefined || this.model.occupation.trim() === '') {
      this.model.occupation = '';
    }
    if (!this.model.engaged) {
      this.model.wedding_date = null;
      this.model.spouse_name = '';
    }
    if (this.model.marital_status === undefined) {
      this.model.marital_status = null;
    }
    if (this.model.literary_abilities === undefined) {
      this.model.literary_abilities = null;
    }
    if (this.model.nif === undefined || this.model.nif === '') {
      this.model.nif = null;
    }
    if (this.model.newsletter === undefined || this.model.newsletter === null) {
      this.model.newsletter = false;
    }
    if (this.model.cartao_vantagens === undefined || this.model.cartao_vantagens === null) {
      this.model.cartao_vantagens = false;
    }
  }

  // VALIDAÇÂO NIF

  isNifValid() {
    if (!this.model.nif || this.model.country !== globals.portugalCountryName) {
      return true;
    }
    return this.utilsService.validateNIF(this.model.nif);
  }



  isPTSelected() {
    if (this.countries && this.model.country) {
      return this.model.country == globals.portugalCountryName;
    } else {
      return false;
    }
  }

  searchPostal(postal) {
    if (this.model.postal.length === 8 && this.isPTSelected()) {
      const splited_postal = this.model.postal.split('-', 2);
      if (splited_postal[0].length === 4 && splited_postal[1].length === 3) {
        this.utilsService
          .getPostalInfo(splited_postal[0], splited_postal[1])
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            this.model.district = this.districts.find(dist => dist.id === aux.district_id).name;
            this.model.city = this.cities.find(city => city.id === aux.city_id).name;
            this.model.locality = aux.locality;
          });
      }
    }
  }

  changeNewsletter() {
    if (!this.model.newsletter && this.model.cartao_vantagens) {
      this.utilsService.sendConfirmationQuestion('Se não desejar receber newsletter não poderá usufruir do cartão vantagens. ', 'Tem a certeza que deseja anular a newsletter?').then(confirm => {
        if (confirm) {
          this.model.cartao_vantagens = false;
        } else {
          this.model.newsletter = true;
        }
      });
    }
  }

  changeCard() {
    if (!this.model.newsletter && this.model.cartao_vantagens) {
      this.utilsService.sendConfirmationQuestion('Para usufruir do cartão vantagens necessita de aderir à newsletter', 'Deseja aderir?').then(confirm => {
        if (confirm) {
          this.model.newsletter = true;
        } else {
          this.model.cartao_vantagens = false;
        }
      });
    }
  }

  updatePhoneCode() {
    this.phone_code = this.countries.find(country => country.countryName === this.model.country).phoneCode;
  }
}
