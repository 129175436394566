import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, Host, Inject, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { DestinationWeddingsService } from '../../../services/destination-weddings.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploader } from 'ng2-file-upload';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-sub-regions-wd',
  templateUrl: './sub-regions-wd.component.html',
  styleUrls: ['./sub-regions-wd.component.scss']
})
export class SubRegionsWDComponent implements OnInit {

  nr_items_page = globals.nr_items_page;
  search: any = {};
  p; // paginação;
  regions: any[];
  subRegions: any[] = [];
  addSubRegion: boolean;
  regionId;
  subRegionId;
  uploaderFeatured: FileUploader;
  imgDate: any;
  addUrl;
  model: any = {};
  featured_img: any = {};
  loading = false;

  permissions: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private wdService: DestinationWeddingsService,
    private utilsService: UtilsService,
    private sanitizer: DomSanitizer,
    private auth: AuthService,
  ) { }


  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('DestinationWeddingsCreate');
    this.permissions.edit = this.auth.hasPermissions('DestinationWeddingsEdit');
    this.permissions.delete = this.auth.hasPermissions('DestinationWeddingsDelete');
    this.permissions.read = this.auth.hasPermissions('DestinationWeddingsRead');
    this.addSubRegion = false;
    if (this.permissions.read) {
      this.getUrlParams();
    }
  }


  getUrlParams() {
    this.route.params.subscribe(params => {
      this.regionId = params.regionId;
      if (this.regionId) {
        this.getSubRegionsByRegionId();
        this.getRegions();
        this.defineUploaderFeatured();
      } else {
        this.getAllWDSubRegions();
      }
    });
  }

  getRegions() {
    this.wdService.getAllWDRegions()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.regions = res as any;
      });
  }

  getSubRegionsByRegionId() {
    this.wdService.getSubRegionsByRegionId(this.regionId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.subRegions = res as any;
      });
  }

  getAllWDSubRegions() {
    this.wdService.getAllWDSubRegions()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.subRegions = res as any;
      });
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }

  goToSubRegion(subRegionId): void {
    this.router.navigate([globals.baseUrls.destinationWeddings + '/subregion/' + subRegionId]);
  }

  goToSubRegionPlaces(subRegionId): void {
    this.router.navigate([globals.baseUrls.destinationWeddings + '/subregion/' + subRegionId + '/places']);
  }

  goToSubRegionImages(subRegionId): void {
    this.router.navigate([globals.baseUrls.destinationWeddings + '/subregion/' + subRegionId + '/images']);
  }

  goToTypes(): void {
    this.router.navigate([globals.baseUrls.destinationWeddings + '/subregions/types']);
  }

  addSubRegionToogle() {
    this.addSubRegion = !this.addSubRegion;
  }

  changeActive(subRegionId, subRegion) {
    this.wdService.changeActiveSubRegion(subRegionId, subRegion)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          if (subRegion.is_visible) {
            this.utilsService.sendNotification('success', 'SubRegião visivel no WD');
          } else {
            this.utilsService.sendNotification('success', 'SubRegião Não visivel no WD');
          }
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  deleteSubRegion(subRegionId) {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja elimiar a SubRegião?', 'Este processo é irreversível').then(confirm => {
      if (confirm) {
        this.wdService.deleteSubRegion(subRegionId)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (aux.success) {
              this.utilsService.sendNotification('success', 'SubRegião e seus locais removidos');
              this.getAllWDSubRegions();
            } else {
              this.utilsService.sendSystemErrorAlert();
            }
          });
      }
    });
  }

  defineUploaderFeatured() {
    this.uploaderFeatured = new FileUploader({
      url: globals.base_api_path + 'files/upload/subregionWD/featured',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderFeatured.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.model.id = JSON.parse(response).subregion_id;
      this.model.featured_img = JSON.parse(response).url;
      this.imgDate = Date.now();
      //console.log(this.model.id);
      this.wdService.updateWDSubRegion(this.subRegionId, this.model)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Dados da SubRegião WD atualizados (imagem atribuida)');
            this.addUrl = '';
            this.loading = false;
          } else if (!aux.success) {
            this.utilsService.sendSystemErrorAlert();
          }
        });
      this.defineUploaderFeatured();
    };
    this.uploaderFeatured._onErrorItem = (err) => {
      //console.log(err);
      this.uploaderFeatured.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderFeatured();
    };
  }

  uploadFeaturedImage() {
    this.uploaderFeatured.queue.forEach(img => {
      img._onBuildForm = (form) => {
        form.append('countryName', this.utilsService.getUrlFromName(this.regions[this.regionId - 1].countryName));
        form.append('regionName', this.utilsService.getUrlFromName(this.regions[this.regionId - 1].name));
        form.append('subRegionName', this.utilsService.getUrlFromName(this.model.name));
      };
      img.upload();
    });
    this.uploaderFeatured.uploadAll();
  }

  showFeaturedImage() {
    if (this.uploaderFeatured.queue.length > 1) {
      this.uploaderFeatured.queue.splice(0, 1);
    }
    this.uploaderFeatured.queue.forEach(foto => {
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      this.featured_img.url = url;
      this.featured_img.foto = foto;
    });
  }

  createSubRegion() {
    this.model.video_url = this.addUrl;
    this.model.region_id = this.regionId;
    this.loading = true;
    this.wdService.createSubRegionWD(this.model)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          this.model.id = aux.id;
          this.subRegionId = aux.id;
          this.uploadFeaturedImage();
          this.utilsService.sendNotification('success', 'SubRegião do WD criado');
        }
      });
  }
}
