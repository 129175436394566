import * as globals from '../../assets/globals';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ObservableLike } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WeddingsService {

  constructor(private http: HttpClient) { }

  apiRoute = globals.base_api_path + 'weddings/';

  getAllWeddings(): Observable<any> {
    return this.http.get(this.apiRoute + 'allweddings');
  }

  getWeddingVideos(weddingId): Observable<any> {
    return this.http.get(this.apiRoute + weddingId + '/videos');
  }

  getWeddingImgs(weddingId): Observable<any> {
    return this.http.get(this.apiRoute + weddingId + '/imgs');
  }

  getWeddingProviders(weddingId): Observable<any> {
    return this.http.get(this.apiRoute + weddingId + '/providers');
  }

  updateStoreWedding(id, body): Observable<any> {
    return this.http.put(this.apiRoute + 'store/' + id, body);
  }

  deleteStoreWedding(id): Observable<any> {
    return this.http.delete(this.apiRoute + 'store/' + id);
  }

  insertImagesStore(id, urls): Observable<any> {
    return this.http.post(this.apiRoute + 'store/' + id + '/images', { images: urls });
  }

  deleteImagesStore(id, urls): Observable<any> {
    return this.http.put(this.apiRoute + 'store/' + id + '/images/delete', { images: urls });
  }

  insertVideosStore(id, urls): Observable<any> {
    return this.http.post(this.apiRoute + 'store/' + id + '/videos', { videos: urls });
  }

  deleteVideosStore(id, urls): Observable<any> {
    return this.http.put(this.apiRoute + 'store/' + id + '/videos/delete', { videos: urls });
  }

  newPublicWedding(body): Observable<any> {
    return this.http.post(this.apiRoute + 'public', body);
  }

  updatePublicWedding(id, body): Observable<any> {
    return this.http.put(this.apiRoute + 'public/' + id, body);
  }

  deletePublicWedding(id): Observable<any> {
    return this.http.delete(this.apiRoute + 'public/' + id);
  }

  insertImagesPublic(id, urls): Observable<any> {
    return this.http.post(this.apiRoute + 'public/' + id + '/images', { images: urls });
  }

  deleteImagesPublic(id, urls): Observable<any> {
    return this.http.put(this.apiRoute + 'public/' + id + '/images/delete', { images: urls });
  }

  insertVideosPublic(id, urls): Observable<any> {
    return this.http.post(this.apiRoute + 'public/' + id + '/videos', { videos: urls });
  }

  deleteVideosPublic(id, urls): Observable<any> {
    return this.http.put(this.apiRoute + 'public/' + id + '/videos/delete', { videos: urls });
  }

  updatePublicWeddingProviders(weddingId, providers): Observable<any> {
    return this.http.put(this.apiRoute + 'public/' + weddingId + '/providers', { stores: providers });
  }

  newStoreWedding(body): Observable<any> {
    return this.http.post(this.apiRoute + 'store', body);
  }
}
