import * as globals from '../../../../assets/globals';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, TemplateRef } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from 'src/app/services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-brand-plans',
  templateUrl: './brand-plans.component.html',
  styleUrls: ['./brand-plans.component.scss']
})
export class BrandPlansComponent implements OnInit {


  nr_items_page = globals.nr_items_page;
  search: any = {};
  p; // paginação;
  modalRef: BsModalRef;
  permissions: any = {};

  plans: any[];

  planInfo: any = {};
  planEdit: any = {};
  planCreate: any = {};

  constructor(
    private utils: UtilsService,
    private modalService: BsModalService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('ManagementCreate');
    this.permissions.edit = this.auth.hasPermissions('ManagementEdit');
    this.permissions.delete = this.auth.hasPermissions('ManagementDelete');
    this.permissions.read = this.auth.hasPermissions('ManagementRead');
    if (this.permissions.read) {
      this.getPlans();
    }
  }

  getPlans() {
    this.utils.getBrandPlansInfo()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utils.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.plans = res as any;
      });
  }

  openInfoModal(modal: TemplateRef<any>, plan) {
    this.planInfo = plan;
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  openEditModal(modal: TemplateRef<any>, plan) {
    this.planEdit = { ...plan };
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  openCreateModal(modal: TemplateRef<any>) {
    this.planCreate = {
      show_name: true,
      show_address: false,
      show_country: true,
      show_phone_nr: false,
      tickets: true,
      show_website: false,
      show_stores: false,
      nr_videos: 0,
      testimonials: true,
      save_fav: true,
      social: true,
      statistic: false,
      subscribable: false,
      listed: false,
      announcements_delay: 24
    };
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  closeModal() {
    this.modalRef.hide();
    this.planInfo = {};
    this.planEdit = {};
    this.planCreate = {};
  }

  editPlan() {
    if (
      (this.planEdit.nr_videos >= 0 || this.planEdit.nr_videos === undefined || this.planEdit.nr_videos === null)
    ) {
      if (this.planEdit.name !== undefined && this.planEdit.name !== null && this.planEdit.name.trim().length > 0) {
        this.utils.updateBrandPlanInfo(this.planEdit.id, this.planEdit)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utils.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const index = this.plans.findIndex(plan => plan.id === this.planEdit.id);
            if (index !== -1) {
              this.plans[index] = { ...this.planEdit };
            }
            this.closeModal();
            this.utils.sendNotification('success', 'Plano Alterado');
          });
      } else {
        this.utils.sendConfirmation('warning', 'Tem de preecher o nome');
      }
    } else {
      this.utils.sendConfirmation('warning', 'Não pode ter limites inferiores a 0');
    }
  }

  createPlan() {
    if (
      (this.planCreate.nr_videos >= 0 || this.planCreate.nr_videos === undefined || this.planCreate.nr_videos === null)
    ) {
      if (this.planCreate.name !== undefined && this.planCreate.name !== null && this.planCreate.name.trim().length > 0) {
        this.utils.createBrandPlanInfo(this.planCreate)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utils.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            this.getPlans();
            this.closeModal();
            this.utils.sendNotification('success', 'Plano Criado');
          });
      } else {
        this.utils.sendConfirmation('warning', 'Tem de preecher o nome');
      }
    } else {
      this.utils.sendConfirmation('warning', 'Não pode ter limites inferiores a 0');
    }
  }

  deletePlan(plan) {
    this.utils.sendConfirmationQuestion(plan.name, 'Tem a certeza que deseja apagar este plano?').then(confirm => {
      if (confirm) {
        this.utils.deleteBrandPlanInfo(plan.id)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utils.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const index = this.plans.findIndex(obj => obj.id === plan.id);
            if (index !== -1) {
              this.plans.splice(index, 1);
            }
            this.utils.sendNotification('success', 'Plano apagado');
          });
      }
    });
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }

}
