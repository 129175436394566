import * as globals from '../../assets/globals';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  apiRoute = globals.base_api_path + 'files/';

  constructor(private _http: HttpClient) {}

  downloadAttachment(file: String) {
    return this._http.get(this.apiRoute + 'download/attachment/' + file, {
      responseType: 'blob',
    });
  }

  deleteAttachment(uploadName: String) {
    return this._http.delete(this.apiRoute + 'attachment/' + uploadName);
  }
  
}
