import * as globals from '../../../../assets/globals';

import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-navheader',
  templateUrl: './navheader.component.html',
  styleUrls: ['./navheader.component.scss']
})
export class NavheaderComponent implements OnInit {

  siteUrl = globals.site_base_url;
  baseUrls = globals.baseUrls;

  constructor(
    private auth: AuthService,
  ) { }

  ngOnInit() {
  }

  logout() {
    this.auth.logout();
  }

}
