import * as globals from '../../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { BrandsService } from '../../../../services/brands.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from '../../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-professional-brands',
  templateUrl: './professional-brands.component.html',
  styleUrls: ['./professional-brands.component.scss']
})

export class ProfessionalBrandsComponent implements OnInit {

  nr_items_page = globals.nr_items_page;
  p; // paginação;
  brands: any[] = [];
  search: any = {};
  userId;
  professionalId;

  permissions: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private brandsService: BrandsService,
    private utilsService: UtilsService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.read = this.auth.hasPermissions('UsersRead');
    this.permissions.edit = this.auth.hasPermissions('BrandsEdit');
    this.permissions.create = this.auth.hasPermissions('BrandsCreate');
    if (this.permissions.read) {
      this.getUrlParams();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.userId = params.userId;
      this.professionalId = params.professionalId;
      this.getBrandsByProfessionalId();
    });
  }

  getBrandsByProfessionalId() {
    this.brandsService
      .getBrandsByProfessionalId(this.professionalId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.brands = res as any;
      });
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }

  goToCreateBrand(): void {
    this.router.navigate([globals.baseUrls.brands + '/create/' + this.professionalId]);
  }

  goToBrand(brandId, page): void {
    this.router.navigate([globals.baseUrls.brands + '/' + brandId + '/' + page]);
  }

  changeHidden(brandId, brand) {
    this.brandsService.changeHidden(brandId, brand)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          this.utilsService.sendNotification('success', 'Marca Não visivel');
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });;
  }

}
