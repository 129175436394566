import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';

import { AuthService } from 'src/app/services/auth.service';
import { BlogService } from '../../../services/blog.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-bloggers',
  templateUrl: './bloggers.component.html',
  styleUrls: ['./bloggers.component.scss']
})

export class BloggersComponent implements OnInit {

  bloggersRoles = [{}] as any;
  nr_items_page = globals.nr_items_page;
  p; // paginação;
  bloggers;
  createBloggerBool: Boolean;
  model: any = {};
  error = '';
  loading = false;
  search: any = {};
  uploaderFeatured: FileUploader;
  featured_img: any = {};
  bloggerId;
  imgDate: any;
  permissions: any = {};

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private blogService: BlogService,
    private utilsService: UtilsService,
    public sanitizer: DomSanitizer,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('BlogCreate');
    this.permissions.edit = this.auth.hasPermissions('BlogEdit');
    this.permissions.delete = this.auth.hasPermissions('BlogDelete');
    this.permissions.read = this.auth.hasPermissions('BlogRead');
    if (this.permissions.read) {
      this.getAllBloggers();
      this.defineUploader();
    }
    this.createBloggerBool = false;
  }

  getAllBloggers() {
    this.blogService.getAllBloggers()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.bloggers = res as any;
      });
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }

  createBlogger() {
    this.createBloggerToogle();
  }

  createBloggerToogle() {
    this.createBloggerBool = !this.createBloggerBool;
  }

  goToBlogger(bloggerId) {
    this.router.navigate([globals.baseUrls.blog + '/blogger/' + bloggerId]);
  }

  goToBloggerPosts(bloggerId) {
    this.router.navigate([globals.baseUrls.blog + '/posts/' + bloggerId]);
  }

  confirmRegister() {
    this.loading = true;
    this.blogService.createBlogger(this.model)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (!err.error) {
            this.utilsService.sendSystemErrorAlert();
          } else if (err.error.message.code === 'ER_DUP_ENTRY') {
            const message = err.error.message.sqlMessage;
            if (message.includes('email')) {
              this.utilsService.sendConfirmation('error', 'O email fornecido já se encontra registado');
            }
          }
          this.loading = false;
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          this.bloggerId = aux.id;
          this.uploadFeaturedImage();
          this.utilsService.sendNotification('success', 'Blogger Criado');
        }
        this.loading = false;
      });
  }

  changeActivated(bloggerId, blogger) {
    this.blogService.changeActivated(bloggerId, blogger)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          if (blogger.activated) {
            this.utilsService.sendNotification('success', 'Blogger visivel');
          } else {
            this.utilsService.sendNotification('success', 'Blogger Não visivel');
          }
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  defineUploader() {
    this.uploaderFeatured = new FileUploader({
      url: globals.base_api_path + 'files/upload/blogger/profile-img',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderFeatured.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.model.foto_url = JSON.parse(response).url;
      this.imgDate = Date.now();
      this.blogService.editData(this.bloggerId, this.model)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Fotografia de perfil atualizada');
            this.loading = false;
          } else if (!aux.success) {
            this.utilsService.sendSystemErrorAlert();
          }
        });
      this.defineUploader();
    };
    this.uploaderFeatured._onErrorItem = (err) => {
      this.uploaderFeatured.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploader();
    };
  }

  uploadFeaturedImage() {
    this.uploaderFeatured.queue.forEach(img => {
      img._onBuildForm = (form) => {
        form.append('bloggerName', this.utilsService.getUrlFromName(this.model.name));
      };
      img.upload();
    });
    this.uploaderFeatured.uploadAll();
  }

  showFeaturedImage() {
    if (this.uploaderFeatured.queue.length > 1) {
      this.uploaderFeatured.queue.splice(0, 1);
    }
    this.uploaderFeatured.queue.forEach(foto => {
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      this.featured_img.url = url;
      this.featured_img.foto = foto;
    });
  }
}
