import * as globals from '../../assets/globals';
import * as jwt_decode from 'jwt-decode';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loginUrl = globals.base_api_path + 'admin-users/login';

  constructor(
    private router: Router,
    private http: HttpClient,
  ) { }

  login(email: string, password: string) {
    return new Observable<{ success, error?}>(observer => {
      this.http.post<string>(this.loginUrl, { email, password })
        .pipe(
          catchError((err: HttpErrorResponse) => {
            console.log(err);
            observer.next({ success: false, error: err });
            return null;
          }) as any)
        .subscribe(
          token => {
            if (token) {
              const tokenDecoded = jwt_decode(token);
              sessionStorage.setItem('token', '' + token); // Token
              sessionStorage.setItem('token_expires_at', tokenDecoded.exp); // Token's expiration date
              sessionStorage.setItem('user_id', tokenDecoded.id); // User's id
              sessionStorage.setItem('user_name', tokenDecoded.name); // Name of the User
              sessionStorage.setItem('user_username', tokenDecoded.username); // User's username
              sessionStorage.setItem('user_email', tokenDecoded.email); // Email do utilizador
              sessionStorage.setItem('user_role', tokenDecoded.role); // User's role
              sessionStorage.setItem('user_role_name', tokenDecoded.roleName); // User's role
              sessionStorage.setItem('active', tokenDecoded.active); // User's role
              sessionStorage.setItem('permissions', tokenDecoded.permissions); // User's permissions
              observer.next({ success: true });
            } else {
              observer.next({ success: false });
            }
          }
        );
    });
  }

  logout() {
    sessionStorage.removeItem('token'); // Token
    sessionStorage.removeItem('token_expires_at'); // Token's expiration date
    sessionStorage.removeItem('user_id'); // User's id
    sessionStorage.removeItem('user_name'); // Name of the User
    sessionStorage.removeItem('user_username'); // User's username
    sessionStorage.removeItem('user_email'); // Email do utilizador
    sessionStorage.removeItem('user_role'); // User's role
    sessionStorage.removeItem('user_role_name'); // User's role
    sessionStorage.removeItem('active'); // User's role
    sessionStorage.removeItem('permissions'); // User's permissions
    this.router.navigate(['/login']);
  }

  public isLoggedIn() {
    // Checks if user has token
    if (sessionStorage.getItem('token')) {
      let current_time = Date.now().valueOf() / 1000;
      let token_expiration_time = parseInt(sessionStorage.getItem('token_expires_at'));
      // Checks if token has expired
      return token_expiration_time > current_time;
    }
    return false;
  }

  public hasPermissions(permission): boolean {
    const permissions = JSON.parse(sessionStorage.getItem('permissions'));
    return !!permissions[permission]; // Converte 0 e 1 em boolean (false e true)
  }

  getUserInfo() {
    return {
      id: sessionStorage.getItem('user_id'),
      name: sessionStorage.getItem('user_name'),
      email: sessionStorage.getItem('user_email'),
      username: sessionStorage.getItem('user_username'),
      role: sessionStorage.getItem('user_role'),
      roleName: sessionStorage.getItem('user_role_name'),
      permissions: JSON.parse(sessionStorage.getItem('permissions')),
    }
  }

  getUserToken() {
    return sessionStorage.getItem('token');
  }
}
