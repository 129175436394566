import * as globals from '../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { StaticPagesService } from '../../services/static-pages.service';
import { UtilsService } from '../../services/utils.service';
import { catchError } from 'rxjs/operators';

declare var tinymce: any;
@Component({
  selector: 'app-static-pages',
  templateUrl: './static-pages.component.html',
  styleUrls: ['./static-pages.component.scss']
})

export class StaticPagesComponent implements OnInit {

  nr_items_page = globals.nr_items_page;
  p; // paginação;
  pages;
  search: any = {};
  page;
  pageId;
  allFields: Boolean;
  permissions: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private staticPagesService: StaticPagesService,
    private utilsService: UtilsService,
    private auth: AuthService,
    ) { }


  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('StaticPagesCreate');
    this.permissions.edit = this.auth.hasPermissions('StaticPagesEdit');
    this.permissions.delete = this.auth.hasPermissions('StaticPagesDelete');
    this.permissions.read = this.auth.hasPermissions('StaticPagesRead');
    if (this.permissions.read) {
      this.getAllPages();
    }
  }

  getAllPages() {
    this.staticPagesService.getAllPages()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.pages = res as any;
        //console.log(this.pages);
      });
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }

  goToPage(pageId): void {
    this.router.navigate([globals.baseUrls.staticPages + '/' + pageId]);
  }

  changeIsVisible(pageId, page) {
    this.staticPagesService.changeIsVisible(pageId, page)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          if (aux.is_visible) {
            this.utilsService.sendNotification('success', 'Página visivel');
          } else {
            this.utilsService.sendNotification('success', 'Página Não visivel');
          }
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  goToAddPage() {
    this.router.navigate([globals.baseUrls.staticPages + '/create']);
  }
}
