import * as globals from '../../assets/globals';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ObservableLike } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class BrandsService {

  constructor(private http: HttpClient) { }

  apiRoute = globals.base_api_path + 'brands/';


  getAllBrands(): Observable<any> {
    return this.http.get(this.apiRoute);
  }

  getBrandInfoByBrandId(brandId): Observable<any> {
    return this.http.get(this.apiRoute + brandId);
  }

  getBrandVideosByBrandId(brandId): Observable<any> {
    return this.http.get(this.apiRoute + brandId + '/videos');
  }

  getBrandProductsByBrandId(brandId): Observable<any> {
    return this.http.get(this.apiRoute + brandId + '/products');
  }

  updateBrand(brandId, model) {
    return this.http.put(this.apiRoute + 'update/' + brandId, model);
  }

  updateBrandLogo(brandId, url): Observable<any> {
    return this.http.put(this.apiRoute + 'update/' + brandId + '/logo', { url: url });
  }

  updateBrandFeaturedImg(brandId, url): Observable<any> {
    return this.http.put(this.apiRoute + 'update/' + brandId + '/featured', { url: url });
  }

  getStoreNamesThatSellBrand(brandId) {
    return this.http.get(this.apiRoute + brandId + '/store-names');
  }

  addVideo(brandId, url): Observable<any> {
    return this.http.post(this.apiRoute + brandId + '/videos', { 'url': url });
  }

  deleteVideo(id): Observable<any> {
    return this.http.delete(this.apiRoute + 'video/' + id);
  }

  changeHidden(brandId, model): Observable<any> {
    return this.http.put(this.apiRoute + brandId + '/hidden', model);
  }

  getBrandTestimonials(brandId): Observable<any> {
    return this.http.get(this.apiRoute + brandId + '/testimonials');
  }

  approveTestimony(testimonyId): Observable<any> {
    return this.http.put(this.apiRoute + testimonyId + '/approve', null);
  }

  rejectTestimony(testimonyId): Observable<any> {
    return this.http.put(this.apiRoute + testimonyId + '/reject', null);
  }

  hiddenTestimony(testimonyId): Observable<any> {
    return this.http.put(this.apiRoute + testimonyId + '/testimony/hidden', null);
  }

  showTestimony(testimonyId): Observable<any> {
    return this.http.put(this.apiRoute + testimonyId + '/testimony/show', null);
  }

  deleteBrand(brandId: Observable<any>) {
    return this.http.put(this.apiRoute + 'delete/' + brandId, null);
  }

  getBrandsByProfessionalId(professioanlId): Observable<any> {
    return this.http.get(this.apiRoute + 'professional/' + professioanlId);
  }

  createProduct(model) {
    return this.http.post(this.apiRoute + 'create-product', { products: model });
  }

  createBrand(model) {
    return this.http.post(this.apiRoute + 'new-brand', model);
  }

  updateBrandsPlans() {
    return this.http.put(this.apiRoute + 'update-brandsPlan', null);
  }
}
