import * as globals from '../../assets/globals';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ObservableLike } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StoresService {

  constructor(private http: HttpClient) { }

  apiRoute = globals.base_api_path + 'stores/';


  getAllStores(): Observable<any> {
    //console.log(this.apiRoute);
    return this.http.get(this.apiRoute);
  }

  getStoresByProfessionalId(id): Observable<any> {
    return this.http.get(this.apiRoute + 'professional/' + id);
  }

  getAllStoresWithLocality(): Observable<any> {
    return this.http.get(this.apiRoute + 'with-locality');
  }

  getAllStoresForBrands(): Observable<any> {
    return this.http.get(this.apiRoute + 'for-brands');
  }

  getStoreInfoByStoreId(storeId): Observable<any> {
    return this.http.get(this.apiRoute + storeId);
  }

  getStoreFaqsByStoreId(storeId): Observable<any> {
    return this.http.get(this.apiRoute + storeId + '/faqs');
  }

  getStoreImagesByStoreId(storeId): Observable<any> {
    return this.http.get(this.apiRoute + storeId + '/images');
  }

  getStoreVideosByStoreId(storeId): Observable<any> {
    return this.http.get(this.apiRoute + storeId + '/videos');
  }

  getStoreTestimonialsByStoreId(storeId): Observable<any> {
    return this.http.get(this.apiRoute + storeId + '/testimonials');
  }

  getStorePromotionsByStoreId(storeId): Observable<any> {
    return this.http.get(this.apiRoute + storeId + '/promotions');
  }

  getStoreWeddingsByStoreId(storeId): Observable<any> {
    return this.http.get(this.apiRoute + storeId + '/weddings');
  }

  getStoreWeddingByWeddingId(weddingId): Observable<any> {
    return this.http.get(this.apiRoute + 'wedding/' + weddingId);
  }

  approveTestimony(testimonyId): Observable<any> {
    return this.http.put(this.apiRoute + testimonyId + '/approve', null);
  }

  rejectTestimony(testimonyId): Observable<any> {
    return this.http.put(this.apiRoute + testimonyId + '/reject', null);
  }

  getStoresThatSellBrand(brandId): Observable<any> {
    return this.http.get(this.apiRoute + 'stores-sell/' + brandId);
  }

  changeHidden(storeId, model): Observable<any> {
    return this.http.put(this.apiRoute + storeId + '/hidden', model);
  }

  changeDestinationWedding(storeId, model): Observable<any> {
    return this.http.put(this.apiRoute + storeId + '/weddingdestination', model);
  }

  changeisOnline(storeId, model): Observable<any> {
    return this.http.put(this.apiRoute + storeId + '/isonline', model);
  }

  updateStore(storeId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'update/' + storeId, model);
  }

  updateStoreLogo(storeId, url): Observable<any> {
    return this.http.put(this.apiRoute + 'update/' + storeId + '/logo/', { url: url });
  }

  updateStoreFeaturedImg(storeId, url): Observable<any> {
    return this.http.put(this.apiRoute + 'update/' + storeId + '/featured/', { url: url });
  }

  deleteStore(storeId): Observable<any> {
    return this.http.put(this.apiRoute + 'delete/' + storeId, null);
  }

  addFaqs(storeId, faqs): Observable<any> {
    return this.http.post(this.apiRoute + storeId + '/faqs', { 'faqs': faqs });
  }

  updateFaq(faq): Observable<any> {
    return this.http.put(this.apiRoute + 'faqs/' + faq.id, faq);
  }

  deleteFaq(id): Observable<any> {
    return this.http.delete(this.apiRoute + 'faqs/' + id);
  }

  hiddenTestimony(testimonyId): Observable<any> {
    return this.http.put(this.apiRoute + testimonyId + '/testimony/hidden', null);
  }

  showTestimony(testimonyId): Observable<any> {
    return this.http.put(this.apiRoute + testimonyId + '/testimony/show', null);
  }

  uploadPictureStorebyId(id, imgs): Observable<any> {
    return this.http.post(this.apiRoute + id + '/newimage', { images: imgs });
  }

  deletePicturebyId(storeId, id): Observable<any> {
    return this.http.delete(this.apiRoute + storeId + '/image/' + id);
  }

  addVideo(storeId, url): Observable<any> {
    return this.http.post(this.apiRoute + storeId + '/videos', { 'url': url });
  }

  deleteVideo(id): Observable<any> {
    return this.http.delete(this.apiRoute + 'video/' + id);
  }

  addPromotion(storeId, modelPromotion): Observable<any> {
    return this.http.post(this.apiRoute + storeId + '/promotions', modelPromotion);
  }

  updatePromotion(promoId, modelPromotion): Observable<any> {
    return this.http.put(this.apiRoute + 'promo/' + promoId, modelPromotion);
  }

  deletePromotion(promoId): Observable<any> {
    return this.http.delete(this.apiRoute + 'promo/' + promoId);
  }

  getStorePromotionByPromotionId(promotionId): Observable<any> {
    return this.http.get(this.apiRoute + 'promotion/' + promotionId);
  }

  createDirectoryStore(store): Observable<any> {
    return this.http.post(this.apiRoute + 'directory/', store);
  }

  createStore(model) {
    return this.http.post(this.apiRoute + 'new-store', model);
  }

  updateStoresPlans() {
    return this.http.put(this.apiRoute + 'update-storesPlan', null);
  }
}
