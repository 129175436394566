import * as globals from '../../../assets/globals';

import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { BannersService } from 'src/app/services/banners.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {

  permissions: any = {};
  banners: any = [];

  dtOptions: any = {};

  constructor(
    private bannersService: BannersService,
    private auth: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.dtOptions = {
      paging: false,
      info: false
    };
    this.permissions.create = this.auth.hasPermissions('BannersCreate');
    this.permissions.edit = this.auth.hasPermissions('BannersEdit');
    this.permissions.delete = this.auth.hasPermissions('BannersDelete');
    this.permissions.read = this.auth.hasPermissions('BannersRead');
    if (this.permissions.read) {
      this.getBanners();
    }
  }

  getBanners() {
    this.bannersService.getAllBanners()
    .subscribe(res => {
      this.banners = res;
    });
  }

  openDetails(id) {
    this.router.navigate([globals.baseUrls.banners, id]);
  }

}
