export const base_api_path = 'https://api-gestao.ilovebrides.pt/'; //Servidor ILB
//export const base_api_path = 'http://admin-api.senber.pt/'; //Servidor Senber (TESTES)
//export const base_api_path = 'http://localhost:8090/'; //Local


export const site_base_url = 'https://ilovebrides.pt/';
//export const site_base_url = 'http://senber.pt/';

export const baseUrls = {
  home: '',
  login: 'login',
  blog: 'blog',
  users: 'users',
  stores: 'stores',
  brands: 'brands',
  ilovetv: 'tv',
  weddings: 'weddings',
  courses: 'courses',
  staticPages: 'static-pages',
  banners: 'banners',
  destinationWeddings: 'destination-weddings',
  management: 'management',
  sweepstakes: 'sorteios',
  homepage: 'homepage',
  announcements: 'announcements',
  pages: 'pages',
  magazine: 'magazine'
}

//Url base para os ficheiros
export const filesBasePath = '';

export const portugalCountryCode = 'PT';
export const portugalCountryName = 'Portugal';

export const nr_items_page = 10;

export const defaults = {
  cartao_vantagens_oferta: '10% de desconto'
};

export const wedding_providers_activities = ['Vestidos de Noiva', 'Sapatos de Noiva', 'Ramo de Noiva', 'Fatos de Noivo', 'Sapatos de Noivo', 'Alianças', 'Convites', 'Brindes', 'Bolo de Noiva', 'Fotografia', 'Vídeo', 'Música', 'Local do Banquete', 'Catering', 'Transporte', 'Decoração', 'Marcadores de Mesa', 'Animação', 'Lua de Mel', 'Wedding Planner', 'Cabelos', 'Maquilhagem'];

export const limitPhotosWeddings = 50;

//Códigos de Erros
export const errorCodes = {
  planLimitExceeded: 'PLAN_LIMIT_EXCEEDED',
  adminUserWrongCredentials: 'ADMIN_USER_WRONG_CREDENTIALS',
  adminUserNotActive: 'ADMIN_USER_NOT_ACTIVE',
};
