import { Component, OnInit, TemplateRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilsService } from '../../../../services/utils.service';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { MagazinesService } from '../../../../services/magazines.service';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import * as globals from '../../../../../assets/globals';

@Component({
  selector: 'app-magazines-images',
  templateUrl: './magazines-images.component.html',
  styleUrls: ['./magazines-images.component.scss']
})
export class MagazinesImagesComponent implements OnInit {

  uploader: FileUploader;
  imgs: any[];
  nrImgs = 0;

  permissions: any = {};
  images: any[];
  magazineYear;
  magazineId;

  p1 = 1;
  siteUrl = globals.site_base_url;

  constructor(private magazinesService: MagazinesService,
    private utilsService: UtilsService,
    private spinner: NgxSpinnerService,
    private auth: AuthService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.permissions.edit = this.auth.hasPermissions('MagazinesEdit');
    this.permissions.read = this.auth.hasPermissions('MagazinesRead');
    if (this.permissions.read) {
      this.defineUploader();
      this.getUrlParams();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.magazineYear = params.magazineYear;
      this.magazineId = params.magazineId;
      this.getMagazineImages();
    });
  }

  getMagazineImages() {
    this.magazinesService.getPaperMagazineImages(this.magazineYear)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        this.images = aux;
      });
  }

  defineUploader() {
    this.uploader = new FileUploader({
      url: globals.base_api_path + 'files/upload/paper-magazine/images',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.imgs = [];
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.imgs.push(JSON.parse(response));
      this.uploadImgs();
    };
    this.uploader._onErrorItem = (err) => {
      this.uploader.clearQueue();
      this.utilsService.sendSystemErrorAlert();
    };
  }

  onUpload() {
    this.uploader.queue.forEach(img => {
      img._onBuildForm = (form) => {
        form.append('magazineId', this.magazineId);
        form.append('magazineYear', this.magazineYear);
      };
      this.nrImgs++;
      img.upload();
    });
  }

  uploadImgs() {
    if (this.imgs.length === this.nrImgs) {
      this.magazinesService.uploadPaperMagazineImages(this.magazineId, this.imgs)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            this.utilsService.sendSystemErrorAlert();
            return Observable.throw(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.getMagazineImages();
            this.utilsService.sendNotification('success', 'Imagens adicionadas');
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
          //this.resetImages();
        });
    }
  }

  removeImage(index: number) {
    index = 8 * (this.p1 - 1) + index;
    this.utilsService.sendConfirmationQuestion('Tem a certeza que quer apagar a imagem?').then(confirm => {
      if (confirm) {
        this.magazinesService.deletePaperMagazineImage(this.magazineId, this.images[index].id)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (aux.success) {
              this.images.splice(index, 1);
              this.utilsService.sendNotification('success', 'Imagem apagada');
            } else {
              this.utilsService.sendSystemErrorAlert();
            }
          });
      }
    });
  }

  resetImages() {
    this.imgs = [];
    this.nrImgs = 0;
    this.uploader.clearQueue();
  }

}
