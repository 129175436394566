import * as globals from '../../../../../assets/globals';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, Host, OnInit } from '@angular/core';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';
import { FormControl, FormGroup } from '@angular/forms';

import { AuthService } from 'src/app/services/auth.service';
import { BlogService } from '../../../../services/blog.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from '../../../../services/utils.service';
import { catchError } from 'rxjs/operators';

declare var tinymce: any;

@Component({
  selector: 'app-post-details',
  templateUrl: './post-details.component.html',
  styleUrls: ['./post-details.component.scss']
})

export class PostDetailsComponent implements OnInit {

  uploaderFeatured: FileUploader;
  uploaderImages: FileUploader;
  uploaderBlog: FileUploader;

  permissions: any = {};

  featured_img: any = {};
  images: any[] = [];
  imagesToInsert: any[] = [];
  imagesToDelete: any[] = [];
  addUrl;
  videosToAdd = [];
  videosToDelete = [];
  showImgsArray;
  updated = false;
  model_edit: any = {
    html_post: '',
    title: '',
    sub_title: '',
    category: '',
    featured_img: ''
  };
  editorForm: FormGroup;
  categories = [];
  post;
  postId;
  bloggerId: string;
  allFields: Boolean;
  siteUrl = globals.site_base_url;
  bloggers;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private blogService: BlogService,
    private utilsService: UtilsService,
    public sanitizer: DomSanitizer,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('BlogCreate');
    this.permissions.edit = this.auth.hasPermissions('BlogEdit');
    this.permissions.delete = this.auth.hasPermissions('BlogDelete');
    this.permissions.read = this.auth.hasPermissions('BlogRead');
    if (this.permissions.edit) {
      this.getUrlParams();
    }
  }

  initModel() {
    this.model_edit = {};
    this.featured_img = {};
    this.model_edit.images = [];
    this.model_edit.videos = [];
    this.showImgsArray = [];
    this.allFields = true;
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.postId = params.postId;
      this.initModel();
      this.defineUploaderFeatured();
      this.defineUploaderImages();
      this.defineUploaderBlog();
      this.getSubCategories();
      this.getAllBloggers();
      this.getPost();
    });
  }

  onSubmit() {
    //this.addToModel();
    //console.log('on submit');
    this.editPost();
  }

  goBack() {

  }

  verifyModel() {
    if (this.model_edit.title == undefined || this.model_edit.title.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_edit.sub_title == undefined || this.model_edit.sub_title.trim().length === 0) {
      this.model_edit.sub_title = '';
    }
    if (this.model_edit.category == undefined) {
      this.allFields = false;
    }
    if (this.model_edit.posted == undefined || this.model_edit.posted.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_edit.html_post == undefined || this.model_edit.html_post.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_edit.featured_img == undefined) {
      this.model_edit.featured_img = this.post.feature_img;
    }
  }

  editPost() {
    this.verifyModel();
    if (this.allFields == false) {
      this.utilsService.sendConfirmation('warning', 'Necessita de Preencher todos os campos em branco');
      this.allFields = true;
    } else {
      this.blogService.updatePost(this.postId, this.model_edit)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.uploadFeaturedImage();
            this.uploadImages();
            this.insertVideos();
            this.removeImages();
            this.removeVideos();
            this.utilsService.sendNotification('success', 'Post atualizado');
            this.goBack();
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
        });
    }
  }

  getPost() {
    this.blogService.getPostById(this.postId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        this.post = aux;
        //console.log(this.post);
        this.initEditor(aux.html_post);
        //console.log(res);
        this.model_edit.title = aux.title;
        this.model_edit.sub_title = aux.sub_title;
        this.model_edit.category = aux.blogcategory_id;
        this.model_edit.blogger_id = aux.blogger_id;
        this.model_edit.posted = this.utilsService.convertDateStringToDate(aux.posted);
        this.featured_img.url = aux.feature_img;
        this.getPostImgs();
        this.getPostVideos();
      });
  }

  getPostImgs() {
    this.blogService.getPostImgs(this.postId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(resImages => {
        //console.log(resImages);
        this.model_edit.images = resImages;
        this.showImgsArray = resImages;
      });
  }

  getPostVideos() {
    this.blogService.getPostVideos(this.postId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(resVideos => {
        //console.log(resVideos);
        this.model_edit.videos = resVideos;
      });
  }

  initEditor(content) {
    tinymce.init({
      selector: 'textarea',  // change this value according HTML
      document_base_url: globals.site_base_url,
      menubar: 'insert file tools',
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste imagetools wordcount',
        'code',
        'autoresize'
      ],
      min_height: 500,
      max_height: 1000,
      autoresize_bottom_margin: 15,
      toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify fullscreen| bullist numlist outdent indent | link | mybutton table | code',
      content_css: [
        '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
        '//www.tiny.cloud/css/codepen.min.css'
      ],
      setup: editor => {
        editor.on('init', () => {
          editor.setContent(content);
          this.model_edit.html_post = editor.getContent();
        });
        editor.on('keyup change', () => {
          this.model_edit.html_post = editor.getContent();
        });
        editor.ui.registry.addButton('mybutton', {
          text: 'Image',
          tooltip: 'image',
          onAction: function (_) {
            var input = document.getElementById('ImgUploadHtml');
            input.click();
            editor.insertContent(input);
          }
        });
      }
    });
  }

  getSubCategories() {
    this.blogService.getBlogCategoriesWithSubCategories()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.categories = res as any;
      });
  }

  defineUploaderBlog() {
    this.uploaderBlog = new FileUploader({
      url: globals.base_api_path + 'files/post/text',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderBlog.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const location = JSON.parse(response).url;
      tinymce.activeEditor.insertContent('<img style="width: 100%;" src="' + location + '" alt="Imagem inserida no corpo do post"/></div>');
      this.defineUploaderBlog();
    };
    this.uploaderBlog._onErrorItem = (err) => {
      this.uploaderBlog.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderBlog();
    };
  }

  uploadQueueFiles() {
    this.uploaderBlog.uploadAll();
  }

  defineUploaderFeatured() {
    this.uploaderFeatured = new FileUploader({
      url: globals.base_api_path + 'files/post/featured',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',

    });
    this.uploaderFeatured.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.model_edit.featured_img = JSON.parse(response).url;
      this.model_edit.id = JSON.parse(response).post_id;
      this.blogService.updatePost(this.postId, this.model_edit)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (this.updated && aux.success) {
            this.utilsService.sendNotification('success', 'Post atualizado');
            this.updated = false;
          } else if (!aux.success) {
            this.utilsService.sendSystemErrorAlert();
          }
        });
      this.defineUploaderFeatured();
    };
    this.uploaderFeatured._onErrorItem = (err) => {
      this.uploaderFeatured.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderFeatured();
    };
  }

  defineUploaderImages() {
    this.uploaderImages = new FileUploader({
      url: globals.base_api_path + 'files/post',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderImages.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.imagesToInsert.push(JSON.parse(response).url);
      this.insertImages();
    };
    this.uploaderImages._onErrorItem = (err) => {
      this.uploaderImages.clearQueue();
      //console.log(err);
      this.utilsService.sendSystemErrorAlert();
    };
  }

  uploadFeaturedImage() {
    this.uploaderFeatured.queue.forEach((img, index) => {
      img._onBuildForm = (form) => {
        form.append('id', this.postId);  //TODO post id
      };
      img.upload();
    });
  }


  uploadImages() {
    this.uploaderImages.queue.forEach((img, index) => {
      //console.log('img');
      //console.log(this.model_edit.id);
      img._onBuildForm = (form) => {
        form.append('id', this.postId); //TODO post id
      };
      img.upload();
    });
  }

  showFeaturedImage() {
    if (this.uploaderFeatured.queue.length > 1) {
      this.uploaderFeatured.queue.splice(0, 1);
    }
    this.uploaderFeatured.queue.forEach(foto => {
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      this.featured_img.url = url;
      this.featured_img.foto = foto;
    });
  }

  showImages() {
    this.showImgsArray = [...this.model_edit.images, ...[]];
    this.uploaderImages.queue.forEach(foto => {
      const img: any = {};
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      img.sanitizer_url = url;
      img.foto = foto;
      img.toUpload = true;
      this.showImgsArray.push(img);
    });
  }

  deleteImage(index) {
    const img = this.showImgsArray.splice(index, 1)[0];
    if (img.toUpload) {
      const uploaderIndex = this.uploaderImages.queue.findIndex(item => item.file.name == img.foto.file.name);
      this.uploaderImages.queue.splice(uploaderIndex, 1);
    } else {
      this.imagesToDelete.push(img.id);
    }
  }

  removeImages() {
    if (this.imagesToDelete.length > 0) {
      this.blogService.deleteImages(this.postId, this.imagesToDelete)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe();
    }
  }

  insertImages() {
    if (this.uploaderImages.queue.length == this.imagesToInsert.length && this.imagesToInsert.length > 0) {
      this.blogService.insertImages(this.postId, this.imagesToInsert)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe();
    }
  }

  insertVideos() {
    if (this.videosToAdd.length > 0) {
      this.blogService.insertVideos(this.postId, this.videosToAdd)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe();
    }
  }

  removeVideos() {
    if (this.videosToDelete.length > 0) {
      this.blogService.deleteVideos(this.postId, this.videosToDelete)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe();
    }
  }

  addVideo() {
    if (this.addUrl && this.addUrl.trim() !== '') {
      this.videosToAdd.push(this.addUrl);
      this.model_edit.videos.push({ video_url: this.addUrl });
      this.addUrl = '';
    }
  }

  deleteVideo(index) {
    this.videosToDelete.push(this.model_edit.videos.splice(index, 1)[0].id);
  }

  getEmbedVideos(index) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      this.utilsService.getEmbedUrlFromVideoUrl(this.model_edit.videos[index].video_url)
    );
  }

  deletePost() {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja elimiar o Post?', 'Este processo é irreversível').then(confirm => {
      //this.spinner.show();
      this.blogService.deletePost(this.postId)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          //this.spinner.hide();
          this.utilsService.sendNotification('success', 'Post eliminado');
          this.goBack();
        });
    });
  }

  getAllBloggers() {
    this.blogService.getAllBloggers()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.bloggers = res as any;
      });
  }
}
