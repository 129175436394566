import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Inject, Injectable } from "@angular/core";
import { Observable, throwError } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { LOCAL_STORAGE } from '@ng-toolkit/universal'
import { Router } from '@angular/router';
import { UtilsService } from '../services/utils.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private utils: UtilsService,
        private auth: AuthService,
        private router: Router
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Clone the request to add the token header
        if (sessionStorage.getItem("token")) {
            const authReq = req.clone({ headers: req.headers.set("x-access-token", sessionStorage.getItem("token")) });

            // Send the newly created request
            return next.handle(authReq)
                .pipe(
                    catchError((err) => {
                        // Checks if error was caused due to invalid/expired token
                        if (err instanceof HttpErrorResponse && err.status === 401) {
                            this.utils.sendConfirmation('error', 'Não tem permissões', 'O utilizador não tem permissões para a operação que está a tentar efetuar');
                        } else {
                            this.utils.sendSystemErrorAlert();
                        }
                        return throwError(err);
                    }) as any);
        } else {
            return next.handle(req).pipe(
                catchError((err) => {
                    // Checks if error was caused due to invalid/expired token
                    if (err instanceof HttpErrorResponse && err.status === 401) {
                        this.auth.logout();
                    }
                    return throwError(err);
                }) as any);
        }
    }
}
