import * as globals from '../../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProfessionalsService } from '../../../../services/professionals.service';
import { UsersService } from '../../../../services/users.service';
import { UtilsService } from '../../../../services/utils.service';
import { catchError } from 'rxjs/operators';

type distico = 'white' | 'black' | 'black-background';
type distico_language = 'pt' | 'en';

@Component({
  selector: 'app-professional-user',
  templateUrl: './professional-user.component.html',
  styleUrls: ['./professional-user.component.scss']
})
export class ProfessionalUserComponent implements OnInit {

  constructor(
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    private router: Router,
    private professionalsService: ProfessionalsService,
    private usersService: UsersService,
    private spinner: NgxSpinnerService,
    private auth: AuthService,
  ) { }

  userId;
  professionalId;
  changePassword: Boolean;
  model_user: any = {};
  model_professional: any = {};
  model_password: any = {};
  countries: any[];
  districts: any[];
  cities: any[];
  editing = false;
  loading;
  editInfo: Boolean;
  edited: Boolean;
  phone_code;

  oldEmail;

  permissions: any = {};

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('UsersCreate');
    this.permissions.edit = this.auth.hasPermissions('UsersEdit');
    this.permissions.delete = this.auth.hasPermissions('UsersDelete');
    this.permissions.read = this.auth.hasPermissions('UsersRead');
    this.changePassword = false;
    this.edited = false;
    if (this.permissions.read) {
      this.getUrlParams();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.userId = params['userId'];
      this.professionalId = params['professionalId'];
      this.initDropdowns();
      this.getData();
    });
  }

  getData() {
    this.usersService.getUserData(this.userId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.model_user = data;
        this.oldEmail = this.model_user.email;
        this.professionalsService
          .getProfessionalData(this.userId)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(data => {
            this.model_professional = data;
            this.searchPostal(null);
            this.updatePhoneCode();
          });
      });
  }

  editInfoToggle() {
    this.editInfo = !this.editInfo;
  }

  cancelInfoToggle() {
    this.getData();
  }

  mudarPassword() {
    this.changePassword = !this.changePassword;
  }

  initDropdowns() {
    this.utilsService.getCountries()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.countries = data as any;
        this.updatePhoneCode();
      });
    this.utilsService.getDistricts()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any).subscribe(data => {
          this.districts = data as any;
        });
    this.utilsService.getCities()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.cities = data as any;
      });
  }

  editData() {
    this.loading = true;
    if (this.isNifValid()) {
      if (this.isValidIBAN()) {
        if (
          this.model_professional.address == undefined ||
          this.model_professional.address.trim().length === 0
        ) {
          this.model_professional.address = "";
        }
        if (
          this.model_professional.postal == undefined ||
          this.model_professional.postal.trim().length === 0
        ) {
          this.model_professional.postal = "";
        }
        if (
          this.model_professional.iban == undefined ||
          this.model_professional.iban.trim().length === 0
        ) {
          this.model_professional.iban = "";
        }
        this.model_user.district = this.model_professional.district;
        this.model_user.oldEmail = this.oldEmail;
        if (this.oldEmail != this.model_user.email) {
          this.model_user.changeEmail = true;
        }
        this.usersService.putUserData(this.userId, this.model_user)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            let aux: { success: Boolean };
            aux = res as any;
            if (aux.success) {
              this.professionalsService.putProfessionalData(this.userId, this.model_professional)
                .pipe(
                  catchError((err: HttpErrorResponse) => {
                    if (err.error && err.error.message.code === 'ER_DUP_ENTRY') {
                      const message = err.error.message.sqlMessage;
                      if (message.includes('email')) {
                        this.utilsService.sendConfirmation('error', 'O email fornecido já se encontra registado');
                      } else if (message.includes('username')) {
                        this.utilsService.sendConfirmation('error', 'O username introduzido já se encontra utilizado');
                      } else if (message.includes('nif')) {
                        this.utilsService.sendConfirmation('error', 'O nif introduzido já se encontra utilizado');
                      }
                    } else {
                      this.utilsService.sendSystemErrorAlert();
                    }
                    return this.utilsService.sendSystemErrorAlert(err);
                  }) as any)
                .subscribe(res => {
                  let aux: { success: Boolean };
                  aux = res as any;
                  if (aux.success) {
                    this.utilsService.sendNotification('success', 'Dados de Perfil atualizados');
                  } else {
                    this.utilsService.sendSystemErrorAlert();
                  }
                  this.loading = false;
                  this.editing = false;
                });
            } else {
              this.utilsService.sendSystemErrorAlert()
              this.loading = false;
            }
          });
        this.edited = true;
        this.editInfoToggle();
      } else {
        this.loading = false;
        this.utilsService.sendConfirmation('warning', 'O IBAN introduzido não é válido');
      }
    } else {
      this.loading = false;
      this.utilsService.sendConfirmation('warning', 'O NIF inserido não é válido');
    }
  }

  alterarPassword() {
    if (this.model_password.password == this.model_password.password_check) {
      this.usersService.changePassword(this.userId, this.model_password.password)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          let aux: { success: Boolean };
          aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Password Atualizada');
            this.changePassword = false;
            this.model_password = {};
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
        });
    } else {
      this.utilsService.sendConfirmation('warning', 'As passwords não coincidem');
    }
  }

  isNifValid() {
    if (!this.model_professional.nif || this.model_professional.country !== globals.portugalCountryName) {
      return true;
    }
    return this.utilsService.validateNIF(this.model_professional.nif);
  }

  //VALIDAÇÂO IBAN

  isValidIBAN() {
    if (!this.model_professional.iban) {
      return true;
    }
    return this.utilsService.isValidIBANNumber(this.model_professional.iban);
  }

  isPTSelected() {
    if (this.countries && this.model_professional.country) {
      return this.model_professional.country == globals.portugalCountryName;
    } else {
      return false;
    }
  }

  searchPostal(postal) {
    if (this.model_professional.postal.length == 8 && this.isPTSelected()) {
      const splited_postal = this.model_professional.postal.split('-', 2);
      if (splited_postal[0].length === 4 && splited_postal[1].length === 3) {
        //console.log(splited_postal);
        this.utilsService.getPostalInfo(splited_postal[0], splited_postal[1])
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            this.model_professional.district = this.districts.find(dist => dist.id === aux.district_id).name;
            this.model_professional.city = this.cities.find(city => city.id === aux.city_id).name;
            this.model_professional.locality = aux.locality;
          });
      }
    }
  }

  updatePhoneCode() {
    this.phone_code = this.countries.find(country => country.countryName === this.model_professional.country).phoneCode;
  }

  deleteAccount() {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja eliminar a sua conta?', 'Este processo é irreversível').then(confirm => {
      if (confirm) {
        this.spinner.show();
        this.professionalsService.deleteAccount(this.userId)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            this.spinner.hide();
            this.utilsService.sendConfirmation('success', 'Conta eliminada');
            this.goBack();
          });
      }
    });
  }

  goBack() {
    const aux = this.router.url.split('/');
    aux.length -= 2;
    const backLink = aux.join('/');
    this.router.navigate([backLink]);
  }
}
