import * as globals from '../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { BrandsService } from '../../services/brands.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from '../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})


export class BrandsComponent implements OnInit {

  permissions: any = {};

  nr_items_page = globals.nr_items_page;
  p; // paginação;
  brands;
  search: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private brandsService: BrandsService,
    private utilsService: UtilsService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    console.log('ole');
    this.permissions.create = this.auth.hasPermissions('BrandsCreate');
    this.permissions.edit = this.auth.hasPermissions('BrandsEdit');
    this.permissions.delete = this.auth.hasPermissions('BrandsDelete');
    this.permissions.read = this.auth.hasPermissions('BrandsRead');
    if (this.permissions.read) {
      this.getAllBrands();
    }
  }

  getAllBrands() {
    this.brandsService.getAllBrands()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.brands = res as any;
        // this.initStoresArray();
      });
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }

  goToCreateBrand(): void {
    this.router.navigate([globals.baseUrls.brands + '/create']);
  }

  goToBrand(brandId, page): void {
    this.router.navigate([globals.baseUrls.brands + '/' + brandId + '/' + page]);
  }

  changeHidden(brandId, brand) {
    this.brandsService.changeHidden(brandId, brand)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          this.utilsService.sendNotification('success', 'Marca Não visivel');
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });;
  }

  /*updateBrandsPlans() {
    this.brandsService.updateBrandsPlans()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          this.utilsService.sendNotification('success', 'Planos atualizados');
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }*/
}
