import * as globals from '../../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, Host, Inject, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { BlogService } from '../../../../services/blog.service';
import { FileUploader } from 'ng2-file-upload';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from '../../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-blogger-details',
  templateUrl: './blogger-details.component.html',
  styleUrls: ['./blogger-details.component.scss']
})

export class BloggerDetailsComponent implements OnInit {
  siteUrl = globals.site_base_url;
  changePassword: Boolean;
  model_edit: any = {};
  model_password: any = {};
  blogger;
  bloggerId: any;
  loading = false;
  hidden = true;
  edited: Boolean;
  uploaderFeatured: FileUploader;
  featured_img: any = {};
  imgDate: any;
  permissions: any = {};

  constructor(private route: ActivatedRoute,
    private router: Router,
    private blogService: BlogService,
    private utilsService: UtilsService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('BlogCreate');
    this.permissions.edit = this.auth.hasPermissions('BlogEdit');
    this.permissions.delete = this.auth.hasPermissions('BlogDelete');
    this.permissions.read = this.auth.hasPermissions('BlogRead');
    if (this.permissions.read) {
      this.getUrlParams();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.bloggerId = params.bloggerId;
      this.getBloggerInfo();
      this.defineUploader();
    });
  }

  getBloggerInfo() {
    this.blogService.getBloggerInfoByBloggerId(this.bloggerId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.blogger = res as any;
        this.model_edit = this.blogger;
      });
  }

  defineUploader() {
    this.uploaderFeatured = new FileUploader({
      url: globals.base_api_path + 'files/upload/blogger/profile-img',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderFeatured.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.model_edit.foto_url = JSON.parse(response).url;
      this.imgDate = Date.now();
      this.blogService.editData(this.bloggerId, this.model_edit)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Fotografia de perfil atualizada');
            this.loading = false;
          } else if (!aux.success) {
            this.utilsService.sendSystemErrorAlert();
          }
        });
      this.defineUploader();
    };
    this.uploaderFeatured._onErrorItem = (err) => {
      this.uploaderFeatured.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploader();
    };
  }

  uploadFeaturedImage() {
    this.uploaderFeatured.queue.forEach(img => {
      img._onBuildForm = (form) => {
        form.append('bloggerName', this.utilsService.getUrlFromName(this.model_edit.name));
      };
      img.upload();
    });
    this.uploaderFeatured.uploadAll();
  }

  showFeaturedImage() {
    if (this.uploaderFeatured.queue.length > 1) {
      this.uploaderFeatured.queue.splice(0, 1);
    }
    this.uploaderFeatured.queue.forEach(foto => {
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      this.featured_img.url = url;
      this.featured_img.foto = foto;
    });
  }

  mudarPassword() {
    this.changePassword = true;
  }

  alterarPassword() {
    this.loading = true;
    if (this.model_password.password == this.model_password.password_check) {
      this.blogService.changePassword(this.bloggerId, this.model_password.password)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          let aux: { success: Boolean };
          aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Password Atualizada');
            this.loading = false;
            this.changePassword = false;
            this.model_password = {};
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
        });
    } else {
      this.utilsService.sendConfirmation('warning', 'As passwords não coincidem');
    }
  }

  editData() {
    this.loading = true;
    this.blogService.editData(this.bloggerId, this.model_edit)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (!err.error) {
            this.utilsService.sendSystemErrorAlert();
          } else if (err.error.message.code === 'ER_DUP_ENTRY') {
            const message = err.error.message.sqlMessage;
            if (message.includes('email')) {
              this.utilsService.sendConfirmation('error', 'O email fornecido já se encontra registado');
            }
          }
          this.loading = false;
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          this.uploadFeaturedImage();
          this.utilsService.sendNotification('success', 'Dados Atualizados');
        }
        this.loading = false;
      });
  }

  deleteBlogger() {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja elimiar o Blogger?', 'Este processo é irreversível').then(confirm => {
      //this.spinner.show();
      this.blogService.deleteBlogger(this.bloggerId)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          //this.spinner.hide();
          this.utilsService.sendNotification('success', 'Blogger eliminado(a)');
          this.goBack();
        });
    });
  }

  cancelInfoToggle() {
    this.model_edit = this.blogger;
  }

  goBack() {
    const aux = this.router.url.split('/');
    aux.length -= 1;
    const backLink = aux.join('/');
    this.router.navigate([backLink]);
  }
}
