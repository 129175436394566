import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, Host, Inject, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { FileUploader } from 'ng2-file-upload';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StoresService } from '../../../services/stores.service';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-store-info',
  templateUrl: './store-info.component.html',
  styleUrls: ['./store-info.component.scss']
})

export class StoreInfoComponent implements OnInit {

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private storesService: StoresService,
    private route: ActivatedRoute,
    private auth: AuthService,
  ) { }

  model_edit: any = {};
  store: unknown;
  storeId: any;
  countries: any[];
  districts: any[];
  activities: any[];
  cities: any[];
  plans: any[];
  error = '';
  loading = false;
  hidden = true;
  editing = false;
  siteUrl = globals.site_base_url;
  editInfo: boolean;
  edited: boolean;

  uploaderLogo: FileUploader;
  uploaderFeatured: FileUploader;
  phoneCode: any;
  imgDate: any;

  selectedPlanSubscribable: boolean = false;
  selectedNextPlanSubscribable: boolean = false;
  permissions: any = {};

  ngOnInit() {
    //this.initDropdowns();
    //this.editInfo = false;
    //this.edited = false;
    this.permissions.create = this.auth.hasPermissions('StoresCreate');
    this.permissions.edit = this.auth.hasPermissions('StoresEdit');
    this.permissions.delete = this.auth.hasPermissions('StoresDelete');
    this.permissions.read = this.auth.hasPermissions('StoresRead');
    if (this.permissions.read) {
      this.getUrlParams();
      this.defineUploaderLogo();
      this.defineUploaderFeatured();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.storeId = params['storeId'];
      this.initDropdowns();
      this.getStoreInfo();
    });
  }

  defineUploaderLogo() {
    this.uploaderLogo = new FileUploader({
      url: globals.base_api_path + 'files/upload/store/logo',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderLogo.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const url = JSON.parse(response).url;
      this.storesService.updateStoreLogo(this.storeId, url)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          if ((res as any).success) {
            this.model_edit.logo_url = url;
            this.imgDate = Date.now();
            this.utilsService.sendNotification('success', 'Logo atualizado');
          }
        });
      this.defineUploaderLogo();
    };
    this.uploaderLogo._onErrorItem = (err) => {
      this.uploaderLogo.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderLogo();
    };
  }

  defineUploaderFeatured() {
    this.uploaderFeatured = new FileUploader({
      url: globals.base_api_path + 'files/upload/store/featured',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderFeatured.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const url = JSON.parse(response).url;
      this.storesService.updateStoreFeaturedImg(this.storeId, url)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          if ((res as any).success) {
            this.model_edit.featured_img_url = url;
            this.imgDate = Date.now();
            this.utilsService.sendNotification('success', 'Imagem de destaque atualizada');
          }
        });
      this.defineUploaderFeatured();
    };
    this.uploaderFeatured._onErrorItem = (err) => {
      this.uploaderFeatured.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderFeatured();
    };
  }

  onUploadLogo() {
    this.uploaderLogo.queue.forEach(img => {
      img._onBuildForm = (form) => {
        form.append('storeName', this.utilsService.getUrlFromName(this.model_edit.name));
        form.append('storeId', this.model_edit.id);
        form.append('activity', this.utilsService.getUrlFromName(this.model_edit.activity_name));
      };
      img.upload();
    });
    this.uploaderLogo.uploadAll();
  }

  onUploadFeatured() {
    this.uploaderFeatured.queue.forEach(img => {
      img._onBuildForm = (form) => {
        form.append('storeName', this.utilsService.getUrlFromName(this.model_edit.name));
        form.append('storeId', this.model_edit.id);
        form.append('activity', this.utilsService.getUrlFromName(this.model_edit.activity_name));
      };
      img.upload();
    });
    this.uploaderFeatured.uploadAll();
  }

  getStoreInfo() {
    this.storesService.getStoreInfoByStoreId(this.storeId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.store = res as any;
        this.model_edit = this.store;
        if (this.model_edit.paid_plan_limit !== undefined && this.model_edit.paid_plan_limit !== null) {
          this.model_edit.paid_plan_limit = this.utilsService.convertDateStringToDate(this.model_edit.paid_plan_limit);
        }
        if (this.model_edit.next_paid_plan_limit !== undefined && this.model_edit.next_paid_plan_limit !== null) {
          this.model_edit.next_paid_plan_limit = this.utilsService.convertDateStringToDate(this.model_edit.next_paid_plan_limit);
        }
        this.updatePhoneCode();
        this.isSelectedPlanSubscribable();
        console.log(this.store);
      });
  }

  editInfoToggle() {
    this.editInfo = !this.editInfo;
  }

  cancelInfoToggle() {
    this.editInfo = false;
    // this.parent.getStoreByUrlParams();
    this.getStoreInfo();
  }

  editData() {
    if (this.model_edit.address == undefined || this.model_edit.address.trim().length == 0) {
      this.model_edit.address = '';
    }
    if (this.model_edit.postal == undefined || this.model_edit.postal.trim().length == 0) {
      this.model_edit.postal = '';
    }
    if (!this.model_edit.cartao_vantagens) {
      this.model_edit.cartao_vantagens_oferta = '';
    }
    if (this.model_edit.paid_plan_limit == undefined || (this.model_edit.paid_plan_limit !== null && this.model_edit.paid_plan_limit.trim().length == 0)) {
      this.model_edit.paid_plan_limit = null;
    }
    if (this.model_edit.next_paid_plan_limit == undefined || (this.model_edit.next_paid_plan_limit !== null && this.model_edit.next_paid_plan_limit.trim().length == 0)) {
      this.model_edit.next_paid_plan_limit = null;
    }
    if (this.model_edit.next_store_plan_id == undefined || (this.model_edit.next_store_plan_id !== null && this.model_edit.next_store_plan_id.trim().length == 0)) {
      this.model_edit.next_store_plan_id = null;
    }
    /*if (this.model_edit.vision360 == undefined || this.model_edit.vision360.trim().length == 0) {
      this.model_edit.vision360 = null;
    }*/
    // this.loading = true;
    this.storesService.updateStore(this.storeId, this.model_edit)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        let aux: { success: Boolean };
        aux = res as any;
        if (aux.success) {
          this.loading = false;
          this.edited = true;
          this.utilsService.sendNotification('success', 'Dados da loja atualizados');
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  initDropdowns() {
    this.utilsService.getCountries()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.countries = data as any;
        this.updatePhoneCode();
      });
    this.utilsService.getDistricts()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.districts = data as any;
      });
    this.utilsService.getCities()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.cities = data as any;
      });
    this.utilsService.getActivities()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.activities = data as any;
      });
    this.utilsService.getStorePlansInfo()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.plans = data as any;
        console.log(this.plans);
      });
  }


  isPTSelected() {
    if (this.countries && this.model_edit.country) {
      return this.model_edit.country == globals.portugalCountryName;
    } else {
      return false;
    }
  }

  searchPostal(postal: any) {
    if (this.model_edit.postal.length == 8 && this.isPTSelected()) {
      const splited_postal = this.model_edit.postal.split('-', 2);
      if (splited_postal[0].length === 4 && splited_postal[1].length === 3) {
        this.utilsService
          .getPostalInfo(splited_postal[0], splited_postal[1])
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            this.model_edit.district = this.districts.find(dist => dist.id === aux.district_id).name;
            this.model_edit.city = this.cities.find(city => city.id === aux.city_id).name;
            this.model_edit.locality = aux.locality;
          });
      }
    }
  }

  updatePhoneCode() {
    if (this.countries && this.model_edit.country) {
      this.phoneCode = this.countries.find(country => country.countryName === this.model_edit.country).phoneCode;
    }
  }

  deleteStore() {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja elimiar a Loja?', 'Este processo é irreversível').then(confirm => {
      //this.spinner.show();
      if (confirm) {
        this.storesService.deleteStore(this.storeId)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            //this.spinner.hide();
            this.utilsService.sendNotification('success', 'Loja eliminada');
            this.goBack();
          });
      }
    });
  }

  goBack() {
    const aux = this.router.url.split('/');
    aux.length -= 2;
    const backLink = aux.join('/');
    this.router.navigate([backLink]);
  }

  isSelectedPlanSubscribable() {
    this.selectedPlanSubscribable = this.plans.find(plan => plan.id == this.model_edit.store_plan_id).subscribable;
    if (!this.selectedPlanSubscribable) {
      this.model_edit.paid_plan_limit = null;
    }
  }

  isSelectedNextPlanSubscribable() {
    this.selectedNextPlanSubscribable = this.plans.find(plan => plan.id == this.model_edit.next_store_plan_id).subscribable;
    if (!this.selectedNextPlanSubscribable) {
      this.model_edit.next_paid_plan_limit = null;
    }
  }
}
