import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, Host, Inject, OnInit } from '@angular/core';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';

import { AuthService } from 'src/app/services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StoresService } from '../../../services/stores.service';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-store-promotions',
  templateUrl: './store-promotions.component.html',
  styleUrls: ['./store-promotions.component.scss']
})

export class StorePromotionsComponent implements OnInit {
  siteUrl = globals.site_base_url;
  model_create: any = {};
  imgPreview;
  storeId;
  store;
  addPromo: boolean;
  promotions;
  limitPromotions
  limitFlag = false;
  uploader: FileUploader;
  anexo;
  init_date: any;
  end_date: any;

  uploadedImg = false;

  permissions: any = {};

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private storesService: StoresService,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('StoresCreate');
    this.permissions.edit = this.auth.hasPermissions('StoresEdit');
    this.permissions.delete = this.auth.hasPermissions('StoresDelete');
    this.permissions.read = this.auth.hasPermissions('StoresRead');
    this.promotions = [];
    this.addPromo = false;
    if (this.permissions.read) {
      this.getUrlParams();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.storeId = params['storeId'];
      this.getPromotions();
      this.getStoreInfo();
    });
  }

  getStoreInfo() {
    this.storesService.getStoreInfoByStoreId(this.storeId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.store = res as any;
      });
  }

  defineUploader() {
    this.uploader = new FileUploader({
      url: globals.base_api_path + 'ficheiros/upload/promo/foto',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.model_create.img_url = JSON.parse(response).url;
      this.updatePromo(this.model_create.id, this.model_create, true);
    };
    this.uploader.onErrorItem = (item: any, response: any, status: any, headers: any) => {
      this.removePromo(this.model_create.id, true);
      this.utilsService.sendSystemErrorAlert();
      this.defineUploader();
    };
  }

  createPromotion() {
    this.model_create.init_date = this.utilsService.convertDateStringToDate(this.init_date);
    this.model_create.end_date = this.utilsService.convertDateStringToDate(this.end_date);
    if (this.model_create.init_date <= this.model_create.end_date) {
      if (this.verifyOverlapPromotions(this.model_create)) {
        if (this.model_create.type === 'discount') {
          this.model_create.offer = this.model_create.offer + ' €';
        } else if (this.model_create.type === 'percentage') {
          this.model_create.offer = this.model_create.offer + ' %';
        }
        this.storesService.addPromotion(this.storeId, this.model_create)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              if (!err.error) {
                return this.utilsService.sendSystemErrorAlert(err);
              } else if (err.error.code === globals.errorCodes.planLimitExceeded) {
                this.utilsService.sendConfirmation('error', 'Atingiu o limite de promoções simultâneas na data selecionada que pode colocar na loja com o plano atual.');
              }
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (aux.success) {
              this.model_create.id = aux.id;
              if (this.uploadedImg) {
                this.uploadImage();
              } else {
                this.utilsService.sendNotification('success', 'Promoção criada');
                this.addPromoToggle();
              }
            } else {
              this.utilsService.sendSystemErrorAlert();
            }
          });
      } else {
        this.utilsService.sendConfirmation('error', 'Atingiu o limite de promoções simultâneas na data selecionada que pode colocar na loja com o plano atual.');
      }
    } else {
      this.utilsService.sendConfirmation('warning', 'A data de final da promoção não pode ser anterior à de inicio');
    }
  }

  verifyOverlapPromotions(promotion) {
    let overlapCount = 0;
    this.promotions.forEach(promo => {
      if (promo.id !== promotion.id) {
        let initDate = new Date(promo.init_date);
        initDate.setMinutes(initDate.getMinutes() + (initDate.getTimezoneOffset() * -1));
        let endDate = new Date(promo.end_date);
        endDate.setMinutes(endDate.getMinutes() + (endDate.getTimezoneOffset() * -1));
        if (this.utilsService.datesOverlap(new Date(promotion.init_date), new Date(promotion.end_date), initDate, endDate)) {
          overlapCount++;
        }
      }
    });
    return overlapCount < this.limitPromotions;
  }

  getPromotions() {
    this.storesService.getStorePromotionsByStoreId(this.storeId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.promotions = res as any;
        //console.log(this.promotions);
      });
  }

  addPromoToggle() {
    this.addPromo = !this.addPromo;
    if (this.addPromo) {
      this.defineUploader();
      this.init_date = this.utilsService.convertDateStringToDate(new Date().toISOString());
      this.end_date = this.utilsService.convertDateStringToDate(new Date().toISOString());
    } else {
      this.getPromotions();
      this.model_create = {};
      this.uploader.clearQueue();
    }
  }

  getOffer(promotion_id) {
    const index = this.promotions.findIndex(obj => obj.id === promotion_id);
    if (index !== -1) {
      const promotion = this.promotions[index];
    }
  }


  updatePromo(promoId, modelPromo, created?) {
    this.storesService.updatePromotion(promoId, modelPromo)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          if (created) {
            this.utilsService.sendNotification('success', 'Promoção criada');
            this.addPromoToggle();
          } else {
            this.utilsService.sendNotification('success', 'Promoção atualizada');
          }
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  removePromo(promotion_id, onError?) {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja eliminar a promoção?', 'Este processo é irreversível').then(confirm => {
      if (confirm) {
        this.storesService.deletePromotion(promotion_id)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (aux.success && !onError) {
              const index = this.promotions.findIndex(promo => promo.id = promotion_id);
              if (index !== -1) {
                this.promotions.splice(index, 1);
                this.limitFlag = this.promotions.some(promo => !this.verifyOverlapPromotions(promo));
              }
              this.utilsService.sendNotification('success', 'Promoção apagada');
            }
          });
      }
    });
  }

  uploadImage() {
    this.uploader.queue.forEach((img, index) => {
      img._onBuildForm = (form) => {
        form.append('storeId', this.storeId);
        form.append('storeName', this.utilsService.getUrlFromName(this.store.name));
        form.append('promoId', this.model_create.id);
      };
      img.upload();
    });
  }

  showImage() {
    this.uploader.queue.forEach(foto => {
      this.imgPreview = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
    });
    this.uploadedImg = true;
  }
}
