import { Component, OnInit, Inject } from '@angular/core';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';
import * as globals from '../../../../assets/globals';
import { UtilsService } from '../../../services/utils.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { StoresService } from '../../../services/stores.service';
import { WeddingsService } from '../../../services/weddings.service';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-wedding-professional-details',
  templateUrl: './wedding-professional-details.component.html',
  styleUrls: ['./wedding-professional-details.component.scss']
})
export class WeddingProfessionalDetailsComponent implements OnInit {


  uploaderFeatured: FileUploader;
  uploaderImages: FileUploader;
  weddingId;
  siteUrl = globals.site_base_url;
  model: any = {};
  showImgsArray;
  anexo;
  featured_img: any = {};
  images: any[] = [];
  activities = globals.wedding_providers_activities;
  imagesToInsert: any[] = [];
  imagesToDelete: any[] = [];
  nrInsertedImgs;

  edit: Boolean;
  updated = false;
  addUrl;
  videosToAdd = [];
  videosToDelete = [];

  imgDate: any;

  finishUI: Boolean;
  finishDI: Boolean;
  finishUV: Boolean;
  finishDV: Boolean;

  constructor(public sanitizer: DomSanitizer,
    private router: Router,
    private utilsService: UtilsService,
    private storesService: StoresService,
    private weddingsService: WeddingsService,
    private route: ActivatedRoute, ) { }

  ngOnInit() {
    this.initModel();
    this.edit = false;
    this.defineUploaderFeatured();
    this.defineUploaderImages();
    this.getWeddingByUrlParams();
  }

  initModel() {
    this.model = {};
    this.featured_img = {};
    this.model.images = [];
    this.model.videos = [];
    this.model.providers = [];
    this.showImgsArray = [];
    this.finishUI = false;
    this.finishDI = false;
    this.finishUV = false;
    this.finishDV = false;
  }

  getWeddingByUrlParams() {
    this.route.params.subscribe(params => {
      this.model.wedding_id = params['weddingId'];
      this.weddingId = params['weddingId'];
      this.getWedding();
    });
  }

  getWedding() {
    this.storesService.getStoreWeddingByWeddingId(this.weddingId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        if (res) {
          this.model = res;
          this.model.date = this.utilsService.convertDateStringToDate(this.model.date);
          this.getWeddingImgs();
          this.getWeddingVideos();
        }
      });
  }

  getWeddingImgs() {
    this.weddingsService.getWeddingImgs(this.weddingId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(resImages => {
        this.model.images = resImages;
        this.showImgsArray = resImages;
      });
  }

  getWeddingVideos() {
    this.weddingsService.getWeddingVideos(this.weddingId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(resVideos => {
        this.model.videos = resVideos;
      });
  }

  editWeddingToggle() {
    this.edit = !this.edit;
  }

  cancelEditWeddingToggle() {
    this.editWeddingToggle();
    this.getWedding();
  }

  defineUploaderFeatured() {

  }

  defineUploaderImages() {

  }

  deleteWedding() {

  }

  uploadFeaturedImage() {
    this.uploaderFeatured.queue.forEach((img, index) => {
      img._onBuildForm = (form) => {
        form.append('id', this.model.id);
      };
      img.upload();
    });
  }

  uploadImages() {

  }

  updateWedding() {

  }


  showFeaturedImage() {

  }

  showImages() {

  }

  deleteImage(index) {
    const img = this.showImgsArray.splice(index, 1)[0];
    if (img.toUpload) {
      const uploaderIndex = this.uploaderImages.queue.findIndex(item => item.file.name == img.foto.file.name);
      this.uploaderImages.queue.splice(uploaderIndex, 1);
    } else {
      this.imagesToDelete.push(img.id);
    }
  }

  insertImages() {

  }

  removeImages() {

  }

  insertVideos() {

  }

  removeVideos() {

  }

  addVideo() {
    if (this.addUrl && this.addUrl.trim() !== '') {
      this.videosToAdd.push(this.addUrl);
      this.model.videos.push({ video_url: this.addUrl });
      this.addUrl = '';
    }
  }

  deleteVideo(index) {
    this.videosToDelete.push(this.model.videos.splice(index, 1)[0].id);
  }

  getEmbedVideos(index) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      this.utilsService.getEmbedUrlFromVideoUrl(this.model.videos[index].video_url)
    );
  }

  goBack() {

  }
}
