import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, Host, Inject, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Observable, forkJoin } from 'rxjs';

import { AuthService } from 'src/app/services/auth.service';
import { BrandsService } from '../../../services/brands.service';
import { FileUploader } from 'ng2-file-upload';
import { HttpErrorResponse } from '@angular/common/http';
import { StoresService } from '../../../services/stores.service';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-create-brand',
  templateUrl: './create-brand.component.html',
  styleUrls: ['./create-brand.component.scss']
})
export class CreateBrandComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private brandsService: BrandsService,
    private storesService: StoresService,
    private titleService: Title,
    public sanitizer: DomSanitizer,
    private auth: AuthService,
    ) { }

  model_create: any = {};

  countries: any[];
  districts: any[];
  cities: any[];
  plans: any[];
  error = "";
  brands: any[] = [];
  brandId;
  imgDate: any;
  selectedDistrictId;
  editInfo;
  uploaderLogo: FileUploader;
  uploaderFeatured: FileUploader;
  displayImgsUrl: any = {};
  phoneCode;
  bolFeaturedImg;
  bolLogoImg;
  stores;
  selectedStores = [];
  selectedStoreNames = [];
  selectedStoresBackup = [];
  selectedStoreNamesBackup = [];
  loadingStores;
  storesToAdd = [];
  storesToRemove = [];
  storeNamesToAdd = [];
  storeNamesToRemove = [];

  insertName: any = {};
  professionalId;
  selectedPlanSubscribable: boolean = false;

  permissions: any = {};

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('BrandsCreate');
    this.permissions.edit = this.auth.hasPermissions('BrandsEdit');
    this.permissions.delete = this.auth.hasPermissions('BrandsDelete');
    this.permissions.read = this.auth.hasPermissions('BrandsRead');
    this.bolFeaturedImg = true;
    this.bolLogoImg = true;
    if (this.permissions.create) {
      this.getUrlParams();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.professionalId = params.professionalId;
      this.defineUploaderLogo();
      this.defineUploaderFeatured();
      this.initDropdowns();
      this.getStores();
      this.initModel();
    });
  }

  initModel() {
    this.model_create = {};
    this.model_create.professional_id = this.professionalId;
    this.model_create.storeNamesToAdd = [];
  }

  getStores() {
    this.storesService.getAllStoresForBrands()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.stores = res;
        this.utilsService.sortArrayalphabetically(this.stores, 'name');
        this.loadingStores = false;
      });
  }

  initDropdowns() {
    this.utilsService.getCountries()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.countries = data as any;
        this.updatePhoneCode();
      });
    this.utilsService.getDistricts()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.districts = data as any;
      });
    this.utilsService.getCities()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.cities = data as any;
      });
    this.utilsService.getBrandPlansInfo()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.plans = data as any;
        console.log(this.plans);
      });
  }

  createBrand() {
    this.model_create.storesToAdd = this.storesToAdd;
    this.model_create.storesToRemove = this.storesToRemove;
    this.model_create.storeNamesToAdd = this.storeNamesToAdd;
    this.model_create.storeNamesToRemove = this.storeNamesToRemove;
    this.model_create.professional_id = this.professionalId;
    this.modelUndefinedToNull();
    if (this.uploaderLogo.queue.length === 0 && this.uploaderFeatured.queue.length === 0) {
      this.utilsService.sendConfirmation('warning', 'Tem de Inserir um Logo e uma imagem de destaque');
    } else if (this.uploaderLogo.queue.length === 0) {
      this.utilsService.sendConfirmation('warning', 'Tem de Inserir um Logo');
    } else if (this.uploaderFeatured.queue.length === 0) {
      this.utilsService.sendConfirmation('warning', 'Tem de Inserir uma Imagem de destaque');
    } else {
      this.brandsService
        .createBrand(this.model_create)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.brandId = aux.id;
            this.storesToAdd = [];
            this.storesToRemove = [];
            this.storeNamesToAdd = [];
            this.storeNamesToRemove = [];
            this.uploadImages(this.brandId);
            this.utilsService.sendNotification('success', 'Marca Atualizada');
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
        });
    }
  }

  sortBrands() {
    this.brands.sort(function (a, b) {
      if (a.nome < b.nome) {
        return -1;
      }
      if (a.nome > b.nome) {
        return 1;
      }
      return 0;
    });
  }


  isPTSelected() {
    if (this.countries && this.model_create.country) {
      return this.model_create.country == globals.portugalCountryName;
    } else {
      return false;
    }
  }

  searchPostal(postal) {
    if (this.model_create.postal.length == 8 && this.isPTSelected()) {
      const splited_postal = this.model_create.postal.split('-', 2);
      if (splited_postal[0].length === 4 && splited_postal[1].length === 3) {
        this.utilsService
          .getPostalInfo(splited_postal[0], splited_postal[1])
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            this.model_create.district = this.districts.find(dist => dist.id === aux.district_id).name;
            this.model_create.city = this.cities.find(city => city.id === aux.city_id).name;
            this.model_create.locality = aux.locality;
          });
      }
    }
  }

  updateCities(event) {
    let aux = this.districts.find(district => district.name == this.model_create.district);
    this.selectedDistrictId = aux.id;
  }

  modelUndefinedToNull() {
    if (this.model_create.name === undefined) {
      this.model_create.name = '';
    }
    if (this.model_create.email === undefined) {
      this.model_create.email = '';
    }
    if (this.model_create.website === undefined) {
      this.model_create.website = '';
    }
    if (!this.model_create.hide_address) {
      this.model_create.hide_address = false;
    }
    if (this.model_create.address === undefined || this.model_create.address.trim().length === 0) {
      this.model_create.address = '';
    }
    if (this.model_create.postal === undefined || this.model_create.postal.trim().length === 0) {
      this.model_create.postal = '';
    }
    if (this.model_create.district === undefined) {
      this.model_create.district = null;
    }
    if (this.model_create.locality === undefined) {
      this.model_create.locality = null;
    }
    if (this.model_create.city === undefined) {
      this.model_create.city = null;
    }
    if (this.model_create.country === undefined) {
      this.model_create.country = null;
    }
    if (this.model_create.brand_presentation === undefined || this.model_create.brand_presentation.trim().length === 0) {
      this.model_create.brand_presentation = '';
    }
  }

  defineUploaderLogo() {
    this.uploaderLogo = new FileUploader({
      url: globals.base_api_path + 'files/upload/brand/logo',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderLogo.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const url = JSON.parse(response).url;
      this.brandsService.updateBrandLogo(this.brandId, url)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.model_create.logo_url = url;
            this.imgDate = Date.now();
            this.utilsService.sendNotification('success', 'Logo atualizado');
          }
        });
      this.defineUploaderLogo();
    };
    this.uploaderLogo._onErrorItem = (err) => {
      this.uploaderLogo.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderLogo();
    };
  }

  defineUploaderFeatured() {
    this.uploaderFeatured = new FileUploader({
      url: globals.base_api_path + 'files/upload/brand/featured',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderFeatured.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const url = JSON.parse(response).url;
      this.brandsService.updateBrandFeaturedImg(this.brandId, url)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.model_create.featured_img_url = url;
            this.imgDate = Date.now();
            this.utilsService.sendNotification('success', 'Imagem de destaque atualizada');
          }
        });
      this.defineUploaderFeatured();
    };
    this.uploaderFeatured._onErrorItem = (err) => {
      this.uploaderFeatured.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderFeatured();
    };
  }

  showLogo() {
    this.uploaderLogo.queue.forEach(foto => {
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      this.displayImgsUrl.logo = url;
    });
  }

  showFeatured() {
    this.uploaderFeatured.queue.forEach(foto => {
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      this.displayImgsUrl.featured = url;
    });
  }

  uploadImages(brandId) {
    this.onUploadLogo(brandId);
    this.onUploadFeatured(brandId);
  }

  onUploadLogo(id) {
    this.uploaderLogo.queue.forEach(img => {
      this.bolLogoImg = false;
      img._onBuildForm = (form) => {
        form.append('brandName', this.utilsService.getUrlFromName(this.model_create.name));
        form.append('brandId', this.brandId);
      };
      img.upload();
    });
    this.uploaderLogo.uploadAll();
  }

  onUploadFeatured(id) {
    this.uploaderFeatured.queue.forEach(img => {
      this.bolFeaturedImg = false;
      img._onBuildForm = (form) => {
        form.append('brandName', this.utilsService.getUrlFromName(this.model_create.name));
        form.append('brandId', this.brandId);
      };
      img.upload();
    });
    this.uploaderFeatured.uploadAll();
  }

  updatePhoneCode() {
    this.phoneCode = this.countries.find(country => country.countryName === this.model_create.country).phoneCode;
  }

  addStore(event) {
    const id = event.id;
    const index = this.storesToRemove.indexOf(id);
    if (index > -1) {
      this.storesToRemove.splice(index, 1);
    } else {
      this.storesToAdd.push(id);
    }
  }

  removeStore(event) {
    const id = event.value.id;
    const index = this.storesToAdd.indexOf(id);
    if (index > -1) {
      this.storesToAdd.splice(index, 1);
    } else {
      this.storesToRemove.push(id);
    }
  }

  addStoreName(event) {
    const index = this.storeNamesToRemove.findIndex(store => store.name == event.name && store.locality == event.locality);
    if (index > -1) {
      this.storeNamesToRemove.splice(index, 1);
    } else {
      this.storeNamesToAdd.push(event);
    }
  }

  removeStoreName(event) {
    const index = this.storeNamesToAdd.findIndex(store => store.name == event.value.name && store.locality == event.value.locality);
    if (index > -1) {
      this.storeNamesToAdd.splice(index, 1);
    } else {
      this.storeNamesToRemove.push(event.value);
    }
  }

  clearStores() {
    this.storesToRemove = this.selectedStoresBackup;
  }

  clearStoreNames() {
    this.storesToRemove = this.selectedStoresBackup;
  }

  enterStoreName() {
    if (this.insertName.name && this.insertName.name.trim() !== '' && this.insertName.locality && this.insertName.locality.trim() !== '') {
      if (!this.alreadyInserted(this.insertName)) {
        this.selectedStoreNames = [...this.selectedStoreNames, ...[{ name: this.insertName.name, locality: this.insertName.locality }]];
        this.addStoreName({ name: this.insertName.name, locality: this.insertName.locality });
        this.insertName = {};
      } else {
        this.utilsService.sendConfirmation('warning', 'Já adicionou esta loja');
      }
    } else {
      this.utilsService.sendConfirmation('warning', 'Tem de preencher o nome e a localidade da loja a adicionar');
    }
  }

  isSelectedPlanSubscribable() {
    this.selectedPlanSubscribable = this.plans.find(plan => plan.id == this.model_create.brand_plan_id).subscribable;
    if (!this.selectedPlanSubscribable) {
      this.model_create.paid_plan_limit = null;
    }
  }

  //verifica se o nome da loja que estamos a inserir já existe
  alreadyInserted(addStore) {
    return this.model_create.storeNamesToAdd.findIndex(store => store.name === addStore.name && store.locality === addStore.locality) !== -1;
  }
}
