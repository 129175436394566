import { Component, OnInit, TemplateRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilsService } from '../../../../services/utils.service';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { MagazinesService } from '../../../../services/magazines.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-magazines-comments',
  templateUrl: './magazines-comments.component.html',
  styleUrls: ['./magazines-comments.component.scss']
})
export class MagazinesCommentsComponent implements OnInit {

  permissions: any = {};
  comments: any[] = [];
  selectedComment: any = {};

  modalRef: BsModalRef;

  constructor(private magazinesService: MagazinesService,
    private utilsService: UtilsService,
    private spinner: NgxSpinnerService,
    private auth: AuthService,
    private modalService: BsModalService) { }

  ngOnInit() {
    this.permissions.edit = this.auth.hasPermissions('MagazinesEdit');
    this.permissions.read = this.auth.hasPermissions('MagazinesRead');
    if (this.permissions.read) {
      this.getDigitalMagazinesComments();
    }
  }

  getDigitalMagazinesComments() {
    this.magazinesService.getDigitalMagazinesComments()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        if (res) {
          this.comments = res as any;
          this.comments.forEach(comment => {
            comment.date = this.utilsService.convertDateStringToDate(comment.date);
          })
          console.log(this.comments);
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  changeIsVisible(comment) {
    this.magazinesService.changeIsVisibleComment(comment.id, comment)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          this.utilsService.sendNotification('success', 'Visibilidade do comentário alterada');
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  openResponsesModal(modal: TemplateRef<any>, comment) {
    this.selectedComment = { ...comment };
    this.selectedComment.responses.forEach(response => {
      response.date = this.utilsService.convertDateStringToDate(response.date);
    })
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  deleteComment(comment) {
    this.utilsService.sendConfirmationQuestion(comment.id, 'Tem a certeza que deseja apagar este comentário?').then(confirm => {
      if (confirm) {
        this.magazinesService.deleteDigitalMagazineComment(comment.id)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const index = this.comments.findIndex(obj => obj.id === comment.id);
            if (index !== -1) {
              this.comments.splice(index, 1);
            }
            this.utilsService.sendNotification('success', 'Comentário removido!');
          });
      }
    });
  }

  closeModal() {
    this.modalRef.hide();
  }

  changeIsVisibleResponse(response) {
    this.magazinesService.changeIsVisibleCommentResponse(response.id, response)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          this.utilsService.sendNotification('success', 'Visibilidade da resposta ao comentário alterada');
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  deleteResponse(response) {
    this.utilsService.sendConfirmationQuestion(response.id, 'Tem a certeza que deseja apagar esta resposta a comentário?').then(confirm => {
      if (confirm) {
        this.magazinesService.deleteDigitalMagazineCommentResponse(response.id)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const index = this.selectedComment.responses.findIndex(obj => obj.id === response.id);
            if (index !== -1) {
              this.selectedComment.responses.splice(index, 1);
            }
            this.utilsService.sendNotification('success', 'Resposta a comentário removida!');
          });
      }
    });
  }
}
