import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as globals from '../../assets/globals';

@Injectable({
  providedIn: 'root'
})
export class ProfessionalsService {

  constructor(private http: HttpClient) { }

  apiRoute = globals.base_api_path + 'professional/';

  getAllProfessionalUsers(): Observable<any> {
    return this.http.get(this.apiRoute);
  }

  getProfessionalData(user_id): Observable<any> {
    return this.http.get(this.apiRoute + '/user/' + user_id);
  }

  putProfessionalData(user_id, model) {
    return this.http.put(this.apiRoute + '/update/user/' + user_id, model);
  }

  deleteAccount(userId): Observable<any> {
    return this.http.put(this.apiRoute + '/delete/' + userId, null);
  }

}
