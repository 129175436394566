import * as globals from '../../assets/globals';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminUsersService {

  constructor(private http: HttpClient) { }

  apiRoute = globals.base_api_path + 'admin-users/';

  getAllUsers(): Observable<any> {
    return this.http.get(this.apiRoute);
  }

  createUser(user): Observable<any> {
    return this.http.post(this.apiRoute, user);
  }

  deleteUser(userId): Observable<any> {
    return this.http.delete(this.apiRoute + userId);
  }

  updateUser(userId, user): Observable<any> {
    return this.http.put(this.apiRoute + userId, user);
  }

  updatePassword(userId, password): Observable<any> {
    return this.http.put(this.apiRoute + userId + "/password", {password});
  }

  updatePasswordByAdmin(userId, password): Observable<any> {
    return this.http.put(this.apiRoute + userId + "/password-admin", {password});
  }

  
}
