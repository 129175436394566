import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FilterPipe } from 'ngx-filter-pipe';
import { HttpErrorResponse } from '@angular/common/http';
import { IloveTVService } from '../../../services/ilove-tv.service';
import { Subject } from 'rxjs';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-video-details',
  templateUrl: './video-details.component.html',
  styleUrls: ['./video-details.component.scss']
})
export class VideoDetailsComponent implements OnInit {

  subCategories = [];
  model: any = {};
  selectedSubCategories = [];
  selectedSubCategoriesBackup = [];
  loadingSubCategories;
  subCategoriesToAdd = [];
  subCategoriesToRemove = [];
  videos: any[] = [];
  positionBackup = 5;
  videoId;

  permissions: any = {};

  constructor(
    private sanitizer: DomSanitizer,
    private utilsService: UtilsService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private tvService: IloveTVService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('BrandsCreate');
    this.permissions.edit = this.auth.hasPermissions('BrandsEdit');
    this.permissions.delete = this.auth.hasPermissions('BrandsDelete');
    this.permissions.read = this.auth.hasPermissions('BrandsRead');
    if (this.permissions.read) {
      this.getSubCategories();
      this.activatedRoute.params.subscribe(params => {
        this.videoId = params['videoId'];
        this.getVideo();
      });
    }
  }

  getVideo() {
    this.tvService.getVideo(this.videoId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.model = res as any;
        if (this.model.limitDate) {
          this.model.limitDate = this.utilsService.convertDateStringToDate(this.model.limitDate);
        }
        this.positionBackup = this.model.position;
        //this.model.embedUrl = this.getEmbedVideos(this.utilsService.getEmbedUrlFromVideoUrl(this.model.url));
        this.getVideoSubCategories();
      });
  }

  getEmbedVideos(embed_url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(embed_url);
  }

  editVideo() {
    this.model.subCategoriesToAdd = this.subCategoriesToAdd;
    this.model.subCategoriesToRemove = this.subCategoriesToRemove;
    if (this.model.url.trim().length > 0) {
      if (this.positionBackup != this.model.position && this.model.position !== 5) {
        this.tvService.countVideoPosition(this.model.position)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const tst = res as any;
            if (tst > 0) {
              this.utilsService.sendConfirmationQuestion('Já existe um video nessa posição. Deseja substituir?', 'Este processo é irreversível').then(confirm => {
                if (confirm) {
                  this.tvService.updateVideoWithUpdatePosition(this.videoId, this.model)
                    .pipe(
                      catchError((err: HttpErrorResponse) => {
                        return this.utilsService.sendSystemErrorAlert(err);
                      }) as any)
                    .subscribe(res => {
                      const aux = res as any;
                      if (aux.success) {
                        this.utilsService.sendNotification('success', 'Video adicionado (com update)');
                        this.getVideo();
                      } else {
                        this.utilsService.sendSystemErrorAlert();
                      }
                    });
                } else {
                  this.utilsService.sendNotification('error', 'Video não adicionado');
                }
              });
            } else {
              this.updateVideo();
            }
          });
      } else {
        this.updateVideo();
      }
    } else {
      this.utilsService.sendConfirmation('info', 'Insira um url');
    }
  }

  updateVideo() {
    this.tvService.updateVideo(this.videoId, this.model)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          this.utilsService.sendNotification('success', 'Video editado');
          this.goBack();
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  goBack() {
    const aux = this.router.url.split('/');
    aux.length -= 2;
    const backLink = aux.join('/');
    this.router.navigate([backLink]);
  }

  getSubCategories() {
    this.loadingSubCategories = true;
    this.utilsService.getBlogSubCategories()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.subCategories = res as any;
        this.loadingSubCategories = false;
      });
  }

  getVideoSubCategories() {
    this.tvService.getVideoSubCategories(this.videoId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        this.selectedSubCategories = aux.map(subCategory => subCategory.blogsubcategory_id);
        this.selectedSubCategoriesBackup = this.selectedSubCategories;
      });
  }

  addSubCategory(event) {
    const id = event.id;
    const index = this.subCategoriesToRemove.indexOf(id);
    if (index > -1) {
      this.subCategoriesToRemove.splice(index, 1);
      //console.log(this.subCategoriesToRemove);
    } else {
      this.subCategoriesToAdd.push(id);
      //console.log(this.subCategoriesToAdd);
    }
  }

  removeSubCategory(event) {
    const id = event.value.id;
    const index = this.subCategoriesToAdd.indexOf(id);
    if (index > -1) {
      this.subCategoriesToAdd.splice(index, 1);
      //console.log(this.subCategoriesToAdd);
    } else {
      this.subCategoriesToRemove.push(id);
      //console.log(this.subCategoriesToRemove);
    }
  }

  clearSubCategories() {
    this.subCategoriesToRemove = this.selectedSubCategoriesBackup;
  }
}
