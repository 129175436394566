import * as globals from '../../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';

import { AuthService } from 'src/app/services/auth.service';
import { DestinationWeddingsService } from '../../../../services/destination-weddings.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { UtilsService } from '../../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-subregion-images',
  templateUrl: './subregion-images.component.html',
  styleUrls: ['./subregion-images.component.scss']
})

export class SubregionImagesComponent implements OnInit {

  uploader: FileUploader;
  imgs: any[];
  nrImgs;
  siteUrl = globals.site_base_url;
  anexo: any;
  photosPerPage: number = 8;
  regions: any[];
  p1 = 1; //Pagination
  addPic: Boolean;
  pictures = [];
  limitPictures;
  subRegionId;
  subRegion;
  selectedFile: File = null;

  permissions: any = {};

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private wdService: DestinationWeddingsService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('DestinationWeddingsCreate');
    this.permissions.edit = this.auth.hasPermissions('DestinationWeddingsEdit');
    this.permissions.delete = this.auth.hasPermissions('DestinationWeddingsDelete');
    this.permissions.read = this.auth.hasPermissions('DestinationWeddingsRead');
    this.nrImgs = 0;
    if (this.permissions.read) {
      this.getRegions();
      this.defineUploader();
      this.getUrlParams();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.subRegionId = params.subRegionId;
      this.getSubRegionPictures();
      this.getWDSubRegionInfo();
    });
  }

  getWDSubRegionInfo() {
    this.wdService.getWDSubRegionInfoByWDSubRegionID(this.subRegionId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        this.subRegion = aux;
      });
  }

  defineUploader() {
    this.uploader = new FileUploader({
      url: globals.base_api_path + 'files/upload/subregionWD/gallery',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.imgs = [];
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.imgs.push(JSON.parse(response));
      this.uploadImgs();
    };
    this.uploader._onErrorItem = (err) => {
      this.uploader.clearQueue();
      this.utilsService.sendSystemErrorAlert();
    };
  }

  onUpload() {
    this.uploader.queue.forEach(img => {
      img._onBuildForm = (form) => {
        form.append('countryName', this.utilsService.getUrlFromName(this.regions[this.subRegion.region_id - 1].countryName));
        form.append('regionName', this.utilsService.getUrlFromName(this.regions[this.subRegion.region_id - 1].name));
        form.append('subRegionName', this.utilsService.getUrlFromName(this.subRegion.name));
      };
      this.nrImgs++;
      img.upload();
    });
  }

  uploadImgs() {
    if (this.imgs.length === this.nrImgs) {
      this.wdService.uploadPictureSubRegionById(this.subRegionId, this.imgs)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            this.utilsService.sendSystemErrorAlert();
            return Observable.throw(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.getSubRegionPictures();
            this.utilsService.sendNotification('success', 'Fotografias adicionadas');
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
          this.resetImages();
        });
    }
  }

  resetImages() {
    this.imgs = [];
    this.nrImgs = 0;
    this.uploader.clearQueue();
  }

  getSubRegionPictures() {
    this.wdService.getSubRegionImagesBySubRegionId(this.subRegionId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        this.pictures = aux;
      });
  }

  removePicture(index: number) {
    index = this.photosPerPage * (this.p1 - 1) + index;
    this.utilsService.sendConfirmationQuestion('Tem a certeza que quer apagar a fotografia?').then(confirm => {
      if (confirm) {
        this.wdService.deleteSubRegionPictureById(this.subRegionId, this.pictures[index].id)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (aux.success) {
              this.pictures.splice(index, 1);
              this.utilsService.sendNotification('success', 'Imagem apagada');
            } else {
              this.utilsService.sendSystemErrorAlert();
            }
          });
      }
    });
  }

  getRegions() {
    this.wdService.getAllWDRegions()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.regions = res as any;
      });
  }
}
