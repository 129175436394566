import * as globals from '../../assets/globals';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ObservableLike } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) { }

  apiRoute = globals.base_api_path + 'users/';
  filesApiRoute = globals.base_api_path + 'files/';

  getAllUsers(): Observable<any> {
    return this.http.get(this.apiRoute);
  }

  getUsersNewsletter(): Observable<any> {
    return this.http.get(this.apiRoute + 'newsletter');
  }

  getAllCompanies(): Observable<any> {
    return this.http.get(this.apiRoute + 'companies');
  }

  getUserByUserId(userId): Observable<any> {
    return this.http.get(this.apiRoute + userId);
  }

  changeNewsletter(userId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'change/newsletter/' + userId, model);
  }

  getUserData(id) {
    return this.http.get(this.apiRoute + id);
  }

  changePassword(id, password): Observable<any> {
    return this.http.put(this.apiRoute + 'change-password/' + id, { password: password });
  }

  putUserData(id: string, model) {
    return this.http.put(this.apiRoute + 'edit/' + id, model);
  }

  deleteProfileImg(url) {
    return this.http.put(this.filesApiRoute + 'delete/profile-img/', { path: url });
  }

  createUserProfessional(model) {
    return this.http.post(this.apiRoute + 'create/professional', model);
  }

  createUserPublic(model) {
    return this.http.post(this.apiRoute + 'create/public', model);
  }
}
