import * as globals from '../../assets/globals';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BannersService {

  constructor(
    private http: HttpClient
    ) { }

  apiRoute = globals.base_api_path + 'banners/';
  filesApiRoute = globals.base_api_path + 'ficheiros/';

  getAllBanners(): Observable<any> {
    return this.http.get(this.apiRoute);
  }

  getAllBannerTypes(): Observable<any> {
    return this.http.get(this.apiRoute + 'types');
  }

  getBannerInfo(id): Observable<any> {
    return this.http.get(this.apiRoute + id);
  }

  updateBanner(bannerId, banner): Observable<any> {
    return this.http.put(this.apiRoute + bannerId, banner);
  }

  createImageInfo(bannerId, image): Observable<any> {
    return this.http.post(this.apiRoute + bannerId + '/image/', image);
  }

  updateImageInfo(imageId, image): Observable<any> {
    return this.http.put(this.apiRoute + 'image/' + imageId, image);
  }

  deleteImageInfo(imageId): Observable<any> {
    return this.http.delete(this.apiRoute + 'image/' + imageId);
  }
}
