import * as globals from '../../../../assets/globals';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, TemplateRef } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from 'src/app/services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-activity-types',
  templateUrl: './activity-types.component.html',
  styleUrls: ['./activity-types.component.scss']
})
export class ActivityTypesComponent implements OnInit {

  nr_items_page = globals.nr_items_page;
  search: any = {};
  p; // paginação;
  activityTypes: any[] = [];
  permissions: any = {};

  modalRef: BsModalRef;
  typeEdit: any = {};
  typeCreate: any = {};

  constructor(
    private utils: UtilsService,
    private modalService: BsModalService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('ManagementCreate');
    this.permissions.edit = this.auth.hasPermissions('ManagementEdit');
    this.permissions.delete = this.auth.hasPermissions('ManagementDelete');
    this.permissions.read = this.auth.hasPermissions('ManagementRead');
    if (this.permissions.read) {
      this.getActivityTypes();
    }
  }

  getActivityTypes() {
    this.utils.getActivitiyTypes()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utils.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.activityTypes = res as any;
      });
  }

  openEditType(modal: TemplateRef<any>, activity) {
    this.typeEdit = { ...activity };
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  openCreateType(modal: TemplateRef<any>, activity) {
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  closeModal() {
    this.modalRef.hide();
    this.typeEdit = {};
    this.typeCreate = {};
  }

  editType() {
    if (this.typeEdit.name !== undefined && this.typeEdit.name.trim().length > 0) {
      this.utils.updateActivitiyTypes(this.typeEdit.id, this.typeEdit)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utils.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const index = this.activityTypes.findIndex(type => type.id === this.typeEdit.id);
          if (index !== -1) {
            this.activityTypes[index] = { ...this.typeEdit };
          }
          this.closeModal();
          this.utils.sendNotification('success', 'Tipo de Atividade atualizado');
        });
    } else {
      this.utils.sendConfirmation('warning', 'Tem de preecher todos os campos');
    }
  }

  createType() {
    if (this.typeCreate.name !== undefined && this.typeCreate.name.trim().length > 0) {
      this.utils.createActivitiyTypes(this.typeCreate)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utils.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          this.getActivityTypes();
          this.closeModal();
          this.utils.sendNotification('success', 'Tipo de Atividade criado');
        });
    } else {
      this.utils.sendConfirmation('warning', 'Tem de preecher todos os campos');
    }
  }

  deleteType(activity) {
    this.utils.sendConfirmationQuestion(activity.name, 'Tem a certeza que deseja apagar este tipo de atividade?').then(confirm => {
      if (confirm) {
        this.utils.deleteActivitiyTypes(activity.id)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utils.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const index = this.activityTypes.findIndex(obj => obj.id === activity.id);
            if (index !== -1) {
              this.activityTypes.splice(index, 1);
            }
            this.utils.sendNotification('success', 'Atividade Eliminada');
          });
      }
    });
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }

}
