import * as globals from '../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from '../../services/utils.service';
import { WeddingsService } from '../../services/weddings.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-weddings',
  templateUrl: './weddings.component.html',
  styleUrls: ['./weddings.component.scss']
})
export class WeddingsComponent implements OnInit {

  nr_items_page = globals.nr_items_page;
  p; // paginação;
  weddings;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private weddingsService: WeddingsService,
    private utilsService: UtilsService
    ) { }

  ngOnInit() {
    this.getWeddings();
  }

  getWeddings() {
    this.weddingsService.getAllWeddings()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.weddings = res as any;
        this.weddings.forEach(wedding => {
          wedding.date = this.utilsService.convertDateStringToDate(wedding.date);
        });
      });
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }

  goToWedding(weddingId, type): void {
    switch (type) {
      case 'Public':
        this.router.navigate([globals.baseUrls.weddings + '/' + type.toLowerCase() + '/' + weddingId]);
        break;
      case 'Store':
        this.router.navigate([globals.baseUrls.weddings + '/' + type.toLowerCase() + '/' + weddingId]);
        break;
    }
  }

  goToCreateWedding(type): void {
    switch (type) {
      case 'public':
        this.router.navigate([globals.baseUrls.weddings + '/create/' + type]);
        break;
      case 'store':
        this.router.navigate([globals.baseUrls.weddings + '/create/' + type]);
        break;
    }
  }
}
