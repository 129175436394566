import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import * as globals from '../../assets/globals';
import { ReturnStatement } from '@angular/compiler';
import { Observable, throwError } from 'rxjs';

type PopupType = 'success' | 'error' | 'warning' | 'info' | 'question';
const youtubeLinkName = 'youtube';
const youtuLinkName = 'youtu.be';
const youtubeUrlEmbed = 'https://www.youtube.com/embed/';
const vimeoLinkName = 'vimeo';
const vimeoUrlEmbed = 'https://player.vimeo.com/video/';

const siteSec = 'http://';
const siteSec2 = 'https://';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private http: HttpClient) { }

  apiRoute = globals.base_api_path + 'utils/';

  getNrUsers() {
    return this.http.get(this.apiRoute + 'nrusers');
  }

  getNrStores() {
    return this.http.get(this.apiRoute + 'nrstores');
  }

  getNrBrands() {
    return this.http.get(this.apiRoute + 'nrbrands');
  }

  getActivities(type?) {
    if (type) {
      return this.http.get(this.apiRoute + 'activities/' + type);
    } else {
      return this.http.get(this.apiRoute + 'activities/');
    }
  }

  updateActivitiy(activityId, activity) {
    return this.http.put(this.apiRoute + 'activities/' + activityId, activity);
  }

  createActivitiy(activity) {
    return this.http.post(this.apiRoute + 'activities/', activity);
  }

  deleteActivitiy(activityId) {
    return this.http.delete(this.apiRoute + 'activities/' + activityId);
  }

  getActivitiyTypes() {
    return this.http.get(this.apiRoute + 'activity-types/');
  }

  updateActivitiyTypes(typeId, type) {
    return this.http.put(this.apiRoute + 'activity-types/' + typeId, type);
  }

  createActivitiyTypes(type) {
    return this.http.post(this.apiRoute + 'activity-types/', type);
  }

  deleteActivitiyTypes(typeId) {
    return this.http.delete(this.apiRoute + 'activity-types/' + typeId);
  }

  getCategories() {
    return this.http.get(this.apiRoute + 'categories');
  }

  updateCategory(category_id, category) {
    return this.http.put(this.apiRoute + 'categories/' + category_id, category);
  }

  createCategory(category) {
    return this.http.post(this.apiRoute + 'categories', category);
  }

  deleteCategory(category_id) {
    return this.http.delete(this.apiRoute + 'categories/' + category_id);
  }

  getCategoryTypes() {
    return this.http.get(this.apiRoute + 'category-types/');
  }

  updateCategoryTypes(typeId, type) {
    return this.http.put(this.apiRoute + 'category-types/' + typeId, type);
  }

  createCategoryTypes(type) {
    return this.http.post(this.apiRoute + 'category-types/', type);
  }

  deleteCategoryTypes(typeId) {
    return this.http.delete(this.apiRoute + 'category-types/' + typeId);
  }

  getInterestsList() {
    return this.http.get(this.apiRoute + 'interests-list');
  }

  getCountries() {
    return this.http.get(this.apiRoute + 'countries');
  }

  getDistricts() {
    return this.http.get(this.apiRoute + 'districts');
  }

  getCities() {
    return this.http.get(this.apiRoute + 'cities');
  }

  getLocalitiesByDistrict(district) {
    return this.http.get(this.apiRoute + 'localities/district/' + district);
  }

  getMaritalStatus() {
    return this.http.get(this.apiRoute + 'marital-status');
  }

  getLiteraryAbilities() {
    return this.http.get(this.apiRoute + 'literary-abilities');
  }

  getPostalInfo(postalNr, postalExt) {
    return this.http.get(this.apiRoute + 'postal-info/' + postalNr + '/' + postalExt);
  }

  getMenu() {
    return this.http.get(this.apiRoute + 'menu');
  }

  getStorePlansInfo() {
    return this.http.get(this.apiRoute + 'info-plans/stores');
  }

  updateStorePlansInfo(planId, plan) {
    return this.http.put(this.apiRoute + 'info-plans/stores/' + planId, plan);
  }

  createStorePlansInfo(plan) {
    return this.http.post(this.apiRoute + 'info-plans/stores/', plan);
  }

  deleteStorePlansInfo(planId) {
    return this.http.delete(this.apiRoute + 'info-plans/stores/' + planId);
  }

  getBrandPlansInfo() {
    return this.http.get(this.apiRoute + 'info-plans/brands');
  }

  updateBrandPlanInfo(planId, plan) {
    return this.http.put(this.apiRoute + 'info-plans/brands/' + planId, plan);
  }

  createBrandPlanInfo(plan) {
    return this.http.post(this.apiRoute + 'info-plans/brands/', plan);
  }

  deleteBrandPlanInfo(planId) {
    return this.http.delete(this.apiRoute + 'info-plans/brands/' + planId);
  }

  getPublicPlansInfo() {
    return this.http.get(this.apiRoute + 'info-plans/public');
  }

  getBlogCategories(): Observable<any> {
    return this.http.get(this.apiRoute + 'blogcategories');
  }

  getBlogSubCategories(): Observable<any> {
    return this.http.get(this.apiRoute + 'blogsubcategories');
  }

  getCompanyInfo(): Observable<any> {
    return this.http.get(this.apiRoute + 'company-info');
  }

  updateCompanyInfo(model): Observable<any> {
    return this.http.put(this.apiRoute + 'update/company-info', model);
  }

  updateCompanyLogo(url): Observable<any> {
    return this.http.put(this.apiRoute + 'update/company-logo/', { url });
  }

  getSiteSettings(): Observable<any> {
    return this.http.get(this.apiRoute + 'settings',);
  }

  updateSiteSettings(selector, value): Observable<any> {
    return this.http.put(this.apiRoute + 'settings', {selector, value});
  }



  /* SORT ARRAY BY YEAR*/
  sortArrayByYear(array) {
    return array.sort((i1, i2) => i2.year - i1.year);
  }

  /* SORT ARRAY BY PLANS (PREMIUM > FREE) */
  sortArrayByPlans(array) {
    return array.sort((i1, i2) => i2.plan_id - i1.plan_id);
  }

  sortStoreArrayByPlans(array) {
    return array.sort((i1, i2) => i2.store_plan_id - i1.store_plan_id);
  }

  sortArrayalphabetically(array, orderBy) {
    return array.sort(function (a, b) {
      return a[orderBy].localeCompare(b[orderBy]);
    });
  }

  /*CHECK IF ELEMENT EXISTS*/
  checkYearHasProducts(products, year) {
    return products.some(p => p.year === year);
  }

  checkBrandHasProducts(products, brand_id) {
    return products.some(p => p.brand_id === brand_id);
  }

  checkCategoryHasProducts(products, category_id) {
    return products.some(p => p.category_id === category_id);
  }

  /* Convert name to urlName e.g I Love Brides to i-love-brides */
  getUrlFromName(name) {
    name = name.toLowerCase().split(' ').join('-');
    name = name.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
    name = name.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    name = name.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    name = name.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    name = name.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
    name = name.replace(new RegExp('[Ç]', 'gi'), 'c');
    return name;
  }

  getSequencialNumberArray(size) {
    return Array.apply(null, { length: size }).map(Number.call, Number);
  }

  /*RANDOMIZE ARRAY*/
  shuffleArray(array) {
    let i, j, temp;
    for (i = array.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  /*VALIDAÇÕES*/

  validateNIF(value) {
    const nif = typeof value === 'string' ? value : value.toString();
    const validationSets = {
      one: ['1', '2', '3', '5', '6', '8'],
      two: [
        '45',
        '70',
        '71',
        '72',
        '74',
        '75',
        '77',
        '79',
        '90',
        '91',
        '98',
        '99'
      ]
    };

    if (nif.length !== 9) {
      return false;
    }

    if (
      !validationSets.one.includes(nif.substr(0, 1)) &&
      !validationSets.two.includes(nif.substr(0, 2))
    ) {
      return false;
    }

    const total =
      nif[0] * 9 +
      nif[1] * 8 +
      nif[2] * 7 +
      nif[3] * 6 +
      nif[4] * 5 +
      nif[5] * 4 +
      nif[6] * 3 +
      nif[7] * 2;
    const modulo11 = Number(total) % 11;

    const checkDigit = modulo11 < 2 ? 0 : 11 - modulo11;

    return checkDigit === Number(nif[8]);
  }

  isValidIBANNumber(input) {
    if (!input) {
      input = '';
    }
    const CODE_LENGTHS = {
      AD: 24,
      AE: 23,
      AT: 20,
      AZ: 28,
      BA: 20,
      BE: 16,
      BG: 22,
      BH: 22,
      BR: 29,
      CH: 21,
      CR: 21,
      CY: 28,
      CZ: 24,
      DE: 22,
      DK: 18,
      DO: 28,
      EE: 20,
      ES: 24,
      FI: 18,
      FO: 18,
      FR: 27,
      GB: 22,
      GI: 23,
      GL: 18,
      GR: 27,
      GT: 28,
      HR: 21,
      HU: 28,
      IE: 22,
      IL: 23,
      IS: 26,
      IT: 27,
      JO: 30,
      KW: 30,
      KZ: 20,
      LB: 28,
      LI: 21,
      LT: 20,
      LU: 20,
      LV: 21,
      MC: 27,
      MD: 24,
      ME: 22,
      MK: 19,
      MR: 27,
      MT: 31,
      MU: 30,
      NL: 18,
      NO: 15,
      PK: 24,
      PL: 28,
      PS: 29,
      PT: 25,
      QA: 29,
      RO: 24,
      RS: 22,
      SA: 24,
      SE: 24,
      SI: 19,
      SK: 24,
      SM: 27,
      TN: 24,
      TR: 26
    };

    let iban = input.toUpperCase().replace(/[^A-Z0-9]/g, ''),
      code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/),
      digits;

    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
      return false;
    }

    digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
      return letter.charCodeAt(0) - 55;
    });

    return this.mod97(digits) === 1;
  }

  mod97(string) {
    let checksum = string.slice(0, 2),
      fragment;

    for (let offset = 2; offset < string.length; offset += 7) {
      fragment = checksum + string.substring(offset, offset + 7);
      checksum = parseInt(fragment, 10) % 97;
    }

    return checksum;
  }

  /* ALERTS SweetAlerts2 */

  sendNotification(type: PopupType, title: string, text?: string) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 3000,
      background: '#aaa'
    });
    Toast.fire({
      icon: type,
      title: title,
      text: text,
    });
  }

  sendConfirmation(type: PopupType, title: string, text?: string) {
    Swal.fire({
      icon: type,
      title: title,
      text: text,
      confirmButtonColor: '#222',
    });
  }

  sendConfirmationQuestion(title: string, text?: string) {
    return new Promise(response => {
      Swal.fire({
        title: title,
        text: text,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#222',
        cancelButtonColor: '#c90b02',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.value) {
          response(true);
        }
        response(false);
      });
    });
  }

  sendConfirmationQuestionWithButtonText(title: string, textConfirmButton: string, textCancelButton: string, text?: string) {
    return new Promise(response => {
      Swal.fire({
        title: title,
        text: text,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#222',
        cancelButtonColor: '#222',
        confirmButtonText: textConfirmButton,
        cancelButtonText: textCancelButton,
      }).then((result) => {
        if (result.value) {
          response(true);
        }
        response(false);
      });
    });
  }

  sendMessageFooter(title: string, text: string, footer: string, type?: PopupType) {
    let message: any = {}
    message.title = title;
    message.text = text;
    message.footer = footer;
    message.confirmButtonColor = '#222';
    if (type) {
      message.type = type;
    }
    Swal.fire(message);
  }

  sendSystemErrorAlert(err?) {
    if (err) console.log(err);
    this.sendConfirmation('error', 'Ooops ! \n Aconteceu algo inesperado.', 'Por favor tente de novo, caso o erro continue contacte connosco');
    return throwError(err);
  }

  notFound(string) {
    this.sendConfirmation('info', 'Não existem Resultados', string);
  }

  async verifyPassword(text) {
    const { value: password } = await Swal.fire({
      title: 'Confirmar Password',
      text: text,
      showCancelButton: true,
      confirmButtonColor: '#222',
      cancelButtonColor: '#c90b02',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      input: 'password',
      inputAttributes: {
        autocapitalize: 'off',
        autocorrect: 'off'
      }
    });
    if (password) {
      return new Promise(inserted => inserted(password));
    }
  }

  async insertPopUp(title, text) {
    const { value: input } = await Swal.fire({
      title: title,
      text: text,
      showCancelButton: true,
      confirmButtonColor: '#222',
      cancelButtonColor: '#c90b02',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
        autocorrect: 'off'
      }
    });
    if (input) {
      return new Promise(inserted => inserted(input));
    }
  }

  getSiteUrlWithSecurity(url: string) {
    let secUrl = '';
    if (url.includes(siteSec) || url.includes(siteSec2)) {
      return url;
    } else {
      secUrl = 'http://' + url;
      return secUrl;
    }
  }

  getEmbedUrlById(videoId) {
    const embedUrl = youtubeUrlEmbed + videoId;
    return embedUrl;
  }

  convertDateToMySQLDateTime(date) {
    //Data para mysql new Date().toISOString().slice(0, 19).replace('T', ' ');
    return date.toISOString().slice(0, 19).replace('T', ' ');
  }

  //Converte a string da data com hora em data Ano-mes-dia
  convertDateStringToDate(date: String) {
    return date.split('T')[0];
  }

  convertTimeStringToTime(time: String) {
    let hour = time.split(':')[0];
    let min = time.split(':')[1];
    let times = hour + ':' + min;
    //console.log(times);
    return times;
  }

  datesOverlap(begin1, end1, begin2, end2) {
    // dates overlap if (StartA <= EndB)  and  (EndA >= StartB)
    return (begin1 <= end2 && end1 >= begin2);
  }

  // analytics
  sendAnalyticsEvent(category, label, action, value?) {
    (<any>window).ga('send', 'event', {
      eventCategory: category,
      eventLabel: label,
      eventAction: action,
      eventValue: value
    });
  }

  getEmbedUrlFromVideoUrl(url: string) {
    let code = '';
    let embedUrl = '';
    if (url.includes(youtubeLinkName)) {
      code = url.split('v=')[1];
      const separatorPosition = code.indexOf('&');
      if (separatorPosition != -1) {
        code = code.substring(0, separatorPosition);
      }
      embedUrl = youtubeUrlEmbed + code;
    } else if (url.includes(vimeoLinkName)) {
      const aux = url.split('/');
      code = aux[aux.length - 1];
      embedUrl = vimeoUrlEmbed + code;
    } else if (url.includes(youtuLinkName)) {
      const aux = url.split('/');
      code = aux[aux.length - 1];
      embedUrl = youtubeUrlEmbed + code;
      //console.log(embedUrl);
    }
    return embedUrl;
  }
}
