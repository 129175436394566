import * as globals from '../../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';
import { FormControl, FormGroup } from '@angular/forms';

import { AuthService } from 'src/app/services/auth.service';
import { CoursesService } from '../../../../services/courses.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilsService } from '../../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-speaker-details',
  templateUrl: './speaker-details.component.html',
  styleUrls: ['./speaker-details.component.scss']
})

export class SpeakerDetailsComponent implements OnInit {

  uploaderFeatured: FileUploader;
  model_edit: any = {};
  featured_img: any = {};
  speakerId;
  imgDate: any;
  speaker: any = {};
  siteUrl = globals.site_base_url;
  permissions: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private coursesService: CoursesService,
    private utilsService: UtilsService,
    private spinner: NgxSpinnerService,
    public sanitizer: DomSanitizer,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('CoursesCreate');
    this.permissions.edit = this.auth.hasPermissions('CoursesEdit');
    this.permissions.delete = this.auth.hasPermissions('CoursesDelete');
    this.permissions.read = this.auth.hasPermissions('CoursesRead');
    if (this.permissions.read) {
      this.defineUploaderFeatured();
      this.getUrlParams();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.speakerId = params.speakerId;
      this.getSpeakerById();
    });
  }

  getSpeakerById() {
    this.coursesService.getSpeakerById(this.speakerId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        if (res) {
          this.model_edit = res as any;
          this.model_edit.date = this.utilsService.convertDateStringToDate(this.model_edit.date);
          this.speaker = this.model_edit;
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  defineUploaderFeatured() {
    this.uploaderFeatured = new FileUploader({
      url: globals.base_api_path + 'files/upload/speaker/featured',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderFeatured.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.model_edit.id = JSON.parse(response).speaker_id;
      this.model_edit.img_url = JSON.parse(response).url;
      this.imgDate = Date.now();
      this.coursesService.updateSpeaker(this.speakerId, this.model_edit)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Speaker atualizado');
          } else if (!aux.success) {
            this.utilsService.sendSystemErrorAlert();
          }
        });
      this.defineUploaderFeatured();
    };
    this.uploaderFeatured._onErrorItem = (err) => {
      //console.log(err);
      this.uploaderFeatured.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderFeatured();
    };
  }

  uploadFeaturedImage() {
    this.uploaderFeatured.queue.forEach((img, index) => {
      img._onBuildForm = (form) => {
        form.append('id', this.speakerId);
      };
      img.upload();
    });
  }

  showFeaturedImage() {
    if (this.uploaderFeatured.queue.length > 1) {
      this.uploaderFeatured.queue.splice(0, 1);
    }
    this.uploaderFeatured.queue.forEach(foto => {
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      this.featured_img.url = url;
      this.featured_img.foto = foto;
    });
  }

  cancelEditSpeaker() {
    this.model_edit = this.speaker;
  }

  updateSpeaker() {
    if (this.model_edit.description == undefined || this.model_edit.description.trim().length === 0) {
      this.utilsService.sendConfirmation('warning', 'Necessita de Preencher todos os campos em branco');
    } else {
      this.coursesService.updateSpeaker(this.speakerId, this.model_edit)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Speaker atualizado');
            this.uploadFeaturedImage();
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
        });
    }
  }

  deleteSpeaker() {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja elimiar o speaker?', 'Este processo é irreversível').then(confirm => {
      if (confirm) {
        this.coursesService.deleteSpeaker(this.speakerId)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            this.utilsService.sendNotification('success', 'Speaker eliminado');
            this.goBack();
          });
      }
    });
  }

  goBack() {
    const aux = this.router.url.split('/');
    aux.length -= 2;
    const backLink = aux.join('/');
    this.router.navigate([backLink]);
  }
}
