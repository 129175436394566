import * as globals from '../../../../assets/globals';

import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { StoresService } from 'src/app/services/stores.service';
import { UtilsService } from 'src/app/services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-create-directory-store',
  templateUrl: './create-directory-store.component.html',
  styleUrls: ['./create-directory-store.component.scss']
})
export class CreateDirectoryStoreComponent implements OnInit {

  model_create: any = {
    country: globals.portugalCountryName
  };
  countries: any[];
  districts: any[];
  activities: any[];
  cities: any[];
  phoneCode;
  permissions: any = {};

  constructor(
    private storesService: StoresService,
    private utilsService: UtilsService,
    private spinner: NgxSpinnerService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('StoresCreate');
    if (this.permissions.create) {
      this.initDropdowns();
    }
  }

  createDirectoryStore() {
    this.spinner.show();
    this.storesService.createDirectoryStore(this.model_create)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.spinner.hide();
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.spinner.hide();
        this.utilsService.sendNotification('success', 'Loja de diretório criada');
      });
  }

  isPTSelected() {
    if (this.countries && this.model_create.country) {
      return this.model_create.country === globals.portugalCountryName;
    } else {
      return false;
    }
  }

  searchPostal() {
    if (this.model_create.postal.length == 8 && this.isPTSelected()) {
      const splited_postal = this.model_create.postal.split('-', 2);
      if (splited_postal[0].length === 4 && splited_postal[1].length === 3) {
        this.utilsService
          .getPostalInfo(splited_postal[0], splited_postal[1])
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            this.model_create.district = this.districts.find(dist => dist.id === aux.district_id).name;
            this.model_create.city = this.cities.find(city => city.id === aux.city_id).name;
            this.model_create.locality = aux.locality;
          });
      }
    }
  }

  initDropdowns() {
    this.utilsService.getCountries()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.countries = data as any;
        this.updatePhoneCode();
      });
    this.utilsService.getDistricts()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.districts = data as any;
      });
    this.utilsService.getActivities()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.activities = data as any;
      });
    this.utilsService.getCities()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.cities = data as any;
      });
  }

  updatePhoneCode() {
    if (this.countries && this.model_create.country) {
      this.phoneCode = this.countries.find(country => country.countryName === this.model_create.country).phoneCode;
    }
  }

  clearFields() {
    this.model_create = {
      country: globals.portugalCountryName
    };
  }

}
