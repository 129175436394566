import * as globals from '../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, TemplateRef } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FilterPipe } from 'ngx-filter-pipe';
import { HttpErrorResponse } from '@angular/common/http';
import { IloveTVService } from '../../services/ilove-tv.service';
import { Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { UtilsService } from '../../services/utils.service';
import { catchError } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-ilove-tv',
  templateUrl: './ilove-tv.component.html',
  styleUrls: ['./ilove-tv.component.scss']
})

export class ILoveTVComponent implements OnInit {

  constructor(
    private titleService: Title,
    private sanitizer: DomSanitizer,
    private utilsService: UtilsService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private tvService: IloveTVService,
    private modalService: BsModalService,
    private auth: AuthService,
  ) { }

  modalRef: BsModalRef;
  videos: any[] = [];
  storeId;
  p;
  youtubevideos: any[];
  allVideos: any[];
  subcategoryId;
  search: any = {};
  subCategories = [];
  filters: any = {};
  selectedSubCategoryId;
  featuredVideos: any[] = [];
  addVid: Boolean;
  editVid: Boolean;
  model: any = {};
  loadingSubCategories;
  dtOptions: any = {};

  permissions: any = {};

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('ILoveTvCreate');
    this.permissions.edit = this.auth.hasPermissions('ILoveTvEdit');
    this.permissions.delete = this.auth.hasPermissions('ILoveTvDelete');
    this.permissions.read = this.auth.hasPermissions('ILoveTvRead');
    this.dtOptions = {
      paging: false,
      info: false
    };
    this.addVid = false;
    this.editVid = false;
    if (this.permissions.read) {
      this.getSubCategories();
      this.activatedRoute.params.subscribe(params => {
        this.subcategoryId = params.subCategoryId;
        if (this.subcategoryId == undefined) {
          this.getVideos();
        } else {
          this.getVideosbySubCategory();
        }
      });
    }
  }

  getVideos() {
    // TODO verificar resposta vazia
    this.tvService.getVideos()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.videos = res as any;
        this.videos.forEach(video => {
          video.embedUrl = this.getEmbedVideos(this.utilsService.getEmbedUrlFromVideoUrl(video.url));
          if (video.priority == 0 || video.priority == 1) {
            this.featuredVideos.push(video);
          }
        });
      });
  }

  getEmbedVideos(embed_url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(embed_url);
  }

  getVideosbySubCategory() {
    this.tvService.getVideosbySubCategory(this.subcategoryId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.videos = res as any;
        this.videos.forEach(video => {
          video.embedUrl = this.getEmbedVideos(this.utilsService.getEmbedUrlFromVideoUrl(video.url));
        });
      });
  }

  updateSearch() {
    this.search.filter = { $or: [{ title: this.search.searchTerm }] };
  }

  getSubCategories() {
    this.loadingSubCategories = true;
    this.utilsService.getBlogSubCategories()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.subCategories = res as any;
        this.loadingSubCategories = false;
      });
  }

  updateURLSubCategories() {
    let url = globals.baseUrls.ilovetv;
    if (this.filters.subCategory) {
      this.selectedSubCategoryId = this.subCategories.find(category => category.name === this.filters.subCategory).id;
      url += '/' + this.selectedSubCategoryId;
    }
    this.router.navigate([url]);
  }

  initModel() {
    this.model.url = '';
    this.model.title = '';
    this.model.position = '5';
    this.model.limitDate = '';
    this.model.subCategoriesToAdd = [];
  }

  addNewVideoToogle() {
    this.model.position = '5';
    this.addVid = !this.addVid;
  }

  cancelAddNewVideoToogle() {
    this.addNewVideoToogle();
    this.initModel();
  }

  addNewVideo() {
    console.log(this.model.position);
    if (this.model.url.trim().length > 0 && this.model.title.trim().length > 0) {
      if ((this.model.position !== 5 && this.model.limitDate) || this.model.position == 5) {
        const samePosition: any[] = this.videos.filter(video => video.position !== 5 && video.position == this.model.position);
        if (samePosition.length > 0) {
          this.utilsService.sendConfirmationQuestion('Já existe um video nessa posição. Deseja substituir?', 'Este processo é irreversível').then(confirm => {
            if (confirm) {
              this.tvService.addVideoWithUpdatePosition(this.model)
                .pipe(
                  catchError((err: HttpErrorResponse) => {
                    return this.utilsService.sendSystemErrorAlert(err);
                  }) as any)
                .subscribe(res => {
                  const aux = res as any;
                  if (aux.success) {
                    this.utilsService.sendNotification('success', 'Video adicionado (com update)');
                    this.initModel();
                    this.getVideos();
                  } else {
                    this.utilsService.sendSystemErrorAlert();
                  }
                  this.addNewVideoToogle();
                });
            } else {
              this.utilsService.sendNotification('error', 'Video não adicionado');
            }
          });
        } else {
          this.tvService.addVideo(this.model)
            .pipe(
              catchError((err: HttpErrorResponse) => {
                return this.utilsService.sendSystemErrorAlert(err);
              }) as any)
            .subscribe(res => {
              const aux = res as any;
              if (aux.success) {
                this.utilsService.sendNotification('success', 'Video adicionado');
                this.initModel();
                this.getVideos();
              } else {
                this.utilsService.sendSystemErrorAlert();
              }
              this.addNewVideoToogle();
            });
        }
      } else {
        this.utilsService.sendConfirmation('info', 'Insira a data limite');
      }
    } else {
      this.utilsService.sendConfirmation('info', 'Insira um url e um título');
    }
  }

  removeVideo(index) {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja elimiar o video?', 'Este processo é irreversível').then(confirm => {
      if (confirm) {
        this.tvService.deleteVideo(this.videos[index].id)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (aux.success) {
              this.utilsService.sendNotification('success', 'Video removido');
              this.videos.splice(index, 1);
              this.addVid = false;
              this.getVideos();
            } else {
              this.utilsService.sendSystemErrorAlert();
            }
          });
      }
    });
  }

  editVideo(videoId) {
    this.router.navigate([globals.baseUrls.ilovetv + '/video/' + videoId]);
  }


  openFeaturedVideos(modal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  closeModal() {
    this.modalRef.hide();
  }
}
