import * as globals from '../../../../assets/globals';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, TemplateRef } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from 'src/app/services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit {

  permissions: any = {};

  nr_items_page = globals.nr_items_page;
  search: any = {};
  p; // paginação;
  activities: any[] = [];
  activityTypes: any[] = [];

  modalRef: BsModalRef;
  activityEdit: any = {};
  activityCreate: any = {};

  constructor(
    private utils: UtilsService,
    private modalService: BsModalService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('ManagementCreate');
    this.permissions.edit = this.auth.hasPermissions('ManagementEdit');
    this.permissions.delete = this.auth.hasPermissions('ManagementDelete');
    this.permissions.read = this.auth.hasPermissions('ManagementRead');
    if (this.permissions.read) {
      this.getActivities();
      this.getActivityTypes();
    }
  }

  getActivities() {
    this.utils.getActivities()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utils.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.activities = res as any;
      });
  }

  getActivityTypes() {
    this.utils.getActivitiyTypes()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utils.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.activityTypes = res as any;
      });
  }

  openEditActivity(modal: TemplateRef<any>, activity) {
    this.activityEdit = { ...activity };
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  openCreateActivity(modal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  closeModal() {
    this.modalRef.hide();
    this.activityEdit = {};
    this.activityCreate = {};
  }

  editActivity() {
    if (this.activityEdit.name !== undefined && this.activityEdit.name.trim().length > 0 && this.activityEdit.type_id !== undefined) {
      this.utils.updateActivitiy(this.activityEdit.id, this.activityEdit)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utils.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const index = this.activities.findIndex(activity => activity.id === this.activityEdit.id);
          if (index !== -1) {
            this.activities[index] = { ...this.activityEdit };
            this.activities[index].type = this.activityTypes.find(type => type.id == this.activities[index].type_id).name;
          }
          this.closeModal();
          this.utils.sendNotification('success', 'Atividade Atualizada');
        });
    } else {
      this.utils.sendConfirmation('warning', 'Tem de preecher todos os campos');
    }
  }

  createActivity() {
    if (this.activityCreate.name !== undefined && this.activityCreate.name.trim().length > 0 && this.activityCreate.type_id !== undefined) {
      this.utils.createActivitiy(this.activityCreate)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utils.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          this.getActivities();
          this.closeModal();
          this.utils.sendNotification('success', 'Atividade criada');
        });
    } else {
      this.utils.sendConfirmation('warning', 'Tem de preecher todos os campos');
    }
  }

  deleteActivity(activity) {
    this.utils.sendConfirmationQuestion(activity.name, 'Tem a certeza que deseja apagar esta atividade?').then(confirm => {
      if (confirm) {
        this.utils.deleteActivitiy(activity.id)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utils.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const index = this.activities.findIndex(obj => obj.id === activity.id);
            if (index !== -1) {
              this.activities.splice(index, 1);
            }
            this.utils.sendNotification('success', 'Atividade Eliminada');
          });
      }
    });
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }
}
