import * as globals from '../../../assets/globals';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BlogService } from '../../services/blog.service';
import { IloveTVService } from '../../services/ilove-tv.service';
import { AuthService } from 'src/app/services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilsService } from '../../services/utils.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-homepage-site',
  templateUrl: './homepage-site.component.html',
  styleUrls: ['./homepage-site.component.scss']
})
export class HomepageSiteComponent implements OnInit {

  constructor(
    private sanitizer: DomSanitizer,
    private utilsService: UtilsService,
    private tvService: IloveTVService,
    private blogService: BlogService,
    private auth: AuthService,
    private modalService: BsModalService) { }

  modalRef: BsModalRef;
  permissions: any = {};
  homeVideo: any = {};
  posts = [];
  homePosts = [];
  postsToAdd = [];
  dtOptions: any = {};
  addVid: Boolean;
  model: any = {};
  subCategories = [];
  loadingSubCategories;
  videos: any[] = [];

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('HomepageCreate');
    this.permissions.edit = this.auth.hasPermissions('HomepageEdit');
    this.permissions.delete = this.auth.hasPermissions('HomepageDelete');
    this.permissions.read = this.auth.hasPermissions('HomepageRead');
    this.dtOptions = {
      paging: false,
      info: false,
      searching: false,
    };
    this.addVid = false;
    if (this.permissions.read) {
      this.getHomePosts();
      this.getHomeVideo();
    }
  }

  getHomeVideo() {
    this.tvService.getHomeVideo()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.homeVideo = res as any;
        this.homeVideo.embed_url = '';
        this.homeVideo.embedUrl = this.getEmbedVideos(this.utilsService.getEmbedUrlFromVideoUrl(this.homeVideo.url));
      });
  }

  getHomePosts() {
    this.blogService.getHomePosts()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.homePosts = res as any;
      });
  }

  addNewVideo() {
    if (this.model.url.trim().length > 0 && this.model.title.trim().length > 0) {
      this.tvService.addHomeVideo(this.model)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Video adicionado');
            this.initModel();
            this.getHomeVideo();
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
          this.addNewVideoToogle();
        });
    } else {
      this.utilsService.sendConfirmation('info', 'Insira um url e um título');
    }
  }

  getSubCategories() {
    this.loadingSubCategories = true;
    this.utilsService.getBlogSubCategories()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.subCategories = res as any;
        this.loadingSubCategories = false;
      });
  }

  getVideos(modal: TemplateRef<any>) {
    this.tvService.getVideos()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.videos = res as any;
        this.modalRef = this.modalService.show(modal, {
          class: 'modal-dialog-centered modal-lg'
        });
      });
  }

  getPosts(modal: TemplateRef<any>) {
    this.blogService.getAllPostsNotHome()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.posts = res as any;
        this.modalRef = this.modalService.show(modal, {
          class: 'modal-dialog-centered modal-lg'
        });
      });
  }

  changeIsHome(videoId, video) {
    this.tvService.changeIsHome(videoId, video)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          if (video.is_home) {
            this.utilsService.sendNotification('success', 'Video colocado na Home');
          } else {
            this.utilsService.sendNotification('success', 'Video retirado da Home');
          }
          this.getHomeVideo();
          this.closeModal();
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  removePostFromHome(postId, index) {
    this.blogService.removePostFromHome(postId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          this.utilsService.sendNotification('success', 'Post retirado da Home');
          this.homePosts.splice(index, 1);
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  addNewPostToHome(postId, post) {
    if (this.homePosts.length >= 4) {
      this.utilsService.sendConfirmation('info', 'Já possui 4 posts para a Home do site!');
    } else {
      //this.postsToAdd.push(post);
      this.homePosts.push(post);
      this.blogService.addPostToHome(postId)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Post adicionado à Home');
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
        });
      if (this.homePosts.length >= 4) {
        this.closeModal();
        this.utilsService.sendConfirmation('info', 'Já possui 4 posts para a Home do site!');
      }
    }
  }

  addNewVideoToogle() {
    this.initModel();
    this.getSubCategories();
    this.addVid = !this.addVid;
  }

  cancelAddNewVideoToogle() {
    this.addNewVideoToogle();
    this.initModel();
  }

  initModel() {
    this.model.url = '';
    this.model.title = '';
    this.model.position = '5';
    this.model.limitDate = '';
    this.model.subCategoriesToAdd = [];
  }

  getEmbedVideos(embed_url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(embed_url);
  }

  openVideos(modal: TemplateRef<any>) {
    this.getVideos(modal);
  }

  openPosts(modal: TemplateRef<any>) {
    this.getPosts(modal);
  }

  closeModal() {
    this.modalRef.hide();
  }
}
