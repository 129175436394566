import * as globals from '../../../../assets/globals';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, TemplateRef } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from 'src/app/services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  nr_items_page = globals.nr_items_page;
  search: any = {};
  permissions: any = {};
  p; // paginação;
  categories: any[] = [];
  categoryTypes: any[] = [];

  modalRef: BsModalRef;
  categoryEdit: any = {};
  categoryCreate: any = {};

  constructor(
    private utils: UtilsService,
    private modalService: BsModalService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('ManagementCreate');
    this.permissions.edit = this.auth.hasPermissions('ManagementEdit');
    this.permissions.delete = this.auth.hasPermissions('ManagementDelete');
    this.permissions.read = this.auth.hasPermissions('ManagementRead');
    if (this.permissions.read) {
      this.getCategories();
      this.getCategoryTypes();
    }
  }

  getCategories() {
    this.utils.getCategories()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utils.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.categories = res as any;
      });
  }

  getCategoryTypes() {
    this.utils.getCategoryTypes()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utils.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.categoryTypes = res as any;
      });
  }

  openEditCategory(modal: TemplateRef<any>, category) {
    this.categoryEdit = { ...category };
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  openCreateCategory(modal: TemplateRef<any>) {
    this.categoryCreate = {};
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  closeModal() {
    this.modalRef.hide();
    this.categoryEdit = {};
    this.categoryCreate = {};
  }

  editCategory(category) {
    if (this.categoryEdit.name !== undefined && this.categoryEdit.name.trim().length > 0 && this.categoryEdit.type_id !== undefined) {
      this.utils.updateCategory(this.categoryEdit.id, this.categoryEdit)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utils.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const index = this.categories.findIndex(category => category.id === this.categoryEdit.id);
          if (index !== -1) {
            this.categories[index] = { ...this.categoryEdit };
            this.categories[index].type = this.categoryTypes.find(type => type.id == this.categories[index].type_id).name;
          }
          this.closeModal();
          this.utils.sendNotification('success', 'Categoria Atualizada');
        });
    } else {
      this.utils.sendConfirmation('warning', 'Tem de preecher todos os campos');
    }
  }

  createCategory() {
    if (this.categoryCreate.name !== undefined && this.categoryCreate.name.trim().length > 0 && this.categoryCreate.type_id !== undefined) {
      this.utils.createCategory(this.categoryCreate)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utils.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          this.getCategories();
          this.closeModal();
          this.utils.sendNotification('success', 'Categoria criada');
        });
    } else {
      this.utils.sendConfirmation('warning', 'Tem de preecher todos os campos');
    }
  }

  deleteCategory(category) {
    this.utils.sendConfirmationQuestion(category.name, 'Tem a certeza que deseja apagar esta categoria?').then(confirm => {
      if (confirm) {
        this.utils.deleteCategory(category.id)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utils.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const index = this.categories.findIndex(obj => obj.id === category.id);
            if (index !== -1) {
              this.categories.splice(index, 1);
            }
            this.utils.sendNotification('success', 'Categoria Eliminada');
          });
      }
    });
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }

}
