import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ProfessionalsService } from '../../../services/professionals.service';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-users-professional',
  templateUrl: './users-professional.component.html',
  styleUrls: ['./users-professional.component.scss']
})
export class UsersProfessionalComponent implements OnInit {

  nr_items_page = globals.nr_items_page;
  p; // paginação;
  professionals;
  search: any = {};
  permissions: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private professionalService: ProfessionalsService,
    private utilsService: UtilsService,
    private auth: AuthService
  ) { }


  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('UsersCreate');
    this.permissions.edit = this.auth.hasPermissions('UsersEdit');
    this.permissions.delete = this.auth.hasPermissions('UsersDelete');
    this.permissions.read = this.auth.hasPermissions('UsersRead');
    if (this.permissions.read) {
      this.getAllProfessionalUsers();
    }
  }

  getAllProfessionalUsers() {
    this.professionalService.getAllProfessionalUsers()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.professionals = res as any;
        console.log(this.professionals);
      });
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }

  goToCreateProfessional(): void {
    this.router.navigate([globals.baseUrls.users + '/create/professional']);
  }

  goToBrands(userId, professionalId) {
    this.router.navigate([globals.baseUrls.users + '/' + userId + '/professional/' + professionalId + '/brands']);
  }

  goToStores(userId, professionalId) {
    this.router.navigate([globals.baseUrls.users + '/' + userId + '/professional/' + professionalId + '/stores']);
  }

  goToProfessional(userId, professionalId) {
    this.router.navigate([globals.baseUrls.users + '/' + userId + '/professional/' + professionalId]);
  }
}
