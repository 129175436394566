import { Component, OnInit, Inject } from '@angular/core';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';
import * as globals from '../../../../assets/globals';
import { UtilsService } from '../../../services/utils.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { PublicService } from '../../../services/public.service';
import { WeddingsService } from '../../../services/weddings.service';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { StoresService } from '../../../services/stores.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-create-wedding-professional',
  templateUrl: './create-wedding-professional.component.html',
  styleUrls: ['./create-wedding-professional.component.scss']
})
export class CreateWeddingProfessionalComponent implements OnInit {

  uploaderFeatured: FileUploader;
  uploaderImages: FileUploader;
  user_id;
  store_id;

  model: any = {};
  showImgsArray;
  anexo;
  featured_img: any = {};
  images: any[] = [];
  activities = globals.wedding_providers_activities;
  imagesToInsert: any[] = [];
  imagesToDelete: any[] = [];
  nrInsertedImgs;

  edit: Boolean;
  updated = false;
  addUrl;
  videosToAdd = [];
  videosToDelete = [];

  imgDate: any;

  finishUI: Boolean;
  finishDI: Boolean;
  finishUV: Boolean;
  finishDV: Boolean;

  constructor(public sanitizer: DomSanitizer,
    private router: Router,
    private utilsService: UtilsService,
    private publicService: PublicService,
    private storesService: StoresService,
    private weddingsService: WeddingsService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService, ) { }

  ngOnInit() {
    this.initModel();
    this.edit = false;
    this.defineUploaderFeatured();
    this.defineUploaderImages();
    this.initModelStores();
  }

  initModel() {
    this.model = {};
    this.featured_img = {};
    this.model.images = [];
    this.model.videos = [];
    this.model.providers = [];
    this.showImgsArray = [];
    this.finishUI = false;
    this.finishDI = false;
    this.finishUV = false;
    this.finishDV = false;
  }

  getWeddingByUrlParams() {
    this.route.params.subscribe(params => {
      this.model.wedding_id = params['wedding_id'];
    });
  }

  initModelStores() {
    this.model.providers = [];
    for (const activity of this.activities) {
      this.model.providers.push({ name: activity, stores: '' });
    }
  }

  getWeddingImgs() {
    this.weddingsService.getWeddingImgs(this.model.wedding_id)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(resImages => {
        this.model.images = resImages;
        this.showImgsArray = resImages;
      });
  }

  getWeddingVideos() {
    this.weddingsService.getWeddingVideos(this.model.wedding_id)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(resVideos => {
        this.model.videos = resVideos;
      });
  }

  defineUploaderFeatured() {

  }

  defineUploaderImages() {

  }

  uploadFeaturedImage() {
    this.uploaderFeatured.queue.forEach((img, index) => {
      img._onBuildForm = (form) => {
        form.append('id', this.model.id);
      };
      img.upload();
    });
  }

  uploadImages() {

  }

  createWedding() {

  }

  showFeaturedImage() {

  }

  showImages() {

  }

  deleteImage(index) {
    const img = this.showImgsArray.splice(index, 1)[0];
    if (img.toUpload) {
      const uploaderIndex = this.uploaderImages.queue.findIndex(item => item.file.name == img.foto.file.name);
      this.uploaderImages.queue.splice(uploaderIndex, 1);
    } else {
      this.imagesToDelete.push(img.id);
    }
  }

  insertImages() {

  }

  removeImages() {

  }

  insertVideos() {

  }

  removeVideos() {

  }

  addVideo() {
    if (this.addUrl && this.addUrl.trim() !== '') {
      this.videosToAdd.push(this.addUrl);
      this.model.videos.push({ video_url: this.addUrl });
      this.addUrl = '';
    }
  }

  deleteVideo(index) {
    this.videosToDelete.push(this.model.videos.splice(index, 1)[0].id);
  }

  getEmbedVideos(index) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      this.utilsService.getEmbedUrlFromVideoUrl(this.model.videos[index].video_url)
    );
  }

  goBack() {

  }

}
