import * as globals from '../../../../assets/globals';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, Host, OnInit } from '@angular/core';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';
import { FormControl, FormGroup } from '@angular/forms';

import { AuthService } from 'src/app/services/auth.service';
import { BlogService } from '../../../services/blog.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

declare var tinymce: any;

@Component({
  selector: 'app-create-post',
  templateUrl: './create-post.component.html',
  styleUrls: ['./create-post.component.scss']
})
export class CreatePostComponent implements OnInit {

  uploaderFeatured: FileUploader;
  uploaderImages: FileUploader;
  uploaderBlog: FileUploader;

  permissions: any = {};

  featured_img: any = {};
  images: any[] = [];
  imagesToInsert: any[] = [];
  imagesToDelete: any[] = [];
  addUrl;
  videosToAdd = [];
  videosToDelete = [];
  showImgsArray;
  showBlogImgsArray;
  updated = false;
  model_create: any = {
    html_post: '',
  };
  editorForm: FormGroup;
  categories = [];
  dataModel;
  bloggers;
  allFields: Boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private blogService: BlogService,
    private utilsService: UtilsService,
    public sanitizer: DomSanitizer,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('BlogCreate');
    this.permissions.edit = this.auth.hasPermissions('BlogEdit');
    this.permissions.delete = this.auth.hasPermissions('BlogDelete');
    this.permissions.read = this.auth.hasPermissions('BlogRead');
    if (this.permissions.create) {
      this.initEditor();
      this.initModel();
      this.defineUploaderFeatured();
      this.defineUploaderImages();
      this.defineUploaderBlog();
      this.getSubCategories();
      this.getAllBloggers();
      this.editorForm = new FormGroup({
        editor: new FormControl(null),
        title: new FormControl(null),
        sub_title: new FormControl(null),
        category: new FormControl(null),
        posted: new FormControl(null),
        addUrl: new FormControl(null),
        blogger_id: new FormControl(null),
      });
    }
  }

  initEditor() {
    tinymce.remove('textarea');
    tinymce.init({
      selector: 'textarea',  // change this value according HTML
      document_base_url: globals.site_base_url,
      menubar: 'insert file tools',
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste imagetools wordcount',
        'code',
        'autoresize',
      ],
      paste_data_images: true,
      min_height: 500,
      max_height: 1000,
      autoresize_bottom_margin: 15,
      toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify fullscreen| bullist numlist outdent indent | link | mybutton table | code',
      content_css: [
        '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
        '//www.tiny.cloud/css/codepen.min.css'
      ],
      setup: editor => {
        editor.on('keyup change', () => {
          this.model_create.html_post = editor.getContent();
        });
        editor.ui.registry.addButton('mybutton', {
          text: 'Image',
          tooltip: 'image',
          onAction: function (_) {
            var input = document.getElementById('ImgUploadHtml');
            input.click();
            editor.insertContent(input);
          }
        });
      }
    });
  }

  initModel() {
    this.model_create = {};
    this.featured_img = {};
    this.model_create.images = [];
    this.model_create.videos = [];
    this.showImgsArray = [];
    this.showBlogImgsArray = [];
    this.allFields = true;
  }

  getSubCategories() {
    this.blogService.getBlogCategoriesWithSubCategories()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.categories = res as any;
      });
  }

  getAllBloggers() {
    this.blogService.getAllBloggers()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.bloggers = res as any;
      });
  }

  onSubmit() {
    this.addToModel();
    this.createPost();
  }

  addToModel() {
    this.model_create.title = this.editorForm.get('title').value;
    this.model_create.sub_title = this.editorForm.get('sub_title').value;
    this.model_create.category = this.editorForm.get('category').value;
    this.model_create.posted = this.editorForm.get('posted').value;
    this.model_create.blogger_id = this.editorForm.get('blogger_id').value;
  }

  verifyModel() {
    if (this.model_create.title == undefined || this.model_create.title.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_create.sub_title == undefined || this.model_create.sub_title.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_create.category == undefined || this.model_create.category.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_create.posted == undefined || this.model_create.posted.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_create.html_post == undefined || this.model_create.html_post.trim().length === 0) {
      this.allFields = false;
    }
  }

  createPost() {
    this.verifyModel();
    if (this.allFields == false) {
      this.utilsService.sendConfirmation('warning', 'Necessita de Preencher todos os campos em branco');
      //this.allFields = true;
    } else {
      console.log('Create Post');
      console.log(this.model_create);
      this.blogService.createPost(this.model_create)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          var aux = res as any;
          if (aux.success) {
            this.model_create.id = aux.id;
            //this.uploadBlogImages();
            this.uploadFeaturedImage();
            this.uploadImages();
            this.insertVideos();
            this.utilsService.sendNotification('success', 'Post criado');
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
        });
    }
  }

  defineUploaderBlog() {
    this.uploaderBlog = new FileUploader({
      url: globals.base_api_path + 'files/post/text',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderBlog.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const location = JSON.parse(response).url;
      tinymce.activeEditor.insertContent('<img style="width: 100%;" src="' + location + '" alt="Imagem inserida no corpo do post"/></div>');
      this.defineUploaderBlog();
    };
    this.uploaderBlog._onErrorItem = (err) => {
      this.uploaderBlog.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderBlog();
    };
  }

  uploadQueueFiles() {
    this.uploaderBlog.uploadAll();
  }

  defineUploaderFeatured() {
    this.uploaderFeatured = new FileUploader({
      url: globals.base_api_path + 'files/post/featured',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',

    });
    this.uploaderFeatured.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.model_create.featured_img = JSON.parse(response).url;
      //this.model_create.id = JSON.parse(response).post_id;
      this.blogService.updatePost(this.model_create.id, this.model_create)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (this.updated && aux.success) {
            this.utilsService.sendNotification('success', 'Post atualizado');
            this.updated = false;
          } else if (!aux.success) {
            this.utilsService.sendSystemErrorAlert();
          }
        });
      this.defineUploaderFeatured();
    };
    this.uploaderFeatured._onErrorItem = (err) => {
      this.uploaderFeatured.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderFeatured();
    };
  }

  defineUploaderImages() {
    this.uploaderImages = new FileUploader({
      url: globals.base_api_path + 'files/post',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderImages.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.imagesToInsert.push(JSON.parse(response).url);
      this.insertImages();
    };
    this.uploaderImages._onErrorItem = (err) => {
      this.uploaderImages.clearQueue();
      //console.log(err);
      this.utilsService.sendSystemErrorAlert();
    };
  }

  uploadFeaturedImage() {
    this.uploaderFeatured.queue.forEach((img, index) => {
      img._onBuildForm = (form) => {
        form.append('id', this.model_create.id);
      };
      img.upload();
    });
  }

  uploadImages() {
    this.uploaderImages.queue.forEach((img, index) => {
      img._onBuildForm = (form) => {
        form.append('id', this.model_create.id);
      };
      img.upload();
    });
  }

  showFeaturedImage() {
    if (this.uploaderFeatured.queue.length > 1) {
      this.uploaderFeatured.queue.splice(0, 1);
    }
    this.uploaderFeatured.queue.forEach(foto => {
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      this.featured_img.url = url;
      this.featured_img.foto = foto;
    });
  }

  showImages() {
    this.showImgsArray = [...this.model_create.images, ...[]];
    this.uploaderImages.queue.forEach(foto => {
      const img: any = {};
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      img.sanitizer_url = url;
      img.foto = foto;
      img.toUpload = true;
      this.showImgsArray.push(img);
    });
  }

  deleteImage(index) {
    const img = this.showImgsArray.splice(index, 1)[0];
    if (img.toUpload) {
      const uploaderIndex = this.uploaderImages.queue.findIndex(item => item.file.name == img.foto.file.name);
      this.uploaderImages.queue.splice(uploaderIndex, 1);
    } else {
      this.imagesToDelete.push(img.id);
    }
  }

  insertImages() {
    if (this.uploaderImages.queue.length == this.imagesToInsert.length && this.imagesToInsert.length > 0) {
      this.blogService.insertImages(this.model_create.id, this.imagesToInsert)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe();
    }
  }

  removeImages() {
    if (this.imagesToDelete.length > 0) {
      this.blogService.deleteImages(this.model_create.id, this.imagesToDelete)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe();
    }
  }

  insertVideos() {
    if (this.videosToAdd.length > 0) {
      this.blogService.insertVideos(this.model_create.id, this.videosToAdd)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe();
    }
  }

  removeVideos() {
    if (this.videosToDelete.length > 0) {
      this.blogService.deleteVideos(this.model_create.id, this.videosToDelete)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe();
    }
  }

  addVideo() {
    //console.log('addVideo');
    this.addUrl = this.editorForm.get('addUrl').value;
    if (this.addUrl && this.addUrl.trim() !== '') {
      this.videosToAdd.push(this.addUrl);
      this.model_create.videos.push({ video_url: this.addUrl });
      this.addUrl = '';
    }
  }

  deleteVideo(index) {
    this.videosToDelete.push(this.model_create.videos.splice(index, 1)[0].id);
  }

  getEmbedVideos(index) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      this.utilsService.getEmbedUrlFromVideoUrl(this.model_create.videos[index].video_url)
    );
  }

}
