import * as globals from '../../../../assets/globals';

import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-navleft',
  templateUrl: './navleft.component.html',
  styleUrls: ['./navleft.component.scss']
})
export class NavleftComponent implements OnInit {
  baseUrls = globals.baseUrls;
  siteUrl = globals.site_base_url;

  user: {
    name,
    role,
    permissions
  }

  constructor(
    private auth: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.updateUserInfo();
      }
    });
  }

  updateUserInfo() {
    this.user = {
      name: this.auth.getUserInfo().name,
      role: this.auth.getUserInfo().roleName,
      permissions: this.auth.getUserInfo().permissions,
    };
  }
}
