import * as globals from '../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { AdminUsersService } from 'src/app/services/admin-users.service';
import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  modelLogin: any = {};
  returnUrl;
  sessionExpired;
  error;

  constructor(
    private adminUserService: AdminUsersService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private utils: UtilsService,
  ) { }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    // Checks if user was redirected to login page due to his token expiring
    if (this.route.snapshot.queryParamMap.get('sessionExpired') === 'true') {
      this.sessionExpired = 'A sua sessão expirou.';
    }
  }


  login() {
    this.spinner.show();
    this.authService.login(this.modelLogin.email, this.modelLogin.password)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.utils.sendSystemErrorAlert();
          return null;
        }) as any)
      .subscribe(res => {
        const loginRes = res as any;
        this.spinner.hide();
        if (loginRes.success === true) {
          this.router.navigate([this.returnUrl]);
        } else {
          if (loginRes.error) {
            const error = loginRes.error;
            if (error.error.code === globals.errorCodes.adminUserWrongCredentials) {
              this.error = "O email/username não existe ou a password está errada";
            } else if (error.error.code === globals.errorCodes.adminUserNotActive) {
              this.error = "O utilizador não está ativo";
              this.utils.sendConfirmation('error', 'Este utilizador não se encontra ativo', 'Para mais informações contacte o administrador do sistema')
            }
          } else {
            this.utils.sendSystemErrorAlert();
          }
        }
      }
      );
  }

  // recoverPassword() {
  //   this.utils.insertPopUp('Recuperar Password', 'Insira o email ou o username da sua conta').then(input => {
  //     if (input) {
  //       this.spinner.show();
  //       this.adminUserService.recoverPassword(input)
  //         .catch(err => {
  //           this.spinner.hide();
  //           if (err.error.message.includes('Authentication failed')) {
  //             this.utilsService.sendConfirmation('error', 'Password Errada');
  //           } else {
  //             this.utilsService.sendSystemErrorAlert();
  //           }
  //           return Observable.throw(err);
  //         })
  //         .subscribe(res => {
  //           this.spinner.hide();
  //           this.utilsService.sendConfirmation('success', 'Password Recuperada', 'Verifique o seu email para obter a nova password');
  //           this.router.navigate([this.returnUrl]);
  //         });
  //     }
  //   });
  // }

}
