import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, TemplateRef } from '@angular/core';

import { AdminUserRolesService } from 'src/app/services/admin-user-roles.service';
import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from 'src/app/services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-admin-roles',
  templateUrl: './admin-roles.component.html',
  styleUrls: ['./admin-roles.component.scss']
})
export class AdminRolesComponent implements OnInit {

  modalRef: BsModalRef;
  dtOptions: any = {};
  permissions: any = {};

  roles: any = [];
  actions = [
    { name: 'Ver', selector: 'Read', requiredForOthers: true },
    { name: 'Criar', selector: 'Create', requiredForOthers: false },
    { name: 'Editar', selector: 'Edit', requiredForOthers: false },
    { name: 'Remover', selector: 'Delete', requiredForOthers: false },
  ];
  dialogFields = [
    { name: 'Utilizadores de Administração', baseSelector: 'AdminUsers' },
    { name: 'Utilizadores', baseSelector: 'Users' },
    { name: 'Lojas', baseSelector: 'Stores' },
    { name: 'Marcas', baseSelector: 'Brands' },
    { name: 'Blog', baseSelector: 'Blog' },
    { name: 'ILOVE Tv', baseSelector: 'ILoveTv' },
    { name: 'Destination Weddings', baseSelector: 'DestinationWeddings' },
    { name: 'Cursos', baseSelector: 'Courses' },
    { name: 'Páginas Estáticas', baseSelector: 'StaticPages' },
    { name: 'Banners', baseSelector: 'Banners' },
    { name: 'Gestão', baseSelector: 'Management' },
    { name: 'Sorteios', baseSelector: 'Sweepstakes' },
    { name: 'Homepage Website', baseSelector: 'Homepage' },
    { name: 'Anúncios', baseSelector: 'Announcements' },
    { name: 'Paginas', baseSelector: 'Pages' },
    { name: 'Revistas', baseSelector: 'Magazines' },
  ];

  roleEdit: any;
  roleCreate: any;

  constructor(
    private adminRolesService: AdminUserRolesService,
    private modalService: BsModalService,
    private utils: UtilsService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.dtOptions = {
      paging: false,
      info: false
    };
    this.permissions.create = this.auth.hasPermissions('AdminUsersCreate');
    this.permissions.edit = this.auth.hasPermissions('AdminUsersEdit');
    this.permissions.delete = this.auth.hasPermissions('AdminUsersDelete');
    this.permissions.read = this.auth.hasPermissions('AdminUsersRead');
    if (this.permissions.read) {
      this.getRoles();
    }
  }

  getRoles() {
    this.adminRolesService.getAllRoles()
      .subscribe(res => {
        this.roles = res;
      });
  }

  openEditRoleDialog(modal: TemplateRef<any>, role) {
    this.roleEdit = { ...role };
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  openCreateRoleDialog(modal: TemplateRef<any>, role) {
    this.roleCreate = {};
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  editRole() {
    if (this.roleEdit.name !== undefined && this.roleEdit.name !== null && this.roleEdit.name.trim() !== '') {
      this.dialogFields.forEach(field => {
        this.actions.forEach(action => {
          if (this.roleEdit[field.baseSelector + action.selector] === undefined || this.roleEdit[field.baseSelector + action.selector] === null) {
            this.roleEdit[field.baseSelector + action.selector] = false;
          }
        });
      });
      this.adminRolesService.updateRole(this.roleEdit.id, this.roleEdit)
        .subscribe(res => {
          const index = this.roles.findIndex(role => role.id === this.roleEdit.id);
          if (index !== -1) {
            this.roles[index] = { ...this.roleEdit };
          }
          this.closeModal();
          this.utils.sendNotification('success', 'Role atualizado');
        });
    } else {
      this.utils.sendConfirmation('warning', 'Tem de preencher o nome');
    }
  }

  createRole() {
    if (this.roleCreate.name !== undefined && this.roleCreate.name !== null && this.roleCreate.name.trim() !== '') {
      this.dialogFields.forEach(field => {
        this.actions.forEach(action => {
          if (this.roleCreate[field.baseSelector + action.selector] === undefined || this.roleCreate[field.baseSelector + action.selector] === null) {
            this.roleCreate[field.baseSelector + action.selector] = false;
          }
        });
      });
      this.adminRolesService.createRole(this.roleCreate)
        .subscribe(res => {
          this.getRoles();
          this.closeModal();
          this.utils.sendNotification('success', 'Role Criado');
        });
    } else {
      this.utils.sendConfirmation('warning', 'Tem de preencher o nome');
    }
  }

  deleteRole(role) {
    this.utils.sendConfirmationQuestion(role.name, 'Tem a certeza que quer apagar este role?').then(confirm => {
      if (confirm) {
        this.adminRolesService.deleteRole(role.id)
          .subscribe(res => {
            const index = this.roles.findIndex(item => item.id === role.id);
            if (index !== -1) {
              this.roles.splice(index, 1);
            }
            this.utils.sendNotification('success', 'Role Apagado');
          });
      }
    });
  }

  closeModal() {
    this.modalRef.hide();
    this.roleEdit = {};
    this.roleCreate = {};
  }

  changePermissionEdit(field, action) {
    if (action.requiredForOthers && !this.roleEdit[field.baseSelector + action.selector]) {
      this.actions.forEach(actionAux => {
        this.roleEdit[field.baseSelector + actionAux.selector] = false;
      });
    }
    if (this.roleEdit[field.baseSelector + action.selector]) {
      this.actions.forEach(actionAux => {
        if (actionAux.requiredForOthers) {
          this.roleEdit[field.baseSelector + actionAux.selector] = true;
        }
      });
    }
  }

  changePermissionCreate(field, action) {
    if (action.requiredForOthers && !this.roleCreate[field.baseSelector + action.selector]) {
      this.actions.forEach(actionAux => {
        this.roleCreate[field.baseSelector + actionAux.selector] = false;
      });
    }
    if (this.roleCreate[field.baseSelector + action.selector]) {
      this.actions.forEach(actionAux => {
        if (actionAux.requiredForOthers) {
          this.roleCreate[field.baseSelector + actionAux.selector] = true;
        }
      });
    }
  }

}
