import * as globals from '../../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, Host, Inject, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { DestinationWeddingsService } from '../../../../services/destination-weddings.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploader } from 'ng2-file-upload';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from '../../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-subregion-details',
  templateUrl: './subregion-details.component.html',
  styleUrls: ['./subregion-details.component.scss']
})
export class SubregionDetailsComponent implements OnInit {

  model_edit: any = {};
  model_addUrl: any = {};
  subRegionId: any;
  regions: any[];
  error = '';
  loading = false;
  hidden = true;
  editing = false;
  featured_img: any = {};
  editInfo: boolean;
  edited: boolean;
  deleteVideo: boolean;
  addUrl;
  uploaderLogo: FileUploader;
  uploaderFeatured: FileUploader;
  imgDate: any;
  siteUrl = globals.site_base_url;
  permissions: any = {};

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private wdService: DestinationWeddingsService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('DestinationWeddingsCreate');
    this.permissions.edit = this.auth.hasPermissions('DestinationWeddingsEdit');
    this.permissions.delete = this.auth.hasPermissions('DestinationWeddingsDelete');
    this.permissions.read = this.auth.hasPermissions('DestinationWeddingsRead');
    if (this.permissions.read) {
      this.getRegions();
      this.getUrlParams();
    }
  }
  
  getUrlParams() {
    this.route.params.subscribe(params => {
      this.subRegionId = params.subRegionId;
      this.getWDSubRegionInfo();
      this.defineUploaderFeatured();
    });
  }

  getWDSubRegionInfo() {
    this.wdService.getWDSubRegionInfoByWDSubRegionID(this.subRegionId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        this.model_edit = aux;
        if (this.model_edit.video_url) {
          this.model_edit.video_url = this.getEmbedVideos(this.utilsService.getEmbedUrlFromVideoUrl(this.model_edit.video_url));
        }
      });
  }

  getEmbedVideos(video) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(video);
  }

  defineUploaderFeatured() {
    this.uploaderFeatured = new FileUploader({
      url: globals.base_api_path + 'files/upload/subregionWD/featured',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderFeatured.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.model_edit.id = JSON.parse(response).subregion_id;
      this.model_edit.featured_img = JSON.parse(response).url;
      this.imgDate = Date.now();
      //console.log(this.model.id);
      this.wdService.updateWDSubRegion(this.subRegionId, this.model_edit)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Dados da SubRegião WD atualizados (imagem atribuida)');
            this.addUrl = '';
            this.loading = false;
          } else if (!aux.success) {
            this.utilsService.sendSystemErrorAlert();
          }
        });
      this.defineUploaderFeatured();
    };
    this.uploaderFeatured._onErrorItem = (err) => {
      //console.log(err);
      this.uploaderFeatured.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderFeatured();
    };
  }

  uploadFeaturedImage() {
    this.uploaderFeatured.queue.forEach(img => {
      img._onBuildForm = (form) => {
        form.append('countryName', this.utilsService.getUrlFromName(this.regions[this.model_edit.region_id - 1].countryName));
        form.append('regionName', this.utilsService.getUrlFromName(this.regions[this.model_edit.region_id - 1].name));
        form.append('subRegionName', this.utilsService.getUrlFromName(this.model_edit.name));
      };
      img.upload();
    });
    this.uploaderFeatured.uploadAll();
  }

  showFeaturedImage() {
    if (this.uploaderFeatured.queue.length > 1) {
      this.uploaderFeatured.queue.splice(0, 1);
    }
    this.uploaderFeatured.queue.forEach(foto => {
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      this.featured_img.url = url;
      this.featured_img.foto = foto;
    });
  }

  editInfoToggle() {
    this.editInfo = !this.editInfo;
  }

  cancelInfoToggle() {
    this.editInfo = false;
    // this.parent.getStoreByUrlParams();
    this.getWDSubRegionInfo();
  }

  editData() {
    if (this.deleteVideo === true) {
      this.model_edit.video_url = null;
    }
    if (this.model_edit.description === undefined || this.model_edit.description.trim().length === 0) {
      this.model_edit.description = '';
    }
    this.loading = true;
    this.wdService.updateWDSubRegion(this.subRegionId, this.model_edit)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        let aux: { success: Boolean };
        aux = res as any;
        if (aux.success) {
          if (this.addUrl) {
            this.model_addUrl.addUrl = this.addUrl;
            this.wdService.addWDSubRegionVideo(this.subRegionId, this.model_addUrl)
              .pipe(
                catchError((err: HttpErrorResponse) => {
                  return this.utilsService.sendSystemErrorAlert(err);
                }) as any)
              .subscribe(res => {
                let aux: { success: Boolean };
                aux = res as any;
                if (aux.success) {
                  this.utilsService.sendNotification('success', 'Dados da SubRegião WD atualizados');
                  this.addUrl = '';
                  this.loading = false;
                  this.edited = true;
                  this.deleteVideo = false;
                  this.getWDSubRegionInfo();
                } else {
                  this.utilsService.sendSystemErrorAlert();
                }
              });
          } else {
            this.utilsService.sendNotification('success', 'Dados da SubRegião WD atualizados');
            this.loading = false;
            this.edited = true;
            this.deleteVideo = false;
          }
          this.uploadFeaturedImage();
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  deleteWDSubRegion() {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja elimiar a SubRegião do WD?', 'Este processo é irreversível').then(confirm => {
      //this.spinner.show();
      this.wdService.deleteWDSubRegion(this.subRegionId)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          //this.spinner.hide();
          this.utilsService.sendNotification('success', 'SubRegião eliminada do WD');
          this.goBack();
        });
    });
  }

  goBack() {
    const aux = this.router.url.split('/');
    aux.length -= 2;
    const backLink = aux.join('/');
    this.router.navigate([backLink]);
  }

  getRegions() {
    this.wdService.getAllWDRegions()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.regions = res as any;
      });
  }

  removeVideo() {
    this.deleteVideo = true;
  }
}
