import { Component, OnInit, Inject } from '@angular/core';
import { UtilsService } from '../../../services/utils.service';
import { UsersService } from '../../../services/users.service';
import { ProfessionalsService } from '../../../services/professionals.service';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import * as jwt_decode from 'jwt-decode';

import * as globals from '../../../../assets/globals';
@Component({
  selector: 'app-create-professional',
  templateUrl: './create-professional.component.html',
  styleUrls: ['./create-professional.component.scss']
})
export class CreateProfessionalComponent implements OnInit {

  model: any = {};
  error = '';
  loading = false;
  returnUrl: string;
  storeToRegister;

  countries: any[];
  districts: any[];
  cities: any[];

  phone_code;

  completeRegistration;
  completeId;
  completeEmail;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private utilsService: UtilsService,
    private userService: UsersService) { }

  ngOnInit() {
    this.initDropdowns();
  }

  initDropdowns() {
    this.utilsService.getCountries()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.countries = data as any;
        this.updatePhoneCode();
      });
    this.utilsService.getDistricts()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any).subscribe(data => {
          this.districts = data as any;
        });
    this.utilsService.getCities()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.cities = data as any;
      });
  }

  iniModel() {
    this.model.country = globals.portugalCountryName;
    this.updatePhoneCode();
    this.model.termos = false;
    this.model.hide_address = false;
  }

  createProfessional() {
    console.log("Entra no registo");
    if (this.model.iban === undefined) {
      this.model.iban = '';
    }
    if (this.model.website === undefined) {
      this.model.website = '';
    }
    if (this.model.termos) {
      if ((this.isPTSelected() && this.isNifValid()) || !this.isPTSelected()) {
        if (this.model.password === this.model.password_check) {
          this.loading = true;
          this.userService.createUserProfessional(this.model)
            .pipe(
              catchError((err: HttpErrorResponse) => {
                if (err.error && err.error.message.code === 'ER_DUP_ENTRY') {
                  const message = err.error.message.sqlMessage;
                  if (message.includes('email')) {
                    this.utilsService.sendConfirmation('error', 'O email fornecido já se encontra registado');
                  } else if (message.includes('username')) {
                    this.utilsService.sendConfirmation('error', 'O username introduzido já se encontra utilizado');
                  } else if (message.includes('nif')) {
                    this.utilsService.sendConfirmation('error', 'O nif introduzido já se encontra utilizado');
                  }
                } else {
                  this.utilsService.sendSystemErrorAlert();
                }
                this.loading = false;
                return null;
              }) as any)
            .subscribe(res => {
              const aux = res as any;
              if (aux.success) {
                this.utilsService.sendConfirmation('success', 'Utilizador criado com sucesso');
                this.router.navigate([this.returnUrl]);
              } else {
                this.utilsService.sendSystemErrorAlert();
              }
              this.loading = false;
            });
        } else {
          this.utilsService.sendConfirmation('warning', 'As passwords não coincidem');
        }
      }
    } else {
      this.utilsService.sendConfirmation('warning', 'Tem de aceitar os termos de utilização');
    }
  }

  // VALIDAÇÂO NIF

  isNifValid() {
    if (!this.model.nif || this.model.country !== globals.portugalCountryName) {
      return true;
    }
    return this.utilsService.validateNIF(this.model.nif);
  }

  // VALIDAÇÂO IBAN

  isValidIBAN() {
    if (!this.model.iban) {
      return true;
    }
    return this.utilsService.isValidIBANNumber(this.model.iban);
  }


  isPTSelected() {
    if (this.countries && this.model.country) {
      return this.model.country == globals.portugalCountryName;
    } else {
      return false;
    }
  }

  searchPostal(postal) {
    if (this.model.postal.length === 8 && this.isPTSelected()) {
      const splited_postal = this.model.postal.split('-', 2);
      if (splited_postal[0].length === 4 && splited_postal[1].length === 3) {
        this.utilsService
          .getPostalInfo(splited_postal[0], splited_postal[1])
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            this.model.district = this.districts.find(dist => dist.id === aux.district_id).name;
            this.model.city = this.cities.find(city => city.id === aux.city_id).name;
            this.model.locality = aux.locality;
          });
      }
    }
  }

  updatePhoneCode() {
    this.phone_code = this.countries.find(country => country.countryName === this.model.country).phoneCode;
  }
}
