import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, Host, Inject, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { BrandsService } from '../../../services/brands.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploader } from 'ng2-file-upload';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProductsService } from '../../../services/products.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-products-testimonials',
  templateUrl: './products-testimonials.component.html',
  styleUrls: ['./products-testimonials.component.scss']
})
export class ProductsTestimonialsComponent implements OnInit {

  testimonials;
  brandId;
  permissions: any = {};

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private brandsService: BrandsService,
    private productsService: ProductsService,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private auth: AuthService
    ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('BrandsCreate');
    this.permissions.edit = this.auth.hasPermissions('BrandsEdit');
    this.permissions.delete = this.auth.hasPermissions('BrandsDelete');
    this.permissions.read = this.auth.hasPermissions('BrandsRead');
    this.testimonials = [];
    if (this.permissions.read) {
      this.getUrlParams();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.brandId = params['brandId'];
      this.getBrandProductsTestimonials();
    });
  }

  getBrandProductsTestimonials() {
    this.brandsService.getBrandTestimonials(this.brandId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.testimonials = res;
        console.log(this.testimonials);
      });
  }

  aproveTestimony(testimony) {
    this.brandsService.approveTestimony(testimony.id)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success === true) {
          this.utilsService.sendNotification('success', 'Testemunho aprovado');
          this.getBrandProductsTestimonials();
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  removeTestimony(testimony) {
    this.brandsService.rejectTestimony(testimony.id)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success === true) {
          this.utilsService.sendNotification('success', 'Testemunho rejeitado');
          this.getBrandProductsTestimonials();
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  showhiddenTestimony(testimony, type) {
    if (type == 'hidden') {
      this.brandsService.hiddenTestimony(testimony.id)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success === true) {
            this.utilsService.sendNotification('success', 'Testemunho escondido');
            this.getBrandProductsTestimonials();
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
        });
    } else if (type == 'show') {
      this.brandsService.showTestimony(testimony.id)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success === true) {
            this.utilsService.sendNotification('success', 'Testemunho Visivel');
            this.getBrandProductsTestimonials();
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
        });
    }
  }
}
