import * as globals from '../assets/globals';

import { RouterModule, Routes } from '@angular/router';

import { ActivitiesComponent } from './components/management/activities/activities.component';
import { ActivityTypesComponent } from './components/management/activity-types/activity-types.component';
import { AdminRolesComponent } from './components/admin/admin-roles/admin-roles.component';
import { AdminUsersComponent } from './components/admin/admin-users/admin-users.component';
import { AnnouncementsComponent } from './components/announcements/announcements.component';
import { AuthGuard } from './guards/auth.guard';
import { BannerDetailsComponent } from './components/banners/banner-details/banner-details.component';
import { BannersComponent } from './components/banners/banners.component';
import { BlogCategoriesComponent } from './components/blog/blog-categories/blog-categories.component';
import { BloggerDetailsComponent } from './components/blog/bloggers/blogger-details/blogger-details.component';
import { BloggersComponent } from './components/blog/bloggers/bloggers.component';
import { BrandInfoComponent } from './components/brands/brand-info/brand-info.component';
import { BrandPlansComponent } from './components/management/brand-plans/brand-plans.component';
import { BrandProductsComponent } from './components/brands/brand-products/brand-products.component';
import { BrandVideosComponent } from './components/brands/brand-videos/brand-videos.component';
import { BrandsComponent } from './components/brands/brands.component';
import { CategoriesComponent } from './components/management/categories/categories.component';
import { CategoriesTypesComponent } from './components/management/categories-types/categories-types.component';
import { CompanyInfoComponent } from './components/management/company-info/company-info.component';
import { CountriesWDComponent } from './components/DestinationWeddings/countries-wd/countries-wd.component';
import { CountryDetailsComponent } from './components/DestinationWeddings/countries-wd/country-details/country-details.component';
import { CourseDetailsComponent } from './components/courses/course-details/course-details.component';
import { CourseImagesComponent } from './components/courses/course-images/course-images.component';
import { CourseParticipantsComponent } from './components/courses/course-participants/course-participants.component';
import { CourseVideosComponent } from './components/courses/course-videos/course-videos.component';
import { CoursesComponent } from './components/courses/courses.component';
import { CreateBrandComponent } from './components/brands/create-brand/create-brand.component';
import { CreateDirectoryStoreComponent } from './components/stores/create-directory-store/create-directory-store.component';
import { CreatePageComponent } from './components/static-pages/create-page/create-page.component';
import { CreatePostComponent } from './components/blog/create-post/create-post.component';
import { CreateProfessionalComponent } from './components/users/create-professional/create-professional.component';
import { CreatePublicComponent } from './components/users/create-public/create-public.component';
import { CreateStoreComponent } from './components/stores/create-store/create-store.component';
import { CreateSweepstakeComponent } from './components/sweepstakes/create-sweepstake/create-sweepstake.component';
import { CreateWeddingProfessionalComponent } from './components/weddings/create-wedding-professional/create-wedding-professional.component';
import { CreateWeddingPublicComponent } from './components/weddings/create-wedding-public/create-wedding-public.component';
import { HomeComponent } from './components/home/home.component';
import { HomepageSiteComponent } from './components/homepage-site/homepage-site.component';
import { ILoveTVComponent } from './components/ilove-tv/ilove-tv.component';
import { LoginComponent } from './components/login/login.component';
import { NgModule } from '@angular/core';
import { PageDetailsComponent } from './components/static-pages/page-details/page-details.component';
import { PagesComponent } from './components/pages/pages.component';
import { PlacesTypesComponent } from './components/DestinationWeddings/places-types/places-types.component';
import { PostDetailsComponent } from './components/blog/posts/post-details/post-details.component';
import { PostsComponent } from './components/blog/posts/posts.component';
import { ProductsTestimonialsComponent } from './components/brands/products-testimonials/products-testimonials.component';
import { ProfessionalBrandsComponent } from './components/users/users-professional/professional-brands/professional-brands.component';
import { ProfessionalStoresComponent } from './components/users/users-professional/professional-stores/professional-stores.component';
import { ProfessionalUserComponent } from './components/users/users-professional/professional-user/professional-user.component';
import { PromotionDetailsComponent } from './components/stores/store-promotions/promotion-details/promotion-details.component';
import { PublicUserComponent } from './components/users/users-public/public-user/public-user.component';
import { RegionDetailsComponent } from './components/DestinationWeddings/regions-wd/region-details/region-details.component';
import { RegionsWDComponent } from './components/DestinationWeddings/regions-wd/regions-wd.component';
import { SettingsComponent } from './components/management/settings/settings.component';
import { SpeakerDetailsComponent } from './components/courses/speakers/speaker-details/speaker-details.component';
import { SpeakersComponent } from './components/courses/speakers/speakers.component';
import { SponsorDetailsComponent } from './components/courses/sponsors/sponsor-details/sponsor-details.component';
import { SponsorsComponent } from './components/courses/sponsors/sponsors.component';
import { StaticPagesComponent } from './components/static-pages/static-pages.component';
import { StoreFaqsComponent } from './components/stores/store-faqs/store-faqs.component';
import { StoreGalleryComponent } from './components/stores/store-gallery/store-gallery.component';
import { StoreInfoComponent } from './components/stores/store-info/store-info.component';
import { StorePlansComponent } from './components/management/store-plans/store-plans.component';
import { StorePromotionsComponent } from './components/stores/store-promotions/store-promotions.component';
import { StoreTestimonialsComponent } from './components/stores/store-testimonials/store-testimonials.component';
import { StoreWeddingDetailsComponent } from './components/stores/store-weddings/store-wedding-details/store-wedding-details.component';
import { StoreWeddingsComponent } from './components/stores/store-weddings/store-weddings.component';
import { StoresComponent } from './components/stores/stores.component';
import { SubRegionsWDComponent } from './components/DestinationWeddings/sub-regions-wd/sub-regions-wd.component';
import { SubregionDetailsComponent } from './components/DestinationWeddings/sub-regions-wd/subregion-details/subregion-details.component';
import { SubregionImagesComponent } from './components/DestinationWeddings/sub-regions-wd/subregion-images/subregion-images.component';
import { SubregionPlacesComponent } from './components/DestinationWeddings/sub-regions-wd/subregion-places/subregion-places.component';
import { SweepstakeParticipantsComponent } from './components/sweepstakes/sweepstake-participants/sweepstake-participants.component';
import { SweepstakesComponent } from './components/sweepstakes/sweepstakes.component';
import { SweepstakesDetailsComponent } from './components/sweepstakes/sweepstakes-details/sweepstakes-details.component';
import { UsersComponent } from './components/users/users.component';
import { UsersProfessionalComponent } from './components/users/users-professional/users-professional.component';
import { UsersPublicComponent } from './components/users/users-public/users-public.component';
import { VideoDetailsComponent } from './components/ilove-tv/video-details/video-details.component';
import { WeddingProfessionalDetailsComponent } from './components/weddings/wedding-professional-details/wedding-professional-details.component';
import { WeddingPublicDetailsComponent } from './components/weddings/wedding-public-details/wedding-public-details.component';
import { WeddingsComponent } from './components/weddings/weddings.component';
import { DigitalMagazinesComponent } from './components/magazines/digital-magazines/digital-magazines.component';
import { PaperMagazinesComponent } from './components/magazines/paper-magazines/paper-magazines.component';
import { MagazinesCommentsComponent } from './components/magazines/digital-magazines/magazines-comments/magazines-comments.component';
import { MagazinesImagesComponent } from './components/magazines/paper-magazines/magazines-images/magazines-images.component';

const baseUrls = globals.baseUrls;

const routes: Routes = [
  // { path: '', redirectTo: '/' + baseUrls.home, pathMatch: 'full'},
  { path: baseUrls.home, component: HomeComponent, canActivate: [AuthGuard] },
  { path: baseUrls.login, component: LoginComponent },
  { path: baseUrls.users, component: UsersComponent, canActivate: [AuthGuard] },
  { path: baseUrls.users + '/create/professional', component: CreateProfessionalComponent, canActivate: [AuthGuard] },
  { path: baseUrls.users + '/professional', component: UsersProfessionalComponent, canActivate: [AuthGuard] },
  { path: baseUrls.users + '/:userId/professional/:professionalId', component: ProfessionalUserComponent, canActivate: [AuthGuard] },
  { path: baseUrls.users + '/:userId/professional/:professionalId/brands', component: ProfessionalBrandsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.users + '/:userId/professional/:professionalId/stores', component: ProfessionalStoresComponent, canActivate: [AuthGuard] },
  { path: baseUrls.users + '/create/public', component: CreatePublicComponent, canActivate: [AuthGuard] },
  { path: baseUrls.users + '/public', component: UsersPublicComponent, canActivate: [AuthGuard] },
  { path: baseUrls.users + '/:userId/public/:publicId', component: PublicUserComponent, canActivate: [AuthGuard] },
  { path: baseUrls.users + '/:userId/public/:publicId/wedding', component: WeddingPublicDetailsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.stores, component: StoresComponent, canActivate: [AuthGuard] },
  { path: baseUrls.stores + '/create/:professionalId', component: CreateStoreComponent, canActivate: [AuthGuard] },
  { path: baseUrls.stores + '/create-directory', component: CreateDirectoryStoreComponent, canActivate: [AuthGuard] },
  { path: baseUrls.stores + '/:storeId/info', component: StoreInfoComponent, canActivate: [AuthGuard] },
  { path: baseUrls.stores + '/:storeId/faqs', component: StoreFaqsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.stores + '/:storeId/gallery', component: StoreGalleryComponent, canActivate: [AuthGuard] },
  { path: baseUrls.stores + '/:storeId/weddings', component: StoreWeddingsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.stores + '/:storeId/weddings/:weddingId', component: StoreWeddingDetailsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.stores + '/:storeId/promotions', component: StorePromotionsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.stores + '/:storeId/promotions/:promotionId', component: PromotionDetailsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.stores + '/:storeId/testimonials', component: StoreTestimonialsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.brands, component: BrandsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.brands + '/create/:professionalId', component: CreateBrandComponent, canActivate: [AuthGuard] },
  { path: baseUrls.brands + '/:brandId/info', component: BrandInfoComponent, canActivate: [AuthGuard] },
  { path: baseUrls.brands + '/:brandId/videos', component: BrandVideosComponent, canActivate: [AuthGuard] },
  { path: baseUrls.brands + '/:brandId/products', component: BrandProductsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.brands + '/:brandId/testimonials', component: ProductsTestimonialsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.ilovetv, component: ILoveTVComponent, canActivate: [AuthGuard] },
  { path: baseUrls.ilovetv + '/video/:videoId', component: VideoDetailsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.ilovetv + '/:subCategoryId', component: ILoveTVComponent, canActivate: [AuthGuard] },
  // { path: baseUrls.weddings, component: WeddingsComponent, canActivate: [AuthGuard] },
  // { path: baseUrls.weddings + '/create/store', component: CreateWeddingProfessionalComponent, canActivate: [AuthGuard] },
  // { path: baseUrls.weddings + '/create/store/:storeId', component: CreateWeddingProfessionalComponent, canActivate: [AuthGuard] },
  // { path: baseUrls.weddings + '/create/public', component: CreateWeddingPublicComponent, canActivate: [AuthGuard] },
  // { path: baseUrls.weddings + '/create/public/:publicId', component: CreateWeddingPublicComponent, canActivate: [AuthGuard] },
  // { path: baseUrls.weddings + '/public/:weddingId', component: WeddingPublicDetailsComponent, canActivate: [AuthGuard] },
  // { path: baseUrls.weddings + '/store/:weddingId', component: WeddingProfessionalDetailsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.blog + '/create', component: CreatePostComponent, canActivate: [AuthGuard] },
  { path: baseUrls.blog + '/posts', component: PostsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.blog + '/posts/:bloggerId', component: PostsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.blog + '/post/:postId', component: PostDetailsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.blog + '/categories', component: BlogCategoriesComponent, canActivate: [AuthGuard] },
  { path: baseUrls.blog + '/bloggers', component: BloggersComponent, canActivate: [AuthGuard] },
  { path: baseUrls.blog + '/blogger/:bloggerId', component: BloggerDetailsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.destinationWeddings + '/countries', component: CountriesWDComponent, canActivate: [AuthGuard] },
  { path: baseUrls.destinationWeddings + '/regions', component: RegionsWDComponent, canActivate: [AuthGuard] },
  { path: baseUrls.destinationWeddings + '/regions/country/:countryId', component: RegionsWDComponent, canActivate: [AuthGuard] },
  { path: baseUrls.destinationWeddings + '/subregions', component: SubRegionsWDComponent, canActivate: [AuthGuard] },
  { path: baseUrls.destinationWeddings + '/subregions/region/:regionId', component: SubRegionsWDComponent, canActivate: [AuthGuard] },
  { path: baseUrls.destinationWeddings + '/country/:countryId', component: CountryDetailsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.destinationWeddings + '/region/:regionId', component: RegionDetailsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.destinationWeddings + '/subregion/:subRegionId', component: SubregionDetailsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.destinationWeddings + '/subregion/:subRegionId/places', component: SubregionPlacesComponent, canActivate: [AuthGuard] },
  { path: baseUrls.destinationWeddings + '/subregion/:subRegionId/images', component: SubregionImagesComponent, canActivate: [AuthGuard] },
  { path: baseUrls.destinationWeddings + '/subregions/types', component: PlacesTypesComponent, canActivate: [AuthGuard] },
  { path: baseUrls.destinationWeddings + '/subregions/type/:typeId', component: SubregionPlacesComponent, canActivate: [AuthGuard] },
  { path: baseUrls.management + '/categories', component: CategoriesComponent, canActivate: [AuthGuard] },
  { path: baseUrls.management + '/category-types', component: CategoriesTypesComponent, canActivate: [AuthGuard] },
  { path: baseUrls.management + '/activities', component: ActivitiesComponent, canActivate: [AuthGuard] },
  { path: baseUrls.management + '/activity-types', component: ActivityTypesComponent, canActivate: [AuthGuard] },
  { path: baseUrls.management + '/store-plans', component: StorePlansComponent, canActivate: [AuthGuard] },
  { path: baseUrls.management + '/brand-plans', component: BrandPlansComponent, canActivate: [AuthGuard] },
  { path: baseUrls.management + '/admin/users', component: AdminUsersComponent, canActivate: [AuthGuard] },
  { path: baseUrls.management + '/admin/roles', component: AdminRolesComponent, canActivate: [AuthGuard] },
  { path: baseUrls.management + '/company-info', component: CompanyInfoComponent, canActivate: [AuthGuard] },
  { path: baseUrls.management + '/settings', component: SettingsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.staticPages, component: StaticPagesComponent, canActivate: [AuthGuard] },
  { path: baseUrls.staticPages + '/create', component: CreatePageComponent, canActivate: [AuthGuard] },
  { path: baseUrls.staticPages + '/:pageId', component: PageDetailsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.courses, component: CoursesComponent, canActivate: [AuthGuard] },
  { path: baseUrls.courses + '/:courseId/info', component: CourseDetailsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.courses + '/:courseId/images', component: CourseImagesComponent, canActivate: [AuthGuard] },
  { path: baseUrls.courses + '/:courseId/videos', component: CourseVideosComponent, canActivate: [AuthGuard] },
  { path: baseUrls.courses + '/:courseId/participants', component: CourseParticipantsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.courses + '/speakers', component: SpeakersComponent, canActivate: [AuthGuard] },
  { path: baseUrls.courses + '/speaker/:speakerId', component: SpeakerDetailsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.courses + '/sponsors', component: SponsorsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.courses + '/sponsor/:sponsorId', component: SponsorDetailsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.banners, component: BannersComponent, canActivate: [AuthGuard] },
  { path: baseUrls.banners + '/:id', component: BannerDetailsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.sweepstakes, component: SweepstakesComponent, canActivate: [AuthGuard] },
  { path: baseUrls.sweepstakes + '/:sweepstakeId/info', component: SweepstakesDetailsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.sweepstakes + '/create', component: CreateSweepstakeComponent, canActivate: [AuthGuard] },
  { path: baseUrls.sweepstakes + '/:sweepstakeId/participants', component: SweepstakeParticipantsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.homepage, component: HomepageSiteComponent, canActivate: [AuthGuard] },
  { path: baseUrls.announcements, component: AnnouncementsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.pages, component: PagesComponent, canActivate: [AuthGuard] },
  { path: baseUrls.magazine + '/digital', component: DigitalMagazinesComponent, canActivate: [AuthGuard] },
  { path: baseUrls.magazine + '/digital/comments', component: MagazinesCommentsComponent, canActivate: [AuthGuard] },
  { path: baseUrls.magazine + '/paper', component: PaperMagazinesComponent, canActivate: [AuthGuard] },
  { path: baseUrls.magazine + '/paper/:magazineYear/:magazineId/images', component: MagazinesImagesComponent, canActivate: [AuthGuard] },
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
