import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, Host, Inject, OnInit } from '@angular/core';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';

import { AuthService } from 'src/app/services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { StoresService } from '../../../services/stores.service';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-store-gallery',
  templateUrl: './store-gallery.component.html',
  styleUrls: ['./store-gallery.component.scss']
})
export class StoreGalleryComponent implements OnInit {

  uploader: FileUploader;
  imgs: any[];
  nrImgs;

  anexo: any;
  siteUrl = globals.site_base_url;
  addPic: Boolean;
  pictures = [];
  limitPictures;
  storeId;
  store;
  selectedFile: File = null;
  photosPerPage: number = 8;

  p = 1; //Pagination

  addVid: Boolean;
  videos = [];
  limitVideos;
  addUrl;

  delVideos: Boolean;

  permissions: any = {};

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private storesService: StoresService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('StoresCreate');
    this.permissions.edit = this.auth.hasPermissions('StoresEdit');
    this.permissions.delete = this.auth.hasPermissions('StoresDelete');
    this.permissions.read = this.auth.hasPermissions('StoresRead');
    this.nrImgs = 0;
    this.addVid = false;
    this.delVideos = false;
    if (this.permissions.read) {
      this.defineUploader();
      this.getUrlParams();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.storeId = params['storeId'];
      this.getStore();
    });
  }

  getStore() {
    this.storesService.getStoreInfoByStoreId(this.storeId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.store = res as any;
        this.getPictures();
        this.getVideos();
      });
  }

  defineUploader() {
    this.uploader = new FileUploader({
      url: globals.base_api_path + 'files/upload/store/foto',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.imgs = [];
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.imgs.push(JSON.parse(response));
      this.uploadImgs();
    };
    this.uploader._onErrorItem = (err) => {
      this.uploader.clearQueue();
      this.utilsService.sendSystemErrorAlert();
    };
  }

  onUpload() {
    if (this.pictures.length + this.uploader.queue.length <= this.limitPictures) {
      if (this.uploader.queue.length > 0) {
        this.spinner.show();
      };
      this.uploader.queue.forEach(img => {
        img._onBuildForm = (form) => {
          form.append('storeName', this.utilsService.getUrlFromName(this.store.name));
          form.append('storeId', this.storeId);
          form.append('activity', this.utilsService.getUrlFromName(this.store.activity_name));
        };
        this.nrImgs++;
        img.upload();
      });
    } else {
      this.utilsService.sendConfirmation('error', 'O número de imagens que está a tentar adicionar ultrapassa o permitido para a loja com o plano atual.');
    }
  }

  uploadImgs() {
    if (this.imgs.length === this.nrImgs) {
      this.storesService.uploadPictureStorebyId(this.storeId, this.imgs)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            if (!err.error) {
              this.utilsService.sendSystemErrorAlert();
            } else if (err.error.code === globals.errorCodes.planLimitExceeded) {
              if (this.pictures.length < this.limitPictures) {
                const nrAdd = this.limitPictures - this.pictures.length;
                this.utilsService.sendConfirmation('error', 'O número de imagens que está a tentar adicionar ultrapassa o permitido para a loja com o plano atual.', 'Só pode adicionar mais ' + nrAdd + (nrAdd === 1 ? ' imagem' : ' imagens'));
              } else {
                this.utilsService.sendConfirmation('error', 'Atingiu o limite de imagens que pode colocar na loja com o plano atual.');
              }
            }
            this.spinner.hide();
            return Observable.throw(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          this.spinner.hide();
          if (aux.success) {
            this.getPictures();
            this.utilsService.sendNotification('success', 'Fotografias adicionadas');
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
          this.resetImages();
        });
    }
  }

  resetImages() {
    this.imgs = [];
    this.nrImgs = 0;
    this.uploader.clearQueue();
  }

  getPictures() {
    this.storesService.getStoreImagesByStoreId(this.storeId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        this.pictures = aux.photos;
        this.limitPictures = aux.limitPhotos;
      });
  }

  removePicture(index: number) {
    index = this.photosPerPage * (this.p - 1) + index;
    this.utilsService.sendConfirmationQuestion('Tem a certeza que quer apagar a fotografia?').then(confirm => {
      if (confirm) {
        this.storesService.deletePicturebyId(this.storeId, this.pictures[index].id)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (aux.success) {
              this.pictures.splice(index, 1);
              this.utilsService.sendNotification('success', 'Imagem apagada');
            } else {
              this.utilsService.sendSystemErrorAlert();
            }
          });
      }
    });
  }

  getVideos() {
    this.storesService.getStoreVideosByStoreId(this.storeId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        //console.log(res);
        const aux = res as any;
        this.videos = aux.videos;
        this.limitVideos = aux.limitVideos;
        this.videos.forEach(video => {
          video.url = this.getEmbedVideos(this.utilsService.getEmbedUrlFromVideoUrl(video.url));
        });
        this.delVideos = false;
      });
  }

  getEmbedVideos(video) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(video);
  }

  addVideo() {
    this.addVid = true;
  }

  addNewVideo() {
    if (this.addUrl.trim().length > 0) {
      this.storesService.addVideo(this.storeId, this.addUrl)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            if (!err.error) {
              this.utilsService.sendSystemErrorAlert();
            } else if (err.error.code === globals.errorCodes.planLimitExceeded) {
              this.utilsService.sendConfirmation('error', 'Atingiu o limite de videos que pode colocar na loja com o plano atual.');
            }
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Video adicionado');
            this.videos.push({ id: aux.id, store_id: this.storeId, url: this.addUrl });
            this.addUrl = '';
            this.addVid = false;
            this.ngOnInit();
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
        });
    } else {
      this.utilsService.sendConfirmation('info', 'Insira um url');
    }
  }

  removeVideo(index) {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja eliminar o video?', 'Este processo é irreversível').then(confirm => {
      if (confirm) {
        this.storesService.deleteVideo(this.videos[index].id)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (aux.success) {
              this.utilsService.sendNotification('success', 'Video removido');
              this.videos.splice(index, 1);
              this.videos.push({ store_id: this.storeId, url: this.addUrl });
              this.addUrl = '';
              this.addVid = false;
              this.delVideos = true;
              this.getVideos();
            } else {
              this.utilsService.sendSystemErrorAlert();
            }
          });
      }
    });
  }
}
