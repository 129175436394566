import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { PagesService } from '../../services/pages.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from 'src/app/services/utils.service';
import { catchError } from 'rxjs/operators';

import * as globals from '../../../assets/globals';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

  nr_items_page = globals.nr_items_page;
  p; // paginação;
  pages;
  search: any = {};
  page;
  pageId;
  allFields: Boolean
  permissions: any = {};
  modalRef: BsModalRef;
  pageEdit: any = {};

  constructor(private utilsService: UtilsService,
    private modalService: BsModalService,
    private auth: AuthService,
    private router: Router,
    private pagesService: PagesService, ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('PagesCreate');
    this.permissions.edit = this.auth.hasPermissions('PagesEdit');
    this.permissions.delete = this.auth.hasPermissions('PagesDelete');
    this.permissions.read = this.auth.hasPermissions('PagesRead');
    if (this.permissions.read) {
      this.getAllPages();
    }
  }


  getAllPages() {
    this.pagesService.getAllPages()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.pages = res as any;
      });
  }

  openEditPage(modal: TemplateRef<any>, page) {
    this.pageEdit = { ...page };
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  closeModal() {
    this.modalRef.hide();
    this.pageEdit = {};
  }

  editPage() {
    if (this.pageEdit.title !== undefined && this.pageEdit.title.trim().length > 0 && this.pageEdit.description !== undefined && this.pageEdit.description.trim().length > 0) {
      this.pagesService.updatePage(this.pageEdit.id, this.pageEdit)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const index = this.pages.findIndex(page => page.id === this.pageEdit.id);
          if (index !== -1) {
            this.pages[index] = { ...this.pageEdit };
          }
          this.closeModal();
          this.utilsService.sendNotification('success', 'Página Atualizada');
        });
    } else {
      this.utilsService.sendConfirmation('warning', 'Tem de preecher todos os campos');
    }
  }

  changeIsVisible(pageId, page) {
    this.pagesService.changeIsVisible(pageId, page)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          if (aux.is_visible) {
            this.utilsService.sendNotification('success', 'Página visivel');
          } else {
            this.utilsService.sendNotification('success', 'Página Não visivel');
          }
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }
}
