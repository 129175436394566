import * as globals from '../../assets/globals';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ObservableLike } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private http: HttpClient) { }

  apiRoute = globals.base_api_path + 'blog/';
  filesApiRoute = globals.base_api_path + 'ficheiros/';

  getAllBloggers(): Observable<any> {
    return this.http.get(this.apiRoute + 'bloggers');
  }

  getAllPosts(): Observable<any> {
    return this.http.get(this.apiRoute + 'posts');
  }

  getPostsByBloggerId(bloggerId): Observable<any> {
    return this.http.get(this.apiRoute + '/blogger/' + bloggerId + '/posts');
  }

  changeActivated(bloggerId, model): Observable<any> {
    return this.http.put(this.apiRoute + bloggerId + '/activated', model);
  }

  getBloggerInfoByBloggerId(bloggerId): Observable<any> {
    return this.http.get(this.apiRoute + 'blogger/' + bloggerId);
  }

  changePassword(bloggerId, password): Observable<any> {
    return this.http.put(this.apiRoute + 'change-password/' + bloggerId, { password: password });
  }

  editData(id: string, model) {
    return this.http.put(this.apiRoute + 'edit/blogger/' + id, model);
  }

  changeFeatured(postId, model): Observable<any> {
    return this.http.put(this.apiRoute + postId + '/featured', model);
  }

  changeBlogHome(postId, model): Observable<any> {
    return this.http.put(this.apiRoute + postId + '/bloghome', model);
  }

  changeOnline(postId, model): Observable<any> {
    return this.http.put(this.apiRoute + postId + '/online', model);
  }

  getPostById(postId): Observable<any> {
    return this.http.get(this.apiRoute + 'postedit/' + postId);
  }

  getPostImgs(postId): Observable<any> {
    return this.http.get(this.apiRoute + 'getpostimages/' + postId);
  }

  getPostVideos(postId): Observable<any> {
    return this.http.get(this.apiRoute + 'getpostvideos/' + postId);
  }

  insertImages(id, urls): Observable<any> {
    return this.http.post(this.apiRoute + 'addimages/' + id, { images: urls });
  }

  deleteImages(id, urls): Observable<any> {
    return this.http.put(this.apiRoute + 'deleteimages/' + id, { images: urls });
  }

  insertVideos(id, urls): Observable<any> {
    return this.http.post(this.apiRoute + 'addvideos/' + id, { videos: urls });
  }

  deleteVideos(id, urls): Observable<any> {
    return this.http.put(this.apiRoute + 'deletevideos/' + id, { videos: urls });
  }

  updatePost(id, model): Observable<any> {
    return this.http.put(this.apiRoute + 'edit/post/' + id, model);
  }

  createBlogger(model): Observable<any> {
    return this.http.post(this.apiRoute + 'new-blogger', model);
  }

  deleteBlogger(bloggerId): Observable<any> {
    return this.http.put(this.apiRoute + 'delete/blogger/' + bloggerId, null);
  }

  deletePost(postId): Observable<any> {
    return this.http.put(this.apiRoute + 'delete/post/' + postId, null);
  }

  getBlogCategoriesWithSubCategories(): Observable<any> {
    return this.http.get(this.apiRoute + 'blogcategories');
  }

  changeSubCategoryActivated(subCategoryId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'subcategory/' + subCategoryId + '/activated', model);
  }

  changeCategoryActivated(categoryId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'category/' + categoryId + '/activated', model);
  }

  createCategory(model): Observable<any> {
    return this.http.post(this.apiRoute + 'new-category', model);
  }

  createSubCategory(model, categoryId): Observable<any> {
    return this.http.post(this.apiRoute + 'new-subcategory/' + categoryId, model);
  }

  updateCategory(categoryId, category) {
    return this.http.put(this.apiRoute + 'edit/category/' + categoryId, category);
  }

  updateSubCategory(subCategoryId, subCategory) {
    return this.http.put(this.apiRoute + 'edit/subcategory/' + subCategoryId, subCategory);
  }

  deleteSubCategory(subCategoryId): Observable<any> {
    return this.http.put(this.apiRoute + 'delete/subcategory/' + subCategoryId, null);
  }

  deleteCategory(categoryId): Observable<any> {
    return this.http.put(this.apiRoute + 'delete/category/' + categoryId, null);
  }

  createPost(post): Observable<any> {
    return this.http.post(this.apiRoute + 'new-post', post);
  }

  getHomePosts(): Observable<any> {
    return this.http.get(this.apiRoute + 'posts/home');
  }

  removePostFromHome(postId): Observable<any> {
    return this.http.put(this.apiRoute + postId + '/remove_home', null);
  }

  getAllPostsNotHome(): Observable<any> {
    return this.http.get(this.apiRoute + 'posts/not-home');
  }

  addPostToHome(postId): Observable<any> {
    return this.http.put(this.apiRoute + postId + '/add_home', null);
  }
}
