import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { StaticPagesService } from '../../../services/static-pages.service';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

declare var tinymce: any;

@Component({
  selector: 'app-create-page',
  templateUrl: './create-page.component.html',
  styleUrls: ['./create-page.component.scss']
})

export class CreatePageComponent implements OnInit {

  model_create: any = {
    html: '',
  };
  permissions: any = {};

  editorForm: FormGroup;
  allFields: Boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private staticPagesService: StaticPagesService,
    private utilsService: UtilsService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('BrandsCreate');
    this.permissions.edit = this.auth.hasPermissions('BrandsEdit');
    this.permissions.delete = this.auth.hasPermissions('BrandsDelete');
    this.permissions.read = this.auth.hasPermissions('BrandsRead');
    if (this.permissions.read) {
      this.initModel();
      this.initEditor();
      this.editorForm = new FormGroup({
        'editor': new FormControl(null),
        'title': new FormControl(null),
        'url': new FormControl(null),
      });
    }
  }

  initModel() {
    this.model_create = {};
    this.allFields = true;
  }

  onSubmit() {
    this.addToModel();
    this.createPage();
  }

  verifyModel() {
    if (this.model_create.title == undefined || this.model_create.title.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_create.url == undefined || this.model_create.url.trim().length === 0) {
      this.allFields = false;
    }
  }

  addToModel() {
    this.model_create.title = this.editorForm.get('title').value;
    this.model_create.url = this.editorForm.get('url').value;
  }

  createPage() {
    this.verifyModel();
    if (this.allFields == false) {
      this.utilsService.sendConfirmation('warning', 'Necessita de Preencher todos os campos em branco');
      this.allFields = true;
    } else {
      this.staticPagesService.createPage(this.model_create)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Página criada');
            this.goBack();
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
        });
    }
  }

  cancelChanges() {
    this.initModel();
  }

  initEditor() {
    tinymce.remove('textarea');
    tinymce.init({
      selector: 'textarea',  // change this value according HTML
      document_base_url: globals.site_base_url,
      menubar: 'insert file tools',
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste imagetools wordcount',
        'code',
        'autoresize',
      ],
      paste_data_images: true,
      min_height: 500,
      max_height: 1000,
      autoresize_bottom_margin: 15,
      toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify fullscreen| bullist numlist outdent indent | link | mybutton table | code',
      content_css: [
        '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
        '//www.tiny.cloud/css/codepen.min.css'
      ],
      setup: editor => {
        editor.on('keyup change', () => {
          this.model_create.html = editor.getContent();
        });
        editor.ui.registry.addButton('mybutton', {
          text: 'Image',
          tooltip: 'image',
          onAction: function (_) {
            var input = document.getElementById('ImgUploadHtml');
            input.click();
            editor.insertContent(input);
          }
        });
      }
    });
  }

  goBack() {
    const aux = this.router.url.split('/');
    aux.length -= 1;
    const backLink = aux.join('/');
    this.router.navigate([backLink]);
  }
}
