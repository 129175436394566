import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';
import { FormControl, FormGroup } from '@angular/forms';

import { AuthService } from 'src/app/services/auth.service';
import { CoursesService } from '../../../services/courses.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.scss']
})

export class CourseDetailsComponent implements OnInit {

  siteUrl = globals.site_base_url;
  uploaderFeatured: FileUploader;
  model_edit: any = {};
  model_activity: any = {};
  featured_img: any = {};
  courseId;
  imgDate: any;
  course: any = {};
  allFields: boolean;
  speakers: any;
  selectedSponsors = [];
  selectedSponsorsBackup = [];
  loadingSponsors;
  sponsorsToAdd = [];
  sponsorsToRemove = [];
  sponsors = [];
  schedule: any = {};
  activities = [];
  activitiesToAdd = [];
  activitiesToRemove = [];
  showActivities = [];
  addActivityBool: boolean;

  permissions: any = {};
  dates = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private coursesService: CoursesService,
    private utilsService: UtilsService,
    public sanitizer: DomSanitizer,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('CoursesCreate');
    this.permissions.edit = this.auth.hasPermissions('CoursesEdit');
    this.permissions.delete = this.auth.hasPermissions('CoursesDelete');
    this.permissions.read = this.auth.hasPermissions('CoursesRead');
    if (this.permissions.read) {
      this.defineUploaderFeatured();
      this.getUrlParams();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.courseId = params.courseId;
      this.getAllSpeakers();
      this.getAllSponsors();
      this.getCourseById();
    });
  }

  getAllSpeakers() {
    this.coursesService.getAllSpeakers()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        if (res) {
          this.speakers = res as any;
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  getAllSponsors() {
    this.coursesService.getAllSponsors()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        if (res) {
          this.sponsors = res as any;
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  getCourseById() {
    this.coursesService.getCourseById(this.courseId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        if (res) {
          this.model_edit = res as any;
          if (this.model_edit.init_date) {
            this.model_edit.init_date = this.utilsService.convertDateStringToDate(this.model_edit.init_date);
          }
          if (this.model_edit.end_date) {
            this.model_edit.end_date = this.utilsService.convertDateStringToDate(this.model_edit.end_date);
          }
          this.course = this.model_edit;
          this.coursesService.getCourseScheduleByCourseId(this.courseId)
            .pipe(
              catchError((err: HttpErrorResponse) => {
                return this.utilsService.sendSystemErrorAlert(err);
              }) as any)
            .subscribe(res => {
              if (res) {
                this.activities = res as any;
                this.activities.forEach(item => {
                  item.init_hour = this.utilsService.convertTimeStringToTime(item.init_hour);
                  if (item.end_hour) {
                    item.end_hour = this.utilsService.convertTimeStringToTime(item.end_hour);
                  }
                });
                console.log(this.activities);
                this.showActivities = this.activities;
              } else {
                this.utilsService.sendSystemErrorAlert();
              }
            });
          this.getCourseSponsors();
          this.getDates(this.course.nr_days);
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  defineUploaderFeatured() {
    this.uploaderFeatured = new FileUploader({
      url: globals.base_api_path + 'files/upload/course/featured',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderFeatured.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.model_edit.id = JSON.parse(response).course_id;
      this.model_edit.featured_img = JSON.parse(response).url;
      this.imgDate = Date.now();
      this.coursesService.updateCourse(this.courseId, this.model_edit)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Curso atualizado');
          } else if (!aux.success) {
            this.utilsService.sendSystemErrorAlert();
          }
        });
      this.defineUploaderFeatured();
    };
    this.uploaderFeatured._onErrorItem = (err) => {
      this.uploaderFeatured.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderFeatured();
    };
  }

  uploadFeaturedImage() {
    this.uploaderFeatured.queue.forEach((img, index) => {
      img._onBuildForm = (form) => {
        form.append('id', this.courseId);
      };
      img.upload();
    });
  }

  showFeaturedImage() {
    if (this.uploaderFeatured.queue.length > 1) {
      this.uploaderFeatured.queue.splice(0, 1);
    }
    this.uploaderFeatured.queue.forEach(foto => {
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      this.featured_img.url = url;
      this.featured_img.foto = foto;
    });
  }

  cancelEditCourse() {
    this.model_edit = this.course;
  }

  updateCourse() {
    this.verifyModel();
    this.model_edit.sponsorsToAdd = this.sponsorsToAdd;
    this.model_edit.sponsorsToRemove = this.sponsorsToRemove;
    if (this.allFields == false) {
      this.utilsService.sendConfirmation('warning', 'Necessita de Preencher todos os campos em branco');
      this.allFields = true;
    } else {
      this.coursesService.updateCourse(this.courseId, this.model_edit)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            //this.utilsService.sendNotification('success', 'Curso atualizado');
            this.uploadFeaturedImage();
            this.updateCourseSchedule();
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
        });
    }
  }

  updateCourseSchedule() {
    this.schedule.activities = this.activities;
    this.schedule.activitiesToAdd = this.activitiesToAdd;
    this.schedule.activitiesToRemove = this.activitiesToRemove;
    console.log(this.schedule);
    this.coursesService.updateCourseSchedule(this.courseId, this.schedule)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          this.utilsService.sendNotification('success', 'Curso atualizado');
          this.activitiesToAdd = [];
          this.activitiesToRemove = [];
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  verifyModel() {
    if (this.model_edit.title == undefined || this.model_edit.title.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_edit.init_date == undefined || this.model_edit.init_date.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_edit.price == undefined || this.model_edit.price.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_edit.place == undefined || this.model_edit.place.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_edit.locality == undefined || this.model_edit.locality.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_edit.conditions == undefined || this.model_edit.conditions.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_edit.description == undefined || this.model_edit.description.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_edit.payment == undefined || this.model_edit.payment.trim().length === 0) {
      this.allFields = false;
    }
  }

  deleteCourse() {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja elimiar o curso?', 'Este processo é irreversível').then(confirm => {
      if (confirm) {
        //this.spinner.show();
        this.coursesService.deleteCourse(this.courseId)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            //this.spinner.hide();
            this.utilsService.sendNotification('success', 'Curso eliminado');
            this.goBack();
          });
      }
    });
  }

  goBack() {
    const aux = this.router.url.split('/');
    aux.length -= 2;
    const backLink = aux.join('/');
    this.router.navigate([backLink]);
  }

  getCourseSponsors() {
    this.coursesService.getCourseSponsors(this.courseId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        this.selectedSponsors = aux.map(sponsor => sponsor.sponsor_id);
        this.selectedSponsorsBackup = this.selectedSponsors;
      });
  }

  addSponsor(event) {
    const id = event.id;
    const index = this.sponsorsToRemove.indexOf(id);
    if (index > -1) {
      this.sponsorsToRemove.splice(index, 1);
    } else {
      this.sponsorsToAdd.push(id);
    }
  }

  removeSponsor(event) {
    const id = event.value.id;
    const index = this.sponsorsToAdd.indexOf(id);
    if (index > -1) {
      this.sponsorsToAdd.splice(index, 1);
    } else {
      this.sponsorsToRemove.push(id);
    }
  }

  clearSponsors() {
    this.sponsorsToRemove = this.selectedSponsorsBackup;
  }

  addActivity() {
    this.activitiesToAdd.push(this.model_activity);
    this.showActivities.push(this.model_activity);
    this.model_activity = {};
  }

  removeActivity(activity) {
    /*const index = this.activities.indexOf(activity);
    this.activities.splice(index, 1);*/
    const index2 = this.showActivities.indexOf(activity);
    this.showActivities.splice(index2, 1);
    this.activitiesToRemove.push(activity);
  }

  addActivityToogle() {
    this.addActivityBool = !this.addActivityBool;
  }

  cancelAddActivityToogle() {
    this.addActivityToogle();
    this.model_activity = {};
  }

  getDates(nrDays) {
    this.dates = [];
    this.dates[0] = this.model_edit.init_date;
    //this.dates[this.model_edit.nr_days - 1] = this.model_edit.end_date;
    for (let i = 1; i < nrDays; i++) {
      var sdate = new Date(this.model_edit.init_date);
      sdate.setDate(sdate.getDate() + i);
      this.dates[i] = sdate;
    }
  }

  nrDaysBetweenDates() {
    const date1 = new Date(this.model_edit.init_date);
    const date2 = new Date(this.model_edit.end_date);
    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;
    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();
    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);
    this.getDates(diffInDays + 1);
  }
}