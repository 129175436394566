import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../services/utils.service';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  c = '2%';
  nrUsers;
  nrStores;
  nrBrands;


  constructor(
    private utilsService: UtilsService) { }

  ngOnInit() {
    this.getNrBrands();
    this.getNrStores();
    this.getNrUsers();
  }

  getNrUsers() {
    this.utilsService.getNrUsers()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.nrUsers = data as any;
      });
  }

  getNrStores() {
    this.utilsService.getNrStores()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.nrStores = data as any;
      });
  }

  getNrBrands() {
    this.utilsService.getNrBrands()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.nrBrands = data as any;
      });
  }
}
