import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, Host, Inject, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { DestinationWeddingsService } from '../../../services/destination-weddings.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploader } from 'ng2-file-upload';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';
@Component({
  selector: 'app-countries-wd',
  templateUrl: './countries-wd.component.html',
  styleUrls: ['./countries-wd.component.scss']
})
export class CountriesWDComponent implements OnInit {

  nr_items_page = globals.nr_items_page;
  search: any = {};
  p; // paginação;
  countries: any[] = [];
  countriesCreate: any[] = [];
  addCountry: boolean;
  model: any = {};
  error = '';
  loading = false;
  hidden = true;
  featured_img: any = {};
  uploaderFeatured: FileUploader;
  addUrl;
  deleteVideo: boolean;
  imgDate: any;
  countryId;

  permissions: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private wdService: DestinationWeddingsService,
    private utilsService: UtilsService,
    private sanitizer: DomSanitizer,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('DestinationWeddingsCreate');
    this.permissions.edit = this.auth.hasPermissions('DestinationWeddingsEdit');
    this.permissions.delete = this.auth.hasPermissions('DestinationWeddingsDelete');
    this.permissions.read = this.auth.hasPermissions('DestinationWeddingsRead');
    this.addCountry = false;
    if (this.permissions.read) {
      this.getCountries();
      this.getAllWDCountries();
      this.defineUploaderFeatured();
    }
  }

  getCountries() {
    this.utilsService.getCountries()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.countriesCreate = res as any;
      });
  }

  getAllWDCountries() {
    this.wdService.getAllWDCountries()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.countries = res as any;
        console.log(this.countries);
      });
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }

  goToCountry(countryId): void {
    this.router.navigate([globals.baseUrls.destinationWeddings + '/country/' + countryId]);
  }

  goToRegionsByCountryId(countryId): void {
    this.router.navigate([globals.baseUrls.destinationWeddings + '/regions/country/' + countryId]);
  }

  addCountryToogle() {
    this.addCountry = !this.addCountry;
  }

  changeActive(countryId, country) {
    this.wdService.changeActiveCountry(countryId, country)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          if (country.active) {
            this.utilsService.sendNotification('success', 'País visivel no WD');
          } else {
            this.utilsService.sendNotification('success', 'País Não visivel no WD');
          }
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  getEmbedVideos(video) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(video);
  }

  defineUploaderFeatured() {
    this.uploaderFeatured = new FileUploader({
      url: globals.base_api_path + 'files/upload/countryWD/featured',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderFeatured.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.model.id = JSON.parse(response).country_id;
      this.model.featured_img = JSON.parse(response).url;
      this.imgDate = Date.now();
      //console.log(this.model.id);
      this.wdService.updateWDCountry(this.countryId, this.model)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Dados do Pais WD atualizados (imagem atribuida)');
            this.addUrl = '';
            this.loading = false;
            this.deleteVideo = false;
          } else if (!aux.success) {
            this.utilsService.sendSystemErrorAlert();
          }
        });
      this.defineUploaderFeatured();
    };
    this.uploaderFeatured._onErrorItem = (err) => {
      //console.log(err);
      this.uploaderFeatured.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderFeatured();
    };
  }

  uploadFeaturedImage() {
    this.uploaderFeatured.queue.forEach(img => {
      img._onBuildForm = (form) => {
        form.append('countryName', this.utilsService.getUrlFromName(this.countriesCreate[this.model.country_id - 1].countryName));
      };
      img.upload();
    });
    this.uploaderFeatured.uploadAll();
  }

  showFeaturedImage() {
    if (this.uploaderFeatured.queue.length > 1) {
      this.uploaderFeatured.queue.splice(0, 1);
    }
    this.uploaderFeatured.queue.forEach(foto => {
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      this.featured_img.url = url;
      this.featured_img.foto = foto;
    });
  }

  createCountryWD() {
    if (!this.model.country_id) {
      this.utilsService.sendConfirmation('warning', 'Para criar o país no WeddingDestination tem de escolher o país correspondente');
    } else {
      this.model.video_url = this.addUrl;
      this.wdService.createCountryWD(this.model)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.model.id = aux.id;
            this.countryId = aux.id;
            this.uploadFeaturedImage();
            this.utilsService.sendNotification('success', 'País do WD criado');
          }
        });
    }
  }
}
