import * as globals from '../../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';
import { LOCAL_STORAGE, WINDOW } from '@ng-toolkit/universal';

import { AuthService } from 'src/app/services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { StoresService } from '../../../../services/stores.service';
import { UtilsService } from '../../../../services/utils.service';
import { WeddingsService } from '../../../../services/weddings.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-store-wedding-details',
  templateUrl: './store-wedding-details.component.html',
  styleUrls: ['./store-wedding-details.component.scss']
})

export class StoreWeddingDetailsComponent implements OnInit {

  uploaderFeatured: FileUploader;
  uploaderImages: FileUploader;
  user_id;
  store_id;

  model: any = {};
  showImgsArray;
  anexo;
  featured_img: any = {};
  images: any[] = [];
  activities = globals.wedding_providers_activities;
  imagesToInsert: any[] = [];
  imagesToDelete: any[] = [];
  nrInsertedImgs;
  siteUrl = globals.site_base_url;
  edit: Boolean;
  updated = false;
  addUrl;
  videosToAdd = [];
  videosToDelete = [];

  imgDate: any;

  finishUI: Boolean;
  finishDI: Boolean;
  finishUV: Boolean;
  finishDV: Boolean;

  weddingId;

  permissions: any = {};

  constructor(
    public sanitizer: DomSanitizer,
    private router: Router,
    private utilsService: UtilsService,
    private storesService: StoresService,
    private weddingsService: WeddingsService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('StoresCreate');
    this.permissions.edit = this.auth.hasPermissions('StoresEdit');
    this.permissions.delete = this.auth.hasPermissions('StoresDelete');
    this.permissions.read = this.auth.hasPermissions('StoresRead');
    this.edit = false;
    if (this.permissions.read) {
      this.initModel();
      this.defineUploaderFeatured();
      this.defineUploaderImages();
      this.initModelStores();
      this.getWeddingByUrlParams();
    }
  }

  initModel() {
    this.model = {};
    this.featured_img = {};
    this.model.images = [];
    this.model.videos = [];
    this.model.providers = [];
    this.showImgsArray = [];
    this.finishUI = false;
    this.finishDI = false;
    this.finishUV = false;
    this.finishDV = false;
  }

  getWeddingByUrlParams() {
    this.route.params.subscribe(params => {
      this.model.wedding_id = params['weddingId'];
      this.weddingId = params['weddingId'];
      this.getWedding();
    });
  }

  initModelStores() {
    this.model.providers = [];
    for (const activity of this.activities) {
      this.model.providers.push({ name: activity, stores: '' });
    }
  }

  getWedding() {
    this.storesService.getStoreWeddingByWeddingId(this.weddingId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        if (res) {
          this.model = res;
          this.model.date = this.utilsService.convertDateStringToDate(this.model.date);
          this.getWeddingImgs();
          this.getWeddingVideos();
        }
        this.initModelStores();
      });
  }

  getWeddingImgs() {
    this.weddingsService.getWeddingImgs(this.weddingId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(resImages => {
        this.model.images = resImages;
        this.showImgsArray = resImages;
      });
  }

  getWeddingVideos() {
    this.weddingsService.getWeddingVideos(this.weddingId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(resVideos => {
        this.model.videos = resVideos;
      });
  }

  editWeddingToggle() {
    this.edit = !this.edit;
  }

  cancelEditWeddingToggle() {
    this.editWeddingToggle();
    this.getWedding();
  }

  defineUploaderFeatured() {
    this.uploaderFeatured = new FileUploader({
      url: globals.base_api_path + 'files/upload/wedding/featured',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderFeatured.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.model.id = JSON.parse(response).wedding_id;
      this.model.featured_img = JSON.parse(response).url;
      this.imgDate = Date.now();
      this.weddingsService.updateStoreWedding(this.model.id, this.model)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (this.updated && aux.success) {
            this.utilsService.sendNotification('success', 'Casamento atualizado');
            this.updated = false;
          } else if (!aux.success) {
            this.utilsService.sendSystemErrorAlert();
          }
        });
      this.defineUploaderFeatured();
    };
    this.uploaderFeatured._onErrorItem = (err) => {
      this.uploaderFeatured.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderFeatured();
    };
  }

  defineUploaderImages() {
    this.uploaderImages = new FileUploader({
      url: globals.base_api_path + 'files/upload/wedding',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderImages.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const url = JSON.parse(response).url;
      this.imagesToInsert.push(url);
      this.insertImages();
    };
    this.uploaderImages._onErrorItem = (err) => {
      this.uploaderImages.clearQueue();
      this.utilsService.sendSystemErrorAlert();
    };
  }

  deleteWedding() {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que quer apagar o casamento?', 'Este processo é irreversível').then(confirm => {
      if (confirm) {
        this.weddingsService.deleteStoreWedding(this.model.id)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (aux.success) {
              this.utilsService.sendNotification('success', 'Casamento apagado');
              this.model = {};
              this.goBack();
            }
          });
      }
    });
  }

  uploadFeaturedImage() {
    this.uploaderFeatured.queue.forEach((img, index) => {
      img._onBuildForm = (form) => {
        form.append('id', this.model.id);
      };
      img.upload();
    });
  }

  uploadImages() {
    if (this.model.images.length + this.uploaderImages.queue.length <= globals.limitPhotosWeddings) {
      if (this.uploaderImages.queue.length > 0) {
        //this.spinner.show();
      };
      this.nrInsertedImgs = this.uploaderImages.queue.length;
      this.uploaderImages.queue.forEach((img, index) => {
        img._onBuildForm = (form) => {
          form.append('id', this.model.id);
        };
        img.upload();
      });
    } else {
      this.utilsService.sendConfirmation('error', 'O número de imagens que está a tentar adicionar ultrapassa o permitido para os casamentos.');
    }
  }

  updateWedding() {
    if (!this.model.date || !this.model.groom || this.model.groom.trim() === '' || !this.model.bride || this.model.bride.trim() === '') {
      this.utilsService.sendConfirmation('warning', 'Para criar o casamento tem de inserir os nomes dos noivos e a data de casamento');
    } else if (this.showImgsArray.length > globals.limitPhotosWeddings) {
      this.utilsService.sendConfirmation('warning', 'Escedeu o limite de fotografias. Só pode fazer upload de ' + globals.limitPhotosWeddings + ' fotografias.');
    } else {
      if (this.model.local == undefined) {
        this.model.local = '';
      }
      if (this.model.description == undefined) {
        this.model.description = '';
      } else {
        this.model.description = this.model.description.replace(/["]/g, '\\"');
        //console.log(this.model.description);
      }
      if (this.uploaderFeatured.queue.length === 0) {
        const aux = this.model;
        aux.images = [];
        this.weddingsService.updateStoreWedding(this.model.id, aux)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (aux.success) {
              this.utilsService.sendNotification('success', 'Casamento atualizado');
            } else {
              this.utilsService.sendSystemErrorAlert();
            }
          });
      } else {
        this.uploadFeaturedImage();
      }
      this.uploadImages();
      this.removeImages();
      this.insertVideos();
      this.removeVideos();
      //this.updateProviders();
      this.editWeddingToggle();
      if (this.finishDI && this.finishDV && this.finishUI && this.finishUV) {
        //this.spinner.hide();
      }
      this.getWedding();
    }
  }


  showFeaturedImage() {
    if (this.uploaderFeatured.queue.length > 1) {
      this.uploaderFeatured.queue.splice(0, 1);
    }
    this.uploaderFeatured.queue.forEach(foto => {
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      this.featured_img.url = url;
      this.featured_img.foto = foto;
    });
  }

  showImages() {
    this.showImgsArray = [...this.model.images, ...[]];
    this.uploaderImages.queue.forEach(foto => {
      const img: any = {};
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      img.sanitizer_url = url;
      img.foto = foto;
      img.toUpload = true;
      this.showImgsArray.push(img);
    });
  }

  deleteImage(index) {
    const img = this.showImgsArray.splice(index, 1)[0];
    if (img.toUpload) {
      const uploaderIndex = this.uploaderImages.queue.findIndex(item => item.file.name == img.foto.file.name);
      this.uploaderImages.queue.splice(uploaderIndex, 1);
    } else {
      this.imagesToDelete.push(img.id);
    }
  }

  insertImages() {
    if (this.nrInsertedImgs == this.imagesToInsert.length && this.nrInsertedImgs > 0) {
      this.weddingsService.insertImagesStore(this.model.id, this.imagesToInsert)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          this.uploaderImages.clearQueue();
          this.imagesToInsert = [];
          if (this.finishDI && this.finishDV && this.finishUV) {
            //this.spinner.hide();
          } else {
            this.finishUI = true;
          }
          this.getWeddingImgs();
        });
    } else {
      if (this.finishDI && this.finishDV && this.finishUV) {
        //this.spinner.hide();
      } else {
        this.finishUI = true;
      }
    }
  }

  removeImages() {
    if (this.imagesToDelete.length > 0) {
      //this.spinner.show();
      this.weddingsService.deleteImagesStore(this.model.id, this.imagesToDelete)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          this.imagesToDelete = [];
          if (this.finishUI && this.finishDV && this.finishUV) {
            //this.spinner.hide();
          } else {
            this.finishDI = true;
          }
          this.getWeddingImgs();
        });
    }
    else {
      if (this.finishUI && this.finishDV && this.finishUV) {
        //this.spinner.hide();
      } else {
        this.finishDI = true;
      }
    }
  }

  insertVideos() {
    if (this.videosToAdd.length > 0) {
      //this.spinner.show();
      this.weddingsService.insertVideosStore(this.model.id, this.videosToAdd)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          this.videosToAdd = [];
          if (this.finishDI && this.finishDV && this.finishUI) {
            //this.spinner.hide();
          } else {
            this.finishUV = true;
          }
          this.getWeddingVideos();
        });
    } else {
      if (this.finishDI && this.finishDV && this.finishUI) {
        //this.spinner.hide();
      } else {
        this.finishUV = true;
      }
    }
  }

  removeVideos() {
    if (this.videosToDelete.length > 0) {
      //this.spinner.show();
      this.weddingsService.deleteVideosStore(this.model.id, this.videosToDelete)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          this.videosToDelete = [];
          if (this.finishDI && this.finishUI && this.finishUV) {
            //this.spinner.hide();
          } else {
            this.finishDV = true;
          }
          this.getWeddingVideos();
        });
    } else {
      if (this.finishDI && this.finishUI && this.finishUV) {
        //this.spinner.hide();
      } else {
        this.finishDV = true;
      }
    }
  }

  addVideo() {
    if (this.addUrl && this.addUrl.trim() !== '') {
      this.videosToAdd.push(this.addUrl);
      this.model.videos.push({ video_url: this.addUrl });
      this.addUrl = '';
    }
  }

  deleteVideo(index) {
    this.videosToDelete.push(this.model.videos.splice(index, 1)[0].id);
  }

  getEmbedVideos(index) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      this.utilsService.getEmbedUrlFromVideoUrl(this.model.videos[index].video_url)
    );
  }

  goBack() {
    const aux = this.router.url.split('/');
    aux.length -= 1;
    const backLink = aux.join('/');
    this.router.navigate([backLink]);
  }
}
