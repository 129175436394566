import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { StaticPagesService } from '../../../services/static-pages.service';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

declare var tinymce: any;

@Component({
  selector: 'app-page-details',
  templateUrl: './page-details.component.html',
  styleUrls: ['./page-details.component.scss']
})

export class PageDetailsComponent implements OnInit {

  model_edit: any = {
    html: '',
    title: '',
    url: '',
  };
  page;
  pageId;
  editorForm: FormGroup;
  allFields: Boolean;

  permissions: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private staticPagesService: StaticPagesService,
    private utilsService: UtilsService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('BrandsCreate');
    this.permissions.edit = this.auth.hasPermissions('BrandsEdit');
    this.permissions.delete = this.auth.hasPermissions('BrandsDelete');
    this.permissions.read = this.auth.hasPermissions('BrandsRead');
    if (this.permissions.read) {
      this.getUrlParams();
    }
  }

  initModel() {
    this.model_edit = {};
    this.allFields = true;
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.pageId = params.pageId;
      this.initModel();
      this.getPage();
    });
  }

  onSubmit() {
    this.editPage();
  }

  verifyModel() {
    if (this.model_edit.title == undefined || this.model_edit.title.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_edit.url == undefined || this.model_edit.url.trim().length === 0) {
      this.allFields = false;
    }
  }

  editPage() {
    this.verifyModel();
    if (this.allFields == false) {
      this.utilsService.sendConfirmation('warning', 'Necessita de Preencher todos os campos em branco');
      this.allFields = true;
    } else {
      this.staticPagesService.updatePage(this.pageId, this.model_edit)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Página atualizado');
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
        });
    }
  }

  getPage() {
    this.staticPagesService.getPageById(this.pageId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        this.page = aux;
        console.log(this.page);
        this.initEditor(aux.html);
        //console.log(res);
        this.model_edit.title = aux.title;
        this.model_edit.url = aux.url;
      });
  }

  cancelChanges() {
    this.model_edit = this.page;
  }

  initEditor(content) {
    tinymce.init({
      selector: 'textarea',  // change this value according HTML
      document_base_url: globals.site_base_url,
      menubar: 'insert file tools',
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste imagetools wordcount',
        'code',
        'autoresize'
      ],
      min_height: 500,
      max_height: 1000,
      autoresize_bottom_margin: 15,
      toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify fullscreen| bullist numlist outdent indent | link | mybutton table | code',
      content_css: [
        '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
        '//www.tiny.cloud/css/codepen.min.css'
      ],
      setup: editor => {
        editor.on('init', () => {
          editor.setContent(content);
          this.model_edit.html = editor.getContent();
        });
        editor.on('keyup change', () => {
          this.model_edit.html = editor.getContent();
        });
        editor.ui.registry.addButton('mybutton', {
          text: 'Image',
          tooltip: 'image',
          onAction: function (_) {
            var input = document.getElementById('ImgUploadHtml');
            input.click();
            editor.insertContent(input);
          }
        });
      }
    });
    if (!this.permissions.edit) {
      tinymce.activeEditor.setMode('readonly');
    }
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }

  goBack() {
    const aux = this.router.url.split('/');
    aux.length -= 1;
    const backLink = aux.join('/');
    this.router.navigate([backLink]);
  }

  deletePage() {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja elimiar a Página?', 'Este processo é irreversível').then(confirm => {
      //this.spinner.show();
      this.staticPagesService.deletePage(this.pageId)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          //this.spinner.hide();
          this.utilsService.sendNotification('success', 'Página eliminada');
          this.goBack();
        });
    });
  }
}
