import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SweepstakesService } from 'src/app/services/sweepstakes.service';
import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from 'src/app/services/utils.service';
import { catchError } from 'rxjs/operators';
import * as globals from '../../../assets/globals';

@Component({
  selector: 'app-sweepstakes',
  templateUrl: './sweepstakes.component.html',
  styleUrls: ['./sweepstakes.component.scss']
})

export class SweepstakesComponent implements OnInit {

  dtOptions: any = {};
  permissions: any = {};

  sweepstakes: any = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sweepstakesService: SweepstakesService,
    private utilsService: UtilsService,
    private modalService: BsModalService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.dtOptions = {
      paging: false,
      info: false
    };
    this.permissions.create = this.auth.hasPermissions('SweepstakesCreate');
    this.permissions.edit = this.auth.hasPermissions('SweepstakesEdit');
    this.permissions.delete = this.auth.hasPermissions('SweepstakesDelete');
    this.permissions.read = this.auth.hasPermissions('SweepstakesRead');
    if (this.permissions.read) {
      this.getSweepstakes();
    }
  }

  getSweepstakes() {
    this.sweepstakesService.getAllSweepstakes()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        this.sweepstakes = [...aux];
        this.sweepstakes.forEach(sweepstake => {
          sweepstake.init_date = this.utilsService.convertDateStringToDate(sweepstake.init_date);
          sweepstake.end_date = this.utilsService.convertDateStringToDate(sweepstake.end_date);
        });
        console.log(this.sweepstakes);
      });
  }

  goToSweepstake(sweepstakeId, page) {
    this.router.navigate([globals.baseUrls.sweepstakes + '/' + sweepstakeId + '/' + page]);
  }

  goToCreateSweepstake() {
    this.router.navigate([globals.baseUrls.sweepstakes + '/create']);
  }

  changeIsVisible(sweepstakeId, sweepstake) {
    this.sweepstakesService.changeIsVisible(sweepstakeId, sweepstake)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          if (sweepstake.is_visible) {
            this.utilsService.sendNotification('success', 'Sorteio visivel');
          } else {
            this.utilsService.sendNotification('success', 'Sorteio Não visivel');
          }
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }
}
