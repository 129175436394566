import * as globals from '../../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, Host, Inject, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { FileUploader } from 'ng2-file-upload';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { PublicService } from '../../../../services/public.service';
import { UsersService } from '../../../../services/users.service';
import { UtilsService } from '../../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-public-user',
  templateUrl: './public-user.component.html',
  styleUrls: ['./public-user.component.scss']
})
export class PublicUserComponent implements OnInit {

  constructor(
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    private publicService: PublicService,
    private usersService: UsersService,
    private spinner: NgxSpinnerService,
    private auth: AuthService,
  ) { }

  userId;
  publicId;
  changePassword: Boolean;
  model_user: any = {};
  model_public: any = {};
  model_password: any = {};
  interests: any[] = [];
  countries: any[];
  districts: any[];
  cities: any[];
  maritalStatus: any[];
  literaryAbilities: any[];
  plans: any[];
  oldPublic;
  loading = false;
  hidden = true;
  edited: Boolean;
  uploader: FileUploader;
  siteUrl = globals.site_base_url;
  imgDate: any;
  phoneCode;

  oldEmail;

  permissions: any = {};


  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('UsersCreate');
    this.permissions.edit = this.auth.hasPermissions('UsersEdit');
    this.permissions.delete = this.auth.hasPermissions('UsersDelete');
    this.permissions.read = this.auth.hasPermissions('UsersRead');
    this.edited = false;
    this.changePassword = false;
    if (this.permissions.read) {
      this.getUrlParams();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.userId = params['userId'];
      this.publicId = params['publicId'];
      this.initDropdowns();
      this.getData();
    });
  }

  getData() {
    this.usersService.getUserData(this.userId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.model_user = data as any;
        this.oldEmail = this.model_user.email;
        this.publicService.getPublicData(this.userId)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(dataPublic => {
            this.model_public = dataPublic as any;
            this.oldPublic = dataPublic as any;
            this.defineUploader();
            if (this.model_public.wedding_date != null) {
              this.model_public.wedding_date = this.utilsService.convertDateStringToDate(this.model_public.wedding_date);
            }
            this.searchPostal(null);
            this.utilsService.getInterestsList()
              .pipe(
                catchError((err: HttpErrorResponse) => {
                  return this.utilsService.sendSystemErrorAlert(err);
                }) as any)
              .subscribe(res => {
                this.interests = res as any;
                this.publicService.getInterestsPublic(this.model_public.id)
                  .pipe(
                    catchError((err: HttpErrorResponse) => {
                      return this.utilsService.sendSystemErrorAlert(err);
                    }) as any)
                  .subscribe(resInterests => {
                    const aux = resInterests as any;
                    this.interests.forEach(interest => {
                      if (aux.some(item => item.id === interest.id && item.type === interest.type)) {
                        interest.selected = true;
                      } else {
                        interest.selected = false;
                      }
                    });
                  });
              });
            this.updatePhoneCode()
          });
      });
    ;
  }

  defineUploader() {
    this.uploader = new FileUploader({
      url: globals.base_api_path + 'files/profile-img/' + this.model_user.username,
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.model_user.profile_img_url = JSON.parse(response).url;
      this.utilsService.sendNotification('success', 'Fotografia de perfil atualizada');
      this.imgDate = Date.now();
      this.defineUploader();
    };
    this.uploader._onErrorItem = (err) => {
      this.uploader.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploader();
    };
  }

  onUpload() {
    this.uploader.queue.forEach((img, index) => {
      img._onBuildForm = (form) => {
        form.append('userId', this.userId);
      };
      img.upload();
    });
  }

  mudarPassword() {
    this.changePassword = true;
  }

  alterarPassword() {
    this.loading = true;
    if (this.model_password.password == this.model_password.password_check) {
      this.usersService.changePassword(this.userId, this.model_password.password)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          let aux: { success: Boolean };
          aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Password Atualizada');
            this.loading = false;
            this.changePassword = false;
            this.model_password = {};
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
        });
    } else {
      this.utilsService.sendConfirmation('warning', 'As passwords não coincidem');
    }
  }

  editData() {
    this.loading = true;
    if (this.isNifValid()) {
      if (this.model_public.address == undefined || this.model_public.address.trim().length === 0) {
        this.model_public.address = '';
      }
      if (this.model_public.occupation == undefined || this.model_public.occupation.trim().length === 0) {
        this.model_public.occupation = '';
      }
      if (this.model_public.postal == undefined || this.model_public.postal.trim().length === 0) {
        this.model_public.postal = '';
      }
      if (this.model_public.locality == undefined || this.model_public.locality.trim().length === 0) {
        this.model_public.locality = '';
      }
      if (this.model_public.engaged === undefined) {
        this.model_public.engaged = false;
      }
      const updated = false;
      this.model_user.district = this.model_public.district;
      if (this.model_public.engaged) {
        this.model_user.wedding_date = this.model_public.wedding_date;
      } else {
        this.model_user.wedding_date = null;
      }
      this.model_user.oldEmail = this.oldEmail;
      if (this.oldEmail != this.model_user.email) {
        this.model_user.changeEmail = true;
      }
      this.usersService.putUserData(this.userId, this.model_user)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            console.log('User error');
            if (!err.error) {
              this.utilsService.sendSystemErrorAlert();
            } else if (err.error.message.code === 'ER_DUP_ENTRY') {
              const message = err.error.message.sqlMessage;
              if (message.includes('email')) {
                this.utilsService.sendConfirmation('error', 'O email fornecido já se encontra registado');
              } else if (message.includes('username')) {
                this.utilsService.sendConfirmation('error', 'O username introduzido já se encontra utilizado');
              }
            }
            this.loading = false;
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          console.log('User success');
          let aux;
          aux = res as any;
          if (aux.success) {
            this.publicService.putPublicData(this.userId, this.model_public, this.oldPublic)
              .pipe(
                catchError((err: HttpErrorResponse) => {
                  if (!err.error) {
                    this.utilsService.sendSystemErrorAlert();
                  } else if (err.error.message.code === 'ER_DUP_ENTRY') {
                    const message = err.error.message.sqlMessage;
                    if (message.includes('nif')) {
                      this.utilsService.sendConfirmation('error', 'O nif introduzido já se encontra utilizado');
                    }
                  }
                  return this.utilsService.sendSystemErrorAlert(err);
                }) as any)
              .subscribe(res => {
                const aux = res as any;
                if (aux.success) {
                  if (this.interests.some(item => item.edited === true)) {
                    this.editInterests();
                  } else {
                    this.loading = false;
                    this.getData();
                    this.utilsService.sendNotification('success', 'Dados Atualizados');
                  }
                } else {
                  this.utilsService.sendSystemErrorAlert();
                  this.loading = false;
                }
              });
          } else {
            this.utilsService.sendSystemErrorAlert();
            this.loading = false;
          }
        });
      this.edited = true;
    } else {
      this.utilsService.sendSystemErrorAlert();
      this.loading = false;
    }
  }

  editInterests() {
    const addedInterests: any[] = this.interests.filter(interest => interest.edited === true && interest.selected === true);
    if (addedInterests.length > 0) {
      this.publicService.addInterestsPublic(this.model_public.id, addedInterests)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.deleteInterests();
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
        });
    } else {
      this.deleteInterests();
    }
  }

  deleteInterests() {
    const deletedInterests: any[] = this.interests.filter(interest => interest.edited === true && interest.selected === false);
    if (deletedInterests.length > 0) {
      this.publicService.removeInterestsPublic(this.model_public.id, deletedInterests)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.resetEditedFlagInterests();
            this.loading = false;
            this.getData();
            this.utilsService.sendNotification('success', 'Dados Atualizados');
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
        });
    } else {
      this.resetEditedFlagInterests();
      this.loading = false;
      this.getData();
      this.utilsService.sendNotification('success', 'Dados Atualizados');
    }
  }

  resetEditedFlagInterests() {
    this.interests.forEach(interest => {
      interest.edited = false;
    });
  }

  isNifValid() {
    if (!this.model_public.nif || this.model_public.country !== globals.portugalCountryName) {
      return true;
    }
    return this.utilsService.validateNIF(this.model_public.nif);
  }

  initDropdowns() {
    this.utilsService.getCountries()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.countries = data as any;
        this.updatePhoneCode();
      });
    this.utilsService.getDistricts()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any).subscribe(data => {
          this.districts = data as any;
        });
    this.utilsService.getCities()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.cities = data as any;
      });
    this.utilsService.getMaritalStatus()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.maritalStatus = data as any;
      });
    this.utilsService.getLiteraryAbilities()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.literaryAbilities = data as any;
      });
    this.utilsService.getPublicPlansInfo()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.plans = data as any;
        console.log(this.plans);
      });
  }

  isPTSelected() {
    if (this.countries && this.model_public.country) {
      return this.model_public.country == globals.portugalCountryName;
    } else {
      return false;
    }
  }

  searchPostal(postal) {
    if (this.model_public.postal.length == 8 && this.isPTSelected()) {
      const splited_postal = this.model_public.postal.split('-', 2);
      if (splited_postal[0].length === 4 && splited_postal[1].length === 3) {
        console.log(splited_postal);
        this.utilsService.getPostalInfo(splited_postal[0], splited_postal[1])
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            this.model_public.district = this.districts.find(dist => dist.id === aux.district_id).name;
            this.model_public.city = this.cities.find(city => city.id === aux.city_id).name;
            this.model_public.locality = aux.locality;
          });
      }
    }
  }

  setEditedInterest(i) {
    this.interests[i].edited = !this.interests[i].edited;
  }

  changeNewsletter() {
    if (!this.model_user.newsletter && this.model_public.cartao_vantagens) {
      this.utilsService.sendConfirmationQuestion('Se não desejar receber newsletter não poderá usufruir do Clube Benefícios. ', 'Tem a certeza que deseja anular a newsletter?').then(confirm => {
        if (confirm) {
          this.model_public.cartao_vantagens = false;
        } else {
          this.model_user.newsletter = true;
        }
      });
    }
  }

  changeCard() {
    if (!this.model_user.newsletter && this.model_public.cartao_vantagens) {
      this.utilsService.sendConfirmationQuestion('Para usufruir do Clube Benefícios necessita de aderir à newsletter', 'Deseja aderir?').then(confirm => {
        if (confirm) {
          this.model_user.newsletter = true;
        } else {
          this.model_public.cartao_vantagens = false;
        }
      });
    }
  }

  deleteProfileImg() {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja eliminar a imagem de perfil?', 'Este processo é irreversível').then(confirm => {
      if (confirm) {
        this.usersService.deleteProfileImg(this.model_user.profile_img_url)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (aux.success) {
              this.model_user.profile_img_url = aux.photo_url;
              this.utilsService.sendNotification('success', 'Foto removida');
            }
          });
      }
    });
  }

  cancelInfoToggle() {
    this.getData();
  }

  updatePhoneCode() {
    this.phoneCode = this.countries.find(country => country.countryName === this.model_public.country).phoneCode;
  }

  deleteAccount() {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja eliminar a sua conta?', 'Este processo é irreversível').then(confirm => {
      if (confirm) {
        this.spinner.show();
        this.publicService.deleteAccount(this.userId)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            this.spinner.hide();
            this.utilsService.sendConfirmation('success', 'Conta eliminada');
          });
      }
    });
  }
}
