import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';

import { ActivitiesComponent } from './components/management/activities/activities.component';
import { ActivityTypesComponent } from './components/management/activity-types/activity-types.component';
import { AdminRolesComponent } from './components/admin/admin-roles/admin-roles.component';
import { AdminUserRolesService } from './services/admin-user-roles.service';
import { AdminUsersComponent } from './components/admin/admin-users/admin-users.component';
import { AdminUsersService } from './services/admin-users.service';
import { AnnouncementsComponent } from './components/announcements/announcements.component';
import { AnnouncementsService } from './services/announcements.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './guards/auth.guard';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AuthService } from './services/auth.service';
import { BannerDetailsComponent } from './components/banners/banner-details/banner-details.component';
import { BannersComponent } from './components/banners/banners.component';
import { BannersService } from './services/banners.service';
import { BlogCategoriesComponent } from './components/blog/blog-categories/blog-categories.component';
import { BlogService } from './services/blog.service';
import { BloggerDetailsComponent } from './components/blog/bloggers/blogger-details/blogger-details.component';
import { BloggersComponent } from './components/blog/bloggers/bloggers.component';
import { BrandInfoComponent } from './components/brands/brand-info/brand-info.component';
import { BrandPlansComponent } from './components/management/brand-plans/brand-plans.component';
import { BrandProductsComponent } from './components/brands/brand-products/brand-products.component';
import { BrandVideosComponent } from './components/brands/brand-videos/brand-videos.component';
import { BrandsComponent } from './components/brands/brands.component';
import { BrandsService } from './services/brands.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CategoriesComponent } from './components/management/categories/categories.component';
import { CountriesWDComponent } from './components/DestinationWeddings/countries-wd/countries-wd.component';
import { CountryDetailsComponent } from './components/DestinationWeddings/countries-wd/country-details/country-details.component';
import { CourseDetailsComponent } from './components/courses/course-details/course-details.component';
import { CourseImagesComponent } from './components/courses/course-images/course-images.component';
import { CourseParticipantsComponent } from './components/courses/course-participants/course-participants.component';
import { CourseVideosComponent } from './components/courses/course-videos/course-videos.component';
import { CoursesComponent } from './components/courses/courses.component';
import { CoursesService } from './services/courses.service';
import { CreateBrandComponent } from './components/brands/create-brand/create-brand.component';
import { CreateDirectoryStoreComponent } from './components/stores/create-directory-store/create-directory-store.component';
import { CreatePageComponent } from './components/static-pages/create-page/create-page.component';
import { CreatePostComponent } from './components/blog/create-post/create-post.component';
import { CreateProfessionalComponent } from './components/users/create-professional/create-professional.component';
import { CreatePublicComponent } from './components/users/create-public/create-public.component';
import { CreateStoreComponent } from './components/stores/create-store/create-store.component';
import { CreateSweepstakeComponent } from './components/sweepstakes/create-sweepstake/create-sweepstake.component';
import { CreateWeddingProfessionalComponent } from './components/weddings/create-wedding-professional/create-wedding-professional.component';
import { CreateWeddingPublicComponent } from './components/weddings/create-wedding-public/create-wedding-public.component';
import { DataTablesModule } from 'angular-datatables';
import { DestinationWeddingsService } from './services/destination-weddings.service';
import { FileUploadModule } from 'ng2-file-upload';
import { FilesService } from './services/files.service';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HomeComponent } from './components/home/home.component';
import { HomepageSiteComponent } from './components/homepage-site/homepage-site.component';
import { ILoveTVComponent } from './components/ilove-tv/ilove-tv.component';
import { IloveTVService } from './services/ilove-tv.service';
import { LoginComponent } from './components/login/login.component';
import { MagazinesService } from './services/magazines.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NavheaderComponent } from './components/shared/navheader/navheader.component';
import { NavleftComponent } from './components/shared/navleft/navleft.component';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgtUniversalModule } from '@ng-toolkit/universal';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from "ngx-spinner";
import { PageDetailsComponent } from './components/static-pages/page-details/page-details.component';
import { PlacesTypesComponent } from './components/DestinationWeddings/places-types/places-types.component';
import { PostDetailsComponent } from './components/blog/posts/post-details/post-details.component';
import { PostsComponent } from './components/blog/posts/posts.component';
import { ProductsService } from './services/products.service';
import { ProductsTestimonialsComponent } from './components/brands/products-testimonials/products-testimonials.component';
import { ProfessionalBrandsComponent } from './components/users/users-professional/professional-brands/professional-brands.component';
import { ProfessionalStoresComponent } from './components/users/users-professional/professional-stores/professional-stores.component';
import { ProfessionalUserComponent } from './components/users/users-professional/professional-user/professional-user.component';
import { ProfessionalsService } from './services/professionals.service';
import { PromotionDetailsComponent } from './components/stores/store-promotions/promotion-details/promotion-details.component';
import { PublicService } from './services/public.service';
import { PublicUserComponent } from './components/users/users-public/public-user/public-user.component';
import { RegionDetailsComponent } from './components/DestinationWeddings/regions-wd/region-details/region-details.component';
import { RegionsWDComponent } from './components/DestinationWeddings/regions-wd/regions-wd.component';
import { SpeakerDetailsComponent } from './components/courses/speakers/speaker-details/speaker-details.component';
import { SpeakersComponent } from './components/courses/speakers/speakers.component';
import { SponsorDetailsComponent } from './components/courses/sponsors/sponsor-details/sponsor-details.component';
import { SponsorsComponent } from './components/courses/sponsors/sponsors.component';
import { StaticPagesComponent } from './components/static-pages/static-pages.component';
import { StaticPagesService } from './services/static-pages.service';
import { StoreFaqsComponent } from './components/stores/store-faqs/store-faqs.component';
import { StoreGalleryComponent } from './components/stores/store-gallery/store-gallery.component';
import { StoreInfoComponent } from './components/stores/store-info/store-info.component';
import { StorePlansComponent } from './components/management/store-plans/store-plans.component';
import { StorePromotionsComponent } from './components/stores/store-promotions/store-promotions.component';
import { StoreTestimonialsComponent } from './components/stores/store-testimonials/store-testimonials.component';
import { StoreWeddingDetailsComponent } from './components/stores/store-weddings/store-wedding-details/store-wedding-details.component';
import { StoreWeddingsComponent } from './components/stores/store-weddings/store-weddings.component';
import { StoresComponent } from './components/stores/stores.component';
import { StoresService } from './services/stores.service';
import { SubRegionsWDComponent } from './components/DestinationWeddings/sub-regions-wd/sub-regions-wd.component';
import { SubregionDetailsComponent } from './components/DestinationWeddings/sub-regions-wd/subregion-details/subregion-details.component';
import { SubregionImagesComponent } from './components/DestinationWeddings/sub-regions-wd/subregion-images/subregion-images.component';
import { SubregionPlacesComponent } from './components/DestinationWeddings/sub-regions-wd/subregion-places/subregion-places.component';
import { SweepstakeParticipantsComponent } from './components/sweepstakes/sweepstake-participants/sweepstake-participants.component';
import { SweepstakesComponent } from './components/sweepstakes/sweepstakes.component';
import { SweepstakesDetailsComponent } from './components/sweepstakes/sweepstakes-details/sweepstakes-details.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UsersComponent } from './components/users/users.component';
import { UsersProfessionalComponent } from './components/users/users-professional/users-professional.component';
import { UsersPublicComponent } from './components/users/users-public/users-public.component';
import { UsersService } from './services/users.service';
import { UtilsService } from './services/utils.service';
import { VideoDetailsComponent } from './components/ilove-tv/video-details/video-details.component';
import { WeddingProfessionalDetailsComponent } from './components/weddings/wedding-professional-details/wedding-professional-details.component';
import { WeddingPublicDetailsComponent } from './components/weddings/wedding-public-details/wedding-public-details.component';
import { WeddingsComponent } from './components/weddings/weddings.component';
import { WeddingsService } from './services/weddings.service';
import localePt from '@angular/common/locales/pt';
import { CompanyInfoComponent } from './components/management/company-info/company-info.component';
import { CategoriesTypesComponent } from './components/management/categories-types/categories-types.component';
import { PagesComponent } from './components/pages/pages.component';
import { SettingsComponent } from './components/management/settings/settings.component';
import { DigitalMagazinesComponent } from './components/magazines/digital-magazines/digital-magazines.component';
import { PaperMagazinesComponent } from './components/magazines/paper-magazines/paper-magazines.component';
import { MagazinesCommentsComponent } from './components/magazines/digital-magazines/magazines-comments/magazines-comments.component';
import { MagazinesImagesComponent } from './components/magazines/paper-magazines/magazines-images/magazines-images.component';

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UsersComponent,
    HomeComponent,
    NavheaderComponent,
    NavleftComponent,
    StoresComponent,
    BrandsComponent,
    ILoveTVComponent,
    WeddingsComponent,
    UsersProfessionalComponent,
    UsersPublicComponent,
    ProfessionalUserComponent,
    PublicUserComponent,
    CreatePublicComponent,
    CreateProfessionalComponent,
    CreateStoreComponent,
    StoreInfoComponent,
    StoreFaqsComponent,
    StoreGalleryComponent,
    StoreWeddingsComponent,
    StorePromotionsComponent,
    StoreTestimonialsComponent,
    CreateBrandComponent,
    BrandInfoComponent,
    BrandVideosComponent,
    BrandProductsComponent,
    StoreWeddingDetailsComponent,
    BloggersComponent,
    PostsComponent,
    BlogCategoriesComponent,
    CreatePostComponent,
    PostDetailsComponent,
    CreateWeddingPublicComponent,
    CreateWeddingProfessionalComponent,
    WeddingPublicDetailsComponent,
    WeddingProfessionalDetailsComponent,
    ProductsTestimonialsComponent,
    PromotionDetailsComponent,
    ProfessionalStoresComponent,
    ProfessionalBrandsComponent,
    VideoDetailsComponent,
    BloggerDetailsComponent,
    CountriesWDComponent,
    RegionsWDComponent,
    SubRegionsWDComponent,
    CountryDetailsComponent,
    RegionDetailsComponent,
    SubregionDetailsComponent,
    CategoriesComponent,
    ActivitiesComponent,
    SubregionPlacesComponent,
    PlacesTypesComponent,
    ActivityTypesComponent,
    StorePlansComponent,
    StaticPagesComponent,
    PageDetailsComponent,
    BrandPlansComponent,
    CreatePageComponent,
    CoursesComponent,
    CourseDetailsComponent,
    CourseImagesComponent,
    CourseVideosComponent,
    SpeakersComponent,
    SpeakerDetailsComponent,
    SponsorsComponent,
    SponsorDetailsComponent,
    CreateDirectoryStoreComponent,
    AdminUsersComponent,
    AdminRolesComponent,
    CourseParticipantsComponent,
    BannerDetailsComponent,
    BannersComponent,
    SweepstakesComponent,
    SweepstakesDetailsComponent,
    CreateSweepstakeComponent,
    SweepstakeParticipantsComponent,
    SubregionImagesComponent,
    HomepageSiteComponent,
    AnnouncementsComponent,
    CompanyInfoComponent,
    CategoriesTypesComponent,
    PagesComponent,
    SettingsComponent,
    DigitalMagazinesComponent,
    PaperMagazinesComponent,
    MagazinesCommentsComponent,
    MagazinesImagesComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    NgSelectModule,
    NgOptionHighlightModule,
    FlexLayoutModule,
    NgxPaginationModule,
    FileUploadModule,
    ReactiveFormsModule,
    FormsModule,
    TooltipModule.forRoot(),
    NgxMaskModule.forRoot(),
    FilterPipeModule,
    NgxSpinnerModule,
    NgtUniversalModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    DataTablesModule,
    TabsModule.forRoot()
  ],
  providers: [
    AdminUsersService,
    AdminUserRolesService,
    AnnouncementsService,
    AuthService,
    AuthGuard,
    BannersService,
    BlogService,
    BrandsService,
    CoursesService,
    DestinationWeddingsService,
    FilesService,
    IloveTVService,
    MagazinesService,
    ProductsService,
    ProfessionalsService,
    PublicService,
    StaticPagesService,
    StoresService,
    UsersService,
    UtilsService,
    WeddingsService,
    {
      provide: LOCALE_ID,
      useValue: 'pt-PT'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
  ]
})
export class AppModule { }
