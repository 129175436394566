import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { BlogService } from '../../../services/blog.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss']
})
export class PostsComponent implements OnInit {

  search: any = {};
  nr_items_page = globals.nr_items_page;
  p; // paginação;
  posts = [] as any;
  permissions: any = {};
  bloggerId;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private blogService: BlogService,
    private utilsService: UtilsService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('BlogCreate');
    this.permissions.edit = this.auth.hasPermissions('BlogEdit');
    this.permissions.delete = this.auth.hasPermissions('BlogDelete');
    this.permissions.read = this.auth.hasPermissions('BlogRead');
    if (this.permissions.read) {
      this.getUrlParams();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.bloggerId = params.bloggerId;
      if (this.bloggerId) {
        this.getPostsByBloggerId();
      } else {
        this.getAllPosts();
      }
    });
  }

  getAllPosts() {
    this.blogService.getAllPosts()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.posts = res as any;
        this.posts.forEach(post => {
          if (post.posted) {
            post.posted = this.utilsService.convertDateStringToDate(post.posted);
          }
        })
      });
  }

  getPostsByBloggerId() {
    this.blogService.getPostsByBloggerId(this.bloggerId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.posts = res as any;
        this.posts.forEach(post => {
          if (post.posted) {
            post.posted = this.utilsService.convertDateStringToDate(post.posted);
          }
        })
        //console.log(this.posts);
      });
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }

  createPost() {
    this.router.navigate([globals.baseUrls.blog + '/create']);
  }

  goToPost(postId) {
    this.router.navigate([globals.baseUrls.blog + '/post/' + postId]);
  }

  changeFeatured(postId, post) {
    this.blogService.changeFeatured(postId, post)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          if (post.is_featured) {
            this.utilsService.sendNotification('success', 'Post destacado');
          } else {
            this.utilsService.sendNotification('success', 'Blogger Não destacado');
          }
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  changeBlogHome(postId, post) {
    this.blogService.changeBlogHome(postId, post)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          if (post.is_bloghome) {
            this.utilsService.sendNotification('success', 'Post na Home do Blog');
          } else {
            this.utilsService.sendNotification('success', 'Blogger fora da Home do Blog');
          }
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  changeOnline(postId, post) {
    this.blogService.changeOnline(postId, post)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          if (post.is_online) {
            this.utilsService.sendNotification('success', 'Post visivel');
          } else {
            this.utilsService.sendNotification('success', 'Post não visivel');
          }
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }
}
