import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';

import { AuthService } from 'src/app/services/auth.service';
import { CoursesService } from '../../../services/courses.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-course-participants',
  templateUrl: './course-participants.component.html',
  styleUrls: ['./course-participants.component.scss']
})
export class CourseParticipantsComponent implements OnInit {

  dtOptions: any = {};
  participants: any[] = [];
  courseId;

  permissions: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private coursesService: CoursesService,
    private utilsService: UtilsService,
    private spinner: NgxSpinnerService,
    public sanitizer: DomSanitizer,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.edit = this.auth.hasPermissions('CoursesEdit');
    this.permissions.read = this.auth.hasPermissions('CoursesRead');
    this.dtOptions = {
      paging: true,
      info: false
    };
    if (this.permissions.read) {
      this.getUrlParams();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.courseId = params.courseId;
      this.getParticipantsByCourseId();
    });
  }

  getParticipantsByCourseId() {
    this.coursesService.getParticipantsByCourseId(this.courseId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        if (res) {
          this.participants = res as any;
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  changeAccepted(participantId, participant) {
    this.coursesService.changeAccepted(participantId, participant)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          if (participant.accepted) {
            this.utilsService.sendNotification('success', 'Participante validado');
          } else {
            this.utilsService.sendNotification('success', 'Participante invalidado');
          }
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }
}
