import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';
import { FormControl, FormGroup } from '@angular/forms';

import { AuthService } from 'src/app/services/auth.service';
import { CoursesService } from '../../../services/courses.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-course-videos',
  templateUrl: './course-videos.component.html',
  styleUrls: ['./course-videos.component.scss']
})
export class CourseVideosComponent implements OnInit {

  addVid: Boolean;
  videos = [];

  courseId;
  addUrl;
  p;
  delVideos: Boolean;

  permissions: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private coursesService: CoursesService,
    private utilsService: UtilsService,
    private spinner: NgxSpinnerService,
    public sanitizer: DomSanitizer,
    private auth: AuthService,
    ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('CoursesCreate');
    this.permissions.edit = this.auth.hasPermissions('CoursesEdit');
    this.permissions.delete = this.auth.hasPermissions('CoursesDelete');
    this.permissions.read = this.auth.hasPermissions('CoursesRead');
    this.addVid = false;
    this.delVideos = false;
    if (this.permissions.read) {
      this.getUrlParams();
    }
  }


  getUrlParams() {
    this.route.params.subscribe(params => {
      this.courseId = params.courseId;
      this.getVideos();
    });
  }

  getVideos() {
    this.coursesService.getCourseVideosByCourseId(this.courseId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.videos = res as any;
        this.videos.forEach(video => {
          video.url = this.getEmbedVideos(this.utilsService.getEmbedUrlFromVideoUrl(video.url));
        });
        this.delVideos = false;
      });
  }

  getEmbedVideos(video) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(video);
  }

  addVideo() {
    this.addVid = true;
  }

  addNewVideo() {
    if (this.addUrl.trim().length > 0) {
      this.coursesService.addVideo(this.courseId, this.addUrl)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Video adicionado');
            this.videos.push({
              id: aux.id,
              course_id: this.courseId,
              url: this.addUrl
            });
            this.addUrl = '';
            this.getVideos();
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
        });
    } else {
      this.utilsService.sendConfirmation('info', 'Insira um url');
    }
  }

  removeVideo(index) {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja eliminar o video?', 'Este processo é irreversível').then(confirm => {
      if (confirm) {
        this.coursesService.deleteVideo(this.videos[index].id)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (aux.success) {
              this.utilsService.sendNotification('success', 'Video removido');
              this.videos.splice(index, 1);
              this.videos.push({ store_id: this.courseId, url: this.addUrl });
              this.addUrl = '';
              this.addVid = false;
              this.delVideos = true;
              this.getVideos();
            } else {
              this.utilsService.sendSystemErrorAlert();
            }
          });
      }
    });
  }
}
