import * as globals from '../../assets/globals';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ObservableLike } from 'rxjs';

import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class DestinationWeddingsService {

  constructor(private http: HttpClient) { }

  apiRoute = globals.base_api_path + 'destination-weddings/';

  getAllWDCountries(): Observable<any> {
    return this.http.get(this.apiRoute + 'countries');
  }

  getAllWDRegions(): Observable<any> {
    return this.http.get(this.apiRoute + 'regions');
  }

  getAllWDSubRegions(): Observable<any> {
    return this.http.get(this.apiRoute + 'subregions');
  }

  getPlacesTypes(): Observable<any> {
    return this.http.get(this.apiRoute + 'types');
  }

  getPlacesBySubRegionId(subRegionId): Observable<any> {
    return this.http.get(this.apiRoute + 'places/subregion/' + subRegionId);
  }

  getPlacesByTypeId(typeId): Observable<any> {
    return this.http.get(this.apiRoute + 'places/type/' + typeId);
  }

  getRegionsByCountryId(countryId): Observable<any> {
    return this.http.get(this.apiRoute + 'regions/country/' + countryId);
  }

  getSubRegionsByRegionId(regionId): Observable<any> {
    return this.http.get(this.apiRoute + 'subregions/region/' + regionId);
  }

  changeActiveCountry(countryId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'country/' + countryId + '/active', model);
  }

  changeActiveRegion(regionId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'region/' + regionId + '/active', model);
  }

  changeActiveSubRegion(subRegionId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'subregion/' + subRegionId + '/active', model);
  }

  changeIsVisibleType(typeId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'type/' + typeId + '/active', model);
  }

  changeIsVisiblePlace(placeId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'place/' + placeId + '/active', model);
  }

  getWDCountryInfoByWDCountryID(countryWDId): Observable<any> {
    return this.http.get(this.apiRoute + 'country/' + countryWDId);
  }

  getWDRegionInfoByWDRegionID(regionId): Observable<any> {
    return this.http.get(this.apiRoute + 'region/' + regionId);
  }

  getWDSubRegionInfoByWDSubRegionID(subRegionId): Observable<any> {
    return this.http.get(this.apiRoute + 'subregion/' + subRegionId);
  }

  updateWDCountry(countryWDId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'update/country/' + countryWDId, model);
  }

  updateWDRegion(regionId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'update/region/' + regionId, model);
  }

  updateWDSubRegion(subRegionId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'update/subregion/' + subRegionId, model);
  }

  updateWDPlace(placeId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'update/place/' + placeId, model);
  }

  updateWDType(typeId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'update/type/' + typeId, model);
  }

  deleteWDCountry(countryWDId): Observable<any> {
    return this.http.put(this.apiRoute + 'delete/country/' + countryWDId, null);
  }

  deleteWDRegion(regionId): Observable<any> {
    return this.http.put(this.apiRoute + 'delete/region/' + regionId, null);
  }

  deleteWDSubRegion(subRegionId): Observable<any> {
    return this.http.put(this.apiRoute + 'delete/subregion/' + subRegionId, null);
  }

  addWDCountryVideo(countryWDId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'add/country/video/' + countryWDId, model);
  }

  addWDRegionVideo(regionId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'add/region/video/' + regionId, model);
  }

  addWDSubRegionVideo(subRegionId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'add/subregion/video/' + subRegionId, model);
  }

  getRegionDistricts(id): Observable<any> {
    return this.http.get(this.apiRoute + 'districts/region/' + id);
  }

  createType(model): Observable<any> {
    return this.http.post(this.apiRoute + 'new-type', model);
  }

  deleteType(id): Observable<any> {
    return this.http.delete(this.apiRoute + 'type/' + id);
  }

  createPlace(model): Observable<any> {
    return this.http.post(this.apiRoute + 'new-place', model);
  }

  deletePlace(id): Observable<any> {
    return this.http.delete(this.apiRoute + 'place/' + id);
  }

  deleteSubRegion(subRegionId: Observable<any>) {
    return this.http.put(this.apiRoute + 'delete/subregion/' + subRegionId, null);
  }

  createCountryWD(model): Observable<any> {
    return this.http.post(this.apiRoute + 'new-country', model);
  }

  createRegionWD(model): Observable<any> {
    return this.http.post(this.apiRoute + 'new-region', model);
  }

  createSubRegionWD(model): Observable<any> {
    return this.http.post(this.apiRoute + 'new-subregion', model);
  }

  uploadPictureSubRegionById(subregionId, imgs): Observable<any> {
    return this.http.post(this.apiRoute + 'subregion/' + subregionId + '/images', { images: imgs });
  }

  deleteSubRegionPictureById(subregionId, id): Observable<any> {
    return this.http.delete(this.apiRoute + 'subregion/' + subregionId + '/images/delete/' + id);
  }

  getSubRegionImagesBySubRegionId(subregionId): Observable<any> {
    return this.http.get(this.apiRoute + 'imgs/subregion/' + subregionId);
  }
}
