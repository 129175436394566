import { ActivatedRoute, Router } from '@angular/router';
import { Component, Host, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgIf } from '@angular/common';
import { StoresService } from '../../../services/stores.service';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-store-faqs',
  templateUrl: './store-faqs.component.html',
  styleUrls: ['./store-faqs.component.scss']
})

export class StoreFaqsComponent implements OnInit {
  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private storesService: StoresService,
    private route: ActivatedRoute,
    private auth: AuthService,
  ) { }

  faqs: any[] = [];
  addedFaqs = [{ question: '', answer: '' }];
  addFaqsBoolean;
  storeId;
  addLoading;

  permissions: any = {};

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('StoresCreate');
    this.permissions.edit = this.auth.hasPermissions('StoresEdit');
    this.permissions.delete = this.auth.hasPermissions('StoresDelete');
    this.permissions.read = this.auth.hasPermissions('StoresRead');
    this.addFaqsBoolean = false;
    this.addLoading = false;
    if (this.permissions.read) {
      this.getUrlParams();
      this.getFaqs();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.storeId = params['storeId'];
      this.getFaqs();
    });
  }

  plusFaq() {
    this.addedFaqs.push({ 'question': '', 'answer': '' });
  }

  minusFaq(index) {
    this.faqs.splice(index, 1);
  }

  addFaqs() {
    this.addLoading = true;
    for (let i = 0; i < this.addedFaqs.length; i++) {
      if (this.addedFaqs[i].question.trim().length === 0 || this.addedFaqs[i].answer.trim().length === 0) {
        this.addedFaqs.splice(i, 1);
      }
    }
    if (this.addedFaqs.length > 0) {
      this.storesService.addFaqs(this.storeId, this.addedFaqs)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.addFaqsBoolean = false;
            this.addLoading = false;
            this.getFaqs();
            this.utilsService.sendNotification('success', 'FAQ(s) adicionadas');
          }
        });
    } else {
      this.utilsService.sendNotification('info', 'Nenhuma FAQ foi adicionada');
      this.addFaqsBoolean = false;
      this.addLoading = false;
      this.getFaqs();
    }
  }

  addFaqsToglle() {
    this.addFaqsBoolean = !this.addFaqsBoolean;
  }

  updateFaq(index) {
    this.storesService.updateFaq(this.faqs[index])
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          this.utilsService.sendNotification('success', 'FAQ Atualizada', '');
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  deleteFaq(index) {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja elimiar a FAQ?', 'Este processo é irreversível').then(confirm => {
      if (confirm) {
        this.storesService.deleteFaq(this.faqs[index].id)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (aux.success) {
              this.faqs.splice(index, 1);
              this.utilsService.sendNotification('success', 'FAQ Apagada', '');
            } else {
              this.utilsService.sendSystemErrorAlert();
            }
          });
      }
    });
  }

  getFaqs() {
    this.storesService.getStoreFaqsByStoreId(this.storeId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.faqs = res as any;
        console.log(this.faqs);
      });
  }

}
