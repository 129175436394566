import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, Host, Inject, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { BrandsService } from '../../../services/brands.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploader } from 'ng2-file-upload';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { ProductsService } from '../../../services/products.service';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-brand-products',
  templateUrl: './brand-products.component.html',
  styleUrls: ['./brand-products.component.scss']
})
export class BrandProductsComponent implements OnInit {

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private brandsService: BrandsService,
    private productsService: ProductsService,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private auth: AuthService,
  ) { }

  nr_items_page = globals.nr_items_page;
  p; // paginação;

  model: any = [{}];
  imgs: any[];
  error = '';
  userId;
  addProdutos: boolean;
  addedProdutos: number;
  productsAdd: any = [];
  currentYear = new Date().getFullYear();
  brandId;
  brand;
  uploader: FileUploader;

  produtos: [
    {
      id: Number;
      name: string;
      description: string;
      // montante: Number,
      // quantidade: Number,
      category_id: string;
      brand_id: string;
      webmarket: boolean;
      img_url: string;
    }
  ];
  marcas: [
    {
      _id: string;
      nome: string;
    }
  ];

  fotos: any = [];
  categories: any[];
  permissions: any = {};

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('BrandsCreate');
    this.permissions.edit = this.auth.hasPermissions('BrandsEdit');
    this.permissions.delete = this.auth.hasPermissions('BrandsDelete');
    this.permissions.read = this.auth.hasPermissions('BrandsRead');
    this.addProdutos = false;
    this.addedProdutos = 0;
    if (this.permissions.read) {
      this.defineUploader();
      this.getUrlParams();
      this.getCategories();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.brandId = params.brandId;
      this.getBrandProducts();
      this.getBrandInfo();
    });
  }

  getBrandInfo() {
    this.brandsService.getBrandInfoByBrandId(this.brandId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.brand = res as any;
      });
  }

  defineUploader() {
    this.uploader = new FileUploader({
      url: globals.base_api_path + 'files/upload/product',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.imgs = [];
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.onImgUpload(JSON.parse(response));
    };
  }

  getBrandProducts() {
    this.brandsService.getBrandProductsByBrandId(this.brandId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.produtos = res as any;
      });
  }

  categoryDefault(index) {
    if (index === 0) {
      this.model.forEach(item => {
        item.category_id = this.model[0].category_id;
      });
    }
  }

  adicionarProdutos() {
    if (this.uploader.queue.length > 0) {
      this.spinner.show();
    };
    this.uploader.queue.forEach((img, index) => {
      img._onBuildForm = (form) => {
        form.append('model', this.utilsService.getUrlFromName(this.model[index].name));
        form.append('brand', this.utilsService.getUrlFromName(this.brand.name));
        form.append('brandId', this.brand.id);
        form.append('year', this.model[index].ano);
        form.append('category', this.utilsService.getUrlFromName(this.getCategoryName(this.model[index].category_id)));
      };
      img.upload();
    });
  }

  onImgUpload(res) {
    let product = this.model.find(item => this.utilsService.getUrlFromName(item.name) == res.modelName);
    this.productsAdd.push({
      brand_id: this.brandId,
      category_id: product.category_id,
      name: product.name,
      description: product.description,
      year: product.ano,
      webmarket: product.webmarket,
      img_url: res.url,
    })
    product.img_url = res.url;
    this.uploadProducts();
  }

  uploadProducts() {
    if (this.productsAdd.length === this.addedProdutos) {
      this.brandsService.createProduct(this.productsAdd)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            this.spinner.hide();
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          //console.log('uplaod');
          const aux = res as any;
          this.spinner.hide();
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Produtos adicionados');
            this.getBrandProducts();
            this.defineUploader();
            this.model = [{}];
            this.addedProdutos = 0;
            this.productsAdd = [];
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
        });
    }
  }

  getCategoryName(categoryId: Number) {
    return this.categories.find(category => category.id == categoryId).name;
  }

  updateProduct(index) {
    this.productsService
      .updateProduct(this.produtos[index].id, this.produtos[index])
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        let aux: { success: Boolean };
        aux = res as any;
        if (aux.success) {
          this.utilsService.sendNotification('success', 'Produto Atualizado');
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  deleteProduct(index) {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja apagar o produto?').then(response => {
      this.productsService.deleteProduct(this.produtos[index].id)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          let aux: { success: Boolean };
          aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Produto Apagado');
            this.produtos.splice(index, 1);
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
        });
    });
  }

  showPictures() {
    this.uploader.queue.forEach(foto => {
      if (this.addedProdutos !== 0) {
        this.model.push({});
      }
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      this.model[this.addedProdutos].url = url;
      this.model[this.addedProdutos].foto = foto;
      this.model[this.addedProdutos].ano = this.currentYear;
      this.model[this.addedProdutos].webmarket = false;
      this.addedProdutos++;
    });
  }

  createProduct() {
    if (!this.model.webmarket) {
      this.model.webmarket = false;
    }
    if (!this.model.description) {
      this.model.description = '';
    }
    this.model.userId = this.userId;
    this.brandsService.createProduct(this.model)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as {
          nome: string;
          titulo: string;
          descrição: string;
          montante: Number;
          quantidade: Number;
          categoria: string;
          marca: string;
          webmarket: boolean;
        };
        this.adicionaProduto(aux);
      });
  }

  getNomeMarca(id: string) {
    for (const aux of this.marcas) {
      if ((aux._id = id)) {
        return aux.nome;
      }
    }
  }

  sortProdutos() {
    this.produtos.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  adicionaProduto(produto) {
    if (!this.produtos) {
      this.produtos = [
        {
          id: produto.id,
          name: produto.nome,
          description: produto.descrição,
          category_id: produto.categoria,
          brand_id: this.getNomeMarca(produto.marcaId),
          webmarket: produto.webmarket,
          img_url: produto.img_url
        }
      ];
    } else {
      this.produtos.push({
        id: produto.id,
        name: produto.nome,
        description: produto.descrição,
        category_id: produto.categoria,
        brand_id: this.getNomeMarca(produto.marcaId),
        webmarket: produto.webmarket,
        img_url: produto.img_url
      });
    }
    this.sortProdutos();
  }

  getCategories() {
    this.utilsService.getCategories()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        this.categories = aux;
      });
  }

  cancel() {
    this.defineUploader();
    this.model = [{}];
    this.addedProdutos = 0;
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }
}
