import { NavigationEnd, Router } from '@angular/router';

import { AuthService } from './services/auth.service';
import { Component } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  title = 'app';
  userLoggedIn: boolean = false;

  constructor(
    public router: Router,
    private config: NgSelectConfig,
    public auth: AuthService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.userLoggedIn = this.auth.isLoggedIn();
      }
    });
    this.config.clearAllText = 'Limpar seleção';
    this.config.openOnEnter = false;
  }
}
