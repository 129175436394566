import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from 'src/app/services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})


export class SettingsComponent implements OnInit {

  permissions: any = {};
  settings: any = [
    {
      name: 'Geral', settings: [
        { name: 'Iva', selector: 'iva', type: 'number' }
      ]
    },
    {
      name: 'Anúncios', settings: [
        { name: 'Preço por periodo de tempo', selector: 'announcementsPublicPricePerPeriod', type: 'number' },
        { name: 'Periodo de tempo', selector: 'announcementsPublicPeriod', type: 'number' },
      ]
    },
  ]

  constructor(
    private auth: AuthService,
    private utils: UtilsService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('ManagementCreate');
    this.permissions.edit = this.auth.hasPermissions('ManagementEdit');
    this.permissions.delete = this.auth.hasPermissions('ManagementDelete');
    this.permissions.read = this.auth.hasPermissions('ManagementRead');
    this.getSettings()
  }

  getSettings() {
    this.utils.getSiteSettings()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utils.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.settings.forEach(block => {
          block.settings.forEach(setting => {
            setting.value = res[setting.selector]
          })
        });
        console.log(this.settings);
      });
  }

  updateSetting(selector, value) {
    if(this.permissions.edit) {
      this.utils.updateSiteSettings(selector, value)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utils.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.utils.sendNotification('success', 'Configuração atualizada')
      });
    }

  }

}
