import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as globals from '../../assets/globals';


@Injectable({
  providedIn: 'root'
})
export class MagazinesService {

  apiRoute = globals.base_api_path + 'magazines/';

  constructor(private http: HttpClient) { }


  getDigitalMagazines(): Observable<any> {
    return this.http.get(this.apiRoute + 'digitalmagazines');
  }

  getPaperMagazines(): Observable<any> {
    return this.http.get(this.apiRoute + 'papermagazines');
  }

  changeIsCurrent(magazineId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'papermagazine/' + magazineId + '/current', model);
  }

  changeIsPreview(magazineId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'papermagazine/' + magazineId + '/preview', model);
  }

  changeIsVisible(magazineId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'papermagazine/' + magazineId + '/visible', model);
  }

  addPaperMagazine(magazine) {
    return this.http.post(this.apiRoute + 'add/papermagazine', magazine);
  }

  editPaperMagazine(magazineId, magazine) {
    return this.http.put(this.apiRoute + 'edit/papermagazine/' + magazineId, magazine);
  }

  deletePaperMagazine(magazineId): Observable<any> {
    return this.http.put(this.apiRoute + 'delete/papermagazine/' + magazineId, null);
  }

  addDigitalMagazine(magazine) {
    return this.http.post(this.apiRoute + 'add/digitalmagazine', magazine);
  }

  editDigitalMagazine(magazineId, magazine) {
    return this.http.put(this.apiRoute + 'edit/digitalmagazine/' + magazineId, magazine);
  }

  deleteDigitalMagazine(magazineId): Observable<any> {
    return this.http.put(this.apiRoute + 'delete/digitalmagazine/' + magazineId, null);
  }

  getDigitalMagazinesComments(): Observable<any> {
    return this.http.get(this.apiRoute + 'digitalmagazines/comments');
  }

  deleteDigitalMagazineComment(commentId): Observable<any> {
    return this.http.delete(this.apiRoute + 'delete/digitalmagazine/comment/' + commentId);
  }

  changeIsVisibleComment(commentId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'digitalmagazine/comment/' + commentId + '/visible', model);
  }

  deleteDigitalMagazineCommentResponse(responseId): Observable<any> {
    return this.http.delete(this.apiRoute + 'delete/digitalmagazine/comment-response/' + responseId);
  }

  changeIsVisibleCommentResponse(responseId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'digitalmagazine/comment-response/' + responseId + '/visible', model);
  }

  getPaperMagazineImages(magazineYear): Observable<any> {
    return this.http.get(this.apiRoute + 'papermagazine/images/' + magazineYear);
  }

  uploadPaperMagazineImages(id, imgs): Observable<any> {
    return this.http.post(this.apiRoute + 'papermagazine/new-images/' + id, { images: imgs });
  }

  deletePaperMagazineImage(magazineId, id): Observable<any> {
    return this.http.delete(this.apiRoute + 'papermagazine/' + magazineId + '/delete-image/' + id);
  }
}
