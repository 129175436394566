import { ActivatedRoute, Router } from '@angular/router';
import { Component, Host, Inject, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { StoresService } from '../../../services/stores.service';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-store-testimonials',
  templateUrl: './store-testimonials.component.html',
  styleUrls: ['./store-testimonials.component.scss']
})


export class StoreTestimonialsComponent implements OnInit {

  testimonials;
  modelTestimony;
  storeId;

  permissions: any = {};

  constructor(
    private utilsService: UtilsService,
    private storesService: StoresService,
    private route: ActivatedRoute,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    //this.store = this.parent.getStore();
    this.permissions.create = this.auth.hasPermissions('StoresCreate');
    this.permissions.edit = this.auth.hasPermissions('StoresEdit');
    this.permissions.delete = this.auth.hasPermissions('StoresDelete');
    this.permissions.read = this.auth.hasPermissions('StoresRead');
    this.testimonials = [];
    if (this.permissions.read) {
      this.initTestimonyModel();
      this.getUrlParams();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.storeId = params['storeId'];
      this.getTestimonials();
    });
  }

  initTestimonyModel() {
    this.modelTestimony = {};
  }

  getTestimonials() {
    this.storesService.getStoreTestimonialsByStoreId(this.storeId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.testimonials = res;
        console.log(this.testimonials);
      });
  }

  approveStoreTestimony(testimony) {
    this.storesService.approveTestimony(testimony.id)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success === true) {
          this.utilsService.sendNotification('success', 'Testemunho aprovado');
          this.getTestimonials();
        } else {
          this.utilsService.sendSystemErrorAlert()
        }
      });
  }

  removeStoreTestimony(testimony) {
    this.storesService.rejectTestimony(testimony.id)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success === true) {
          this.utilsService.sendNotification('success', 'Testemunho rejeitado');
          this.getTestimonials();
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  showhiddenTestimony(testimony, type) {
    if (type == 'hidden') {
      this.storesService.hiddenTestimony(testimony.id)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success === true) {
            this.utilsService.sendNotification('success', 'Testemunho escondido');
            this.getTestimonials();
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
        });
    } else if (type == 'show') {
      this.storesService.showTestimony(testimony.id)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success === true) {
            this.utilsService.sendNotification('success', 'Testemunho Visivel');
            this.getTestimonials();
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
        });
    }
  }
}
