import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, Host, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { BrandsService } from '../../../services/brands.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-brand-videos',
  templateUrl: './brand-videos.component.html',
  styleUrls: ['./brand-videos.component.scss']
})
export class BrandVideosComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private utilsService: UtilsService,
    private brandsService: BrandsService,
    private auth: AuthService,
  ) { }

  permissions: any = {};
  addVid: Boolean;
  videos = [];
  limitVideos;

  brandId;
  addUrl;
  p;
  delVideos: Boolean;

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('BrandsCreate');
    this.permissions.edit = this.auth.hasPermissions('BrandsEdit');
    this.permissions.delete = this.auth.hasPermissions('BrandsDelete');
    this.permissions.read = this.auth.hasPermissions('BrandsRead');
    this.addVid = false;
    this.delVideos = false;
    if (this.permissions.read) {
      this.getUrlParams();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.brandId = params['brandId'];
      this.getVideos();
    });
  }

  getVideos() {
    this.brandsService.getBrandVideosByBrandId(this.brandId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.videos = res as any;
        this.videos.forEach(video => {
          video.url = this.getEmbedVideos(this.utilsService.getEmbedUrlFromVideoUrl(video.url));
        });
        this.delVideos = false;
      });
  }

  getEmbedVideos(video) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(video);
  }

  addVideo() {
    this.addVid = true;
  }

  addNewVideo() {
    if (this.addUrl.trim().length > 0) {
      this.brandsService.addVideo(this.brandId, this.addUrl)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Video adicionado');
            this.videos.push({
              id: aux.id,
              brand_id: this.brandId,
              url: this.addUrl
            });
            this.addUrl = '';
            this.getVideos();
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
        });
    } else {
      this.utilsService.sendConfirmation('info', 'Insira um url');
    }
  }

  removeVideo(index) {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja elimiar o video?', 'Este processo é irreversível').then(confirm => {
      if (confirm) {
        this.brandsService.deleteVideo(this.videos[index].id)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (aux.success) {
              this.utilsService.sendNotification('success', 'Video removido');
              this.videos.splice(index, 1);
              this.videos.push({ store_id: this.brandId, url: this.addUrl });
              this.addUrl = '';
              this.addVid = false;
              this.delVideos = true;
              this.getVideos();
            } else {
              this.utilsService.sendSystemErrorAlert();
            }
          });
      }
    });
  }
}
