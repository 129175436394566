import * as globals from '../../assets/globals';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ObservableLike } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class CoursesService {

  constructor(private http: HttpClient) { }

  apiRoute = globals.base_api_path + 'courses/';

  getAllCourses(): Observable<any> {
    return this.http.get(this.apiRoute + 'courses');
  }

  getAllSpeakers(): Observable<any> {
    return this.http.get(this.apiRoute + 'speakers');
  }

  getAllSponsors(): Observable<any> {
    return this.http.get(this.apiRoute + 'sponsors');
  }

  getCourseById(courseId): Observable<any> {
    return this.http.get(this.apiRoute + 'course/' + courseId);
  }

  getCourseSponsors(courseId): Observable<any> {
    return this.http.get(this.apiRoute + 'course/' + courseId + '/sponsors');
  }

  getSpeakerById(speakerId): Observable<any> {
    return this.http.get(this.apiRoute + 'speaker/' + speakerId);
  }

  getSponsorById(sponsorId): Observable<any> {
    return this.http.get(this.apiRoute + 'sponsor/' + sponsorId);
  }

  getParticipantsByCourseId(courseId): Observable<any> {
    return this.http.get(this.apiRoute + 'participants/' + courseId);
  }

  updateCourse(id, body): Observable<any> {
    return this.http.put(this.apiRoute + 'update/course/' + id, body);
  }

  updateCourseSchedule(id, body): Observable<any> {
    return this.http.put(this.apiRoute + 'update/schedule/' + id, body);
  }

  updateSpeaker(id, body): Observable<any> {
    return this.http.put(this.apiRoute + 'update/speaker/' + id, body);
  }

  updateSponsor(id, body): Observable<any> {
    return this.http.put(this.apiRoute + 'update/sponsor/' + id, body);
  }

  getCourseVideosByCourseId(courseId): Observable<any> {
    return this.http.get(this.apiRoute + 'course/' + courseId + '/videos');
  }

  getCourseScheduleByCourseId(id): Observable<any> {
    return this.http.get(this.apiRoute + id + '/schedule');
  }

  addVideo(courseId, url): Observable<any> {
    return this.http.post(this.apiRoute + courseId + '/videos', { 'url': url });
  }

  deleteVideo(id): Observable<any> {
    return this.http.delete(this.apiRoute + 'video/' + id);
  }

  deleteCourse(courseId: Observable<any>) {
    return this.http.put(this.apiRoute + 'delete/course/' + courseId, null);
  }

  deleteSpeaker(speakerId: Observable<any>) {
    return this.http.put(this.apiRoute + 'delete/speaker/' + speakerId, null);
  }

  deleteSponsor(sponsorId: Observable<any>) {
    return this.http.put(this.apiRoute + 'delete/sponsor/' + sponsorId, null);
  }

  addSponsor(body): Observable<any> {
    return this.http.post(this.apiRoute + 'add/sponsor', body);
  }

  addSpeaker(body): Observable<any> {
    return this.http.post(this.apiRoute + 'add/speaker', body);
  }

  addCourse(body): Observable<any> {
    return this.http.post(this.apiRoute + 'add/course', body);
  }

  uploadPictureCoursebyId(id, imgs): Observable<any> {
    return this.http.post(this.apiRoute + id + '/newimage', { images: imgs });
  }

  deletePicturebyId(courseId, id): Observable<any> {
    return this.http.delete(this.apiRoute + courseId + '/image/' + id);
  }

  getCourseImagesByCourseId(courseId): Observable<any> {
    return this.http.get(this.apiRoute + courseId + '/images');
  }

  changeAccepted(participantId, model): Observable<any> {
    return this.http.put(this.apiRoute + participantId + '/accepted', model);
  }
}
