import * as globals from '../../assets/globals';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ObservableLike } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StaticPagesService {

  constructor(private http: HttpClient) { }

  apiRoute = globals.base_api_path + 'static-pages/';

  getAllPages(): Observable<any> {
    return this.http.get(this.apiRoute);
  }

  changeIsVisible(pageId, model): Observable<any> {
    return this.http.put(this.apiRoute + pageId + '/isvisible', model);
  }

  updatePage(id, model): Observable<any> {
    return this.http.put(this.apiRoute + 'edit/page/' + id, model);
  }

  getPageById(pageId): Observable<any> {
    return this.http.get(this.apiRoute + 'page/' + pageId);
  }

  deletePage(pageId): Observable<any> {
    return this.http.put(this.apiRoute + 'delete/page/' + pageId, null);
  }

  createPage(page): Observable<any> {
    return this.http.post(this.apiRoute + 'create/page', page);
  }
}
