import * as globals from '../../../../assets/globals';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';

import { AuthService } from 'src/app/services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { PublicService } from '../../../services/public.service';
import { StoresService } from '../../../services/stores.service';
import { UtilsService } from '../../../services/utils.service';
import { WeddingsService } from '../../../services/weddings.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-wedding-public-details',
  templateUrl: './wedding-public-details.component.html',
  styleUrls: ['./wedding-public-details.component.scss']
})

export class WeddingPublicDetailsComponent implements OnInit {
  editInfo;
  publicId;
  weddingId;
  uploaderFeatured: FileUploader;
  uploaderImages: FileUploader;
  siteUrl = globals.site_base_url;
  model: any = {};
  showImgsArray;
  anexo;
  featured_img: any = {};
  images: any[] = [];
  activities = globals.wedding_providers_activities;
  imagesToInsert: any[] = [];
  imagesToDelete: any[] = [];

  create = true;
  editing = false;
  updated = false;

  editWedding = false;

  addUrl;
  videosToAdd = [];
  videosToDelete = [];

  imgDate: any;

  finishUI: Boolean;
  finishUV: Boolean;

  finishDI: Boolean;
  finishDV: Boolean;

  stores;

  permissions: any = {};

  constructor(
    public sanitizer: DomSanitizer,
    private router: Router,
    private utilsService: UtilsService,
    private publicService: PublicService,
    private storesService: StoresService,
    private weddingsService: WeddingsService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.edit = this.auth.hasPermissions('UsersEdit');
    this.permissions.read = this.auth.hasPermissions('UsersRead');
    if (this.permissions.read) {
      this.initModel();
      this.initModelStores();
      this.defineUploaderFeatured();
      this.defineUploaderImages();
      this.getWeddingByUrlParams();
    }
  }

  getWeddingByUrlParams() {
    this.route.params.subscribe(params => {
      this.initModel();
      this.publicId = params.publicId;
      this.model.wedding_id = params.weddingId;
      this.model.publicId = params.publicId;
      this.weddingId = params.weddingId;
      if (this.publicId) {
        this.getPublicWeddingByPublicId();
      } else if (this.weddingId) {
        this.getWedding();
      }
      this.getStores();
    });
  }

  initModel() {
    this.model = {};
    this.featured_img = {};
    this.model.images = [];
    this.model.videos = [];
    this.model.providers = [];
    this.showImgsArray = [];
    this.finishUI = false;
    this.finishUV = false;
    this.finishDI = false;
    this.finishDV = false;
  }

  initModelStores() {
    this.model.providers = [];
    for (const activity of this.activities) {
      this.model.providers.push({ name: activity, store: {} });
    }
  }

  getPublicWeddingByPublicId() {
    this.publicService.getPublicWeddingByPublicId(this.publicId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        if (res) {
          this.editWedding = true;
          this.model = res as any;
          this.model.date = this.utilsService.convertDateStringToDate(this.model.date);
          this.weddingId = this.model.wedding_id;
          this.getWeddingImgs();
          this.getWeddingVideos();
          this.getWeddingProviders();
        } else {
          this.router.navigate([globals.baseUrls.weddings + '/create/public/' + this.publicId]);
        }
        this.initModelStores();
      });
  }

  getWedding() {
    this.publicService.getPublicWeddingByWeddingId(this.weddingId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        if (res) {
          this.editWedding = true;
          this.model = res;
          this.model.date = this.utilsService.convertDateStringToDate(this.model.date);
          this.getWeddingImgs();
          this.getWeddingVideos();
          this.getWeddingProviders();
        }
        this.initModelStores();
      });
  }

  getWeddingImgs() {
    this.weddingsService.getWeddingImgs(this.weddingId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(resImages => {
        this.model.images = resImages;
        this.showImgsArray = resImages;
      });
  }

  getWeddingVideos() {
    this.weddingsService.getWeddingVideos(this.weddingId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(resVideos => {
        this.model.videos = resVideos;
      });
  }

  getWeddingProviders() {
    this.weddingsService.getWeddingProviders(this.weddingId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(resProviders => {
        const aux = resProviders as any;
        aux.forEach(item => {
          const index = this.model.providers.findIndex(category => category.name === item.provider_category);
          this.model.providers[index].store = { name: item.storeName, id: item.storeId };
        });
        console.log(aux);
      });
  }

  editWeddingToggle() {
    this.editWedding = !this.editWedding;
  }

  cancelEditWeddingToggle() {
    this.editWeddingToggle();
    this.getWedding();
  }

  defineUploaderFeatured() {
    this.uploaderFeatured = new FileUploader({
      url: globals.base_api_path + 'files/upload/wedding/featured',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderFeatured.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.model.id = JSON.parse(response).wedding_id;
      this.model.featured_img = JSON.parse(response).url;
      this.imgDate = Date.now();
      this.weddingsService.updatePublicWedding(this.model.id, this.model)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (this.updated && aux.success) {
            this.utilsService.sendNotification('success', 'Casamento atualizado');
            this.updated = false;
          } else if (!aux.success) {
            this.utilsService.sendSystemErrorAlert();
          }
        });
      this.defineUploaderFeatured();
    };
    this.uploaderFeatured._onErrorItem = (err) => {
      //console.log(err);
      this.uploaderFeatured.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderFeatured();
    };
  }

  defineUploaderImages() {
    this.uploaderImages = new FileUploader({
      url: globals.base_api_path + 'files/upload/wedding',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderImages.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.imagesToInsert.push(JSON.parse(response).url);
      this.insertImages();
    };
    this.uploaderImages._onErrorItem = (err) => {
      this.uploaderImages.clearQueue();
      //console.log(err);
      this.utilsService.sendSystemErrorAlert();
    };
  }

  deleteWedding() {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que quer apagar o casamento?', 'Este processo é irreversível').then(confirm => {
      if (confirm) {
        this.weddingsService.deletePublicWedding(this.weddingId)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (aux.success) {
              this.utilsService.sendNotification('success', 'Casamento apagado');
              this.ngOnInit();
              this.create = true;
            }
          });
      }
    });
  }

  uploadFeaturedImage() {
    this.uploaderFeatured.queue.forEach((img, index) => {
      img._onBuildForm = (form) => {
        form.append('id', this.weddingId);
      };
      img.upload();
    });
  }

  uploadImages() {
    if (this.model.images.length + this.uploaderImages.queue.length <= globals.limitPhotosWeddings) {
      if (this.uploaderImages.queue.length > 0) {
        this.spinner.show();
      };
      this.uploaderImages.queue.forEach((img, index) => {
        img._onBuildForm = (form) => {
          form.append('id', this.weddingId);
        };
        img.upload();
      });
    } else {
      this.utilsService.sendConfirmation('error', 'O número de imagens que está a tentar adicionar ultrapassa o permitido para os casamentos.');
    }
  }

  updateWedding() {
    if (!this.model.date || !this.model.groom || this.model.groom.trim() === '' || !this.model.bride || this.model.bride.trim() == '') {
      this.utilsService.sendConfirmation('warning', 'Para criar o casamento tem de inserir os nomes dos noivos e a data de casamento');
    } else if (this.showImgsArray.length > globals.limitPhotosWeddings) {
      this.utilsService.sendConfirmation('warning', 'Escedeu o limite de fotografias. Só pode fazer upload de ' + globals.limitPhotosWeddings + ' fotografias.');
    } else {
      if (this.uploaderFeatured.queue.length === 0) {
        if (this.model.local == undefined) {
          this.model.local = '';
        }
        if (this.model.history == undefined) {
          this.model.history = '';
        } else {
          this.model.history = this.model.history.replace(/["]/g, '\\"');
        }
        if (this.model.memories == undefined) {
          this.model.memories = '';
        } else {
          this.model.memories = this.model.memories.replace(/["]/g, '\\"');
        }
        if (this.model.tips == undefined) {
          this.model.tips = '';
        } else {
          this.model.tips = this.model.tips.replace(/["]/g, '\\"');
        }
        if (this.model.changes == undefined) {
          this.model.changes = '';
        } else {
          this.model.changes = this.model.changes.replace(/["]/g, '\\"');
        }
        this.editing = true;
        const aux = this.model;
        aux.images = [];
        this.weddingsService.updatePublicWedding(this.weddingId, aux)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (aux.success) {
              this.utilsService.sendNotification('success', 'Casamento atualizado');
            } else {
              this.utilsService.sendSystemErrorAlert();
            }
          });
      } else {
        this.updated = true;
        this.uploadFeaturedImage();
      }
      this.uploadImages();
      this.removeImages();
      this.insertVideos();
      this.removeVideos();
      this.updateProviders();
      this.editWeddingToggle();
      if (this.finishDI && this.finishDV && this.finishUI && this.finishUV) {
        this.spinner.hide();
        this.finishUI = false;
        this.finishUV = false;

        this.finishDI = false;
        this.finishDV = false;
      }
      this.getWedding();
    }
  }

  showFeaturedImage() {
    if (this.uploaderFeatured.queue.length > 1) {
      this.uploaderFeatured.queue.splice(0, 1);
    }
    this.uploaderFeatured.queue.forEach(foto => {
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      this.featured_img.url = url;
      this.featured_img.foto = foto;
    });
  }

  showImages() {
    this.showImgsArray = [...this.model.images, ...[]];
    this.uploaderImages.queue.forEach(foto => {
      const img: any = {};
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      img.sanitizer_url = url;
      img.foto = foto;
      img.toUpload = true;
      this.showImgsArray.push(img);
    });
  }

  deleteImage(index) {
    const img = this.showImgsArray.splice(index, 1)[0];
    if (img.toUpload) {
      const uploaderIndex = this.uploaderImages.queue.findIndex(item => item.file.name == img.foto.file.name);
      this.uploaderImages.queue.splice(uploaderIndex, 1);
    } else {
      this.imagesToDelete.push(img.id);
    }
  }

  insertImages() {
    if (this.uploaderImages.queue.length == this.imagesToInsert.length && this.imagesToInsert.length > 0) {
      this.weddingsService.insertImagesPublic(this.weddingId, this.imagesToInsert)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          this.imagesToInsert = [];
          this.getWeddingImgs();
          if (this.create == true && this.editing == false) {
            if (this.finishUV) {
              this.spinner.hide();
              this.finishUI = false;
              this.finishUV = false;
            } else {
              this.finishUI = true;
            }
          } else if (this.create == false && this.editing == true) {
            if (this.finishDI && this.finishDV && this.finishUV) {
              this.spinner.hide();
              this.finishUI = false;
              this.finishUV = false;
              this.finishDI = false;
              this.finishDV = false;
            } else {
              this.finishUI = true;
            }
          }
        });
    } else {
      if (this.create == true && this.editing == false) {
        if (this.finishUV) {
          this.spinner.hide();
          this.finishUI = false;
          this.finishUV = false;
        } else {
          this.finishUI = true;
        }
      } else if (this.create == false && this.editing == true) {
        if (this.finishDI && this.finishDV && this.finishUV) {
          this.spinner.hide();
          this.finishUI = false;
          this.finishUV = false;
          this.finishDI = false;
          this.finishDV = false;
        } else {
          this.finishUI = true;
        }
      }
    }
  }

  removeImages() {
    if (this.imagesToDelete.length > 0) {
      this.spinner.show();
      this.weddingsService.deleteImagesPublic(this.weddingId, this.imagesToDelete)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          this.imagesToDelete = [];
          if (this.finishUI && this.finishDV && this.finishUV) {
            this.spinner.hide();
            this.finishUI = false;
            this.finishUV = false;
            this.finishDI = false;
            this.finishDV = false;
          } else {
            this.finishDI = true;
          }
          this.getWeddingImgs();
        });
    } else {
      if (this.finishUI && this.finishDV && this.finishUV) {
        this.spinner.hide();
        this.finishUI = false;
        this.finishUV = false;
        this.finishDI = false;
        this.finishDV = false;
      } else {
        this.finishDI = true;
      }
    }
  }

  insertVideos() {
    if (this.videosToAdd.length > 0) {
      this.spinner.show();
      this.weddingsService.insertVideosPublic(this.weddingId, this.videosToAdd)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          this.videosToAdd = [];
          this.getWeddingVideos();
          if (this.create == true && this.editing == false) {
            if (this.finishUI) {
              this.spinner.hide();
              this.finishUI = false;
              this.finishUV = false;
            } else {
              this.finishUV = true;
            }
          } else if (this.create == false && this.editing == true) {
            if (this.finishDI && this.finishDV && this.finishUI) {
              this.spinner.hide();
              this.finishUI = false;
              this.finishUV = false;
              this.finishDI = false;
              this.finishDV = false;
            } else {
              this.finishUV = true;
            }
          }
        });
    } else {
      if (this.create == true && this.editing == false) {
        if (this.finishUI) {
          this.spinner.hide();
          this.finishUI = false;
          this.finishUV = false;
        } else {
          this.finishUV = true;
        }
      } else if (this.create == false && this.editing == true) {
        if (this.finishDI && this.finishDV && this.finishUI) {
          this.spinner.hide();
          this.finishUI = false;
          this.finishUV = false;
          this.finishDI = false;
          this.finishDV = false;
        } else {
          this.finishUV = true;
        }
      }
    }
  }

  removeVideos() {
    if (this.videosToDelete.length > 0) {
      this.spinner.show();
      this.weddingsService.deleteVideosPublic(this.weddingId, this.videosToDelete)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          this.videosToDelete = [];
          if (this.finishDI && this.finishUI && this.finishUV) {
            this.spinner.hide();
            this.finishUI = false;
            this.finishUV = false;
            this.finishDI = false;
            this.finishDV = false;
          } else {
            this.finishDV = true;
          }
          this.getWeddingVideos();
        });
    } else {
      if (this.finishDI && this.finishUI && this.finishUV) {
        this.spinner.hide();
        this.finishUI = false;
        this.finishUV = false;
        this.finishDI = false;
        this.finishDV = false;
      } else {
        this.finishDV = true;
      }
    }
  }

  addVideo() {
    if (this.addUrl && this.addUrl.trim() !== '') {
      this.videosToAdd.push(this.addUrl);
      this.model.videos.push({ video_url: this.addUrl });
      this.addUrl = '';
    }
  }

  deleteVideo(index) {
    this.videosToDelete.push(this.model.videos.splice(index, 1)[0].id);
  }

  getEmbedVideos(index) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      this.utilsService.getEmbedUrlFromVideoUrl(this.model.videos[index].video_url)
    );
  }

  updateProviders() {
    this.weddingsService.updatePublicWeddingProviders(this.weddingId, this.model.providers)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe();
  }

  getStores() {
    this.storesService.getAllStoresWithLocality()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.stores = res;
      });
  }

  createWedding() {
    if (!this.model.date || !this.model.groom || this.model.groom.trim() === '' || !this.model.bride || this.model.bride.trim() == '') {
      this.utilsService.sendConfirmation('warning', 'Para criar o casamento tem de inserir os nomes dos noivos e a data de casamento');
    } else if (this.showImgsArray.length > globals.limitPhotosWeddings) {
      this.utilsService.sendConfirmation('warning', 'Escedeu o limite de fotografias. Só pode fazer upload de ' + globals.limitPhotosWeddings + ' fotografias.');
    } else {
      if (this.model.local == undefined) {
        this.model.local = '';
      }
      if (this.model.history == undefined) {
        this.model.history = '';
      } else {
        this.model.history = this.model.history.replace(/["]/g, '\\"');
      }
      if (this.model.memories == undefined) {
        this.model.memories = '';
      } else {
        this.model.memories = this.model.memories.replace(/["]/g, '\\"');
      }
      if (this.model.tips == undefined) {
        this.model.tips = '';
      } else {
        this.model.tips = this.model.tips.replace(/["]/g, '\\"');
      }
      if (this.model.changes == undefined) {
        this.model.changes = '';
      } else {
        this.model.changes = this.model.changes.replace(/["]/g, '\\"');
      }
      const aux = this.model;
      aux.images = [];
      this.editing = false;
      this.create = true;
      this.weddingsService.newPublicWedding(this.model)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            this.spinner.hide();
            this.finishUI = false;
            this.finishUV = false;
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.model.wedding_id = aux.id;
            this.model.id = aux.id;
            this.weddingId = aux.id;
            this.uploadFeaturedImage();
            this.uploadImages();
            this.insertVideos();
            this.updateProviders();
            if (this.finishUI && this.finishUV) {
              this.spinner.hide();
              this.finishUI = false;
              this.finishUV = false;
            }
            this.utilsService.sendNotification('success', 'Casamento criado');
            //this.ngOnInit();
          }
        });
    }
  }
}
