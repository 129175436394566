import * as globals from '../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UsersService } from '../../services/users.service';
import { UtilsService } from '../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})

export class UsersComponent implements OnInit {
  nr_items_page = globals.nr_items_page;
  p; // paginação;
  users;
  search: any = {};
  permissions: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private usersService: UsersService,
    private utilsService: UtilsService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('UsersCreate');
    this.permissions.edit = this.auth.hasPermissions('UsersEdit');
    this.permissions.delete = this.auth.hasPermissions('UsersDelete');
    this.permissions.read = this.auth.hasPermissions('UsersRead');
    if (this.permissions.read) {
      this.getAllUsers();
    }
  }

  getAllUsers() {
    this.usersService.getAllUsers()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.users = res as any;
      });
  }

  /*getUsersNewsletter() {
    this.usersService.getUsersNewsletter()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        console.log(res);
      });
  }*/

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }

  goToCreateUser(link: string): void {
    switch (link) {
      case 'public':
        this.router.navigate([globals.baseUrls.users + '/create/' + link]);
        break;
      case 'professional':
        this.router.navigate([globals.baseUrls.users + '/create/' + link]);
        break;
    }
  }
}
