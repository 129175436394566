import * as globals from '../../../../assets/globals';

import { Component, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from 'src/app/services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss']
})
export class CompanyInfoComponent implements OnInit {

  permissions: any = {};
  modelEdit: any = {};
  info: any = {};
  siteUrl = globals.site_base_url;
  imgDate: any;
  uploaderLogo: FileUploader;
  allFields: boolean;

  constructor(private utils: UtilsService,
    private auth: AuthService) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('ManagementCreate');
    this.permissions.edit = this.auth.hasPermissions('ManagementEdit');
    this.permissions.delete = this.auth.hasPermissions('ManagementDelete');
    this.permissions.read = this.auth.hasPermissions('ManagementRead');
    if (this.permissions.read) {
      this.defineUploaderLogo();
      this.getCompanyInfo();
    }
  }

  getCompanyInfo() {
    this.utils.getCompanyInfo()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utils.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.info = res as any;
        this.modelEdit = this.info;
      });
  }

  editData() {
    this.checkAllFields();
    if (this.allFields == false) {
      this.utils.sendConfirmation('info', 'Tem de preencher todas as informações');
    } else {
      this.utils.updateCompanyInfo(this.modelEdit)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utils.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          console.log(aux);
          if (aux.success) {
            this.utils.sendNotification('success', 'Dados ILB atualizados');
          } else {
            this.utils.sendSystemErrorAlert();
          }
        });
    }
  }

  cancelInfoToggle() {
    this.modelEdit = this.info;
  }

  onUploadLogo() {
    this.uploaderLogo.queue.forEach(img => {
      /*img._onBuildForm = (form) => {
        form.append('storeName', this.utils.getUrlFromName(this.modelEdit.name));
        form.append('storeId', this.modelEdit.id);
        form.append('activity', this.utilsService.getUrlFromName(this.modelEdit.activity_name));
      };*/
      img.upload();
    });
    this.uploaderLogo.uploadAll();
  }

  defineUploaderLogo() {
    this.uploaderLogo = new FileUploader({
      url: globals.base_api_path + 'files/upload/ilb/logo',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderLogo.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const url = JSON.parse(response).url;
      console.log(url);
      this.utils.updateCompanyLogo(url)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utils.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          if ((res as any).success) {
            this.modelEdit.logo_url = url;
            this.imgDate = Date.now();
            this.utils.sendNotification('success', 'Logo ILB atualizado');
          }
        });
      this.defineUploaderLogo();
    };
    this.uploaderLogo._onErrorItem = (err) => {
      this.uploaderLogo.clearQueue();
      this.utils.sendSystemErrorAlert();
      this.defineUploaderLogo();
    };
  }

  checkAllFields() {
    if (this.modelEdit.title === undefined || this.modelEdit.title.trim().length === 0) {
      this.allFields = false;
    }
    if (this.modelEdit.phone_nr === undefined || this.modelEdit.phone_nr.trim().length === 0) {
      this.allFields = false;
    }
    if (this.modelEdit.email === undefined || this.modelEdit.siteUrl.trim().length === 0) {
      this.allFields = false;
    }
    if (this.modelEdit.description === undefined || this.modelEdit.description.trim().length === 0) {
      this.allFields = false;
    }
    if (this.modelEdit.keywords === undefined || this.modelEdit.keywords.trim().length === 0) {
      this.allFields = false;
    }
    if (this.modelEdit.companyName === undefined || this.modelEdit.companyName.trim().length === 0) {
      this.allFields = false;
    }
    if (this.modelEdit.vatId === undefined || this.modelEdit.vatId.trim().length === 0) {
      this.allFields = false;
    }
    if (this.modelEdit.address === undefined || this.modelEdit.address.trim().length === 0) {
      this.allFields = false;
    }
    if (this.modelEdit.postal === undefined || this.modelEdit.postal.trim().length === 0) {
      this.allFields = false;
    }
    if (this.modelEdit.locality === undefined || this.modelEdit.locality.trim().length === 0) {
      this.allFields = false;
    }
    if (this.modelEdit.city === undefined || this.modelEdit.city.trim().length === 0) {
      this.allFields = false;
    }
    if (this.modelEdit.country === undefined || this.modelEdit.country.trim().length === 0) {
      this.allFields = false;
    }
    if (this.modelEdit.duns === undefined || this.modelEdit.duns.trim().length === 0) {
      this.allFields = false;
    }
    if (this.modelEdit.foundationYear === undefined || this.modelEdit.foundationYear.trim().length === 0) {
      this.allFields = false;
    }
    if (this.modelEdit.minEmployees === undefined || this.modelEdit.minEmployees.trim().length === 0) {
      this.allFields = false;
    }
    if (this.modelEdit.maxEmployees === undefined || this.modelEdit.maxEmployees.trim().length === 0) {
      this.allFields = false;
    }
  }
}
