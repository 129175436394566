import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as globals from '../../assets/globals';

@Injectable({
  providedIn: 'root'
})
export class IloveTVService {

  constructor(private http: HttpClient) { }

  apiRoute = globals.base_api_path + 'ilovetv/';

  getVideos(): Observable<any> {
    return this.http.get(this.apiRoute + 'videos');
  }

  getVideo(videoId): Observable<any> {
    return this.http.get(this.apiRoute + 'video/' + videoId);
  }

  countVideoPosition(position): Observable<any> {
    return this.http.get(this.apiRoute + 'count/position/' + position);
  }

  getVideoSubCategories(id): Observable<any> {
    return this.http.get(this.apiRoute + 'subcategories/' + id);
  }

  getVideosbySubCategory(id): Observable<any> {
    return this.http.get(this.apiRoute + 'subcategory/' + id + '/videos');
  }

  deleteVideo(id): Observable<any> {
    return this.http.delete(this.apiRoute + 'video/' + id);
  }

  addVideo(model): Observable<any> {
    return this.http.post(this.apiRoute + 'new-video', model);
  }

  addVideoWithUpdatePosition(model): Observable<any> {
    return this.http.post(this.apiRoute + 'new-video/update', model);
  }

  updateVideo(videoId, model) {
    return this.http.put(this.apiRoute + 'update/' + videoId, model);
  }

  updateVideoWithUpdatePosition(videoId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'update/update/' + videoId, model);
  }

  getHomeVideo(): Observable<any> {
    return this.http.get(this.apiRoute + 'video/home');
  }

  addHomeVideo(model): Observable<any> {
    return this.http.post(this.apiRoute + 'new-home-video', model);
  }

  changeIsHome(videoId, model): Observable<any> {
    return this.http.put(this.apiRoute + videoId + '/is_home', model);
  }
}
