import * as globals from '../../../assets/globals';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, TemplateRef } from '@angular/core';

import { AnnouncementsService } from 'src/app/services/announcements.service';
import { AuthService } from 'src/app/services/auth.service';
import { FileUploader } from 'ng2-file-upload';
import { FilesService } from 'src/app/services/files.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from 'src/app/services/utils.service';
import { catchError } from 'rxjs/operators';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss']
})
export class AnnouncementsComponent implements OnInit {

  dtOptions = {
    paging: false,
    info: false,
    order: [[0, 'desc']]
  };

  nr_items_page = 10;
  pPub; //Pagination
  pPro; //Pagination

  permissions: any = {};

  announcements;
  infoAnnouncement;
  editAnnouncement;

  modalRef: BsModalRef;
  uploader: FileUploader;

  constructor(
    private announcementsService: AnnouncementsService,
    private utils: UtilsService,
    private modalService: BsModalService,
    private auth: AuthService,
    private filesService: FilesService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('AnnouncementsCreate');
    this.permissions.edit = this.auth.hasPermissions('AnnouncementsEdit');
    this.permissions.delete = this.auth.hasPermissions('AnnouncementsDelete');
    this.permissions.read = this.auth.hasPermissions('AnnouncementsRead');
    if (this.permissions.read) {
      this.getAnnouncements();
    }
  }

  defineUploader() {
    this.uploader = new FileUploader({
      url: globals.base_api_path + 'files/attachments',
      authToken: this.auth.getUserToken(),
      authTokenHeader: 'x-access-token',
    });
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const attachment = JSON.parse(response);
      this.editAnnouncement.original_name = attachment.originalname;
      this.editAnnouncement.upload_name = attachment.uploadname;
      this.updateAnnouncementAttachment();
    };
  }

  getAnnouncements() {
    this.announcementsService.getAllAnnouncements()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utils.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.announcements = res;
        console.log(this.announcements);
      });
  }

  openCreateUser(modal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  closeModal() {
    this.modalRef.hide();
    this.infoAnnouncement = {};
  }

  openInfoAnnouncement(modal: TemplateRef<any>, announcement) {
    this.infoAnnouncement = { ...announcement };
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  openEditAnnouncement(modal: TemplateRef<any>, announcement) {
    this.editAnnouncement = { ...announcement };
    if (this.editAnnouncement.init_date) {
      this.editAnnouncement.init_date = this.utils.convertDateStringToDate(this.editAnnouncement.init_date);
    }
    if (this.editAnnouncement.end_date) {
      this.editAnnouncement.end_date = this.utils.convertDateStringToDate(this.editAnnouncement.end_date);
    }
    this.defineUploader();
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  updateAnnouncement() {
    if (this.permissions.edit) {
      this.announcementsService.updateAnnouncement(this.editAnnouncement)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utils.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          this.getAnnouncements();
          this.closeModal();
          this.utils.sendNotification('success', 'Anúncio atualizado');
        });
    } else {
      this.utils.sendNotification('error', 'Não tem permissões para esta ação');
    }
  }

  updateAnnouncementAttachment() {
    if (this.permissions.edit) {
      this.announcementsService.updateAnnouncementAttachment(this.editAnnouncement)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utils.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          this.utils.sendNotification('success', 'Anexo atualizado (inserido ou apagado)');
        });
    } else {
      this.utils.sendNotification('error', 'Não tem permissões para esta ação');
    }
  }

  deleteAnnouncement(announcement) {
    if (this.permissions.delete) {
      this.utils.sendConfirmationQuestion(announcement.title, 'Tem a certeza que quer eliminar este anúncio? Esta ação é irreversivél').then(confirm => {
        if (confirm) {
          this.announcementsService.deleteAnnouncement(announcement.id).pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utils.sendSystemErrorAlert(err);
            }) as any)
            .subscribe(res => {
              const indexPub = this.announcements.public.findIndex(a => a.id === announcement.id);
              if (indexPub !== -1) {
                this.announcements.public.splice(indexPub, 1);
              } else {
                const indexPro = this.announcements.professionals.findIndex(a => a.id === announcement.id);
                if (indexPro !== -1) {
                  this.announcements.professionals.splice(indexPro, 1);
                }
              }

              this.utils.sendNotification('success', 'Anúncio apagado');
            });
        }
      })
    } else {
      this.utils.sendNotification('error', 'Não tem permissões para esta ação');
    }
  }

  downloadAttachment(file) {
    this.filesService.downloadAttachment(file)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utils.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(
        data => saveAs(data, file),
        error => console.error(error)
      );
  }

  uploadFile() {
    if (this.permissions.edit) {
      this.uploader.uploadAll();
    }
  }

  deleteAttachment() {
    if (this.permissions.edit) {
      this.filesService.deleteAttachment(this.editAnnouncement.upload_name)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utils.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          this.editAnnouncement.original_name = null;
          this.editAnnouncement.upload_name = null;
          this.updateAnnouncementAttachment();
        });
    } else {
      this.utils.sendNotification('error', 'Não tem permissões para esta ação');
    }
  }
}
