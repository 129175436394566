import * as globals from '../../../../assets/globals';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, TemplateRef } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from 'src/app/services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-store-plans',
  templateUrl: './store-plans.component.html',
  styleUrls: ['./store-plans.component.scss']
})
export class StorePlansComponent implements OnInit {

  nr_items_page = globals.nr_items_page;
  search: any = {};
  permissions: any = {};
  p; // paginação;
  modalRef: BsModalRef;

  plans: any[];

  planInfo: any = {};
  planEdit: any = {};
  planCreate: any = {};

  constructor(
    private utils: UtilsService,
    private modalService: BsModalService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('ManagementCreate');
    this.permissions.edit = this.auth.hasPermissions('ManagementEdit');
    this.permissions.delete = this.auth.hasPermissions('ManagementDelete');
    this.permissions.read = this.auth.hasPermissions('ManagementRead');
    if (this.permissions.read) {
      this.getPlans();
    }
  }

  getPlans() {
    this.utils.getStorePlansInfo()
      // .pipe(
      //   catchError((err: HttpErrorResponse) => {
      //     return this.utils.sendSystemErrorAlert(err);
      //   }) as any)
      .subscribe(res => {
        this.plans = res as any;
      });
  }

  openInfoModal(modal: TemplateRef<any>, plan) {
    this.planInfo = plan;
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  openEditModal(modal: TemplateRef<any>, plan) {
    this.planEdit = { ...plan };
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  openCreateModal(modal: TemplateRef<any>) {
    this.planCreate = {
      show_name: true,
      show_logo: true,
      show_address: false,
      show_district: true,
      map: false,
      show_phone_nr: false,
      show_website: false,
      tickets_budget: true,
      nr_photos: 0,
      nr_videos: 0,
      announcements_delay: 0,
      comments: true,
      nr_weddings: 0,
      promotions: 0,
      save_fav: true,
      social: true,
      rotating_banners_year: '',
      show_brands: false,
      distico_recomended: true,
      first_four: false,
      statistic: false,
      statistic_promos: false,
      weddings_link: false,
      subscribable: false,
      listed: false,
    };
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  closeModal() {
    this.modalRef.hide();
    this.planInfo = {};
    this.planEdit = {};
    this.planCreate = {};
  }

  editPlan() {
    if (
      (this.planEdit.nr_photos >= 0 || this.planEdit.nr_photos === undefined || this.planEdit.nr_photos === null) &&
      (this.planEdit.nr_videos >= 0 || this.planEdit.nr_videos === undefined || this.planEdit.nr_videos === null) &&
      (this.planEdit.announcements_delay >= 0 || this.planEdit.announcements_delay === undefined || this.planEdit.announcements_delay === null) &&
      (this.planEdit.nr_weddings >= 0 || this.planEdit.nr_weddings === undefined || this.planEdit.nr_weddings === null) &&
      (this.planEdit.promotions >= 0 || this.planEdit.promotions === undefined || this.planEdit.promotions === null)
    ) {
      if (this.planEdit.name !== undefined && this.planEdit.name !== null && this.planEdit.name.trim().length > 0) {
        if (this.planEdit.nr_photos === undefined) {
          this.planEdit.nr_photos = null;
        }
        if (this.planEdit.nr_videos === undefined) {
          this.planEdit.nr_videos = null;
        }
        if (this.planEdit.nr_weddings === undefined) {
          this.planEdit.nr_weddings = null;
        }
        if (this.planEdit.promotions === undefined) {
          this.planEdit.promotions = null;
        }
        if (this.planEdit.announcements_delay === undefined || this.planEdit.announcements_delay === null) {
          this.planEdit.announcements_delay = 0;
        }
        this.utils.updateStorePlansInfo(this.planEdit.id, this.planEdit)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utils.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const index = this.plans.findIndex(plan => plan.id === this.planEdit.id);
            if (index !== -1) {
              this.plans[index] = { ...this.planEdit };
            }
            this.closeModal();
            this.utils.sendNotification('success', 'Plano atualizado');
          });
      } else {
        this.utils.sendConfirmation('warning', 'Tem de preecher o nome');
      }
    } else {
      this.utils.sendConfirmation('warning', 'Não pode ter limites inferiores a 0');
    }
  }

  createPlan() {
    if (
      (this.planCreate.nr_photos >= 0 || this.planCreate.nr_photos === undefined || this.planCreate.nr_photos === null) &&
      (this.planCreate.nr_videos >= 0 || this.planCreate.nr_videos === undefined || this.planCreate.nr_videos === null) &&
      (this.planCreate.announcements_delay >= 0 || this.planCreate.announcements_delay === undefined || this.planCreate.announcements_delay === null) &&
      (this.planCreate.nr_weddings >= 0 || this.planCreate.nr_weddings === undefined || this.planCreate.nr_weddings === null) &&
      (this.planCreate.promotions >= 0 || this.planCreate.promotions === undefined || this.planCreate.promotions === null)
    ) {
      if (this.planCreate.name !== undefined && this.planCreate.name !== null && this.planCreate.name.trim().length > 0) {
        if (this.planCreate.nr_photos === undefined) {
          this.planCreate.nr_photos = null;
        }
        if (this.planCreate.nr_videos === undefined) {
          this.planCreate.nr_videos = null;
        }
        if (this.planCreate.nr_weddings === undefined) {
          this.planCreate.nr_weddings = null;
        }
        if (this.planCreate.promotions === undefined) {
          this.planCreate.promotions = null;
        }
        if (this.planCreate.announcements_delay === undefined || this.planCreate.announcements_delay === null) {
          this.planCreate.announcements_delay = 0;
        }
        this.utils.createStorePlansInfo(this.planCreate)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utils.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            this.getPlans();
            this.closeModal();
            this.utils.sendNotification('success', 'Plano Criado');
          });
      } else {
        this.utils.sendConfirmation('warning', 'Tem de preecher o nome');
      }
    } else {
      this.utils.sendConfirmation('warning', 'Não pode ter limites inferiores a 0');
    }
  }

  deletePlan(plan) {
    this.utils.sendConfirmationQuestion(plan.name, 'Tem a certeza que deseja apagar este plano?').then(confirm => {
      if (confirm) {
        this.utils.deleteStorePlansInfo(plan.id)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utils.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const index = this.plans.findIndex(obj => obj.id === plan.id);
            if (index !== -1) {
              this.plans.splice(index, 1);
            }
            this.utils.sendNotification('success', 'Plano removido');
          });
      }
    });
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }

}
