import * as globals from '../../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { StoresService } from '../../../../services/stores.service';
import { UtilsService } from '../../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-professional-stores',
  templateUrl: './professional-stores.component.html',
  styleUrls: ['./professional-stores.component.scss']
})

export class ProfessionalStoresComponent implements OnInit {


  nr_items_page = globals.nr_items_page;
  search: any = {};
  p; // paginação;
  stores: any[] = [];
  userId;
  professionalId;

  permissions: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private storesService: StoresService,
    private utilsService: UtilsService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.edit = this.auth.hasPermissions('StoresEdit');
    this.permissions.create = this.auth.hasPermissions('StoresCreate');
    this.permissions.read = this.auth.hasPermissions('UsersRead');
    if (this.permissions.read) {
      this.getUrlParams();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.userId = params.userId;
      this.professionalId = params.professionalId;
      this.getStoresByProfessionalId();
    });
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }

  goToCreateStore(): void {
    this.router.navigate([globals.baseUrls.stores + '/create/' + this.professionalId]);
  }

  goToStore(storeId, page): void {
    this.router.navigate([globals.baseUrls.stores + '/' + storeId + '/' + page]);
  }

  getStoresByProfessionalId() {
    this.storesService
      .getStoresByProfessionalId(this.professionalId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.stores = res as any;
        this.stores.forEach(store => {
          if (store.paid_plan_limit) {
            store.paid_plan_limit = this.utilsService.convertDateStringToDate(store.paid_plan_limit);
          }
        })
        console.log(this.stores);
      });
  }

  changeHidden(storeId, store) {
    this.storesService.changeHidden(storeId, store)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          if (store.hidden) {
            this.utilsService.sendNotification('success', 'Loja Não visivel');
          } else {
            this.utilsService.sendNotification('success', 'Loja visivel');
          }
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  changeDestinationWedding(storeId, store) {
    this.storesService.changeDestinationWedding(storeId, store)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          if (store.isDestinationWedding) {
            this.utilsService.sendNotification('success', 'Loja de WeddingDestination');
          } else {
            this.utilsService.sendNotification('success', 'Loja não pertencente a WeddingDestination');
          }
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  changeisOnline(storeId, store) {
    this.storesService.changeisOnline(storeId, store)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          if (store.isOnline) {
            this.utilsService.sendNotification('success', 'Loja online');
          } else {
            this.utilsService.sendNotification('success', 'Loja não online');
          }
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

}
