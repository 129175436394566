import * as globals from '../../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, Host, Inject, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { DestinationWeddingsService } from '../../../../services/destination-weddings.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploader } from 'ng2-file-upload';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from '../../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-region-details',
  templateUrl: './region-details.component.html',
  styleUrls: ['./region-details.component.scss']
})
export class RegionDetailsComponent implements OnInit {

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private wdService: DestinationWeddingsService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private auth: AuthService
  ) { }

  featured_img: any = {};
  model_edit: any = {};
  model_addUrl: any = {};
  regionId: any;
  countryId: any;
  countries: any[];
  insertName: any = {};
  error = '';
  loading = false;
  hidden = true;
  editing = false;
  siteUrl = globals.site_base_url;
  editInfo: boolean;
  edited: boolean;
  deleteVideo: boolean;
  addUrl;
  uploaderLogo: FileUploader;
  uploaderFeatured: FileUploader;
  phoneCode: any;
  imgDate: any;
  subCategories = [];
  districts = [];
  selectedDistricts = [];
  selectedDistrictsBackup = [];
  loadingDistricts;
  districtsToAdd = [];
  districtsToRemove = [];

  permissions: any = {};

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('DestinationWeddingsCreate');
    this.permissions.edit = this.auth.hasPermissions('DestinationWeddingsEdit');
    this.permissions.delete = this.auth.hasPermissions('DestinationWeddingsDelete');
    this.permissions.read = this.auth.hasPermissions('DestinationWeddingsRead');
    if (this.permissions.read) {
      this.getUrlParams();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.regionId = params['regionId'];
      this.getWDRegionInfo();
      this.getCountries();
      this.getSubCategories();
      this.getDistricts();
      this.defineUploaderFeatured();
    });
  }

  getWDRegionInfo() {
    this.wdService.getWDRegionInfoByWDRegionID(this.regionId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        this.model_edit = aux;
        if (this.model_edit.video_url) {
          this.model_edit.video_url = this.getEmbedVideos(this.utilsService.getEmbedUrlFromVideoUrl(this.model_edit.video_url));
        }
        this.getRegionDistricts();
      });
  }

  getEmbedVideos(video) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(video);
  }

  defineUploaderFeatured() {
    this.uploaderFeatured = new FileUploader({
      url: globals.base_api_path + 'files/upload/regionWD/featured',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderFeatured.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.model_edit.id = JSON.parse(response).region_id;
      this.model_edit.featured_img = JSON.parse(response).url;
      this.imgDate = Date.now();
      //console.log(this.model.id);
      this.wdService.updateWDRegion(this.regionId, this.model_edit)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Dados da Região WD atualizados (imagem atribuida)');
            this.addUrl = '';
            this.loading = false;
            this.deleteVideo = false;
          } else if (!aux.success) {
            this.utilsService.sendSystemErrorAlert();
          }
        });
      this.defineUploaderFeatured();
    };
    this.uploaderFeatured._onErrorItem = (err) => {
      //console.log(err);
      this.uploaderFeatured.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderFeatured();
    };
  }

  uploadFeaturedImage() {
    this.uploaderFeatured.queue.forEach(img => {
      img._onBuildForm = (form) => {
        form.append('countryName', this.utilsService.getUrlFromName(this.countries[this.model_edit.wdcountry_id - 1].name));
        form.append('regionName', this.utilsService.getUrlFromName(this.model_edit.name));
      };
      img.upload();
    });
  }

  showFeaturedImage() {
    if (this.uploaderFeatured.queue.length > 1) {
      this.uploaderFeatured.queue.splice(0, 1);
    }
    this.uploaderFeatured.queue.forEach(foto => {
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      this.featured_img.url = url;
      this.featured_img.foto = foto;
    });
  }

  editInfoToggle() {
    this.editInfo = !this.editInfo;
  }

  cancelInfoToggle() {
    this.editInfo = false;
    this.getWDRegionInfo();
  }

  editData() {
    if (this.deleteVideo === true) {
      this.model_edit.video_url = null;
    }
    if (this.model_edit.description === undefined || this.model_edit.description.trim().length === 0) {
      this.model_edit.description = '';
    }
    this.model_edit.districtsToAdd = this.districtsToAdd;
    this.model_edit.districtsToRemove = this.districtsToRemove;
    this.loading = true;
    this.wdService.updateWDRegion(this.regionId, this.model_edit)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        let aux: { success: Boolean };
        aux = res as any;
        if (aux.success) {
          console.log(this.addUrl);
          if (this.addUrl) {
            this.model_addUrl.addUrl = this.addUrl;
            this.wdService.addWDRegionVideo(this.regionId, this.model_addUrl)
              .pipe(
                catchError((err: HttpErrorResponse) => {
                  return this.utilsService.sendSystemErrorAlert(err);
                }) as any)
              .subscribe(res => {
                let aux: { success: Boolean };
                aux = res as any;
                if (aux.success) {
                  this.utilsService.sendNotification('success', 'Dados da Região WD atualizados');
                  this.addUrl = '';
                  this.loading = false;
                  this.edited = true;
                  this.deleteVideo = false;
                  this.uploadFeaturedImage();
                  this.getWDRegionInfo();
                } else {
                  this.utilsService.sendSystemErrorAlert();
                }
              });
          } else {
            this.utilsService.sendNotification('success', 'Dados da Região WD atualizados');
            this.loading = false;
            this.edited = true;
            this.deleteVideo = false;
          }
          this.uploadFeaturedImage();
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  deleteWDRegion() {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja elimiar a Região do WD?', 'Este processo é irreversível').then(confirm => {
      //this.spinner.show();
      this.wdService.deleteWDRegion(this.regionId)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          //this.spinner.hide();
          this.utilsService.sendNotification('success', 'Região eliminada do WD');
          this.goBack();
        });
    });
  }

  removeVideo() {
    this.deleteVideo = true;
  }

  getRegionDistricts() {
    this.wdService.getRegionDistricts(this.regionId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        this.selectedDistricts = aux.map(district => district.district_id);
        this.selectedDistrictsBackup = this.selectedDistricts;
        console.log(this.selectedDistricts);
      });
  }

  addDistrict(event) {
    const id = event.id;
    const index = this.districtsToRemove.indexOf(id);
    if (index > -1) {
      this.districtsToRemove.splice(index, 1);
      //console.log(this.subCategoriesToRemove);
    } else {
      this.districtsToAdd.push(id);
      //console.log(this.subCategoriesToAdd);
    }
  }

  removeDistrict(event) {
    const id = event.value.id;
    const index = this.districtsToAdd.indexOf(id);
    if (index > -1) {
      this.districtsToAdd.splice(index, 1);
      //console.log(this.subCategoriesToAdd);
    } else {
      this.districtsToRemove.push(id);
      //console.log(this.subCategoriesToRemove);
    }
  }

  clearDistricts() {
    this.districtsToRemove = this.selectedDistrictsBackup;
  }

  goBack() {
    const aux = this.router.url.split('/');
    aux.length -= 2;
    const backLink = aux.join('/');
    this.router.navigate([backLink]);
  }

  getCountries() {
    this.wdService.getAllWDCountries()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.countries = res as any;
      });
  }

  getDistricts() {
    this.utilsService.getDistricts()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.districts = res as any;
        console.log(this.districts);
        this.loadingDistricts = false;
      });
  }

  getSubCategories() {
    this.utilsService.getBlogSubCategories()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.subCategories = res as any;
      });
  }
}
