import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, Host, Inject, OnInit } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';

import { AuthService } from 'src/app/services/auth.service';
import { BrandsService } from '../../../services/brands.service';
import { FileUploader } from 'ng2-file-upload';
import { HttpErrorResponse } from '@angular/common/http';
import { StoresService } from '../../../services/stores.service';
import { Title } from '@angular/platform-browser';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-brand-info',
  templateUrl: './brand-info.component.html',
  styleUrls: ['./brand-info.component.scss']
})

export class BrandInfoComponent implements OnInit {

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private brandsService: BrandsService,
    private storesService: StoresService,
    private route: ActivatedRoute,
    private auth: AuthService
  ) { }

  permissions: any = {};
  siteUrl = globals.site_base_url;
  model_edit: any = {};
  countries: any[];
  districts: any[];
  cities: any[];
  plans: any[];
  brandId;
  brand_name;
  error = '';
  loading = false;
  hidden = false;

  edited: Boolean;

  uploaderLogo: FileUploader;
  uploaderFeatured: FileUploader;

  phoneCode;

  imgDate: any;
  testepassword;

  stores;
  selectedStores = [];
  selectedStoreNames = [];
  selectedStoresBackup = [];
  selectedStoreNamesBackup = [];
  loadingStores;
  storesToAdd = [];
  storesToRemove = [];
  storeNamesToAdd = [];
  storeNamesToRemove = [];

  insertName: any = {};

  selectedPlanSubscribable: boolean = false;
  selectedNextPlanSubscribable: boolean = false;

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('BrandsCreate');
    this.permissions.edit = this.auth.hasPermissions('BrandsEdit');
    this.permissions.delete = this.auth.hasPermissions('BrandsDelete');
    this.permissions.read = this.auth.hasPermissions('BrandsRead');
    this.edited = false;
    this.loadingStores = true;
    if (this.permissions.read) {
      this.defineUploaderLogo();
      this.defineUploaderFeatured();
      this.getUrlParams();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.brandId = params['brandId'];
      this.initDropdowns();
      this.getBrandInfo();
    });
  }

  getStores() {
    this.storesService.getAllStoresForBrands()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.stores = res;
        this.utilsService.sortArrayalphabetically(this.stores, 'name');
        this.loadingStores = false;
      });
  }

  defineUploaderLogo() {
    this.uploaderLogo = new FileUploader({
      url: globals.base_api_path + 'files/upload/brand/logo',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderLogo.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const url = JSON.parse(response).url;
      this.brandsService.updateBrandLogo(this.brandId, url)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.model_edit.logo_url = url;
            this.imgDate = Date.now();
            this.utilsService.sendNotification('success', 'Logo atualizado');
          }
        });
      this.defineUploaderLogo();
    };
    this.uploaderLogo._onErrorItem = (err) => {
      this.uploaderLogo.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderLogo();
    };
  }

  defineUploaderFeatured() {
    this.uploaderFeatured = new FileUploader({
      url: globals.base_api_path + 'files/upload/brand/featured',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderFeatured.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const url = JSON.parse(response).url;
      this.brandsService.updateBrandFeaturedImg(this.brandId, url)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.model_edit.featured_img_url = url;
            this.imgDate = Date.now();
            this.utilsService.sendNotification('success', 'Imagem de destaque atualizada');
          }
        });
      this.defineUploaderFeatured();
    };
    this.uploaderFeatured._onErrorItem = (err) => {
      this.uploaderFeatured.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderFeatured();
    };
  }

  onUploadLogo() {
    this.uploaderLogo.queue.forEach(img => {
      img._onBuildForm = (form) => {
        form.append('brandName', this.utilsService.getUrlFromName(this.model_edit.name));
        form.append('brandId', this.model_edit.id);
      };
      img.upload();
    });
    this.uploaderLogo.uploadAll();
  }

  onUploadFeatured() {
    this.uploaderFeatured.queue.forEach(img => {
      img._onBuildForm = (form) => {
        form.append('brandName', this.utilsService.getUrlFromName(this.model_edit.name));
        form.append('brandId', this.model_edit.id);
      };
      img.upload();
    });
    this.uploaderFeatured.uploadAll();
  }

  cancelInfoToggle() {
    this.getUrlParams();
  }

  getBrandInfo() {
    this.brandsService.getBrandInfoByBrandId(this.brandId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.model_edit = res as any;
        if (this.model_edit.paid_plan_limit !== undefined && this.model_edit.paid_plan_limit !== null) {
          this.model_edit.paid_plan_limit = this.utilsService.convertDateStringToDate(this.model_edit.paid_plan_limit);
        }
        this.isSelectedPlanSubscribable();
        this.getStoresSellBrand();
        this.updatePhoneCode();
        this.getStores();
      });
  }

  initDropdowns() {
    this.utilsService.getCountries()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.countries = data as any;
        this.updatePhoneCode();
      });
    this.utilsService.getDistricts()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.districts = data as any;
      });
    this.utilsService.getCities()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.cities = data as any;
      });
    this.utilsService.getBrandPlansInfo()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.plans = data as any;
        console.log(this.plans);
      });
  }

  editData() {
    if (
      this.model_edit.address == undefined ||
      this.model_edit.address.trim().length === 0
    ) {
      this.model_edit.address = '';
    }
    if (
      this.model_edit.postal == undefined ||
      this.model_edit.postal.trim().length === 0
    ) {
      this.model_edit.postal = '';
    }
    if (
      this.model_edit.brand_presentation == undefined ||
      this.model_edit.brand_presentation.trim().length === 0
    ) {
      this.model_edit.brand_presentation = '';
    }
    this.loading = true;
    this.model_edit.storesToAdd = this.storesToAdd;
    this.model_edit.storesToRemove = this.storesToRemove;
    this.model_edit.storeNamesToAdd = this.storeNamesToAdd;
    this.model_edit.storeNamesToRemove = this.storeNamesToRemove;
    this.brandsService
      .updateBrand(this.brandId, this.model_edit)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        this.loading = false;
        if (aux.success) {
          this.edited = true;
          this.storesToAdd = [];
          this.storesToRemove = [];
          this.storeNamesToAdd = [];
          this.storeNamesToRemove = [];
          if (aux.errStores) {
            this.getStoresSellBrand();
            this.utilsService.sendConfirmation('warning', 'Erro ao atualizar as lojas que vendem a marca');
          } else {
            this.utilsService.sendNotification('success', 'Marca Atualizada');
          }
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  isPTSelected() {
    if (this.countries && this.model_edit.country) {
      return this.model_edit.country == globals.portugalCountryName;
    } else {
      return false;
    }
  }

  searchPostal(postal) {
    if (this.model_edit.postal.length == 8 && this.isPTSelected()) {
      const splited_postal = this.model_edit.postal.split('-', 2);
      if (splited_postal[0].length === 4 && splited_postal[1].length === 3) {
        this.utilsService
          .getPostalInfo(splited_postal[0], splited_postal[1])
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            this.model_edit.district = this.districts.find(dist => dist.id === aux.district_id).name;
            this.model_edit.city = this.cities.find(city => city.id === aux.city_id).name;
            this.model_edit.locality = aux.locality;
          });
      }
    }
  }

  updatePhoneCode() {
    this.phoneCode = this.countries.find(country => country.countryName === this.model_edit.country).phoneCode;
  }

  getStoresSellBrand() {
    this.storesService.getStoresThatSellBrand(this.brandId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        this.selectedStores = aux.map(store => store.id);
        console.log(this.selectedStores);
        this.selectedStoresBackup = this.selectedStores;
      });
    this.brandsService.getStoreNamesThatSellBrand(this.brandId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.selectedStoreNames = res as any;
        this.selectedStoreNamesBackup = this.selectedStoreNames;
      });
  }

  addStore(event) {
    const id = event.id;
    const index = this.storesToRemove.indexOf(id);
    if (index > -1) {
      this.storesToRemove.splice(index, 1);
    } else {
      this.storesToAdd.push(id);
    }
  }

  removeStore(event) {
    const id = event.value.id;
    const index = this.storesToAdd.indexOf(id);
    if (index > -1) {
      this.storesToAdd.splice(index, 1);
    } else {
      this.storesToRemove.push(id);
    }
  }

  addStoreName(event) {
    const index = this.storeNamesToRemove.findIndex(store => store.name == event.name && store.locality == event.locality);
    if (index > -1) {
      this.storeNamesToRemove.splice(index, 1);
    } else {
      this.storeNamesToAdd.push(event);
    }
  }

  removeStoreName(event) {
    const index = this.storeNamesToAdd.findIndex(store => store.name == event.value.name && store.locality == event.value.locality);
    if (index > -1) {
      this.storeNamesToAdd.splice(index, 1);
    } else {
      this.storeNamesToRemove.push(event.value);
    }
  }

  clearStores() {
    this.storesToRemove = this.selectedStoresBackup;
  }

  clearStoreNames() {
    this.storesToRemove = this.selectedStoresBackup;
  }

  enterStoreName() {
    if (this.insertName.name && this.insertName.name.trim() !== '' && this.insertName.locality && this.insertName.locality.trim() !== '') {
      if (!this.alreadyInserted(this.insertName)) {
        this.selectedStoreNames = [...this.selectedStoreNames, ...[{ name: this.insertName.name, locality: this.insertName.locality }]];
        this.addStoreName({ name: this.insertName.name, locality: this.insertName.locality });
        this.insertName = {};
      } else {
        this.utilsService.sendConfirmation('warning', 'Já adicionou esta loja');
      }
    } else {
      this.utilsService.sendConfirmation('warning', 'Tem de preencher o nome e a localidade da loja a adicionar');
    }
  }

  // verifica se o nome da loja que estamos a inserir já existe
  alreadyInserted(addStore) {
    return this.selectedStoreNames.findIndex(store => store.name === addStore.name && store.locality === addStore.locality) !== -1;
  }

  deleteBrand() {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja elimiar a marca?', 'Este processo é irreversível').then(confirm => {
      if (confirm) {
        //this.spinner.show();
        this.brandsService.deleteBrand(this.brandId)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            //this.spinner.hide();
            this.utilsService.sendNotification('success', 'Marca eliminada');
            this.goBack();
          });
      }
    });
  }

  goBack() {
    const aux = this.router.url.split('/');
    aux.length -= 2;
    const backLink = aux.join('/');
    this.router.navigate([backLink]);
  }

  isSelectedPlanSubscribable() {
    this.selectedPlanSubscribable = this.plans.find(plan => plan.id == this.model_edit.brand_plan_id).subscribable;
    if (!this.selectedPlanSubscribable) {
      this.model_edit.paid_plan_limit = null;
    }
  }

  isSelectedNextPlanSubscribable() {
    this.selectedNextPlanSubscribable = this.plans.find(plan => plan.id == this.model_edit.next_brand_plan_id).subscribable;
    if (!this.selectedNextPlanSubscribable) {
      this.model_edit.next_paid_plan_limit = null;
    }
  }
}
