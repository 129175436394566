import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';
import { FormControl, FormGroup } from '@angular/forms';
import { CoursesService } from '../../../services/courses.service';
import { AuthService } from 'src/app/services/auth.service';
import { SweepstakesService } from '../../../services/sweepstakes.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';


@Component({
  selector: 'app-sweepstakes-details',
  templateUrl: './sweepstakes-details.component.html',
  styleUrls: ['./sweepstakes-details.component.scss']
})
export class SweepstakesDetailsComponent implements OnInit {

  siteUrl = globals.site_base_url;
  uploaderFeatured: FileUploader;
  model_edit: any = {};
  model_prize: any = {};
  featured_img: any = {};
  imgDate: any;
  sweepstake: any = {};
  allFields: boolean;
  sweepstakeId;
  selectedSponsors = [];
  selectedSponsorsBackup = [];
  loadingSponsors;
  sponsorsToAdd = [];
  sponsorsToRemove = [];
  sponsors = [];
  sweepstakePrizes: any = {};
  prizes = [];
  prizesToAdd = [];
  prizesToRemove = [];
  showPrizes = [];
  addPrizeBool: boolean;
  participants: any[] = [];
  permissions: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sweepstakesService: SweepstakesService,
    private utilsService: UtilsService,
    private spinner: NgxSpinnerService,
    public sanitizer: DomSanitizer,
    private auth: AuthService,
    private coursesService: CoursesService) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('SweepstakesCreate');
    this.permissions.edit = this.auth.hasPermissions('SweepstakesEdit');
    this.permissions.delete = this.auth.hasPermissions('SweepstakesDelete');
    this.permissions.read = this.auth.hasPermissions('SweepstakesRead');
    if (this.permissions.read) {
      this.defineUploaderFeatured();
      this.getUrlParams();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.sweepstakeId = params.sweepstakeId;
      this.getAllSponsors();
      this.getSweepstakeById();
    });
  }

  getAllSponsors() {
    this.coursesService.getAllSponsors()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        if (res) {
          this.sponsors = res as any;
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  getSweepstakeById() {
    this.sweepstakesService.getSweepstakeById(this.sweepstakeId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        if (res) {
          this.model_edit = res as any;
          this.model_edit.init_date = this.utilsService.convertDateStringToDate(this.model_edit.init_date);
          this.model_edit.end_date = this.utilsService.convertDateStringToDate(this.model_edit.end_date);
          this.sweepstake = this.model_edit;
          this.getSweepstakesPrizes();
          this.getSweepstakeSponsors();
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  getSweepstakeSponsors() {
    this.sweepstakesService.getSweepstakeSponsors(this.sweepstakeId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        this.selectedSponsors = aux.map(sponsor => sponsor.sponsor_id);
        this.selectedSponsorsBackup = this.selectedSponsors;
      });
  }

  getSweepstakesPrizes() {
    this.sweepstakesService.getSweepstakePrizes(this.sweepstakeId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.prizes = res as any;
        this.showPrizes = [...this.prizes];
        console.log(this.prizes);
      });
  }

  defineUploaderFeatured() {
    this.uploaderFeatured = new FileUploader({
      url: globals.base_api_path + 'files/upload/sweepstake/featured',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderFeatured.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.model_edit.id = JSON.parse(response).sweepstake_id;
      this.model_edit.featured_img = JSON.parse(response).url;
      this.imgDate = Date.now();
      this.sweepstakesService.updateSweepstake(this.sweepstakeId, this.model_edit)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Sorteio atualizado (imagem)');
          } else if (!aux.success) {
            this.utilsService.sendSystemErrorAlert();
          }
        });
      this.defineUploaderFeatured();
    };
    this.uploaderFeatured._onErrorItem = (err) => {
      //console.log(err);
      this.uploaderFeatured.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderFeatured();
    };
  }

  uploadFeaturedImage() {
    this.uploaderFeatured.queue.forEach((img, index) => {
      img._onBuildForm = (form) => {
        form.append('id', this.sweepstakeId);
      };
      img.upload();
    });
  }

  showFeaturedImage() {
    if (this.uploaderFeatured.queue.length > 1) {
      this.uploaderFeatured.queue.splice(0, 1);
    }
    this.uploaderFeatured.queue.forEach(foto => {
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      this.featured_img.url = url;
      this.featured_img.foto = foto;
    });
  }

  cancelEditSweepstake() {
    this.model_edit = this.sweepstake;
  }

  updateSweepstake() {
    this.verifyModel();
    this.model_edit.sponsorsToAdd = this.sponsorsToAdd;
    this.model_edit.sponsorsToRemove = this.sponsorsToRemove;
    if (this.allFields == false) {
      this.utilsService.sendConfirmation('warning', 'Necessita de Preencher todos os campos em branco');
      this.allFields = true;
    } else {
      this.sweepstakesService.updateSweepstake(this.sweepstakeId, this.model_edit)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Curso atualizado');
            this.uploadFeaturedImage();
            this.updateSweepstakePrizes();
            this.goBack();
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
        });
    }
  }

  deleteSweepstake() {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja elimiar o sorteio?', 'Este processo é irreversível').then(confirm => {
      if (confirm) {
        //this.spinner.show();
        this.sweepstakesService.deleteSweepstake(this.sweepstakeId)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            //this.spinner.hide();
            this.utilsService.sendNotification('success', 'Sorteio eliminado');
            this.goBack();
          });
      }
    });
  }

  addSponsor(event) {
    const id = event.id;
    const index = this.sponsorsToRemove.indexOf(id);
    if (index > -1) {
      this.sponsorsToRemove.splice(index, 1);
    } else {
      this.sponsorsToAdd.push(id);
    }
  }

  removeSponsor(event) {
    const id = event.value.id;
    const index = this.sponsorsToAdd.indexOf(id);
    if (index > -1) {
      this.sponsorsToAdd.splice(index, 1);
    } else {
      this.sponsorsToRemove.push(id);
    }
  }

  clearSponsors() {
    this.sponsorsToRemove = this.selectedSponsorsBackup;
  }

  goBack() {
    const aux = this.router.url.split('/');
    aux.length -= 2;
    const backLink = aux.join('/');
    this.router.navigate([backLink]);
  }

  verifyModel() {
    if (this.model_edit.title == undefined || this.model_edit.title.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_edit.init_date == undefined || this.model_edit.init_date.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_edit.end_date == undefined || this.model_edit.end_date.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_edit.conditions == undefined || this.model_edit.conditions.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_edit.type == undefined || this.model_edit.type.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_edit.featured_img == undefined || this.model_edit.featured_img.trim().length === 0) {
      this.allFields = false;
    }
  }

  addPrize() {
    this.prizesToAdd.push(this.model_prize);
    this.showPrizes.push(this.model_prize);
    this.model_prize = {};
  }

  removePrize(prize) {
    /*const index = this.Prizes.indexOf(Prize);
    this.Prizes.splice(index, 1);*/
    const index2 = this.showPrizes.indexOf(prize);
    this.showPrizes.splice(index2, 1);
    this.prizesToRemove.push(prize);
  }

  addPrizeToogle() {
    this.addPrizeBool = !this.addPrizeBool;
  }

  cancelAddPrizeToogle() {
    this.addPrizeToogle();
    this.model_prize = {};
  }

  updateSweepstakePrizes() {
    this.sweepstakePrizes.prizes = this.prizes;
    this.sweepstakePrizes.prizesToAdd = this.prizesToAdd;
    this.sweepstakePrizes.prizesToRemove = this.prizesToRemove;
    this.sweepstakesService.updateSweepstakePrizes(this.sweepstakeId, this.sweepstakePrizes)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          this.utilsService.sendNotification('success', 'Sorteio atualizado');
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  getParticipantsBySweepstakeId(prize) {
    this.sweepstakesService.getParticipantsBySweepstakeId(this.sweepstakeId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        if (res) {
          this.participants = res as any;
          this.getRandomParticipant(prize);
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  getWinner(prize) {
    if (this.participants.length === 0) {
      this.getParticipantsBySweepstakeId(prize);
    } else {
      this.getRandomParticipant(prize);
    }
  }

  getRandomParticipant(prize) {
    const randomMonth = this.participants[Math.floor(Math.random() * this.participants.length)];
    const index2 = this.participants.indexOf(randomMonth);
    prize.winner_name = randomMonth.name;
    prize.winner_id = randomMonth.id;
    this.participants.splice(index2, 1);
  }
}
