import * as globals from '../../assets/globals';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ObservableLike } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  constructor(private http: HttpClient) { }

  apiRoute = globals.base_api_path + 'pages/';

  getAllPages() {
    return this.http.get(this.apiRoute);
  }

  getPageInfo(slug) {
    return this.http.get(this.apiRoute + '/' + slug);
  }

  changeIsVisible(pageId, model): Observable<any> {
    return this.http.put(this.apiRoute + pageId + '/isvisible', model);
  }

  updatePage(id, model): Observable<any> {
    return this.http.put(this.apiRoute + 'edit/page/' + id, model);
  }
}
