import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, TemplateRef } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { BannersService } from 'src/app/services/banners.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploader } from 'ng2-file-upload';
import { UsersService } from 'src/app/services/users.service';
import { UtilsService } from 'src/app/services/utils.service';
import { PagesService } from 'src/app/services/pages.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-banner-details',
  templateUrl: './banner-details.component.html',
  styleUrls: ['./banner-details.component.scss']
})

export class BannerDetailsComponent implements OnInit {

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private bannersService: BannersService,
    private modalService: BsModalService,
    public sanitizer: DomSanitizer,
    private usersService: UsersService,
    private pagesService: PagesService,
  ) { }

  siteUrl = globals.site_base_url;

  uploader: FileUploader;

  modalRef: BsModalRef;
  dtOptions: any = {};
  permissions: any = {};

  bannerId;
  bannerInfo;
  imageEdit: any = {};
  imageAdd: any = {};

  categories: any = [];
  activities: any = [];
  activityTypes: any = [];
  pages: any = [];
  bannerTypes: any = [];
  users: any = [];

  ngOnInit() {
    this.dtOptions = {
      paging: false,
      info: false
    };
    this.permissions.create = this.auth.hasPermissions('BannersCreate');
    this.permissions.edit = this.auth.hasPermissions('BannersEdit');
    this.permissions.delete = this.auth.hasPermissions('BannersDelete');
    this.permissions.read = this.auth.hasPermissions('BannersRead');
    if (this.permissions.read) {
      this.getUrlParams();
      this.initDropdowns();
      this.defineUploader();
    }
  }

  initDropdowns() {
    forkJoin(
      this.utilsService.getActivities(),
      this.utilsService.getActivitiyTypes(),
      this.utilsService.getCategories(),
      this.bannersService.getAllBannerTypes(),
      this.usersService.getAllCompanies(),
      this.pagesService.getAllPages(),
    ).subscribe(data => {
      this.activities = data[0];
      this.activityTypes = data[1];
      this.categories = data[2];
      this.bannerTypes = data[3];
      this.users = data[4];
      this.pages = data[5];
      this.utilsService.sortArrayalphabetically(this.activities, 'name');
      this.utilsService.sortArrayalphabetically(this.activityTypes, 'name');
      this.utilsService.sortArrayalphabetically(this.categories, 'name');
      this.utilsService.sortArrayalphabetically(this.bannerTypes, 'name');
      this.utilsService.sortArrayalphabetically(this.users, 'company_name');
    });
  }

  defineUploader() {
    this.uploader = new FileUploader({
      url: globals.base_api_path + 'files/upload/banner-img',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.imageAdd.img_url = JSON.parse(response).url;
      this.addImageToBanner();
    };
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.bannerId = params.id;
      this.getBannerInfo();
    });
  }

  getBannerInfo() {
    this.bannersService.getBannerInfo(this.bannerId)
      .subscribe(res => {
        this.bannerInfo = res;
        console.log(this.bannerInfo);
      });
  }

  openEditImageDialog(modal: TemplateRef<any>, image) {
    this.imageEdit = { ...image };
    if (this.imageEdit.start_date !== null) {
      this.imageEdit.start_date = this.utilsService.convertDateStringToDate(this.imageEdit.start_date);
    }
    if (this.imageEdit.end_date !== null) {
      this.imageEdit.end_date = this.utilsService.convertDateStringToDate(this.imageEdit.end_date);
    }
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  closeModal() {
    this.modalRef.hide();
    this.imageEdit = {};
  }

  editImage() {
    this.bannersService.updateImageInfo(this.imageEdit.id, this.imageEdit)
      .subscribe(res => {
        if (this.imageEdit.user_paid !== undefined && this.imageEdit.user_paid !== null && this.imageEdit.user_paid !== '') {
          this.imageEdit.user_paid_company = this.users.find(user => user.id === this.imageEdit.user_paid).company_name;
        } else {
          this.imageEdit.user_paid_company = '';
        }
        const index = this.bannerInfo.images.findIndex(image => image.id === this.imageEdit.id);
        if (index !== -1) {
          this.bannerInfo.images[index] = { ...this.imageEdit };
        }
        this.closeModal();
        this.utilsService.sendNotification('success', 'Informações alteradas');
      });
  }

  deleteImage(image) {
    this.utilsService.sendConfirmationQuestion(image.title, 'Tem a certeza que deseja apagar esta imagem do banner?').then(confirm => {
      if (confirm) {
        this.bannersService.deleteImageInfo(image.id)
          .subscribe(res => {
            const index = this.bannerInfo.images.findIndex(obj => obj.id === image.id);
            if (index !== -1) {
              this.bannerInfo.images.splice(index, 1);
            }
            this.utilsService.sendNotification('success', 'Imagem Apagada');
          });
      }
    });
  }

  setEndDateYesterday(image) {
    this.utilsService.sendConfirmationQuestion(image.title, 'Deseja alterar a data de fim desta imagem para o dia de ontem?').then(confirm => {
      if (confirm) {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        this.editEndDate(image, date);
      }
    });
  }

  setEndDateToday(image) {
    this.utilsService.sendConfirmationQuestion(image.title, 'Deseja alterar a data de fim desta imagem para o dia de hoje?').then(confirm => {
      if (confirm) {
        const date = new Date();
        this.editEndDate(image, date);
      }
    });
  }

  editEndDate(image, date) {
    image.end_date = this.utilsService.convertDateStringToDate(date.toISOString());
    this.bannersService.updateImageInfo(image.id, image)
      .subscribe(res => {
        this.utilsService.sendNotification('success', 'Data final do banner alterada');
      });
  }

  openAddImageDialog(modal: TemplateRef<any>) {
    this.imageAdd = {};
    if (this.imageEdit.start_date !== null) {
      this.imageAdd.start_date = this.utilsService.convertDateStringToDate(new Date().toISOString());
    }
    const url = window.URL
      ? window.URL.createObjectURL(this.uploader.queue[0]._file)
      : (window as any).webkitURL.createObjectURL(this.uploader.queue[0]._file);
    this.imageAdd.imagePreviewUrl = url;
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  uploadImageToBanner() {
    this.uploader.uploadAll();
  }

  addImageToBanner() {
    this.bannersService.createImageInfo(this.bannerInfo.info.id, this.imageAdd)
      .subscribe(res => {
        this.getBannerInfo();
        this.closeModal();
        this.utilsService.sendNotification('success', 'Imagem Adicionada');
      });
  }

  updateBanner() {
    this.bannersService.updateBanner(this.bannerInfo.info.id, this.bannerInfo.info)
      .subscribe(res => {
        this.utilsService.sendNotification('success', 'Banner Atualizado');
      });
  }

}
