import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as globals from '../../assets/globals';

@Injectable({
  providedIn: 'root'
})
export class PublicService {

  constructor(private http: HttpClient) { }

  apiRoute = globals.base_api_path + 'public/';

  getAllPublicUsers(): Observable<any> {
    return this.http.get(this.apiRoute);
  }

  getPublicWeddingByWeddingId(weddingId): Observable<any> {
    return this.http.get(this.apiRoute + 'wedding/' + weddingId);
  }

  getPublicWeddingByPublicId(publicId): Observable<any> {
    return this.http.get(this.apiRoute + 'public/' + publicId);
  }

  changeCartaoVantagens(publicId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'change/cartaovantagens/' + publicId, model);
  }

  changeEngaged(publicId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'change/engaged/' + publicId, model);
  }

  getInterestsPublic(publicId): Observable<any> {
    return this.http.get(this.apiRoute + publicId + '/interests');
  }

  addInterestsPublic(publicId, interests): Observable<any> {
    return this.http.post(this.apiRoute + publicId + '/interests', { interests: interests });
  }

  removeInterestsPublic(publicId, interests): Observable<any> {
    return this.http.put(this.apiRoute + publicId + '/interests/delete', { interests: interests });
  }

  getPublicData(userId) {
    return this.http.get(this.apiRoute + 'user/' + userId);
  }

  putPublicData(user_id, newUser, oldUser) {
    return this.http.put(this.apiRoute + 'update/user/' + user_id, { newUser: newUser, oldUser: oldUser });
  }

  deleteAccount(userId): Observable<any> {
    return this.http.put(this.apiRoute + '/delete/' + userId, null);
  }

  updatePublicPlans() {
    return this.http.put(this.apiRoute + 'update-publicPlan', null);
  }
}
