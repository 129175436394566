import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, TemplateRef } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { BlogService } from '../../../services/blog.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-blog-categories',
  templateUrl: './blog-categories.component.html',
  styleUrls: ['./blog-categories.component.scss']
})

export class BlogCategoriesComponent implements OnInit {
  nr_items_page = globals.nr_items_page;
  p; // paginação;
  categories = [];
  search: any = {};
  createCategoryBool: Boolean;
  createSubCategoryBool: Boolean;
  model: any = {};
  model_subCategory: any = {};
  error = '';
  cat;
  loading = false;
  modalRef: BsModalRef;
  categoryEdit: any = {};
  subCategoryEdit: any = {};
  permissions: any = {};

  constructor(private route: ActivatedRoute,
    private router: Router,
    private blogService: BlogService,
    private utilsService: UtilsService,
    private modalService: BsModalService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('BlogCreate');
    this.permissions.edit = this.auth.hasPermissions('BlogEdit');
    this.permissions.delete = this.auth.hasPermissions('BlogDelete');
    this.permissions.read = this.auth.hasPermissions('BlogRead');
    this.createCategoryBool = false;
    this.createSubCategoryBool = false;
    if (this.permissions.read) {
      this.initModels();
      this.getCategoriesWithSubCategories();
    }
  }

  getCategoriesWithSubCategories() {
    this.blogService.getBlogCategoriesWithSubCategories()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.categories = res as any;
      });
  }

  createCategory() {
    this.blogService.createCategory(this.model)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.createCategoryToogle();
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          this.utilsService.sendNotification('success', 'Categoria Criada');
          this.getCategoriesWithSubCategories();
          this.initModels();
          this.createCategoryToogle();
        } else {
          this.utilsService.sendSystemErrorAlert();
          this.initModels();
          this.createCategoryToogle();
        }
      });
  }

  createSubCategory(categoryId) {
    this.blogService.createSubCategory(this.model_subCategory, categoryId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.createCategoryToogle();
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          this.utilsService.sendNotification('success', 'SubCategoria Criada');
          this.getCategoriesWithSubCategories();
          this.initModels();
          this.createSubCategoryToogle();
        } else {
          this.utilsService.sendSystemErrorAlert();
          this.initModels();
          this.createSubCategoryToogle();
        }
      });
  }

  initModels() {
    this.model.name = '';
    this.model_subCategory.name = '';
  }

  editCategory() {
    if (this.categoryEdit.name !== undefined && this.categoryEdit.name.trim().length > 0) {
      this.blogService.updateCategory(this.categoryEdit.id, this.categoryEdit)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const index = this.categories.findIndex(category => category.id === this.categoryEdit.id);
          if (index !== -1) {
            this.categories[index] = { ...this.categoryEdit };
          }
          console.log(index);
          this.closeModal();
          this.utilsService.sendNotification('success', 'Categoria Atualizada');
        });
    } else {
      this.utilsService.sendConfirmation('warning', 'Tem de preecher todos os campos');
    }
  }

  editSubCategory() {
    if (this.subCategoryEdit.name !== undefined && this.subCategoryEdit.name.trim().length > 0 && this.subCategoryEdit.blogcategory_id !== undefined) {
      this.blogService.updateSubCategory(this.subCategoryEdit.id, this.subCategoryEdit)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          this.getCategoriesWithSubCategories();
          this.closeModal();
          this.utilsService.sendNotification('success', 'SubCategoria Atualizada');
        });
    } else {
      this.utilsService.sendConfirmation('warning', 'Tem de preecher todos os campos');
    }
  }

  changeActivated(type, id, modelc) {
    switch (type) {
      case 'subcategory': {
        this.blogService.changeSubCategoryActivated(id, modelc)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (aux.success) {
              if (modelc.activated) {
                this.utilsService.sendNotification('success', 'SubCategoria ativada');
              } else {
                this.utilsService.sendNotification('success', 'SubCategoria desativada');
              }
            } else {
              this.utilsService.sendSystemErrorAlert();
            }
          });
        break;
      }
      case 'category': {
        this.blogService.changeCategoryActivated(id, modelc)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (aux.success) {
              if (modelc.activated) {
                this.utilsService.sendNotification('success', 'Categoria ativada');
              } else {
                this.utilsService.sendNotification('success', 'Categoria desativada');
              }
            } else {
              this.utilsService.sendSystemErrorAlert();
            }
          });
        break;
      }
    }

  }

  createCategoryToogle() {
    this.createCategoryBool = !this.createCategoryBool;
  }

  createSubCategoryToogle(categoryId?) {
    if (categoryId) {
      this.cat = categoryId;
    }
    this.createSubCategoryBool = !this.createSubCategoryBool;
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }

  openEditCategory(modal: TemplateRef<any>, category) {
    this.categoryEdit = { ...category };
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  openEditSubCategory(modal: TemplateRef<any>, subCategory) {
    this.subCategoryEdit = { ...subCategory };
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  closeModal() {
    this.modalRef.hide();
    this.categoryEdit = {};
    this.subCategoryEdit = {};
  }

  deleteSubCategory(subCategoryId) {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja eliminar a Subcategoria?', 'Este processo é irreversível').then(confirm => {
      if (confirm) {
        //this.spinner.show();
        this.blogService.deleteSubCategory(subCategoryId)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            //this.spinner.hide();
            this.utilsService.sendNotification('success', 'SubCategoria eliminada');
            this.getCategoriesWithSubCategories();
          });
      }
    });
  }

  deleteCategory(categoryId) {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja eliminar a Categoria?', 'Este processo é irreversível').then(confirm => {
      if (confirm) {
        //this.spinner.show();
        this.blogService.deleteCategory(categoryId)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            //this.spinner.hide();
            this.utilsService.sendNotification('success', 'Categoria eliminada');
            this.getCategoriesWithSubCategories();
          });
      }
    });
  }
}
