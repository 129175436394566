import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, Host, Inject, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploader } from 'ng2-file-upload';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StoresService } from '../../../services/stores.service';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-create-store',
  templateUrl: './create-store.component.html',
  styleUrls: ['./create-store.component.scss']
})
export class CreateStoreComponent implements OnInit {

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private storesService: StoresService,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private auth: AuthService,
  ) { }


  createStore: Boolean;
  model_create: any = {};
  storeId;

  professionalId;

  countries: any[];
  districts: any[];
  cities: any[];
  activities: any[];
  plans: any[];
  p;
  loading;

  uploaderLogo: FileUploader;
  uploaderFeatured: FileUploader;
  displayImgsUrl: any = {};

  phoneCode;
  imgDate: any;

  selectedPlanSubscribable: boolean = false;

  permissions: any = {};

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('StoresCreate');
    this.loading = false;
    if (this.permissions.create) {
      this.getUrlParams();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.professionalId = params.professionalId;
      this.defineUploaderLogo();
      this.defineUploaderFeatured();
      this.initDropdowns();
      this.initModel();
    });
  }

  initModel() {
    this.model_create = {};
    this.model_create.cartao_vantagens_oferta = globals.defaults.cartao_vantagens_oferta;
  }

  newStore() {
    this.modelUndefinedToNull();
    if (this.model_create.cartao_vantagens_oferta.trim() == '') {
      this.utilsService.sendConfirmation('warning', 'Tem de Inserir uma oferta para o cartão vantagens');
    }
    if (this.uploaderLogo.queue.length === 0 && this.uploaderFeatured.queue.length === 0) {
      this.utilsService.sendConfirmation('warning', 'Tem de Inserir um Logo e uma imagem de destaque');
    } else if (this.uploaderLogo.queue.length === 0) {
      this.utilsService.sendConfirmation('warning', 'Tem de Inserir um Logo');
    } else if (this.uploaderFeatured.queue.length === 0) {
      this.utilsService.sendConfirmation('warning', 'Tem de Inserir uma Imagem de destaque');
    } else {
      this.loading = true;
      this.model_create.professional_id = this.professionalId;
      this.storesService.createStore(this.model_create)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.storeId = aux.id;
            this.uploadImages(this.storeId);
            this.utilsService.sendNotification('success', 'Loja criada', '');
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
          this.loading = false;
        });
    }
  }

  initDropdowns() {
    this.utilsService.getCountries()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.countries = data as any;
        this.model_create.country = globals.portugalCountryName;
        this.updatePhoneCode();
      });
    this.utilsService.getDistricts()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.districts = data as any;
      });
    this.utilsService.getCities()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.cities = data as any;
      });
    this.utilsService.getActivities()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.activities = data as any;
      });
    this.utilsService.getStorePlansInfo()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(data => {
        this.plans = data as any;
      });
  }

  isPTSelected() {
    if (this.countries && this.model_create.country) {
      return this.model_create.country == globals.portugalCountryName;
    } else {
      return false;
    }
  }

  searchPostal(postal) {
    if (this.model_create.postal.length == 8 && this.isPTSelected()) {
      let splited_postal = this.model_create.postal.split('-', 2);
      if (splited_postal[0].length === 4 && splited_postal[1].length === 3) {
        this.utilsService
          .getPostalInfo(splited_postal[0], splited_postal[1])
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            this.model_create.district = this.districts.find(dist => dist.id === aux.district_id).name;
            this.model_create.city = this.cities.find(city => city.id === aux.city_id).name;
            this.model_create.locality = aux.locality;
          });
      }
    }
  }

  modelUndefinedToNull() {
    if (this.model_create.name === undefined) {
      this.model_create.name = '';
    }
    if (this.model_create.email === undefined) {
      this.model_create.email = '';
    }
    if (this.model_create.phone_nr === undefined) {
      this.model_create.phone_nr = '';
    }
    if (this.model_create.website === undefined) {
      this.model_create.website = '';
    }
    if (this.model_create.activity === undefined) {
      this.model_create.activity = null;
    }
    if (!this.model_create.cartao_vantagens) {
      this.model_create.cartao_vantagens = false;
    }
    if (!this.model_create.cartao_vantagens_oferta) {
      this.model_create.cartao_vantagens_oferta = '';
    }
    if (!this.model_create.isOnline) {
      this.model_create.isOnline = false;
    }
    if (!this.model_create.hide_address) {
      this.model_create.hide_address = false;
    }
    if (this.model_create.isOnline) {
      this.model_create.address = '';
      this.model_create.postal = '';
      this.model_create.district = '';
      this.model_create.locality = '';
      this.model_create.city = '';
      this.model_create.country = '';
    } else {
      if (this.model_create.address === undefined || this.model_create.address.trim().length === 0) {
        this.model_create.address = '';
      }
      if (this.model_create.postal === undefined || this.model_create.postal.trim().length === 0) {
        this.model_create.postal = '';
      }
      if (this.model_create.district === undefined) {
        this.model_create.district = '';
      }
      if (this.model_create.locality === undefined) {
        this.model_create.locality = '';
      }
      if (this.model_create.city === undefined) {
        this.model_create.city = '';
      }
      if (this.model_create.country === undefined) {
        this.model_create.country = '';
      }
    }
    if (this.model_create.store_presentation === undefined) {
      this.model_create.store_presentation = '';
    }
  }

  defineUploaderLogo() {
    this.uploaderLogo = new FileUploader({
      url: globals.base_api_path + 'files/upload/store/logo',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderLogo.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const url = JSON.parse(response).url;
      this.storesService.updateStoreLogo(this.storeId, url)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          if ((res as any).success) {
            this.model_create.logo_url = url;
            this.imgDate = Date.now();
            this.utilsService.sendNotification('success', 'Logo atualizado');
          }
        });
      this.defineUploaderLogo();
    };
    this.uploaderLogo._onErrorItem = (err) => {
      this.uploaderLogo.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderLogo();
    };
  }

  defineUploaderFeatured() {
    this.uploaderFeatured = new FileUploader({
      url: globals.base_api_path + 'files/upload/store/featured',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderFeatured.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const url = JSON.parse(response).url;
      this.storesService.updateStoreFeaturedImg(this.storeId, url)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          if ((res as any).success) {
            this.model_create.featured_img_url = url;
            this.imgDate = Date.now();
            this.utilsService.sendNotification('success', 'Imagem de destaque atualizada');
          }
        });
      this.defineUploaderFeatured();
    };
    this.uploaderFeatured._onErrorItem = (err) => {
      this.uploaderFeatured.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderFeatured();
    };
  }

  showLogo() {
    this.uploaderLogo.queue.forEach(foto => {
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      this.displayImgsUrl.logo = url;
    });
  }

  showFeatured() {
    this.uploaderFeatured.queue.forEach(foto => {
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      this.displayImgsUrl.featured = url;
    });
  }

  uploadImages(storeId) {
    this.onUploadLogo(storeId);
    this.onUploadFeatured(storeId);
  }

  onUploadLogo(storeId) {
    const index = this.activities.findIndex(activity => activity.id == this.model_create.activity_id);
    this.uploaderLogo.queue.forEach(img => {
      img._onBuildForm = (form) => {
        form.append('storeName', this.utilsService.getUrlFromName(this.model_create.name));
        form.append('storeId', storeId);
        form.append('activity', this.utilsService.getUrlFromName(this.activities[index].name));
      };
      img.upload();
    });
    this.uploaderLogo.uploadAll();
  }

  onUploadFeatured(storeId) {
    const index = this.activities.findIndex(activity => activity.id == this.model_create.activity_id);
    this.uploaderFeatured.queue.forEach(img => {
      img._onBuildForm = (form) => {
        form.append('storeName', this.utilsService.getUrlFromName(this.model_create.name));
        form.append('storeId', storeId);
        form.append('activity', this.utilsService.getUrlFromName(this.activities[index].name));
      };
      img.upload();
    });
    this.uploaderFeatured.uploadAll();
  }

  updatePhoneCode() {
    this.phoneCode = this.countries.find(country => country.countryName === this.model_create.country).phoneCode;
  }

}
