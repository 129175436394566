import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as globals from '../../../../assets/globals';
import { UtilsService } from '../../../services/utils.service';
import { PublicService } from '../../../services/public.service';
import { UsersService } from '../../../services/users.service';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-users-public',
  templateUrl: './users-public.component.html',
  styleUrls: ['./users-public.component.scss']
})
export class UsersPublicComponent implements OnInit {

  nr_items_page = globals.nr_items_page;
  p; // paginação;
  public;
  search: any = {};
  permissions: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private publicService: PublicService,
    private utilsService: UtilsService,
    private usersService: UsersService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('UsersCreate');
    this.permissions.edit = this.auth.hasPermissions('UsersEdit');
    this.permissions.delete = this.auth.hasPermissions('UsersDelete');
    this.permissions.read = this.auth.hasPermissions('UsersRead');
    if (this.permissions.read) {
      this.getAllPublicUsers();
    }
  }

  getAllPublicUsers() {
    this.publicService.getAllPublicUsers()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.public = res as any;
        console.log(this.public);
      });
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }

  goToCreatePublic(): void {
    this.router.navigate([globals.baseUrls.users + '/create/public']);
  }

  goToPublic(userId, publicId) {
    this.router.navigate([globals.baseUrls.users + '/' + userId + '/public/' + publicId]);
  }

  goToWedding(userId, publicId) {
    this.router.navigate([globals.baseUrls.users + '/' + userId + '/public/' + publicId + '/wedding']);
  }

  changeCheckBoxes(id, user, type) {
    switch (type) {
      case 'card':
        this.publicService.changeCartaoVantagens(id, user)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (aux.success) {
              if (user.cartao_vantagens) {
                this.utilsService.sendNotification('success', 'Público inscrito no Clube Beneficios');
              } else {
                this.utilsService.sendNotification('success', 'Público não inscrito no Clube Beneficios');
              }
            } else {
              this.utilsService.sendSystemErrorAlert();
            }
          });
        break;
      case 'engaged':
        this.publicService.changeEngaged(id, user)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (aux.success) {
              if (user.engaged) {
                this.utilsService.sendNotification('success', 'Público marcado como noivo(a)');
              } else {
                this.utilsService.sendNotification('success', 'Público não marcado como noivo(a)');
              }
            } else {
              this.utilsService.sendSystemErrorAlert();
            }
          });
        break;
      case 'newsletter':
        this.usersService.changeNewsletter(id, user)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (aux.success) {
              if (user.newsletter) {
                this.utilsService.sendNotification('success', 'Utilizador inscrito na Newsletter');
              } else {
                this.utilsService.sendNotification('success', 'Utilizador não inscrito na Newsletter');
              }
            } else {
              this.utilsService.sendSystemErrorAlert();
            }
          });
        break;
    }
  }

  updatePublicPlans() {
    this.publicService.updatePublicPlans()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          this.utilsService.sendNotification('success', 'Planos atualizados');
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }
}
