import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, Host, Inject, OnInit } from '@angular/core';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';

import { AuthService } from 'src/app/services/auth.service';
import { CoursesService } from '../../../services/courses.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';
@Component({
  selector: 'app-course-images',
  templateUrl: './course-images.component.html',
  styleUrls: ['./course-images.component.scss']
})
export class CourseImagesComponent implements OnInit {

  uploader: FileUploader;
  imgs: any[];
  nrImgs;
  siteUrl = globals.site_base_url;
  anexo: any;
  photosPerPage: number = 8;

  p1 = 1; //Pagination
  addPic: Boolean;
  pictures = [];
  limitPictures;
  courseId;
  course;
  selectedFile: File = null;

  permissions: any = {};

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private coursesService: CoursesService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('CoursesCreate');
    this.permissions.edit = this.auth.hasPermissions('CoursesEdit');
    this.permissions.delete = this.auth.hasPermissions('CoursesDelete');
    this.permissions.read = this.auth.hasPermissions('CoursesRead');
    this.nrImgs = 0;
    if (this.permissions.read) {
      this.defineUploader();
      this.getUrlParams();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.courseId = params.courseId;
      this.getCoursePictures();
    });
  }

  defineUploader() {
    this.uploader = new FileUploader({
      url: globals.base_api_path + 'files/upload/course/foto',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.imgs = [];
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.imgs.push(JSON.parse(response));
      this.uploadImgs();
    };
    this.uploader._onErrorItem = (err) => {
      this.uploader.clearQueue();
      this.utilsService.sendSystemErrorAlert();
    };
  }

  onUpload() {
    if (this.uploader.queue.length > 0) {
      this.spinner.show();
    }
    this.uploader.queue.forEach(img => {
      img._onBuildForm = (form) => {
        form.append('courseId', this.courseId);
      };
      this.nrImgs++;
      img.upload();
    });
  }

  uploadImgs() {
    if (this.imgs.length === this.nrImgs) {
      this.coursesService.uploadPictureCoursebyId(this.courseId, this.imgs)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            this.utilsService.sendSystemErrorAlert();
            this.spinner.hide();
            return Observable.throw(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          this.spinner.hide();
          if (aux.success) {
            this.getCoursePictures();
            this.utilsService.sendNotification('success', 'Fotografias adicionadas');
          } else {
            this.utilsService.sendSystemErrorAlert();
          }
          this.resetImages();
        });
    }
  }

  resetImages() {
    this.imgs = [];
    this.nrImgs = 0;
    this.uploader.clearQueue();
  }

  getCoursePictures() {
    this.coursesService.getCourseImagesByCourseId(this.courseId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        console.log(aux);
        this.pictures = aux.photos;
      });
  }

  removePicture(index: number) {
    index = this.photosPerPage * (this.p1 - 1) + index;
    this.utilsService.sendConfirmationQuestion('Tem a certeza que quer apagar a fotografia?').then(confirm => {
      if (confirm) {
        this.coursesService.deletePicturebyId(this.courseId, this.pictures[index].id)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (aux.success) {
              this.pictures.splice(index, 1);
              this.utilsService.sendNotification('success', 'Imagem apagada');
            } else {
              this.utilsService.sendSystemErrorAlert();
            }
          });
      }
    });
  }

}
