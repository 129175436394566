import * as globals from '../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { StoresService } from '../../services/stores.service';
import { UtilsService } from '../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss']
})
export class StoresComponent implements OnInit {

  nr_items_page = globals.nr_items_page;
  search: any = {};
  p; // paginação;
  stores: any[] = [];
  permissions: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private storesService: StoresService,
    private utilsService: UtilsService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('StoresCreate');
    this.permissions.edit = this.auth.hasPermissions('StoresEdit');
    this.permissions.delete = this.auth.hasPermissions('StoresDelete');
    this.permissions.read = this.auth.hasPermissions('StoresRead');
    if (this.permissions.read) {
      this.getAllStores();
    }
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }

  goToStore(storeId, page): void {
    this.router.navigate([globals.baseUrls.stores + '/' + storeId + '/' + page]);
  }

  goToCreateDirectoryStore() {
    this.router.navigate([globals.baseUrls.stores + '/create-directory']);
  }

  getAllStores() {
    this.storesService.getAllStores()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.stores = res as any;
        this.stores.forEach(store => {
          if (store.paid_plan_limit) {
            store.paid_plan_limit = this.utilsService.convertDateStringToDate(store.paid_plan_limit);
          }
        })
        // this.initStoresArray();
      });
  }

  changeHidden(storeId, store) {
    this.storesService.changeHidden(storeId, store)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          if (store.hidden) {
            this.utilsService.sendNotification('success', 'Loja Não visivel');
          } else {
            this.utilsService.sendNotification('success', 'Loja visivel');
          }
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  changeDestinationWedding(storeId, store) {
    this.storesService.changeDestinationWedding(storeId, store)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          if (store.isDestinationWedding) {
            this.utilsService.sendNotification('success', 'Loja de WeddingDestination');
          } else {
            this.utilsService.sendNotification('success', 'Loja não pertencente a WeddingDestination');
          }
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  changeisOnline(storeId, store) {
    this.storesService.changeisOnline(storeId, store)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          if (store.isOnline) {
            this.utilsService.sendNotification('success', 'Loja online');
          } else {
            this.utilsService.sendNotification('success', 'Loja não online');
          }
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  /*updateStoresPlans() {
    this.storesService.updateStoresPlans()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          this.utilsService.sendNotification('success', 'Planos atualizados');
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }*/
}
