import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, Host, Inject, OnInit, TemplateRef } from '@angular/core';

import { AuthService } from '../../../services/auth.service';
import { DestinationWeddingsService } from '../../../services/destination-weddings.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-places-types',
  templateUrl: './places-types.component.html',
  styleUrls: ['./places-types.component.scss']
})
export class PlacesTypesComponent implements OnInit {

  nr_items_page = globals.nr_items_page;
  search: any = {};
  p; // paginação;
  types: any[] = [];
  createTypeBool: boolean;
  model: any = {};
  loading = false;
  model_edit: any = {};
  modalRef: BsModalRef;

  permissions: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private wdService: DestinationWeddingsService,
    private utilsService: UtilsService,
    private modalService: BsModalService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('DestinationWeddingsCreate');
    this.permissions.edit = this.auth.hasPermissions('DestinationWeddingsEdit');
    this.permissions.delete = this.auth.hasPermissions('DestinationWeddingsDelete');
    this.permissions.read = this.auth.hasPermissions('DestinationWeddingsRead');
    if (this.permissions.read) {
      this.getTypes();
    }
  }

  openEditType(modal: TemplateRef<any>, type) {
    this.model_edit = { ...type };
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  closeModal() {
    this.modalRef.hide();
    this.model_edit = {};
  }

  getTypes() {
    this.wdService.getPlacesTypes()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.types = res as any;
        console.log(this.types);
      });
  }

  changeIsVisible(typeId, type) {
    this.wdService.changeIsVisibleType(typeId, type)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          if (type.is_visible) {
            this.utilsService.sendNotification('success', 'Tipo visivel');
          } else {
            this.utilsService.sendNotification('success', 'Tipo Não visivel');
          }
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  createType() {
    this.wdService.createType(this.model)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.createTypeToogle();
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          this.utilsService.sendNotification('success', 'Tipo Criado');
          this.model.name = '';
          this.getTypes();
          this.createTypeToogle();
        } else {
          this.utilsService.sendSystemErrorAlert();
          this.model.name = '';
          this.createTypeToogle();
        }
      });
  }

  editType(typeId) {
    this.loading = true;
    this.wdService.updateWDType(typeId, this.model_edit)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        let aux: { success: Boolean };
        aux = res as any;
        if (aux.success) {
          this.utilsService.sendNotification('success', 'Dados do tipo WD atualizados');
          this.loading = false;
          this.getTypes();
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  deleteType(typeId) {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja elimiar o tipo?', 'Este processo é irreversível').then(confirm => {
      if (confirm) {
        this.wdService.deleteType(typeId)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (aux.success) {
              this.utilsService.sendNotification('success', 'Tipo removido');
              this.getTypes();
            } else {
              this.utilsService.sendSystemErrorAlert();
            }
          });
      }
    });
  }

  createTypeToogle() {
    this.createTypeBool = !this.createTypeBool;
  }

  goToPlaces(typeId) {
    this.router.navigate([globals.baseUrls.destinationWeddings + '/subregions/type/' + typeId]);
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }
}
