import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as globals from '../../assets/globals';

@Injectable({
  providedIn: 'root'
})

export class ProductsService {

  constructor(private http: HttpClient) { }

  apiRoute = globals.base_api_path + 'products/';
  apiRouteFiles = globals.base_api_path + 'ficheiros/';

  updateProduct(productId, model_product) {
    return this.http.put(this.apiRoute + 'update/' + productId, model_product);
  }

  deleteProduct(productId) {
    return this.http.delete(this.apiRoute + 'product/' + productId);
  }

  getProductTestimonials(productId) {
    return this.http.get(this.apiRoute + productId + '/testimonials');
  }

  addProductTestimony(productId, testimonyModel) {
    return this.http.post(this.apiRoute + productId + '/testimony/new', testimonyModel);
  }
}
