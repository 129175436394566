import * as globals from '../../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';
import { FormControl, FormGroup } from '@angular/forms';

import { AuthService } from 'src/app/services/auth.service';
import { CoursesService } from '../../../../services/courses.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilsService } from '../../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-sponsor-details',
  templateUrl: './sponsor-details.component.html',
  styleUrls: ['./sponsor-details.component.scss']
})
export class SponsorDetailsComponent implements OnInit {

  uploaderFeatured: FileUploader;
  model_edit: any = {};
  featured_img: any = {};
  sponsorId;
  imgDate: any;
  sponsor: any = {};
  siteUrl = globals.site_base_url;
  permissions: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private coursesService: CoursesService,
    private utilsService: UtilsService,
    private spinner: NgxSpinnerService,
    public sanitizer: DomSanitizer,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('CoursesCreate');
    this.permissions.edit = this.auth.hasPermissions('CoursesEdit');
    this.permissions.delete = this.auth.hasPermissions('CoursesDelete');
    this.permissions.read = this.auth.hasPermissions('CoursesRead');
    if (this.permissions.read) {
      this.defineUploaderFeatured();
      this.getUrlParams();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.sponsorId = params.sponsorId;
      this.getSponsorById();
    });
  }

  getSponsorById() {
    this.coursesService.getSponsorById(this.sponsorId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        if (res) {
          this.model_edit = res as any;
          this.sponsor = this.model_edit;
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  defineUploaderFeatured() {
    this.uploaderFeatured = new FileUploader({
      url: globals.base_api_path + 'files/upload/sponsor/logo',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderFeatured.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.model_edit.id = JSON.parse(response).sponsor_id;
      this.model_edit.featured_img = JSON.parse(response).url;
      this.imgDate = Date.now();
      this.coursesService.updateSponsor(this.sponsorId, this.model_edit)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Sponsor atualizado');
          } else if (!aux.success) {
            this.utilsService.sendSystemErrorAlert();
          }
        });
      this.defineUploaderFeatured();
    };
    this.uploaderFeatured._onErrorItem = (err) => {
      //console.log(err);
      this.uploaderFeatured.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderFeatured();
    };
  }

  uploadFeaturedImage() {
    this.uploaderFeatured.queue.forEach((img, index) => {
      img._onBuildForm = (form) => {
        form.append('id', this.sponsorId);
      };
      img.upload();
    });
  }

  showFeaturedImage() {
    if (this.uploaderFeatured.queue.length > 1) {
      this.uploaderFeatured.queue.splice(0, 1);
    }
    this.uploaderFeatured.queue.forEach(foto => {
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      this.featured_img.url = url;
      this.featured_img.foto = foto;
    });
  }

  cancelEditSponsor() {
    this.model_edit = this.sponsor;
  }

  updateSponsor() {
    this.coursesService.updateSponsor(this.sponsorId, this.model_edit)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          this.utilsService.sendNotification('success', 'Sponsor atualizado');
          this.uploadFeaturedImage();
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  deleteSponsor() {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja elimiar o sponsor?', 'Este processo é irreversível').then(confirm => {
      if (confirm) {
        this.coursesService.deleteSponsor(this.sponsorId)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            this.utilsService.sendNotification('success', 'Sponsor eliminado');
            this.goBack();
          });
      }
    });
  }

  goBack() {
    const aux = this.router.url.split('/');
    aux.length -= 2;
    const backLink = aux.join('/');
    this.router.navigate([backLink]);
  }

}
