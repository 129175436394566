import * as globals from '../../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, Host, Inject, OnInit } from '@angular/core';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';

import { AuthService } from 'src/app/services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorePromotionsComponent } from '../store-promotions.component';
import { StoresService } from '../../../../services/stores.service';
import { UtilsService } from '../../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-promotion-details',
  templateUrl: './promotion-details.component.html',
  styleUrls: ['./promotion-details.component.scss']
})

export class PromotionDetailsComponent implements OnInit {

  model_edit: any = {};
  imgPreview;
  storeId;
  promotionId;
  store;
  addPromo: boolean;
  promotion;
  uploader: FileUploader;
  anexo;
  init_date: any;
  end_date: any;
  siteUrl = globals.site_base_url;
  uploadedImg = false;

  permissions: any = {};

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private storesService: StoresService,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    //this.store = this.parent.getStore();
    this.permissions.create = this.auth.hasPermissions('StoresCreate');
    this.permissions.edit = this.auth.hasPermissions('StoresEdit');
    this.permissions.delete = this.auth.hasPermissions('StoresDelete');
    this.permissions.read = this.auth.hasPermissions('StoresRead');
    if (this.permissions.read) {
      this.getUrlParams();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.promotionId = params['promotionId'];
      this.storeId = params['storeId'];
      this.getPromotion();
    });
  }

  getPromotion() {
    this.storesService.getStorePromotionByPromotionId(this.promotionId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.model_edit = res as any;
        this.model_edit.init_date = this.utilsService.convertDateStringToDate(this.model_edit.init_date);
        this.model_edit.end_date = this.utilsService.convertDateStringToDate(this.model_edit.end_date);
        this.promotion = this.model_edit;
        console.log(this.promotion);
      });
  }

  defineUploader() {
    this.uploader = new FileUploader({
      url: globals.base_api_path + 'ficheiros/upload/promo/foto',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.model_edit.img_url = JSON.parse(response).url;
      this.updatePromo(this.model_edit.id, this.model_edit);
    };
    this.uploader.onErrorItem = (item: any, response: any, status: any, headers: any) => {
      this.utilsService.sendSystemErrorAlert();
      this.defineUploader();
    };
  }

  updatePromo(promoId, modelPromo) {
    this.storesService.updatePromotion(promoId, modelPromo)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          this.utilsService.sendNotification('success', 'Promoção atualizada');
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  uploadImage() {
    this.uploader.queue.forEach((img, index) => {
      img._onBuildForm = (form) => {
        form.append('storeId', this.storeId);
        form.append('storeName', this.utilsService.getUrlFromName(this.store.name));
        form.append('promoId', this.model_edit.id);
      };
      img.upload();
    });
  }

  showImage() {
    this.uploader.queue.forEach(foto => {
      this.imgPreview = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
    });
    this.uploadedImg = true;
  }

  goBack() {
    const aux = this.router.url.split('/');
    aux.length -= 1;
    const backLink = aux.join('/');
    this.router.navigate([backLink]);
  }
}
