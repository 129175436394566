import * as globals from '../../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, Host, Inject, OnInit, TemplateRef } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { DestinationWeddingsService } from '../../../../services/destination-weddings.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploader } from 'ng2-file-upload';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from '../../../../services/utils.service';
import { catchError } from 'rxjs/operators';
@Component({
  selector: 'app-subregion-places',
  templateUrl: './subregion-places.component.html',
  styleUrls: ['./subregion-places.component.scss']
})
export class SubregionPlacesComponent implements OnInit {

  nr_items_page = globals.nr_items_page;
  p; // paginação;
  places = [{}];
  types = [];
  search: any = {};
  createPlaceBool: Boolean;
  model_place: any = {};
  model_edit: any = {};
  error = '';
  pla;
  loading = false;
  subRegionId: any;
  typeId: any;
  modalRef: BsModalRef;

  permissions: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private wdService: DestinationWeddingsService,
    private utilsService: UtilsService,
    private modalService: BsModalService,
    private auth: AuthService,
  ) { }


  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('DestinationWeddingsCreate');
    this.permissions.edit = this.auth.hasPermissions('DestinationWeddingsEdit');
    this.permissions.delete = this.auth.hasPermissions('DestinationWeddingsDelete');
    this.permissions.read = this.auth.hasPermissions('DestinationWeddingsRead');
    if (this.permissions.read) {
      this.getUrlParams();
    }
  }

  openEditPlace(modal: TemplateRef<any>, place) {
    this.model_edit = { ...place };
    this.modalRef = this.modalService.show(modal, {
      class: 'modal-dialog-centered modal-lg'
    });
  }

  closeModal() {
    this.modalRef.hide();
    this.model_edit = {};
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.subRegionId = params.subRegionId;
      if (this.subRegionId) {
        this.getPlacesBySubRegionId();
      } else {
        this.typeId = params.typeId;
        this.getPlacesByTypeId();
      }
      this.getTypes();
    });
  }

  getPlacesBySubRegionId() {
    this.wdService.getPlacesBySubRegionId(this.subRegionId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.places = res as any;
        console.log(this.places);
      });
  }

  getPlacesByTypeId() {
    this.wdService.getPlacesByTypeId(this.typeId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.places = res as any;
        console.log(this.places);
      });
  }

  changeIsVisible(placeId, place) {
    this.wdService.changeIsVisiblePlace(placeId, place)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          if (place.is_visible) {
            this.utilsService.sendNotification('success', 'Lugar visivel');
          } else {
            this.utilsService.sendNotification('success', 'Lugar Não visivel');
          }
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  createPlace() {
    this.model_place.subregion_id = this.subRegionId;
    this.wdService.createPlace(this.model_place)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.createPlaceToogle();
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          this.utilsService.sendNotification('success', 'Lugar Criado');
          this.model_place.name = '';
          this.getPlacesBySubRegionId();
          this.createPlaceToogle();
        } else {
          this.utilsService.sendSystemErrorAlert();
          this.model_place.name = '';
          this.createPlaceToogle();
        }
      });
  }

  editPlace(placeId) {
    this.loading = true;
    this.wdService.updateWDPlace(placeId, this.model_edit)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        let aux: { success: Boolean };
        aux = res as any;
        if (aux.success) {
          this.utilsService.sendNotification('success', 'Dados do local WD atualizados');
          this.loading = false;
          this.getUrlParams();
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  deletePlace(placeId) {
    this.utilsService.sendConfirmationQuestion('Tem a certeza que deseja elimiar o lugar?', 'Este processo é irreversível').then(confirm => {
      if (confirm) {
        this.wdService.deletePlace(placeId)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.utilsService.sendSystemErrorAlert(err);
            }) as any)
          .subscribe(res => {
            const aux = res as any;
            if (aux.success) {
              this.utilsService.sendNotification('success', 'Lugar removido');
              this.getUrlParams();
            } else {
              this.utilsService.sendSystemErrorAlert();
            }
          });
      }
    });
  }

  createPlaceToogle() {
    this.createPlaceBool = !this.createPlaceBool;
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }

  getTypes() {
    this.wdService.getPlacesTypes()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.types = res as any;
        console.log(this.types);
      });
  }
}
