import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, Host, Inject, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { DestinationWeddingsService } from '../../../services/destination-weddings.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploader } from 'ng2-file-upload';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-regions-wd',
  templateUrl: './regions-wd.component.html',
  styleUrls: ['./regions-wd.component.scss']
})

export class RegionsWDComponent implements OnInit {

  nr_items_page = globals.nr_items_page;
  search: any = {};
  p; // paginação;
  regions: any[] = [];
  addRegion: boolean;
  countryId;
  regionId;
  imgDate: any;
  subCategories = [];
  districts = [];
  selectedDistricts = [];
  selectedDistrictsBackup = [];
  loadingDistricts;
  districtsToAdd = [];
  districtsToRemove = [];
  uploaderFeatured: FileUploader;
  deleteVideo: boolean;
  addUrl;
  model: any = {};
  countries: any[];
  featured_img: any = {};
  loading = false;

  permissions: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private wdService: DestinationWeddingsService,
    private utilsService: UtilsService,
    private sanitizer: DomSanitizer,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('DestinationWeddingsCreate');
    this.permissions.edit = this.auth.hasPermissions('DestinationWeddingsEdit');
    this.permissions.delete = this.auth.hasPermissions('DestinationWeddingsDelete');
    this.permissions.read = this.auth.hasPermissions('DestinationWeddingsRead');
    this.addRegion = false;
    if (this.permissions.read) {
      this.getUrlParams();
    }
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.countryId = params['countryId'];
      if (this.countryId) {
        this.getRegionsByCountryId();
        this.getCountries();
        this.getDistricts();
        this.getSubCategories();
        this.defineUploaderFeatured();
      } else {
        this.getAllWDRegions();
      }
    });
  }

  getRegionsByCountryId() {
    this.wdService.getRegionsByCountryId(this.countryId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.regions = res as any;
      });
  }

  getAllWDRegions() {
    this.wdService.getAllWDRegions()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.regions = res as any;
      });
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }

  goToRegion(regionId): void {
    this.router.navigate([globals.baseUrls.destinationWeddings + '/region/' + regionId]);
  }

  goToSubRegionsByRegionId(regionId): void {
    this.router.navigate([globals.baseUrls.destinationWeddings + '/subregions/region/' + regionId]);
  }

  addRegionToogle() {
    this.addRegion = !this.addRegion;
  }

  changeActive(regionId, region) {
    this.wdService.changeActiveRegion(regionId, region)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          if (region.is_visible) {
            this.utilsService.sendNotification('success', 'Região visivel no WD');
          } else {
            this.utilsService.sendNotification('success', 'Região Não visivel no WD');
          }
        } else {
          this.utilsService.sendSystemErrorAlert();
        }
      });
  }

  addDistrict(event) {
    const id = event.id;
    const index = this.districtsToRemove.indexOf(id);
    if (index > -1) {
      this.districtsToRemove.splice(index, 1);
      //console.log(this.subCategoriesToRemove);
    } else {
      this.districtsToAdd.push(id);
      //console.log(this.subCategoriesToAdd);
    }
  }

  removeDistrict(event) {
    const id = event.value.id;
    const index = this.districtsToAdd.indexOf(id);
    if (index > -1) {
      this.districtsToAdd.splice(index, 1);
      //console.log(this.subCategoriesToAdd);
    } else {
      this.districtsToRemove.push(id);
      //console.log(this.subCategoriesToRemove);
    }
  }

  clearDistricts() {
    this.districtsToRemove = this.selectedDistrictsBackup;
  }

  getCountries() {
    this.wdService.getAllWDCountries()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.countries = res as any;
      });
  }

  getDistricts() {
    this.utilsService.getDistricts()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.districts = res as any;
        console.log(this.districts);
        this.loadingDistricts = false;
      });
  }

  getSubCategories() {
    this.utilsService.getBlogSubCategories()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.subCategories = res as any;
      });
  }

  defineUploaderFeatured() {
    this.uploaderFeatured = new FileUploader({
      url: globals.base_api_path + 'files/upload/regionWD/featured',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderFeatured.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.model.id = JSON.parse(response).region_id;
      this.model.featured_img = JSON.parse(response).url;
      this.imgDate = Date.now();
      //console.log(this.model.id);
      this.wdService.updateWDRegion(this.regionId, this.model)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Dados da Região WD atualizados (imagem atribuida)');
            this.addUrl = '';
            this.loading = false;
            this.deleteVideo = false;
          } else if (!aux.success) {
            this.utilsService.sendSystemErrorAlert();
          }
        });
      this.defineUploaderFeatured();
    };
    this.uploaderFeatured._onErrorItem = (err) => {
      //console.log(err);
      this.uploaderFeatured.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderFeatured();
    };
  }

  uploadFeaturedImage() {
    this.uploaderFeatured.queue.forEach(img => {
      img._onBuildForm = (form) => {
        form.append('countryName', this.utilsService.getUrlFromName(this.countries[this.countryId - 1].name));
        form.append('regionName', this.utilsService.getUrlFromName(this.model.name));
      };
      img.upload();
    });
    this.uploaderFeatured.uploadAll();
  }

  showFeaturedImage() {
    if (this.uploaderFeatured.queue.length > 1) {
      this.uploaderFeatured.queue.splice(0, 1);
    }
    this.uploaderFeatured.queue.forEach(foto => {
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      this.featured_img.url = url;
      this.featured_img.foto = foto;
    });
  }

  createRegion() {
    this.model.video_url = this.addUrl;
    this.model.wdcountry_id = this.countryId;
    this.model.districtsToAdd = this.districtsToAdd;
    this.model.districtsToRemove = this.districtsToRemove;
    this.loading = true;
    this.wdService.createRegionWD(this.model)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          this.model.id = aux.id;
          this.regionId = aux.id;
          this.uploadFeaturedImage();
          this.utilsService.sendNotification('success', 'Região do WD criado');
        }
      });
  }

}
