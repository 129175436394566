import * as globals from '../../assets/globals';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementsService {

  constructor(private http: HttpClient) { }

  apiRoute = globals.base_api_path + 'announcements/';

  getAllAnnouncements(): Observable<any> {
    return this.http.get(this.apiRoute);
  }

  updateAnnouncement(announcement): Observable<any> {
    return this.http.put(this.apiRoute + announcement.id, announcement);
  }

  updateAnnouncementAttachment(announcement): Observable<any> {
    return this.http.put(this.apiRoute + announcement.id + "/attachment", announcement);
  }

  deleteAnnouncement(announcementId): Observable<any> {
    return this.http.delete(this.apiRoute + announcementId);
  }
}
