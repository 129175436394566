import * as globals from '../../assets/globals';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SweepstakesService {

  constructor(private http: HttpClient) { }

  apiRoute = globals.base_api_path + 'sweepstakes/';


  getAllSweepstakes(): Observable<any> {
    return this.http.get(this.apiRoute);
  }

  getSweepstakeById(sweepstakeId): Observable<any> {
    return this.http.get(this.apiRoute + 'sweepstake/' + sweepstakeId);
  }

  getSweepstakeSponsors(sweepstakeId): Observable<any> {
    return this.http.get(this.apiRoute + 'sweepstake/' + sweepstakeId + '/sponsors');
  }

  getSweepstakePrizes(sweepstakeId): Observable<any> {
    return this.http.get(this.apiRoute + 'sweepstake/' + sweepstakeId + '/prizes');
  }

  createSweepstake(sweepstake): Observable<any> {
    return this.http.post(this.apiRoute + 'create/sweepstake', sweepstake);
  }

  deleteSweepstake(sweepstakeId): Observable<any> {
    return this.http.put(this.apiRoute + 'delete/sweepstake/' + sweepstakeId, null);
  }

  updateSweepstake(sweepstakeId, sweepstake): Observable<any> {
    return this.http.put(this.apiRoute + 'update/sweepstake/' + sweepstakeId, sweepstake);
  }

  changeIsVisible(sweepstakeId, model): Observable<any> {
    return this.http.put(this.apiRoute + sweepstakeId + '/isvisible', model);
  }

  getParticipantsBySweepstakeId(sweepstakeId): Observable<any> {
    return this.http.get(this.apiRoute + 'participants/' + sweepstakeId);
  }

  changeAccepted(participantId, model): Observable<any> {
    return this.http.put(this.apiRoute + 'participant/' + participantId + '/accepted', model);
  }

  updateSweepstakePrizes(id, body): Observable<any> {
    return this.http.put(this.apiRoute + 'update/prizes/' + id, body);
  }
}
