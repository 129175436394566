import * as globals from '../../../../assets/globals';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';
import { FormControl, FormGroup } from '@angular/forms';

import { AuthService } from 'src/app/services/auth.service';
import { CoursesService } from '../../../services/courses.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';
@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.scss']
})
export class SponsorsComponent implements OnInit {

  uploaderFeatured: FileUploader;
  model_create: any = {};
  featured_img: any = {};
  sponsorId;
  imgDate: any;
  createSponsorBool: boolean;

  nr_items_page = globals.nr_items_page;
  p; // paginação;
  sponsors;
  search: any = {};

  permissions: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private coursesService: CoursesService,
    private utilsService: UtilsService,
    public sanitizer: DomSanitizer,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.permissions.create = this.auth.hasPermissions('CoursesCreate');
    this.permissions.edit = this.auth.hasPermissions('CoursesEdit');
    this.permissions.delete = this.auth.hasPermissions('CoursesDelete');
    this.permissions.read = this.auth.hasPermissions('CoursesRead');
    this.createSponsorBool = false;
    this.model_create = {};
    if (this.permissions.read) {
      this.defineUploaderFeatured();
      this.getAllSponsors();
    }
  }

  getAllSponsors() {
    this.coursesService.getAllSponsors()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        this.sponsors = res as any;
      });
  }

  onPageChange(page: number) {
    this.topDiv();
    return page;
  }

  topDiv() {
    document.body.scrollTop = 300; // For Safari
    document.documentElement.scrollTop = 300; // For Chrome, Firefox, IE and Opera
  }

  goToSponsor(sponsorId): void {
    this.router.navigate([globals.baseUrls.courses + '/sponsor/' + sponsorId]);
  }

  addSponsorToogle() {
    this.createSponsorBool = !this.createSponsorBool;
  }

  cancelCreateSponsor() {
    this.addSponsorToogle();
    this.model_create = {};
  }

  defineUploaderFeatured() {
    this.uploaderFeatured = new FileUploader({
      url: globals.base_api_path + 'files/upload/sponsor/logo',
      authToken: sessionStorage.getItem('token'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderFeatured.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.model_create.id = JSON.parse(response).sponsor_id;
      this.model_create.featured_img = JSON.parse(response).url;
      this.imgDate = Date.now();
      this.coursesService.updateSponsor(this.model_create.id, this.model_create)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.utilsService.sendSystemErrorAlert(err);
          }) as any)
        .subscribe(res => {
          const aux = res as any;
          if (aux.success) {
            this.utilsService.sendNotification('success', 'Sponsor atualizado');
          } else if (!aux.success) {
            this.utilsService.sendSystemErrorAlert();
          }
        });
      this.defineUploaderFeatured();
    };
    this.uploaderFeatured._onErrorItem = (err) => {
      //console.log(err);
      this.uploaderFeatured.clearQueue();
      this.utilsService.sendSystemErrorAlert();
      this.defineUploaderFeatured();
    };
  }

  uploadFeaturedImage() {
    this.uploaderFeatured.queue.forEach((img, index) => {
      img._onBuildForm = (form) => {
        form.append('id', this.model_create.id);
      };
      img.upload();
    });
  }

  showFeaturedImage() {
    if (this.uploaderFeatured.queue.length > 1) {
      this.uploaderFeatured.queue.splice(0, 1);
    }
    this.uploaderFeatured.queue.forEach(foto => {
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      this.featured_img.url = url;
      this.featured_img.foto = foto;
    });
  }

  createSponsor() {
    this.coursesService.addSponsor(this.model_create)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.utilsService.sendSystemErrorAlert(err);
        }) as any)
      .subscribe(res => {
        const aux = res as any;
        if (aux.success) {
          this.model_create.id = aux.id;
          this.uploadFeaturedImage();
          this.utilsService.sendNotification('success', 'Sponsor criado');
        }
      });
  }
}
